import Vue from "vue";
import VueRouter from "vue-router";
import AdvancedSettings from "@/views/AdvancedSettings";
import SettingsWoc from "@/views/AdvancedSettings/extra/woc";
import OrderModal from "@/views/OrderModal";
import OrderTab from "@/views/OrderTab";
import OrderUpdate from "@/views/OrderUpdate";
import ServiceTest from "@/views/ServiceTest";
import OrderCopy from "@/views/OrderCopy";
import UpdateContragent from "@/views/UpdateContragent";
import AddDirectoryElement from '@/views/AddDirectoryElement';
import EditDirectoryElement from '@/views/EditDirectoryElement';

import PATHS from "@/router/constants";

Vue.use(VueRouter);

const routes = [
  {
    path: PATHS.ORDER_UPDATE.path,
    name: PATHS.ORDER_UPDATE.name,
    meta: { editableTitle: false },
    component: OrderUpdate,
    beforeEnter: (to, from, next) => {
      next();
    },
  },
  {
    path: PATHS.ADVANCED_SETTINGS.path,
    name: PATHS.ADVANCED_SETTINGS.name,
    meta: { editableTitle: false },
    component: AdvancedSettings,
    beforeEnter: (to, from, next) => {
      next();
    },
  },
  {
    path: PATHS.SETTINGS_WOC.path,
    name: PATHS.SETTINGS_WOC.name,
    meta: { editableTitle: false },
    component: SettingsWoc,
    beforeEnter: (to, from, next) => {
      next();
    },
  },
  {
    path: PATHS.ORDER_MODAL.path,
    name: PATHS.ORDER_MODAL.name,
    meta: { editableTitle: false },
    component: OrderModal,
    beforeEnter: (to, from, next) => {
      next();
    },
  },
  {
    path: PATHS.ORDER_TAB.path,
    name: PATHS.ORDER_TAB.name,
    meta: { editableTitle: false },
    component: OrderTab,
    beforeEnter: (to, from, next) => {
      next();
    },
  },
  {
    path: PATHS.SERVICE_TEST.path,
    name: PATHS.SERVICE_TEST.name,
    meta: { editableTitle: false },
    component: ServiceTest,
    beforeEnter: (to, from, next) => {
      next();
    },
  },
  {
    path: PATHS.ORDER_COPY.path,
    name: PATHS.ORDER_COPY.name,
    meta: { editableTitle: false },
    component: OrderCopy,
    beforeEnter: (to, from, next) => {
      next();
    },
  },
  {
    path: PATHS.UPDATE_CONTRAGENT.path,
    name: PATHS.UPDATE_CONTRAGENT.name,
    meta: { editableTitle: false },
    component: UpdateContragent,
    beforeEnter: (to, from, next) => {
      next();
    },
  },
  {
    path: PATHS.ADD_DIRECTORY_ELEMENT.path,
    name: PATHS.ADD_DIRECTORY_ELEMENT.name,
    meta: { editableTitle: false },
    component: AddDirectoryElement,
    beforeEnter: (to, from, next) => {
      next();
    },
  },
  {
    path: PATHS.EDIT_DIRECTORY_ELEMENT.path,
    name: PATHS.EDIT_DIRECTORY_ELEMENT.name,
    meta: { editableTitle: false },
    component: EditDirectoryElement,
    beforeEnter: (to, from, next) => {
      next();
    },
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
