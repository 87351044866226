<template>
  <div v-if="isActive">
    <div :class="$style.tableRow" v-for="key in count" :key="key">
      <div :class="$style.tableRowcell" data-code="date">
        <div :class="$style.preloaderAnimation"></div>
      </div>
      <div :class="$style.tableRowcell" data-code="method">
        <div :class="$style.preloaderAnimation"></div>
      </div>
      <div :class="$style.tableRowcell" data-code="entity">
        <div :class="$style.preloaderAnimation"></div>
      </div>
      <div :class="$style.tableRowcell" data-code="user">
        <div :class="$style.preloaderAnimation"></div>
      </div>
      <div :class="$style.tableRowcell" data-code="code">
        <div :class="$style.preloaderAnimation"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "preload",
  props: {
    isActive: Boolean
  },
  data: () => {
    return {
      count: [1, 2, 3]
    }
  }
}
</script>

<style lang="scss" module>
@import "./style.scss";
</style>