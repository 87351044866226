<template>
    <div :class="$style.container">
        <div :class="$style.mainInfo">
            <div :class="$style.cell" data-code="article">
                <div :class="$style.preloaderAnimation" style="height: 10px; width: 30px;">&nbsp;</div>
            </div>
            <div v-if="defaults.showCodeColumn" :class="$style.cell" data-code="code">
                <div :class="$style.preloaderAnimation" style="height: 10px; width: 30px;">&nbsp;</div>
            </div>
            <div :class="$style.cell" data-code="show-info">
                <div class="" style="height: 10px; width: 10px;">&nbsp;</div>
            </div>
            <div :class="$style.cell" data-code="name">
                <div :class="$style.preloaderAnimation" :style="`height: 10px; width: ${nameLength}px;`">&nbsp;</div>
            </div>
            <div :class="$style.cell" data-code="count">
                <div :class="$style.preloaderAnimation" style="height: 10px; width: 20px;">&nbsp;</div>
            </div>
            <div :class="$style.cell" data-code="price">
                <div :class="$style.preloaderAnimation" style="height: 10px; width: 40px;">&nbsp;</div>
            </div>
            <div :class="$style.cell" data-code="append-button">
                <div :class="$style.preloaderAnimation" style="height: 10px; width: 30px;">&nbsp;</div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
    props: {
        nameLength: {
            type: Number,
            default: 150,
        }
    },
    computed: {
        ...mapGetters({
            defaults: 'settings/defaults'
        })
    }
}
</script>

<style lang="scss" module>
    @import './SearchResultsItem.scss';
</style>