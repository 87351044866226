/**
 * @author Ilya Orishin. (continue from Artyom Baskakow) 2021
 *
 * Vuex Getters заказа
 */

import moment from "moment";
import numeralWord from "@/utilities/numeralWord";

import { getProductsCount } from "@/utilities/orderProduct";

import { paysGetters } from "@/store/substores/paysDetail";
import { massUseGetters } from "@/store/substores/massUse";
import { orderCopyGetters } from "@/store/substores/orderCopy";
import { orderUpdateGetters } from "@/store/substores/orderUpdate";

const namesWords = ["наименование", "наименования", "наименований"];

export default {
  entity: (state) => ({ entityId: state.currentEntityId, entityType: state.currentEntityType }),
  orderData: (state) => state.data,
  tabMode: (state) => state.tabMode,
  realizations: (state) => state.data.realizations,
  isRunningAction: (state) => (code) => !!state.extraActionRunning?.[code],
  isCreated: (state) => state.isCreated,
  isChanged: (state) => JSON.stringify(state.data) !== JSON.stringify(state.initialData),
  isSaving: (state) => state.isSaving,
  isSaving1c: (state) => state.isSaving1c,
  isSavingEvotor: (state) => state.isSavingEvotor,
  isDiscountLoading: (state) => state.isDiscountLoading,
  isContractsBlocked: (state) => state.isContractsBlocked,
  errorOpenOrder: (state) => state.errorOpenOrder,
  date(state) {
    const date = state.data.order_date ? state.data.order_date.split(" ")[0] : "";
    return date ? new Date(moment(date, "DD.MM.YYYY")) : new Date(moment());
  },
  dateRaw: (state) => state.data.order_date,
  organizationName: (state) => state.data.organization_name,
  organization1cId: (state) => state.data.organization_id,
  organizationData: (state, getters, rootState, rootGetters) => {
    return rootGetters["organizations/getOrganizationById1c"](state.data.organization_id);
  },
  isOrganizationUseNds(state, getters) {
    return getters["organizationData"]?.useNDS;
  },
  organizationId: (state, getters) => {
    return getters["organizationData"]?.id;
  },
  orderId: (state) => state.data.order_id,
  agreementId: (state) => state.data.agreement_id,
  contractId: (state) => state.data.contract_id,
  organizationAccountName: (state) => state.data.organization_account_name,
  organizationAccount1cId: (state) => state.data.organization_account_id,
  organizationAccountId: (state, getters, rootState, rootGetters) => {
    return rootGetters["organizations/getOrganizationAccountById1c"](state.data.organization_account_id)?.id;
  },
  stockId: (state) => state.data.stock_id,
  partnerId: (state) => state.data.partner_id,
  getValue: (state) => (key) => state.data[key],
  getRootValue: (state) => (key) => state[key],
  products: (state) => state.data.products,
  someChecked: (state) => state.checkedIds.length,
  isChecked: (state) => (uuid) => state.checkedIds.includes(uuid),
  orderCurrency: (state) => state.data.currency_code,
  productsQuantity: (state) => `${getProductsCount(state)} ${numeralWord(getProductsCount(state), namesWords)}`,
  totalPrice: (state) => state.data.total_price,
  hasProducts: (state) => state.data.products.length,
  modalTitle: (state) =>
    state.data.order_number && state.data.order_number.length
      ? `Детализация заказа ${state.data.order_number}`
      : "Новый заказ",
  hasOrder1c: (state) => state.data.order_id && state.data.order_id.length,
  ndsTypeText: (state) => {
    switch (+state.data.nds_type) {
      case 1:
        return "В том числе НДС:";
      case 2:
        return "НДС сверху:";
      default:
        return "НДС:";
    }
  },
  isNdsUsed: (state) => +state.data.nds_type != 0,
  totalNds: (state) => state.data.total_nds,
  totalDiscount: (state) => state.data.total_discount,
  totalPriceDirty: (state) => {
    return state.data.products
      .filter((p) => !p?.cancelled)
      .reduce((previousValue, currentValue) => {
        return previousValue + parseFloat(currentValue.price) * parseFloat(currentValue.count);
      }, 0);
  },

  totalAutoDiscount: (state) => state.data.total_auto_discount,

  amoCompany: (state) => state.amoCompany,
  amoContact: (state) => state.amoContact,

  entityData: (state) => {
    return {
      lead_id: state.data.lead_id,
      customer_id: state.data.customer_id,
    };
  },

  leadId: (state) => state.data.lead_id,

  appendCfToLeadPrice: (state) => state.additionalData.appendCfToLeadPrice,
  updateOrderNumber: (state) => state.data.new_date_and_number_on_order_update,
  generalDiscount: (state) => state.generalDiscount,
  selectedProvision: (state) => state.selectedProvision,
  /*
    ↓ Получим массив id сущностей, которых не удалось найти в Amo
    */
  errorLinkedEntities: (state) => state.errorLinkedEntities,

  allCfs: (state) => (Array.isArray(state.data.order_custom_fields) ? state.data.order_custom_fields : []),

  orderCfs(state) {
    return (attribute) => state.data.order_custom_fields.find((val) => val.attribute == attribute)?.value;
  },

  orderDateCfs(state, getters) {
    return (attribute) => new Date(moment(getters.orderCfs(attribute), "DD.MM.YYYY 0:00:00"));
  },

  isCalcRequired(state, getters, rootState, rootGetters) {
    /*
        Проверка, необходим ли пересчет цены
        1. Если массив товаров пуст -> нет необходимости
        2. Если соглашения установлено, получим его ИД цены
        3. Если Ид цены нет -> нет необходимости
        4. Обходим массив товаров, если ИД цены хоть у одного не совпадают -> есть необходимость
        5. По дефолту возвращаем false, если встретился товар где не совпадает ИД цены, возвращаем true
        */
    if (!state.data.products.length) return false;

    let priceTypeId;
    if (state.data.agreement_id) priceTypeId = rootGetters["agreements/getPriceTypeId"](getters.agreementId);

    if (!priceTypeId) return false;

    return state.data.products.every((product) => product.price_type_id != priceTypeId);
  },

  isDisabledStatus(state, getters, rootState, rootGetters) {
    const disabledStatuses = rootGetters["settings/disableWorkOrderStatuses"];
    return !!(disabledStatuses.length && disabledStatuses.includes(state.amoEntity?.status_id));
  },

  sumByCustomField(state) {
    return (field) => {
      return state.data.products
        .filter((p) => p?.cancelled != 1)
        .reduce((acc, item) => {
          const el = item.custom_fields_values.find((cfv) => cfv.attribute == field);
          const value = el ? +el.value : 0;
          return (acc += value);
        }, 0);
    };
  },

  ...massUseGetters,
  ...paysGetters,
  ...orderCopyGetters,
  ...orderUpdateGetters,
};
