import { apiDocflow } from './api';

export const getOrder = (amoEntityId) =>
    apiDocflow.get(`doc-order-details/${amoEntityId}`);

export const saveOrder = (amoEntityId, data) =>
    apiDocflow.put(`doc-order-details/${amoEntityId}`, data);

export const patchOrder = (amoEntityId, data) =>
    apiDocflow.patch(`doc-order-details/${amoEntityId}`, data);
