import {get1cJournalFind, get1cJournalLog, get1cJournalMetaMethod} from "@/api/1c-api/journal";
import moment from "moment";

const defaultFilter = {
  dateRange: [null, null],
  entityId: "",
  method: "",
  code: []
}

const mutationRequest = (v) => {
  v.date = moment(v.created_at).format('DD.MM.YYYY H:mm')
  v.infoActive = false
  v.log = []
  return v
}

const state = () => {
  return {
    preload: false,
    requests: [],
    total: 0,
    offset: 0,
    limit: 20,
    filter: JSON.parse(JSON.stringify(defaultFilter)),
    filterPrev: JSON.parse(JSON.stringify(defaultFilter)),
    collections: {
      dropdownCode: [
        {value: 1, title: 'Обработан'},
        {value: 2, title: 'Ошибка'}
      ],
      metaMethod: []
    }
  }
}

const getters = {
  getRequests: (state) => state.requests,

  getFilterField: (state) => (key) => state.filter[key],

  getFilterItems: (state) => {
    let result = [];
    for(const key in state.filter){
      const value = state.filter[key]
      const item = {}
      if(key === 'dateRange'){
        item.title = `${moment(value[0]).format('DD.MM.YYYY')} - ${moment(value[1]).format('DD.MM.YYYY')}`;
        item.key = key
        item.value = value
        if(value[0] !== null){
          result.push(item)
        }
      } else if(key === 'code') {
        for(const val of value){
          const itemV = {}
          itemV.title = state.collections.dropdownCode.find(v => v.value === val).title;
          itemV.key = key
          itemV.value = val
          result.push(itemV)
        }
      } else {
        item.title = value;
        item.key = key
        item.value = value
        if(value.length !== 0){
          result.push(item)
        }
      }
    }
    return result
  },

  getDropdownCode: (state) => state.collections.dropdownCode,

  getMetaMethod: (state) => state.collections.metaMethod.map(v => ({value: v, title: v})),

  isFilterChanged: (state)  => JSON.stringify(state.filter) !== JSON.stringify(state.filterPrev),

  isPreload: (state) => state.preload,
}

const actions = {
  async load({commit, state}) {
    try {
      const {offset, limit} = state
      const resResult = await get1cJournalFind({...state.filter, ...{offset, limit}})
      commit('setRequests', {data: resResult.data})
      const resMetaMethod = await get1cJournalMetaMethod()
      commit('setMetaMethod', {data: resMetaMethod.data})
    } catch (e){
      console.debug('Ошибка загрузки журнала', {e});
    }
  },

  async initPagination({commit, state}) {
    if (state.total > state.offset) {
      commit('setPreload')
      try{
        const response = await get1cJournalFind({
          ...state.filter, ...{
            offset: state.offset + state.limit,
            limit: state.limit
          }
        })
        commit('setPagination', {data: response.data})
      } catch (e){
        console.debug('Ошибка загрузки', {e})
      }
      commit('setPreload')
    }
  },

  async acceptFilter({commit, state}) {
    const {limit} = state
    commit('setClearRequests')
    commit('setPreload')
    try {
      const response = await get1cJournalFind({...state.filter, ...{offset: 0, limit}})
      commit('setRequests', {data: response.data})
    } catch (e){
      console.debug('Ошибка фильтра', {e})
    }

    commit('setPreload')
  },


  async cancelFilter({commit, state}) {
    commit('setPreload')
    commit('setClearRequests')
    const {limit} = state
    const filter = JSON.parse(JSON.stringify(state.filterPrev))
    commit('setCancelFilter', {filter})
    try {
      const response = await get1cJournalFind({...filter, ...{offset: 0, limit}})
      commit('setRequests', {data: response.data})
    } catch (e){
      console.debug('Ошибка фильтра', {e})
    }
    commit('setCancelFilter', {filter})
    commit('setPreload')
  },

  setFilterField({commit}, payload) {
    commit('setFilterField', payload)
  },

  async actionRequestInfo({commit, state}, {request_id}) {
    const index = state.requests.map(v => v.id).indexOf(String(request_id))
    if (state.requests[index].log.length === 0) {
      try {
        const response = await get1cJournalLog({request_id})
        commit('setRequestLog', {index, result: response.data.result})
      } catch (e){
        console.debug('Ошибка', {e})
      }
    }
    commit('actionRequestInfo', {index})
  },

  async onFilterCancelItem({state, commit}, payload){
    commit('onFilterCancelItem', payload)
    const {limit} = state
    commit('setClearRequests')
    commit('setPreload')
    try {
      const response = await get1cJournalFind({...state.filter, ...{offset: 0, limit}})
      commit('setRequests', {data: response.data})
    } catch (e){
      console.debug('Ошибка', {e})
    }
    commit('setPreload')
  },

  async onFilterReload({state, commit}){
    const {limit, filter} = state
    commit('setClearRequests')
    commit('setPreload')
    try {
      const resResult = await get1cJournalFind({...filter, ...{offset: 0, limit}})
      commit('setRequests', {data: resResult.data})
    } catch (e){
      console.debug('Ошибка', {e})
    }
    commit('setPreload')
  }
}

const mutations = {
  setClearRequests(state){
    state.requests = []
  },

  setRequests: (state, {data}) => {
    state.offset = 0
    state.total = data.total_rows
    state.requests = data.result.map(v => mutationRequest(v))
    state.filterPrev = JSON.parse(JSON.stringify(state.filter))
  },

  setPagination: (state, {data}) => {
    state.offset = data.offset
    const result = data.result.map(v => mutationRequest(v))
    state.requests = [...state.requests, ...result]
  },

  setRequestLog: (state, {index, result}) => {
    state.requests[index].log = result.map(v => {
      v.time = moment(v.time).format('DD.MM.YYYY H:mm')
      return v
    })
  },

  setCancelFilter(state, {filter}) {
    state.filter = filter
  },

  setFilterField: (state, payload) => {
    state.filter[payload.key] = payload.value
  },

  actionRequestInfo(state, {index}) {
    state.requests[index].infoActive = !state.requests[index].infoActive
  },

  setMetaMethod(state, {data}){
    state.collections.metaMethod = data.result
  },

  setPreload(state){
    state.preload = !state.preload
  },

  onFilterCancelItem(state, payload){
    if(payload.key === 'dateRange') {
      state.filter[payload.key] = [null, null]
    } else if(payload.key === 'code') {
      state.filter[payload.key] = state.filter[payload.key].filter(v => v !== payload.value)
    } else {
      state.filter[payload.key] = ""
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};