import { apiDocflow } from './api';

export const getDocuments = (entityId, params) =>
        apiDocflow.get(`docs/${entityId}`, {
        params,
    });

export const createDocument = (entityId, data) =>
    apiDocflow.post(`docs/${entityId}`, data);

export const getDocumentById = (entityId, id) =>
    apiDocflow.get(`docs/${entityId}/${id}`);

export const updateDocumentById = (entityId, id, data) =>
    apiDocflow.put(`docs/${entityId}/${id}`, data);

export const removeDocumentById = (entityId, id) =>
    apiDocflow.delete(`docs/${entityId}/${id}`);
