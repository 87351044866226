/**
 * @author Ilya Orishin. (continue from Artyom Baskakow) 2021
 *
 * Vuex State заказа
 */

import { massUseState } from "@/store/substores/massUse";
import { orderCopyState } from "@/store/substores/orderCopy";
import { orderUpdateState } from "@/store/substores/orderUpdate";

export const orderData = {
  realizations: [],
  contract_id: "",
  agreement_id: "",
  partner_id: "",
  contragent_id: "",
  lead_id: "",
  customer_id: "",
  lead_link: "", // +
  lead_name: "", // +
  pipeline_id: "",
  pipeline_name: "",
  stage_id: "", // id этапа воронки
  stage_name: "", // название этапа воронки

  currency_code: 643,

  order_id: "", // заполняем после ответа из 1С
  order_link: "",
  order_number: "", // заполняем после ответа из 1С
  order_number_short: "", // заполняем после ответа из 1С
  order_date: "", // заполняем после ответа из 1С
  order_ref: "", // заполняем после ответа из 1С

  organization_id: "", //
  organization_name: "", //
  organization_account_id: "", //
  organization_account_name: "", //
  stock_id: "", //
  stock_name: "", //

  is_company: "", // если есть связанная компания, то 1 (юр лицо), если нет, то 0 (физ лицо)
  is_deleted: "",

  company_id: "", //  берем актуальные данные из амо каждый раз
  company_name: "", //
  company_responsible_user_id: "",
  company_short_name: "", // стандарное name у сущности
  company_inn: "", //
  company_kpp: "", //
  company_ogrn: "", //
  company_email: "", //
  company_phone: "", //
  company_physical_address: "", //
  company_registered_address: "", //
  company_postal_address: "", //
  company_web: "", //  стандарное web у сущности
  company_account: "", //
  company_custom_fields: [], // [{name: '', amo_cf_id: '', value: ''}]
  company_1c_code: "",

  contact_id: "", //
  contact_responsible_user_id: "",
  contact_name: "",
  contact_short_name: "", // стандарное name у сущности
  contact_phone: "",
  contact_address: "",
  contact_email: "",
  contact_custom_fields: [], // [{name: '', amo_cf_id: '', value: ''}]
  contact_1c_code: "",

  contacts: [],

  bank_bik: "",
  bank_name: "",
  bank_ks: "",

  current_user_id: "",
  current_user_name: "",
  current_user_phone: "",
  current_user_email: "",

  manager_name: "", // в настройках  "Менеджер": "Ответственный по сделке" (0), "Текущий в системе" (1)
  manager_group_name: "",
  manager_email: "",
  manager_phone: "",

  signer_fio: "", // пока оставляем пустые
  signer_document: "", // пока оставляем пустые
  signer_position: "", // пока оставляем пустые

  total_price: "",
  total_discount: "",
  total_auto_discount: "",
  nds_type: "",
  total_nds: "",
  products: [], // массив позиций заказа
  order_custom_fields: [], // [ { type, name, value, code }, ... ]  code - пустой для примитивов

  order_description: "",

  // Флаг Для обновления даты
  new_date_and_number_on_order_update: false,

  pay_calendar: [],
};

export const initialState = () => ({
  isCreated: false,
  currentEntityId: "",
  currentEntityType: 2,
  errorOpenOrder: "", // текст ошибки, если есть условия, при которых нельзя создать заказ
  isChanged: false, // флаг изменен ли заказ
  isSaving: false, // идет процесс сохранения
  isSaving1c: false, // идет процесс сохранения
  isSavingEvotor: false,
  isDiscountLoading: false,
  isOrganizationUseNds: false, // использует ли организация НДС
  isContractsBlocked: false,
  tabMode: "0",

  errorLinkedEntities: [],

  extraActionRunning: {},

  amoEntity: null, // основная сущность, к которой привязан заказ (Сдекла или Покупатель)
  amoCompany: null, // ссылка на объект амо, прикрепленный к сделке/покупателю
  amoContact: null, // ссылка на объект амо, прикрепленный к сделке/покупателю

  initialData: {}, // объект, дублирующий начальное состояние заказа для отслеживания изменений
  data: orderData,
  additionalData: {
    // дополнительные данные для модального окна заказа и для расчетов
    appendCfToLeadPrice: {}, // {sum: 700, cfs: [{name, value}, {name, value}, ...], }
  },

  pay_calendar_initinal: [], //[{date: '', sum: '', pay_type: ''}],

  params: {}, // объект параметров, пока параметр один, но вдруг в будущем будут еще

  showContract: true,

  disableModalClose: false,

  ...massUseState,
  ...orderCopyState,
  ...orderUpdateState,
});

export const state = initialState();
