<template>
  <GnzsSection
    :disabled="!tariffFeatures.includes('order-iframe')"
    :disabledText="disabledProSectionText"
    header="1C iFrame">
    <template v-slot:description>
      При включении данной настройки вместо модального окна детализации заказа будет открываться iframe в 1С. Функцилнал
      устарел, рекомендуется перейти на новую версию 1C Iframe v2
    </template>
    <template v-slot:header-control>
      <GnzsSwitcher v-model="iframe.use" @input="onUseInput($event)"></GnzsSwitcher>
    </template>
    <div v-if="iframe.use" :class="w.table">
      <div :class="w.tableRow">
        <div>Логин для входа в 1С</div>
        <div>
          <div :class="w.inputWrapper">
            <GnzsInput :class="[w.input, w.w400]" v-model="iframe.login" />
          </div>
        </div>
      </div>
      <div :class="w.tableRow">
        <div>Пароль для входа в 1С</div>
        <div>
          <div :class="w.inputWrapper">
            <GnzsInput :class="[w.input, w.w400]" type="password" v-model="iframe.password" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="!isInsideAmo && tariffFeatures.includes('order-iframe')" :class="w.rIc">
      <GnzsHelper side="left" type="service" :value="helpers.serviceUseOnly" />
    </div>
  </GnzsSection>
</template>

<script>
  import { mapGetters } from "vuex";

  import GnzsSection from "@/gnzs-controls/Section";
  import GnzsInput from "@/gnzs-controls/Input";
  import GnzsSwitcher from "@/gnzs-controls/Switcher";
  import GnzsHelper from "@/gnzs-controls/Helper";

  export default {
    computed: {
      ...mapGetters({
        iframe: "settings/iframe",
        isInsideAmo: "isInsideAmo",
        tariffFeatures: "features/getFeatures",
        disabledProSectionText: "localSettings/disabledProSectionText",
        helpers: "localSettings/helpers",
      }),
    },
    methods: {
      onUseInput(value) {
        if (value) this.$set(this.iframe.v2, "use", false);
      },
    },
    components: {
      GnzsSection,
      GnzsInput,
      GnzsSwitcher,
      GnzsHelper,
    },
  };
</script>

<style lang="scss" module="w">
  @import "@/assets/scss/Settings/style.scss";
</style>
