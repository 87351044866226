<template>
    <GnzsSection 
        header='Использовать функционал "Товары"'
        >
        <template v-slot:description>
            Если включено, то будет использоваться функционал товаров АМО.
        </template>
        <template v-slot:header-control>
            <GnzsSwitcher
                v-model="defaults.isProductsEnabled"
            ></GnzsSwitcher>
        </template>
    </GnzsSection>
</template>

<script>
import { mapGetters } from "vuex";

import GnzsSection from "@/gnzs-controls/Section";
import GnzsSwitcher from "@/gnzs-controls/Switcher";

export default {
    computed: {
        ...mapGetters({
            defaults: "settings/defaults",
        }),
    },
    components: {
        GnzsSection,
        GnzsSwitcher,
    },
};
</script>

<style lang="scss" module="w">
    @import '@/assets/scss/Settings/style.scss';
</style>
