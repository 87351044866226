<template>
  <div>
    <GnzsSection v-for="(section, index) in sections" :key="index" :header="section.header">
      <template v-slot:description>
        {{ section.description }}
      </template>

      <div :class="w.table">
        <div :class="w.tableHeader">
          <div>Поле в 1С</div>
          <div></div>
          <div><span>Поле в </span><span :class="w.amocrmTitle">amoCRM</span></div>
          <div>Обязательное</div>
          <div :class="w.taCenter">Не обновлять</div>
        </div>

        <!-- Фиксированные поля сущности -->
        <div :class="w.tableRow" v-for="(row, j) in section.rows" :key="j">
          <div>{{ row.title }}</div>
          <div v-if="section.entity != 'lead'">
            <GnzsRelation
              :class="w.icon"
              :tooltips="tooltips"
              :reverse="true"
              :value="getSettingsEntityFieldSync(section.entity, row.field)"
              @input="setSettingsEntityFieldSync(section.entity, row.field, $event)" />
          </div>
          <div v-else></div>
          <div>
            <GnzsDropdown
              :class="w.w200"
              :value="getSettingsEntityFieldId(section.entity, row.field)"
              @input="setSettingsEntityFieldId(section.entity, row.field, $event)"
              :items="getAmoEntityCfs(section.entity, row.types)"
              :isSearchable="true"
              :emptyItemTitle="row.emptyItemTitle ? row.emptyItemTitle : 'Выбрать'"></GnzsDropdown>
          </div>
          <div :class="$style.cellRequired">
            <div :class="[w.cellInner, $style.checkboxRequireContainer]">
              <GnzsCheckbox
                :value="getSettingsEntityFieldRequired(section.entity, row.field)"
                @input="setSettingsEntityFieldRequired(section.entity, row.field, $event)" />
            </div>
          </div>
          <div :class="[w.w150, $style.cellRequired]">
            <div :class="[w.cellInner, $style.checkboxRequireContainer]">
              <GnzsCheckbox
                :value="getSettingsEntityFieldUnupdatable(section.entity, row.field)"
                @input="setSettingsEntityFieldUnupdatable(section.entity, row.field, $event)" />
            </div>
          </div>
        </div>

        <!-- Дополнительные поля сущности -->
        <template v-if="tariffFeatures.includes(section.feature_code)">
          <div :class="w.tableRow" v-for="(rowAdd, i) in getSettingsEntityCfs(section.entity)" :key="`_${i}`">
            <div>
              <GnzsInput
                :value="rowAdd.name"
                :class="[rowAdd.name.length ? '' : $style.inputInvalid]"
                placeholder="Название в 1С"
                @input="setSettingsEntityCfs(section.entity, i, { name: $event })" />
            </div>
            <div>
              <GnzsRelation
                :class="w.icon"
                :reverse="true"
                :tooltips="tooltips"
                :value="rowAdd.sync"
                @input="setSettingsEntityCfs(section.entity, i, { sync: $event })" />
            </div>
            <div>
              <GnzsDropdown
                :class="w.w200"
                :value="rowAdd.amo_cf_id"
                @input="setSettingsEntityCfs(section.entity, i, { cfId: $event })"
                :items="getAmoEntityCfs(section.entity)"
                :isSearchable="true"></GnzsDropdown>
            </div>
            <div :class="$style.cellRequired">
              <div :class="[w.cellInner, $style.checkboxRequireContainer]">
                <GnzsCheckbox
                  :value="rowAdd.required"
                  @input="setSettingsEntityCfs(section.entity, i, { required: $event })" />
              </div>
            </div>
            <div :class="[w.w150, $style.cellRequired]">
              <div :class="[w.cellInner, $style.checkboxRequireContainer]">
                <GnzsCheckbox
                  :value="rowAdd.unupdatable"
                  @input="setSettingsEntityCfs(section.entity, i, { unupdatable: $event })" />
              </div>
            </div>
            <div>
              <GnzsButton type="remove" @click="removeCfRow(section.entity, i)" />
            </div>
          </div>
        </template>
      </div>

      <div v-if="tariffFeatures.includes(section.feature_code)" :class="$style.appendButtonContainer">
        <GnzsButton type="append" @click="appendCfRow(section.entity)">Добавить поле</GnzsButton>
      </div>
    </GnzsSection>
  </div>
</template>

<script>
  import GnzsSection from "@/gnzs-controls/Section";
  import GnzsInput from "@/gnzs-controls/Input";
  import GnzsDropdown from "@/gnzs-controls/Dropdown";
  import GnzsCheckbox from "@/gnzs-controls/Checkbox";
  import GnzsButton from "@/gnzs-controls/Button";
  import GnzsRelation from "@/gnzs-controls/Relation";

  export default {
    data() {
      return {
        tooltips: {
          to: "Из amoCRM в 1С",
          from: "Из 1С в aмоCRM",
          fromTo: "Двухсторонний обмен",
        },
        sections: [
          {
            header: "Поля компании",
            description:
              'Сопоставьте поля сущности "Компания", которые будут передаваться между 1С и amoCRM. Обратите внимание, что для корректной работы пользовательских полей требуется доработка на стороне 1С',
            entity: "company",
            rows: [
              { title: "Наименование", field: "company_name", sync: 0 },
              { title: "ИНН", field: "company_inn", sync: 0 },
              { title: "КПП", field: "company_kpp", sync: 0 },
              { title: "ОГРН", field: "company_ogrn", sync: 0 },
              { title: "Email", field: "company_email", sync: 0 },
              { title: "Телефон", field: "company_phone", sync: 0 },
              { title: "Расчетный счет", field: "company_account", sync: 0 },
              { title: "Физический адрес", field: "company_physical_address", sync: 0 },
              { title: "Юридический адрес", field: "company_registered_address", sync: 0 },
              { title: "Почтовый адрес", field: "company_postal_address", sync: 0 },
              { title: "Наименование банка", field: "bank_name", sync: 0 },
              { title: "БИК банка", field: "bank_bik", sync: 0 },
              { title: "Корр. счет банка", field: "bank_ks", sync: 0 },
              { title: "Налоговый номер", field: "company_tax_number", sync: 0 },
              { title: "Код контрагента", field: "company_1c_code", sync: 0 },
            ],
            feature_code: "company-custom-fields",
          },
          {
            header: "Поля контакта",
            description:
              'Сопоставьте поля сущности "Контакт", которые будут передаваться между 1С и amoCRM.  Обратите внимание, что для корректной работы пользовательских полей требуется доработка на стороне 1С',
            entity: "contact",
            rows: [
              { title: "ФИО", field: "contact_name", sync: 0 },
              { title: "Номер телефона", field: "contact_phone", sync: 0 },
              { title: "Email", field: "contact_email", sync: 0 },
              { title: "Адрес регистрации", field: "contact_address", sync: 0 },
              { title: "Код контрагента", field: "contact_1c_code", sync: 0 },
            ],
            feature_code: "contact-custom-fields",
          },
          {
            header: "Поля сделки",
            description:
              'Сопоставьте поля сущности "Сделка", которые будут передаваться между 1С и amoCRM.  Обратите внимание, что для корректной работы пользовательских полей требуется доработка на стороне 1С',
            entity: "lead",
            rows: [
              { title: "Сумма заказа", field: "order_sum", types: ["text", "numeric"], emptyItemTitle: "Бюджет" },
              { title: "Номер заказа", field: "order_number", types: ["text"] },
              { title: "Номер заказа (короткий)", field: "order_number_short", types: ["text", "numeric"] },
              { title: "Дата заказа", field: "order_date", types: ["date"] },
              { title: "Реализация создана", field: "realization_created", types: ["checkbox"] },
              { title: "Дата проведения реализации", field: "realization_date", types: ["date"] },
              { title: "Номер документа реализации", field: "realization_number", types: ["text"] },
            ],
            feature_code: "lead-custom-fields",
          },
        ],
      };
    },
    created() {
      if (!this.tariffFeatures.includes("pays-1с-to-amo")) {
        let leadData = this.sections.find((section) => section.entity == "lead");
        leadData.rows = leadData.rows.filter((row) => {
          return row.field != "payment_sum" && row.field != "payment_flag" && row.field != "partial_payment_flag";
        });
      }
    },
    computed: {
      amoCustomFieldsByTypes() {
        return this.$store.getters["amo/customFieldsByTypes"];
      },
      customFieldsByIds() {
        return this.$store.getters["amo/customFieldsByIds"];
      },
      tariffFeatures() {
        return this.$store.getters["features/getFeatures"];
      },
    },
    methods: {
      getAmoEntityCfs(entity, types = ["all"]) {
        return types.reduce((cfs, t) => [...cfs, ...this.amoCustomFieldsByTypes[entity][t]], []);
      },

      // получение/обновление
      getSettingsEntityFieldId(entity, field) {
        return this.$store.getters["settings/entityFieldId"]({ entity, field });
      },
      setSettingsEntityFieldId(entity, field, id) {
        const type = Object.values(this.customFieldsByIds[entity]).find((cf) => cf.id == id)?.field_type;
        this.$store.dispatch("settings/setOrderFieldId", { entity, field, type, id });
      },

      getSettingsEntityFieldSync(entity, field) {
        return this.$store.getters["settings/entityFieldSync"]({ entity, field });
      },
      setSettingsEntityFieldSync(entity, field, sync) {
        this.$store.dispatch("settings/setOrderFieldSync", { entity, field, sync });
      },

      getSettingsEntityFieldRequired(entity, field) {
        return this.$store.getters["settings/entityFieldRequired"]({ entity, field });
      },
      getSettingsEntityFieldUnupdatable(entity, field) {
        return this.$store.getters["settings/entityFieldUnupdatable"]({ entity, field });
      },
      setSettingsEntityFieldRequired(entity, field, required) {
        this.$store.dispatch("settings/setOrderFieldRequired", { entity, field, required });
      },
      setSettingsEntityFieldUnupdatable(entity, field, unupdatable) {
        this.$store.dispatch("settings/setOrderFieldUnupdatable", { entity, field, unupdatable });
      },

      getSettingsEntityCfs(entity) {
        return this.$store.getters["settings/entityCustomFields"](entity);
      },
      setSettingsEntityCfs(entity, index, { name, cfId, sync, required, unupdatable }) {
        const type = Object.values(this.customFieldsByIds[entity]).find((cf) => cf.id == cfId)?.field_type;
        this.$store.dispatch("settings/setOrderFieldEntityCf", {
          entity,
          index,
          name,
          cfId,
          sync,
          type,
          required,
          unupdatable,
        });
      },

      appendCfRow(entity) {
        this.$store.dispatch("settings/addEntityCf", entity);
      },
      removeCfRow(entity, index) {
        this.$store.dispatch("settings/removeEntityCf", { entity, index });
      },
    },
    components: {
      GnzsSection,
      GnzsInput,
      GnzsDropdown,
      GnzsCheckbox,
      GnzsButton,
      GnzsRelation,
    },
  };
</script>

<style lang="scss" module>
  @import "./style.scss";
</style>

<style lang="scss" module="w">
  @import "@/assets/scss/Settings/style.scss";
</style>
