
/**
 * @author Ilya Orishin. 2021
 * 
 * Утилиты соглашений
 * 
 * Функция позволяет разбить массив на группы по условиям
 * 
 * @param {Object} conditions - условия разбиения
 * @param {Array} items - массив который нужно разбить
 * @param {getter} priceName - имя типа цены
 * @returns {Array} result - [[by cond-n 1],[by cond-n 2]]
 */ 
export const beatArrayByConditions = (conditions, items, priceName) => {
	let result = []
	
	Object.entries(conditions).forEach( ([k,v]) => {
		let element = {}
		element.value = k
		element.title = k
		element.items = items.filter(v).map(i => ({
			value: i.id,
			title: i.name,
			info: priceName(i.price_type_id)?.name || ''
		}))
		result.push(element)
	})

	return result
}