/**
 * @author Ilya Orishin. 2021
 *
 * Справочники
 */

import { load1ctypes, load1ctypesItems, createElement, editElement } from "@/api/1c-api";
import setDirectoryRelations from "@/utilities/setDirectoryRelations";

import Vue from "vue";
import moment from "moment";

/**
 * items - Список справочников из additionalDirectory [{code, type, name}]
 * searchedItems: Найденные справочники
 * itemsData: Данные справочников [{code, type, id, name, additional_fields}]
 * isFull: Полон ли список справочников
 * isLoding: Загружается ли сейчас список справочников
 * isCreating: Создаётся ли сейчас элемент
 * isSaving: Сохраняется ли сейчас элемент
 * offset: оффсет
 * limit: лимит
 * total: всего
 * new: Новый элемент справочника
 * item2add: {code, type, ...} - справочник для добавления
 * item2edit: {code, type, id, ...} - элемент для редактирования
 * item2editInitial - элемент для редактирования исходный
 * isAddModalLoaded - Окно добавления загружено
 * isEditModalLoaded - Окно редактирвоания загружено
 * errorOpenModal - ошибка открытия окна
 */

const state = () => ({
  items: [],
  searchedItems: [],
  itemsData: [],
  isFull: false,
  isLoading: false,
  isCreating: false,
  isSaving: false,
  offset: 0,
  limit: 50,
  total: 0,
  new: null,
  item2add: null,
  item2edit: null,
  item2editInitial: null,
  isAddModalLoaded: false,
  isEditModalLoaded: false,
  errorOpenModal: null,
});

const getters = {
  items: (state) => state.items,
  byType: (state) => (type) => state.items.filter((val) => val.type == type),
  isTypesLoading: (state) => state.isLoading,
  isTypesFull: (state) => state.isFull,
  isCreating: (state) => state.isCreating,
  isSaving: (state) => state.isSaving,
  total: (state) => state.total,
  errorOpenModal: (state) => state.errorOpenModal,

  creatingRules(state) {
    return ({ code, type }) =>
      state.items.find((v) => v.code == code && v.type == type)?.json?.creating_required_fields;
  },
  isItemDataFull(state) {
    return ({ code, type }) => state.itemsData.find((v) => v.code == code && v.type == type)?.isFull;
  },
  isItemDataLoading(state) {
    return ({ code, type }) => state.itemsData.find((v) => v.code == code && v.type == type)?.isLoading;
  },
  itemData(state) {
    /*
		Элементы справочника
		*/
    return ({ code, type }) =>
      state.itemsData
        .find((v) => v.code == code && v.type == type)
        ?.data?.map((v) => ({
          title: v.name,
          value: v.id,
        }));
  },
  itemDataName(state) {
    return ({ code, type, id }) =>
      state.itemsData.find((v) => v.code == code && v.type == type)?.data?.find((v) => v.id == id).name;
  },
  itemDataTotal(state) {
    /*
		Количество записей в элементе справочника
		*/
    return ({ code, type }) => state.itemsData.find((v) => v.code == code && v.type == type)?.total;
  },
  itemDataSearched(state) {
    /*
		Результаты поиска по элементу справочника
		*/
    return ({ code, type }) =>
      state.itemsData
        .find((v) => v.code == code && v.type == type)
        ?.searched?.map((v) => ({
          title: v.name,
          value: v.id,
        }));
  },
  dropDownItems(state) {
    /*
		Список справочников для dropdown
		*/
    return state.items.map((v) => ({
      title: `${v.type}: ${v.name}`,
      value: { code: v.code, type: v.type },
    }));
  },
  newItemRules(state) {
    /*
		Правила создания элемента справочника (требуемые поля)
		*/
    return (item) => {
      if (!item) return [];
      const rules = state.items.find((v) => v.code == item.code && v.type == item.type)?.json?.creation_required_fields;
      return rules ? rules : [];
    };
  },
  newItemValue(state) {
    return (name) =>
      state.new.cfs.find((crf) => crf.name == name)?.value ? state.new.cfs.find((crf) => crf.name == name).value : "";
  },
  newItemTableValue(state) {
    return (table, name) => {
      const attr = state.new.cfs.find((crf) => crf.name == table);
      const item = attr.columns.find((v) => v.name == name);
      return item?.value ? item.value : "";
    };
  },

  item2add: (state) => state.item2add,
  item2edit: (state) => {
    const items = state.itemsData.find((v) => v.code == state.item2edit.code && v.type == state.item2edit.type);
    return items.data.find((v) => v.id == state.item2edit.id);
  },

  item2editChanged: (state, getters) => {
    return JSON.stringify(getters.item2edit) !== JSON.stringify(state.item2editInitial);
  },

  item2addTable: (state) => (table) => state.new.cfs.find((r) => r.attribute == table)?.value,

  itemName: (state) => state.new.name,
  attrValue: (state) => (attribute) => {
    const attr = state.new.cfs.find((r) => r.attribute == attribute);
    if (attr.type == "date") return new Date(moment(attr.value, "DD.MM.YYYY"));
    return attr.value;
  },
  attrTableValue: (state) => (table, index, attribute) => {
    const attr = state.new.cfs.find((r) => r.attribute == table)?.value?.[index]?.find((u) => u.attribute == attribute);
    if (attr.type == "date") return new Date(moment(attr.value, "DD.MM.YYYY"));
    return attr.value;
  },
  isAddModalLoaded: (state) => state.isAddModalLoaded,
  isEditModalLoaded: (state) => state.isEditModalLoaded,

  isCreateEnabled: (state) => {
    return (
      state.new.name != "" &&
      state.new.cfs.filter((cf) => cf.type != "bool" && cf.type != "table").every((cf) => cf.value != "")
    );
  },
};

const actions = {
  /**
   * Загрузка списка справочников
   *
   */
  async load({ state, commit, rootGetters }) {
    try {
      commit("setLoading", true);
      const resp = await load1ctypes({
        limit: state.limit,
        offset: state.offset,
      });

      commit("setItems", resp.data.result);
      commit("setTotal", resp.data.total);
      commit("setLoading", false);
    } catch (e) {
      commit("setLoading", false);
      console.debug(`1С ${rootGetters["partnerName"]}: ошибка загрузки справочников`, e);
    }
  },

  /**
   * Поиск по справочнику
   *
   * @param {string} code - код справочника
   * @param {string} type - тип справочника (Справочник, перечисление)
   * @param {Object} params - параметры запроса
   * @param {string} query - поисковая строка
   */
  async searchItems({ state, commit, rootGetters }, { code, type, params, query }) {
    try {
      commit("clearSearchedData", { code, type });
      commit("setLoading", true);
      const entityData = rootGetters["order/entity"];

      let qs = {
        code,
        type,
        query,
        entity_type: params?.type,
        entity_id: params?.id,
      };

      const relations = state.items.find((v) => v.code == code && v.type == type)?.json?.relations;
      const amoCompany = rootGetters["order/amoCompany"];
      const amoContact = rootGetters["order/amoContact"];
      const orderCfs = rootGetters["order/allCfs"];

      qs = setDirectoryRelations(qs, relations, amoCompany, amoContact, orderCfs, code, type);

      const resp = await load1ctypesItems(entityData, qs);

      commit("setSearchedData", { code, type, data: resp.data.result });
      commit("setLoading", false);
    } catch (e) {
      commit("setLoading", false);
      console.debug(`1С ${rootGetters["partnerName"]}: ошибка загрузки данных по справочнику ${code}`, e);
    }
  },

  /**
   * загрузка элемента справочника
   *
   * @param {string} code - код справочника
   * @param {string} type - тип справочника (Справочник, перечисление)
   * @param {Object} params - параметры запроса
   */
  async load1ctypeItems({ state, getters, commit, rootGetters }, { code, type, params }) {
    if (getters.isItemDataFull({ code, type })) return;
    try {
      commit("setItemDataLoading", { code, type, isLoading: true });
      const entityData = rootGetters["order/entity"];
      let offset = state.itemsData.find((v) => v.code == code && v.type == type)?.offset;
      offset = offset ? offset : 0;

      let qs = {
        code,
        type,
        offset,
        limit: state.limit,
        entity_type: params?.type,
        entity_id: params?.id,
      };

      const relations = state.items.find((v) => v.code == code && v.type == type)?.json?.relations;
      const amoCompany = rootGetters["order/amoCompany"];
      const amoContact = rootGetters["order/amoContact"];
      const orderCfs = rootGetters["order/allCfs"];

      qs = setDirectoryRelations(qs, relations, amoCompany, amoContact, orderCfs, code, type);

      const resp = await load1ctypesItems(entityData, qs);

      commit("setItemData", { code, type, data: resp.data.result });
      commit("setItemDataTotal", { code, type, total: resp.data.total });
      commit("setItemDataLoading", { code, type, isLoading: false });
    } catch (e) {
      commit("setItemDataLoading", { code, type, isLoading: false });
      console.debug(`1С ${rootGetters["partnerName"]}: ошибка загрузки данных по справочнику ${code}`, e);
    }
  },

  /**
   * Хук на открытие окна
   *
   */
  async onModalOpened({ state, dispatch }) {
    const crfs = state.items.find((v) => v.code == state.item2add.code && v.type == state.item2add.type)?.json
      ?.creation_required_fields;

    if (!Array.isArray(crfs)) return;

    const directories = crfs.filter((crf) => crf.type == "Справочник" || crf.type == "Перечисление");

    for (const item of directories) {
      await dispatch("load1ctypeItems", { code: item.code, type: item.type });
    }
  },

  /**
   * Создание элемента справочника
   *
   */
  async create({ state, commit, rootGetters }) {
    try {
      commit("setCreating", true);

      const currentUser = rootGetters["amoUser"];

      await createElement({
        code: state.item2add.code,
        type: state.item2add.type,
        name: state.new.name,
        data: state.new.cfs,
        current_user_id: `${currentUser.id}`,
        current_user_name: `${currentUser.name}`,
        current_user_phone: `${currentUser.phone}`,
        current_user_emall: `${currentUser.email}`,
      });

      commit("setCreating", false);
    } catch (e) {
      console.debug(`1С ${rootGetters["partnerName"]}: ошибка создания элемента справочника`, e);
    } finally {
      commit("setCreating", false);
    }
  },

  /**
   * Редактирование элемента справочника
   *
   */
  async edit({ getters, commit, rootGetters }) {
    try {
      commit("setSaving", true);
      let item2edit = getters.item2edit;

      const currentUser = rootGetters["amoUser"];

      (item2edit["current_user_id"] = `${currentUser.id}`),
        (item2edit["current_user_name"] = `${currentUser.name}`),
        (item2edit["current_user_phone"] = `${currentUser.phone}`),
        (item2edit["current_user_emall"] = `${currentUser.email}`),
        await editElement(item2edit);

      commit("setSaving", false);
    } catch (e) {
      console.debug(`1С ${rootGetters["partnerName"]}: ошибка создания элемента справочника`, e);
    } finally {
      commit("setSaving", false);
    }
  },

  setItem2add({ commit }, payload) {
    commit("setItem2add", payload);
    commit("setNewItem", payload);
    commit("setAddModalLoaded");
  },

  setItem2edit: ({ commit }, payload) => {
    commit("setItem2edit", payload);
    commit("setEditModalLoaded");
  },

  setNewItem: ({ commit }, payload) => commit("setNewItem", payload),
  setNewItemValue: ({ commit }, payload) => commit("setNewItemValue", payload),
  setNewItemTableValue: ({ commit }, payload) => commit("setNewItemTableValue", payload),

  addItemTableRow: ({ commit }, table) => commit("addItemTableRow", table),
  removeItemTableRow: ({ commit }, payload) => commit("removeItemTableRow", payload),

  setAttrValue: ({ commit }, payload) => commit("setAttrValue", payload),

  changeItemName: ({ commit }, name) => commit("changeItemName", name),

  editElementName: ({ commit, dispatch }, name) => {
    commit("editElementName", name);
    dispatch("editElement");
  },
};

const mutations = {
  setItems(state, data) {
    // Порционное пополнение списка справочников
    state.items = state.items.concat(data);
    state.offset += state.limit;
    if (data.length < state.limit) {
      state.isFull = true;
    }
  },

  changeItemName: (state, name) => (state.new.name = name),

  editElementName: (state, name) => {
    const items = state.itemsData.find((v) => v.code == state.item2edit.code && v.type == state.item2edit.type);
    const el = items.data.find((v) => v.id == state.item2edit.id);
    el.name = name;
  },

  setAttrValue(state, payload) {
    // Установка значения табличного элемента
    let attribute = {};
    if (payload.table && payload.table.length) {
      const table = state.new.cfs.find((r) => r.attribute == payload.table);
      if (table?.value?.[payload.index]) {
        attribute = table.value[payload.index].find((u) => u.attribute == payload.attribute);
      }
    } else {
      attribute = state.new.cfs.find((r) => r.attribute == payload.attribute);
    }

    attribute.value = payload.value;

    if (attribute.type == "date") {
      attribute.value = moment(payload.value).format("DD.MM.YYYY");
    }
  },

  setItem2add: (state, payload) => {
    state.item2add = payload;
  },
  setItem2edit: (state, payload) => {
    state.item2editInitial = JSON.parse(JSON.stringify(payload));
    state.item2edit = payload;
  },

  pushItem: (state, item) => {
    state.itemsData.push({
      code: item.code,
      type: item.type,
      data: [item],
    });
  },

  setError(state, error) {
    state.errorOpenModal = error;
    state.isEditModalLoaded = true;
  },

  addItemTableRow(state, table) {
    const attr = state.new.cfs.find((crf) => crf.name == table);
    const crfs = state.items.find((v) => v.code == state.item2add.code && v.type == state.item2add.type)?.json
      ?.creation_required_fields;
    const columns = crfs.find((crf) => crf.name == table).columns;
    const row = [];
    columns.forEach((column) => {
      row.push({
        attribute: column.attribute,
        name: column.name,
        type: column.type,
        code: column.code,
        value: column.type == "bool" ? false : "",
      });
    });
    attr.value.push(row);
  },
  removeItemTableRow(state, { attribute, index }) {
    const table = state.new.cfs.find((r) => r.attribute == attribute);
    table.value.splice(index, 1);
    Vue.set(table, "value", table.value);
  },
  setAddModalLoaded: (state) => (state.isAddModalLoaded = true),
  setEditModalLoaded: (state) => (state.isEditModalLoaded = true),
  setNewItem(state, { code, type }) {
    // Инициализация нового элемента
    const rules = state.items.find((v) => v.code == code && v.type == type)?.json?.creation_required_fields;
    if (!rules) {
      state.errorOpenModal = "Ошибка: отсутствуют правила создания элемента";
      return;
    }
    const payload = [];
    for (const item of rules) {
      let value = "";
      if (item.type == "table") value = [];

      payload.push({
        attribute: item.attribute,
        name: item.name,
        type: item.type,
        code: item.code,
        value,
      });
    }
    state.new = { name: "", cfs: payload };
  },
  setNewItemValue(state, { name, value }) {
    // Установка значения нового элемента
    const attr = state.new.cfs.find((crf) => crf.name == name);
    attr.value = value;
  },

  setTotal: (state, value) => (state.total = value),
  setLoading: (state, value) => (state.isLoading = value),
  setCreating: (state, value) => (state.isCreating = value),
  setSaving: (state, value) => (state.isSaving = value),

  setItemDataTotal(state, { code, type, total }) {
    // Установить общее количество записей элемента справочника
    let item = state.itemsData.find((v) => v.code == code && v.type == type);
    if (item) {
      Vue.set(item, "total", total);
    } else {
      Vue.set(state.itemsData, state.itemsData.length, { code, type, total });
    }
  },
  setItemDataLoading(state, { code, type, isLoading }) {
    // Установить state в значение loading по конкретному справочнику
    let item = state.itemsData.find((v) => v.code == code && v.type == type);
    if (item) {
      Vue.set(item, "isLoading", isLoading);
    } else {
      Vue.set(state.itemsData, state.itemsData.length, { code, type, isLoading });
    }
  },
  setSearchedData(state, { code, type, data }) {
    // Установить результаты поиска
    let item = state.itemsData.find((v) => v.code == code && v.type == type);
    Vue.set(item, "searched", data);
  },
  clearSearchedData(state, { code, type }) {
    // Очистить результаты поиска
    let item = state.itemsData.find((v) => v.code == code && v.type == type);
    Vue.set(item, "searched", []);
  },
  setItemData(state, { code, type, data }) {
    // Порционное пополнение элемента списка справочников
    let item = state.itemsData.find((v) => v.code == code && v.type == type);
    let offset = 0;
    if (item?.offset) offset = item.offset;
    offset += state.limit;
    Vue.set(item, "offset", offset);
    if (data.length < state.limit) {
      Vue.set(item, "isFull", true);
    }
    if (item?.data) data = item.data.concat(data);
    Vue.set(item, "data", data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
