import { apiDocflow } from './api';

export const getOrganizations = (params) =>
        apiDocflow.get('organizations', {
        params,
    });

export const createOrganization = (data) => apiDocflow.post('organizations', data);

export const getOrganizationById = (id) => apiDocflow.get(`organizations/${id}`);

export const updateOrganizationById = (id, data) =>
    apiDocflow.put(`organizations/${id}`, data);

export const removeOrganizationById = (id) =>
    apiDocflow.delete(`organizations/${id}`);
