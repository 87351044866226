/**
 * @author Ilya Orishin. 2021
 */

import moment from 'moment';

/*
Структура календаря
{
    pay_type - вид оплаты - перечисление (0,1,2,3) -> localSettings -> payTypes,
    date - дата оплаты,
    sum - сумма оплаты
}
*/

export const paysGetters = {
    payCalendar: state => state.data.pay_calendar,
    PCValue: state => (index, field) => state.data.pay_calendar[index][field],
    PCDateValue: state => (index, field) => new Date(moment(state.data.pay_calendar[index][field], 'DD.MM.YYYY'))
}

export const paysActions = {
    addRowPC: ({commit}) => commit('addRowPC'),
    copyRowPC: ({commit}, index) => commit('copyRowPC', index),
    removeRowPC: ({commit}, index) => commit('removeRowPC', index),
    setValuePC: ({commit}, payload) => commit('setValuePC', payload),
    checkPC: ({commit}, cb) => commit('checkPC', cb),
    cancelPC: ({commit}) => commit('cancelPC')
}

export const paysMutations = {
    addRowPC: state => state.data.pay_calendar.push({date: '', sum: '', pay_type: ''}),
    copyRowPC: (state, index) => state.data.pay_calendar.splice(index+1, 0, Object.assign({}, state.data.pay_calendar[index])),
    removeRowPC:(state, index) => state.data.pay_calendar = state.data.pay_calendar.filter((v,i) => i != index),
    setValuePC: (state, {index, field, value}) => state.data.pay_calendar.find((v,i) => i == index)[field] = value,
}