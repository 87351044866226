<template>
  <tr :class="$style.tableRow">
    <!-- Номер -->
    <td :class="$style.cell" data-code="number">
      <div :class="$style.checkBoxWrapper">
        <GnzsCheckbox :class="$style.optionsChecker" :value="isChecked(product.uuid)" @input="setChecked">
        </GnzsCheckbox>
        <div :class="$style.counter">{{ index + 1 }}</div>
        <div v-if="defaults.showImages" :class="[$style.imageInner, 'gnzs-1c-scroll-table-draggable-el']">
          <img @click="openGallery" :class="$style.image" v-if="preview" :src="preview" />
          <svg :class="$style.noimage" v-else><use xlink:href="#gnzs-ms-icon__noimage"></use></svg>
        </div>
      </div>
    </td>

    <!-- Артикул -->
    <td v-if="tableField({ key: 'article', action: 'show' })" :class="$style.cell" data-code="article" :title="article">
      <div :class="[$style.cellInner, 'gnzs-1c-scroll-table-draggable-el']">
        {{ article }}
      </div>
    </td>
    <!-- Код -->
    <td v-if="tableField({ key: 'code', action: 'show' })" :class="$style.cell" data-code="code" :title="code">
      <div :class="[$style.cellInner, 'gnzs-1c-scroll-table-draggable-el']">
        {{ code }}
      </div>
    </td>
    <!-- Наименование -->
    <td v-if="tableField({ key: 'name', action: 'show' })" :class="$style.cell" data-code="name" :title="product.name">
      <div :class="[$style.cellInner_flex, 'gnzs-1c-scroll-table-draggable-el']">
        <div :class="[$style.cellValue, { [$style.noDataText]: !productData }]">
          <div :class="[$style.cellInner, { [$style.canceled]: product.cancelled }]">
            {{ product.name }}
          </div>
          <div v-if="product.content && product.content.length" :class="$style.cellInner" data-code="content">
            {{ product.content }}
          </div>
        </div>

        <div v-if="!productData" :class="$style.noDataIcon" title="Данные не найдены">
          <svg>
            <use xlink:href="#gnsz-1c-icon__info-error"></use>
          </svg>
        </div>

        <GnzsCellEditor v-else :class="$style.cellEditor" data-code="name" @onSave="changeContent">
          <template v-slot:control>
            <textarea
              ref="prodContent"
              :disabled="!!product.cancelled"
              placeholder="Введите содержание позиции..."
              :value="prodContent"></textarea>
          </template>
        </GnzsCellEditor>
      </div>
    </td>
    <!-- Склад -->
    <td v-if="stockWorktype == 1" :class="$style.cell" data-code="stock">
      <GnzsDropdown
        v-if="stocks.length"
        :value="product.stock_id"
        :items="stocks"
        :disabled="!!product.cancelled"
        :isGrouped="isGrouped"
        @input="changeStock(product, $event)" />
      <div v-else :class="[$style.cellInner, 'gnzs-1c-scroll-table-draggable-el']">—</div>
    </td>
    <!-- Остаток -->
    <td v-if="stockWorktype == 1 && defaults.showStockColumn" :class="$style.cell" data-code="count">
      <div :class="[$style.cellInner_centered, 'gnzs-1c-scroll-table-draggable-el']">{{ count }}</div>
    </td>
    <!-- Ед. измерения -->
    <td v-if="tableField({ key: 'unit', action: 'show' })" :class="$style.cell" data-code="units">
      <GnzsDropdown
        v-if="units.length"
        :items="units"
        :disabled="!tableField({ key: 'count', action: 'edit' }) || !!product.cancelled"
        :value="product.measure"
        @input="changeUnit($event)" />
      <div v-else :class="[$style.cellInner, 'gnzs-1c-scroll-table-draggable-el']">
        {{ product.measure ? product.measure : "—" }}
      </div>
    </td>
    <!-- Количество -->
    <td v-if="tableField({ key: 'count', action: 'show' })" :class="$style.cell" data-code="count">
      <GnzsInput
        type="number"
        v-model="product.count"
        ref="inputCount"
        :disabled="!tableField({ key: 'count', action: 'edit' }) || !!product.cancelled"
        @input="onChangeProduct"
        @keydown="onlyPositive" />
    </td>
    <!-- Цена -->
    <td v-if="tableField({ key: 'price', action: 'show' })" :class="$style.cell" data-code="price">
      <GnzsInputHints
        :style="{ position: 'relative' }"
        v-model="product.price"
        :disabled="!tableField({ key: 'price', action: 'edit' }) || !!product.cancelled"
        :hints="hintPrices"
        @input="onChangePrice"
        @keydown="floatKeyDown"
        @hintClick="onPriceTypeClick" />
    </td>
    <!-- Статусы обеспечения -->
    <td
      v-show="defaults.isProvisionEnabled && tariffFeatures.includes('provisions')"
      :class="$style.cell"
      data-code="provision">
      <GnzsDropdown
        v-if="statuses.length"
        v-model="product.provision_id"
        :use-empty-item="false"
        :items="statuses"
        :disabled="!!product.cancelled"
        :emptyItemTitle="'Выбрать'"
        @input="onChangeDropdown" />
      <div v-else :class="[$style.cellInner, 'gnzs-1c-scroll-table-draggable-el']">—</div>
    </td>
    <!-- Скидка -->
    <td v-if="tableField({ key: 'discount', action: 'show' })" :class="$style.cell" data-code="discount">
      <GnzsInputDiscount :viewType="product.discount_view_type" @changeViewType="onChangeDiscountViewType">
        <template v-slot:discountPercent>
          <GnzsInput
            :disabled="!tableField({ key: 'discount', action: 'edit' }) || !!product.cancelled"
            v-model="product.discount"
            @input="onChangeProduct"
            @keydown="floatKeyDownNegative" />
        </template>
        <template v-slot:discountCurrency>
          <GnzsInput
            :disabled="!tableField({ key: 'discount', action: 'edit' }) || !!product.cancelled"
            v-model="product.total_discount"
            @input="onChangeProduct"
            @keydown="floatKeyDownNegative" />
        </template>
      </GnzsInputDiscount>
    </td>
    <!-- Авт. Скидка -->
    <td
      v-if="defaults.isAutoDiscountEnabled && tariffFeatures.includes('auto-discount')"
      :class="$style.cell"
      data-code="nds">
      <GnzsInputDiscount :class="$style.autoDiscount">
        <template v-slot:discountPercent>
          <GnzsInput type="number" v-model="product.auto_discount" @input="onChangeProduct" :disabled="true" />
        </template>
      </GnzsInputDiscount>
    </td>
    <!-- НДС -->
    <td v-if="isNdsUsed && tableField({ key: 'vat', action: 'show' })" :class="$style.cell" data-code="nds">
      <GnzsInput
        type="number"
        :disabled="!tableField({ key: 'vat', action: 'edit' }) || !!product.cancelled"
        v-model="product.nds"
        @input="onChangeProduct" />
    </td>
    <!-- Отгружено -->
    <td v-if="defaults.isPartialRealizationEnabled" :class="$style.cell" data-code="count-realized">
      <GnzsInput type="number" :disabled="true" :value="shippedCount" />
    </td>
    <!-- Осталось к отгрузке -->
    <td v-if="defaults.isPartialRealizationEnabled" :class="$style.cell" data-code="count-to-realize">
      <GnzsInput type="number" :disabled="true" :value="toShip" />
    </td>
    <!-- Дополнительные поля -->
    <template v-if="tariffFeatures.includes('table-builder')">
      <ProductsTableRowCustomFields
        v-for="field in tableFieldCustomWorkers"
        :field="field"
        :product="product"
        :key="field.attribute" />
    </template>
    <!-- Отмена -->
    <td v-if="defaults.useCancellationPositions" :class="$style.cell" data-code="cancelled">
      <div :class="$style.centered">
        <div :class="$style.checkBoxWrapper">
          <GnzsCheckbox
            :class="$style.optionsChecker"
            :value="!!product.cancelled"
            :disabled="defaults.isCancellationEditDisabled"
            @input="onCancelProduct">
          </GnzsCheckbox>
        </div>
      </div>
    </td>
    <!-- Причина отмены -->
    <td
      v-if="defaults.useCancellationPositions && defaults.useCancellationReasons"
      :class="$style.cell"
      data-code="reason">
      <GnzsDropdown
        :class="$style.optionsChecker"
        :disabled="defaults.isCancellationEditDisabled"
        v-model="product.cancellation_reason"
        :items="cancellationReasons"
        @input="onChangeDropdown">
      </GnzsDropdown>
    </td>
    <!-- Итоговая цена -->
    <td v-if="tableField({ key: 'sum', action: 'show' })" :class="$style.cell" data-code="total-price">
      <div :class="[$style.cellInner_flexEnd, 'gnzs-1c-scroll-table-draggable-el']">
        <GnzsCellEditor :class="$style.cellEditor" data-code="total-price" align="right" @onSave="changeTotalPrice">
          <template v-slot:control>
            <input ref="prodTotalPrice" type="text" :value="product.total_price" @keydown="floatKeyDown" />
          </template>
        </GnzsCellEditor>

        <div>{{ product.total_price | price }}</div>

        <GnzsButton :class="$style.buttonRemove" type="remove" @click="onDeleteRow"></GnzsButton>
      </div>
    </td>
  </tr>
</template>

<script>
  import { mapActions, mapGetters } from "vuex";
  import _ from "underscore";
  import $ from "jquery";

  import GnzsInput from "@/gnzs-controls/Input";
  import GnzsInputHints from "@/gnzs-controls/InputHints";
  import GnzsInputDiscount from "@/gnzs-controls/InputDiscount";
  import GnzsDropdown from "@/gnzs-controls/Dropdown";
  import GnzsButton from "@/gnzs-controls/Button";
  import GnzsCellEditor from "@/gnzs-controls/CellEditor";
  import GnzsCheckbox from "@/gnzs-controls/Checkbox";
  import ProductsTableRowCustomFields from "./ProductsTableRowCustomFields.vue";

  import { getPricesHints, getStockDropdownItems, getStockCount } from "@/utilities/orderProduct";
  import { floatKeyDown, onlyPositive, floatKeyDownNegative } from "@/utilities/inputUtils";

  export default {
    props: {
      product: {
        type: Object,
      },
      index: {
        type: Number,
      },
    },
    computed: {
      ...mapGetters({
        stockWorktype: "settings/stockWorktype",
        isNdsUsed: "order/isNdsUsed",
        defaults: "settings/defaults",
        activeStatuses: "provisions/provisionStatuses",
        emptyStockStatuses: "provisions/emptyStockProvisionStatuses",
        stockCountBySearch: "settings/stockCountBySearch",
        orderStockId: "order/stockId",
        orderOrgId: "order/organization1cId",
        tariffFeatures: "features/getFeatures",
        organizations: "organizations/orderItems",
        isChecked: "order/isChecked",
        shipped: "realizations/shipped",
        tableField: "settings/tableField",
        tableFieldCustomWorkers: "settings/tableFieldCustomWorkers",
        agreementId: "order/agreementId",
        agreementPtId: "agreements/getPriceTypeId",
        cancellationReasons: "cancellationReasons/dropdownItems",
        auth: "settings/auth",
        amoAccountId: "amoAccountId",
      }),
      stockParams() {
        return { stockCountBySearch: this.stockCountBySearch, stockFormat: this.defaults.stockFormatItems };
      },
      count() {
        if (this.stockWorktype == 3) {
          return getStockCount(this.productData, this.stockParams, this.$props.product.stock_id, this.orderOrgId);
        } else {
          return getStockCount(this.productData, this.stockParams, this.orderStockId, this.orderOrgId);
        }
      },
      statuses() {
        return this.count == 0 || this.count == "—" ? this.emptyStockStatuses : this.activeStatuses;
      },
      productData() {
        return this.$store.getters["products/getById"](this.product.id);
      },
      article() {
        return this.productData?.article || "—";
      },
      code() {
        return this.productData?.code || "—";
      },
      prodContent() {
        return _.isEmpty(this.product.content) ? this.product.name : this.product.content;
      },
      toShip() {
        return this.product.count - this.shippedCount;
      },
      shippedCount() {
        return this.shipped[this.product.id] || 0;
      },
      measure() {
        return this.productData.measure;
      },
      stocks() {
        let orgName = this.organizations.find((val) => val.value == this.orderOrgId)?.title;
        return getStockDropdownItems(this.productData, this.orderOrgId, orgName);
      },
      isGrouped() {
        //Если организация не выбрана не группируем выпадающий список
        return !!this.orderOrgId;
      },
      hintPrices() {
        if (!this.productData) return [];

        const filterPrices =
          this.defaults.isAgreementsEnabled &&
          this.defaults.showAgreementsPrices &&
          this.tariffFeatures.includes("prices-for-agreement");

        let hints = getPricesHints(this.productData);

        if (filterPrices) {
          const ptId = this.agreementPtId(this.agreementId);
          if (ptId) {
            hints = hints.filter((h) => h.id == ptId);
          }
        }

        return hints;
      },
      unitsBack() {
        if (!this.productData) return [];

        let units = this.productData.units;
        if (!units || !units.length) return [];

        if (!units.includes(this.measure)) {
          units = [this.measure, ...units];
        }

        return units.map((u) => ({ title: u, value: u }));
      },
      preview() {
        if (!this.productData?.images?.length) return "";
        const mainImage = this.productData.images.find((i) => i.is_main);
        if (!mainImage) return "";
        return `https://api1c.gnzs.ru/v2/product-images?Client-Id=${this.amoAccountId}&Key=${
          this.auth.key1c
        }&url=${encodeURIComponent(mainImage.preview_url)}`;
      },
      units() {
        if (!this.productData) return [];

        let units = this.productData.units;
        if (!units || !units.length) return [];

        const realUnits = [];

        units.forEach((unit) => {
          if (typeof unit === "object" && unit !== null) {
            realUnits.push({
              title: unit.name,
              value: unit.name,
              numerator: unit.numerator,
              denominator: unit.denominator,
            });
          } else {
            realUnits.push({
              title: unit,
              value: unit,
              numerator: 1,
              denominator: 1,
            });
          }
        });

        const curerentMeasure = realUnits.find((u) => u.value == this.measure);

        if (!curerentMeasure) {
          realUnits.push({
            title: this.measure,
            value: this.measure,
            numerator: 1,
            denominator: 1,
          });
        }

        return realUnits;
      },
    },
    methods: {
      ...mapActions({
        toggleGallery: "products/toggleGallery",
        setGallery: "products/setGallery",
      }),
      openGallery() {
        this.setGallery(this.productData.images);
        this.toggleGallery();
      },
      onCancelProduct(val) {
        this.product.cancelled = val;
        this.onChangeProduct();
      },
      getRowWidth() {
        return document.querySelector("#table")?.offsetWidth || 1000;
      },
      setChecked(value) {
        this.$store.dispatch("order/setChecked", { uuid: this.product.uuid, value });
      },
      changeContent() {
        const content = this.$refs["prodContent"].value.trim();

        this.$store.dispatch("order/setProductFieldValue", {
          product: this.product,
          field: "content",
          value: content,
        });
      },
      changeStock(product, stockId) {
        this.$store.dispatch("order/setProductStock", { product, stockId });
        this.onChangeDropdown();
      },
      changeUnit(unit) {
        const prevUnit = this.product.measure;
        this.$store.dispatch("order/setProductFieldValue", {
          product: this.product,
          field: "measure",
          value: unit,
        });
        this.$store.dispatch("order/onChangeMeasure", { product: this.product, units: this.units, prevUnit });
        this.onChangeDropdown();
      },
      floatKeyDown(e) {
        floatKeyDown(e);
      },
      floatKeyDownNegative(e) {
        floatKeyDownNegative(e);
      },
      onlyPositive(e) {
        onlyPositive(e);
      },
      onChangePrice() {
        this.product.price_type_id = "";
        this.$store.dispatch("order/calcProcuct", this.product);
      },
      onPriceTypeClick(priceObj) {
        this.product.price_type_id = priceObj.id;
        this.$store.dispatch("order/calcProcuct", this.product);
      },
      onChangeProduct() {
        this.$store.dispatch("order/calcProcuct", this.product);
      },
      changeTotalPrice() {
        const totalPrice = this.$refs["prodTotalPrice"].value.trim();
        this.$store.dispatch("order/setProductTotalPrice", {
          totalPrice,
          product: this.product,
        });

        this.product.price_type_id = "";
      },
      onDeleteRow() {
        this.$store.dispatch("order/deleteProduct", this.product.uuid);
        let wrapper = $(`.gnzs-1c-scroll-table-wrapper`);
        let draggable = $(`.gnzs-1c-scroll-table-draggable`);
        setTimeout(() => this.$store.dispatch("table/calc", { wrapper, draggable }), 100);
        this.$store.dispatch("table/setValue", { key: "scrollOffset", value: 0 });
        this.$store.dispatch("table/setValue", { key: "tableOffset", value: 0 });
      },
      onChangeDiscountViewType() {
        this.product.discount_view_type = +!this.product.discount_view_type;
      },
      onChangeDropdown() {
        let wrapper = $(`.gnzs-1c-scroll-table-wrapper`);
        let draggable = $(`.gnzs-1c-scroll-table-draggable`);
        setTimeout(() => this.$store.dispatch("table/calc", { wrapper, draggable }), 100);
      },
    },
    created() {
      // добавляем поле, т.к. при приходе заказов из 1С оно может отсутствовать
      if (typeof this.product.discount_view_type === "undefined") {
        this.$set(this.product, "discount_view_type", 0);
      }
      $(window).on("resize", () => this.onChangeDropdown());
    },
    components: {
      GnzsInput,
      GnzsInputHints,
      GnzsDropdown,
      GnzsInputDiscount,
      GnzsButton,
      GnzsCellEditor,
      GnzsCheckbox,
      ProductsTableRowCustomFields,
    },
  };
</script>

<style lang="scss" module>
  @import "./ProductsTable.scss";
</style>
