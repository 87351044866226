<template>
    <div :class="$style.activation">
            <div :class="[$style.activation__text, styleText]">{{caption}}</div>
            <a v-if="showPayButton" :class="[$style.activation__button, styleButton]" 
               :href="widgetStatus.statusLocaleData.paymentUrl" 
               target="_blank"
            >{{widgetStatus.statusLocaleData.buttonText}}</a>
    </div>
</template>

<script>
export default {
    props: {
        showPayButton: {
            type: Boolean,
            default: true
        },
        widgetStatus: {
            type: Object,
            default: () => ({
                isActive: false,
                status: 'DEMO',
                statusLocaleData: {
                    activationTillText: 'Ошибка',
                    buttonText: 'Продлить',
                    paymentUrl: ''
                },
                tariff: {
                    name: ''
                }
            })
        }
    },
    computed: {
        caption(){
            const tariffName = this.$props.widgetStatus.tariff.name
            return tariffName ? `${tariffName} ${this.activationText}` : this.activationText
        },
        activationText(){
            return this.$props.widgetStatus.statusLocaleData.activationTillText
        },
        styleButton(){
            if (this.$props.widgetStatus.isActive){
                return this.$props.widgetStatus.status == 'DEMO' ? this.$style.activation__button_demo : ''
            } else {
                return this.$style.activation__button_inactive
            }
        },
        styleText(){
            if (this.$props.widgetStatus.isActive){
                return this.$props.widgetStatus.status == 'DEMO' ? this.$style.activation__text_demo : ''
            } else {
                return this.$style.activation__text_inactive
            }
        }
    }
}
</script>

<style lang="scss" module>
    @import "./style.scss";
</style>
