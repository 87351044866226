/**
 * @author Ilya Orishin. 2024
 *
 * Утилиты соглашений
 *
 * Функция позволяет разбить массив на группы по условиям
 *
 * @param {Object} conditions - условия разбиения
 * @param {Array} items - массив который нужно разбить
 * @returns {Array} result - [[by cond-n 1],[by cond-n 2]]
 */
export const beatArrayByConditions = (conditions, items) => {
  let result = [];

  Object.entries(conditions).forEach(([k, v]) => {
    let element = {};
    element.value = k;
    element.title = k;
    element.items = items.filter(v).map((i) => ({
      value: i.id,
      title: i.name,
    }));
    result.push(element);
  });

  return result;
};
