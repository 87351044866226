<template>
    <GnzsSection 
        :disabled="!tariffFeatures.includes('send-to-evotor')" 
        :disabledText="disabledSectionText"
        header='Кнопка "Отправить в Эвотор" в окне заказа'
        >
        <template v-slot:description>
            Если включено, то в модальном окне детализации заказа добавится кнопка "Отправить в Эвотор", при нажатии на которую будет запускаться процесс проведения отправки в Эвотор на стороне 1С. Для корректной работы необходима доработка функционала на стороне 1С.
        </template>
        <template v-slot:header-control>
            <GnzsSwitcher
                v-model="defaults.isEvotorButtonEnabled"
            ></GnzsSwitcher>
        </template>
    </GnzsSection>
</template>

<script>
import { mapGetters } from "vuex";

import GnzsSection from "@/gnzs-controls/Section";
import GnzsSwitcher from "@/gnzs-controls/Switcher";

export default {
    computed: {
        ...mapGetters({
            defaults: "settings/defaults",
            tariffFeatures: 'features/getFeatures',
            disabledSectionText: 'localSettings/disabledSectionText'
        }),
    },
    components: {
        GnzsSection,
        GnzsSwitcher,
    },
};
</script>

<style lang="scss" module="w">
    @import '@/assets/scss/Settings/style.scss';
</style>