<template>
  <GnzsSection header='Отображать поле "Комментарий" в окне детализации заказа'>
    <template v-slot:description>
      При включении данной настройки поле "Комментарий" будет доступно в окне детализации заказа
    </template>
    <template v-slot:header-control>
      <GnzsSwitcher v-model="defaults.isDescriptionEnabled"></GnzsSwitcher>
    </template>
    <div :class="w.table" v-if="defaults.isDescriptionEnabled">
      <div :class="w.tableRow">
        <div>Поле сделки</div>
        <div>
          <GnzsDropdown
            :class="w.w200"
            v-model="extraCfs.descriptionCfId"
            :items="getAmoEntityCfs('lead', ['textarea'])"
            :isSearchable="true"></GnzsDropdown>
        </div>
      </div>
      <div :class="w.tableRow">
        <div>Обновлять данные из поля сделки при открытии</div>
        <div>
          <GnzsSwitcher v-model="defaults.updateDescription" />
        </div>
      </div>
    </div>
  </GnzsSection>
</template>

<script>
  import { mapGetters } from "vuex";

  import GnzsSection from "@/gnzs-controls/Section";
  import GnzsDropdown from "@/gnzs-controls/Dropdown";
  import GnzsSwitcher from "@/gnzs-controls/Switcher";

  export default {
    computed: {
      ...mapGetters({
        defaults: "settings/defaults",
        extraCfs: "settings/extraCfs",
        amoCustomFieldsByTypes: "amo/customFieldsByTypes",
      }),
    },
    methods: {
      getAmoEntityCfs(entity, types = ["all"]) {
        return types.reduce((cfs, t) => [...cfs, ...this.amoCustomFieldsByTypes[entity][t]], []);
      },
    },
    components: {
      GnzsSection,
      GnzsDropdown,
      GnzsSwitcher,
    },
  };
</script>

<style lang="scss" module="w">
  @import "@/assets/scss/Settings/style.scss";
</style>
