<template>
  <GnzsSection
    header="Дополнительные действия"
    :disabled="!tariffFeatures.includes('additional-buttons')"
    :disabledText="disabledSectionText">
    <template v-slot:description>Функционал настройки кнопок с дополнительными действиями в окне заказа</template>

    <div v-if="!isInsideAmo && tariffFeatures.includes('additional-buttons')" :class="w.rIc">
      <GnzsHelper side="left" type="service" :value="helpers.serviceUseOnly" />
    </div>

    <div :class="w.table">
      <div :class="[w.tableHeader, $style.tableHeader]" v-if="extraActions.length">
        <div>Название кнопки</div>
        <div>Код кнопки</div>
      </div>

      <!-- Дополнительные поля сущности -->
      <template>
        <div :class="w.tableRow" v-for="(button, index) in extraActions" :key="index">
          <div>
            <GnzsInput
              :value="button.name"
              :class="[button.name.length ? '' : $style.inputInvalid]"
              placeholder="Название кнопки"
              @input="setExtraAction({ index, name: $event })" />
          </div>
          <div>
            <GnzsInput
              :value="button.code"
              :class="[button.code.length ? '' : $style.inputInvalid]"
              placeholder="Код кнопки"
              @keydown="cyrillicKeyDown"
              @input="setExtraAction({ index, code: $event })" />
          </div>
          <div>
            <GnzsButton type="remove" @click="removeExtraAction(index)" />
          </div>
        </div>
      </template>
    </div>

    <div :class="$style.appendButtonContainer">
      <GnzsButton type="append" @click="appendExtraAction">Добавить кнопку</GnzsButton>
    </div>
  </GnzsSection>
</template>

<script>
  import { mapActions, mapGetters } from "vuex";
  import GnzsSection from "@/gnzs-controls/Section";
  import GnzsInput from "@/gnzs-controls/Input";
  import GnzsHelper from "@/gnzs-controls/Helper";
  import GnzsButton from "@/gnzs-controls/Button";
  import { cyrillicKeyDown } from "@/utilities/inputUtils";

  export default {
    computed: {
      ...mapGetters({
        isInsideAmo: "isInsideAmo",
        extraActions: "settings/extraActions",
        helpers: "localSettings/helpers",
        tariffFeatures: "features/getFeatures",
        disabledSectionText: "localSettings/disabledSectionText",
      }),
    },
    methods: {
      ...mapActions({
        appendExtraAction: "settings/appendExtraAction",
        setExtraAction: "settings/setExtraAction",
        removeExtraAction: "settings/removeExtraAction",
      }),
      cyrillicKeyDown(e) {
        cyrillicKeyDown(e);
      },
    },
    components: {
      GnzsSection,
      GnzsInput,
      GnzsHelper,
      GnzsButton,
    },
  };
</script>

<style lang="scss" module="w">
  @import "@/assets/scss/Settings/style.scss";
</style>

<style lang="scss" module>
  @import "./style.scss";
</style>
