import {copy1cOrder} from '@/api/1c-api';

import Vue from 'vue';

export const orderCopyState = {
    orderCopy: {
        loading: true,
        header: 'Копирование заказа...',
        leadId: '',
        href: '',
        errorText: ''
    }
}

export const orderCopyGetters = {
    orderCopyLoading: state => state.orderCopy.loading,
    orderCopyHeader: state => state.orderCopy.header,
    orderCopyLeadId: state => state.orderCopy.leadId,
    orderCopyHref: state => state.orderCopy.href,
    orderCopyErrorText: state => state.orderCopy.errorText
}

export const orderCopyActions = {
    async copyOrder({commit, getters}){
        try{
            commit('setOrderCopyValue', {key: 'loading', value: true})
            
            const response = await copy1cOrder(getters.orderData)

            commit('setOrderCopyValue', {key: 'header', value: 'Заказ успешно скопирован'})
            commit('setOrderCopyValue', {key: 'leadId', value: response.data.leadId})
            commit('setOrderCopyValue', {key: 'href', value: response.data.href})
        } catch (e){
            commit('setOrderCopyValue', {key: 'header', value: 'Ошибка копирования заказа'})
            commit('setOrderCopyValue', {key: 'errorText', value: e.message})
            console.debug('Ошибка копирования заказа', {e})
        } finally {
            commit('setOrderCopyValue', {key: 'loading', value: false})
        }
    },
}

export const orderCopyMutations = {
    setOrderCopyValue: (state, {key, value}) =>{
        Vue.set(state.orderCopy, key, value)
    }
}