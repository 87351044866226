<template>
    <div :class="$style.root">
        <slot v-if="viewType==0" name="discountPercent"></slot>
        <slot v-else name="discountCurrency"></slot>

        <button 
            :class="[
                $style.toggleButton,
                {[$style.toggleButton_view_percent]: viewType==0},
                {[$style.toggleButton_view_currency]: viewType==1},
            ]"
            @click="onChangeViewType"
        >
            <svg>
                <use v-if="viewType==1" xlink:href="#gnzs-1c-icon__coins"></use>
                <use v-else xlink:href="#gnzs-1c-icon__percent"></use>
            </svg>
        </button>
    </div>
</template>

<script>

export default {
    props: {
        viewType: {
            type: Number,
            default: 0, // 0 - проценты, 1 - значение в валюте
        },
    },
    methods: {
        onChangeViewType(){
            this.$emit('changeViewType');
        }
    },
}
</script>

<style lang="scss" module>
    @import './style.scss';
</style>