<template>
    <GnzsSection header="Синхронизация заказов">
        <template v-slot:description>
            Настройте правила синхронизации заказов
        </template>

        <div :class="w.table">

            <div :class="w.tableRow" v-if="false">
                <div>Создавать контрагента при синхронизации</div>
                <div :class="w.flex">
                    <GnzsDropdownCheckboxes
                        :class="w.w200"
                        v-model="enableContragentCreating"
                        :items="contragentCreatingSyncTypes"
                        :use-empty-item="false"
                    />
                </div>
            </div>

            <div :class="w.tableRow">
                <div>Обновлять ответственного в карточке</div>
                <div>
                    <div :class="[w.withHelper, w.w228]">
                        <GnzsDropdownCheckboxes
                            :class="w.w200"
                            v-model="updateResponsibleUser" 
                            :items="updateResponsibleUserCards"
                            :use-empty-item="false"
                        ></GnzsDropdownCheckboxes>
                        <GnzsHelper side="right" :value="helpers.updateResponsible"/>
                    </div>
                </div>
            </div>

            <div :class="w.tableRow">
                <div :class="w.switcherTitle">Обновлять наименование контрагента</div>
                <div>
                    <div :class="w.withHelperSwitcher">
                        <GnzsSwitcher
                            v-model="updateEntityNames"
                        ></GnzsSwitcher>
                        <GnzsHelper side="right" :value="helpers.updateContragentName"/>
                    </div>
                </div>
            </div>
        </div>

        <div :class="w.subtitle">
            Создание заказа из 1С
        </div>

        <div :class="w.table">
            <div :class="w.tableRow">
                <div>При создании заказа в 1С</div>
                <div>
                    <GnzsDropdown 
                        :class="w.w200"
                        v-model="orderAttachType"
                        :items="orderAttachTypes"
                    ></GnzsDropdown>
                </div>
            </div>

            <div v-if="orderAttachType==2" :class="w.tableRow">
                <div>Этапы поиска сделки</div>
                <div>
                    <GnzsDropdownCheckboxes 
                        :class="w.w200"
                        v-model="orderAttachSearchStatuses"
                        :items="amoStatuses"
                    />
                </div>
            </div>

            <div v-if="orderAttachType!=3" :class="w.tableRow">
                <div>Добавлять теги</div>
                <div>
                    <GnzsInput 
                        :class="w.w200" 
                        v-model="orderAttachTags" 
                        placeholder="Перечислите теги через запятую" 
                    />
                </div>
            </div>
        </div>


    </GnzsSection>
</template>

<script>
import {mapGetters} from 'vuex';

import GnzsHelper from '@/gnzs-controls/Helper';
import GnzsSection from '@/gnzs-controls/Section';
import GnzsInput from '@/gnzs-controls/Input';
import GnzsDropdown from '@/gnzs-controls/Dropdown';
import GnzsSwitcher from '@/gnzs-controls/Switcher';
import GnzsDropdownCheckboxes from '@/gnzs-controls/DropdownCheckboxes';

const mapFieldModel = function(fieldName){
    return {
        get(){
            return this.$store.getters[`settings/orderAttachment1cToAmo`][fieldName];
        },
        set(value){
            this.$store.dispatch('settings/setOrderAttachment1cToAmoData', {field: fieldName, value});
        }
    }
}

const mapFieldModelDefaults = function(fieldName){
    return {
        get(){
            return this.$store.getters['settings/defaults'][fieldName];
        },
        set(value){
            this.$store.dispatch('settings/setDefaultsField', {field: fieldName, value});
        }
    }
}

export default {
    computed: {
        ...mapGetters({
            helpers: 'localSettings/helpers',
            orderAttachTypes: 'localSettings/orderAttachTypes',
            amoStatuses: 'amo/statusesDropdownItems',
            updateResponsibleUserCards: 'localSettings/updateResponsibleUserCards',
            contragentCreatingSyncTypes: 'localSettings/syncTypes',
        }),
        orderAttachType: { ...mapFieldModel('type') },
        orderAttachSearchStatuses: { ...mapFieldModel('searchStatuses') },
        orderAttachTags: { ...mapFieldModel('tags') },
        updateEntityNames: {...mapFieldModelDefaults.call(this, 'updateEntityNames')},
        updateResponsibleUser: {...mapFieldModelDefaults.call(this, 'updateResponsibleUser')},
        enableContragentCreating: { ...mapFieldModelDefaults.call(this, 'enableContragentCreating') },

    },
    components: {
        GnzsSection,
        GnzsInput,
        GnzsDropdown,
        GnzsSwitcher,
        GnzsDropdownCheckboxes,
        GnzsHelper
    }
}
</script>


<style lang="scss" module="w">
    @import '@/assets/scss/Settings/style.scss';
</style>