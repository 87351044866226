<template>
    <Modal ref="modal" :closable="!!organizationId">
        <template v-slot>
            <div v-if="organizationId">
                <div :class="$style.header">
                    Новый договор
                </div>

                <div :class="$style.body">
                    <!-- Номер договора -->
                    <div :class="$style.row">
                        <div :class="[$style.cell, numberRequired ? $style.required : '']">
                            Номер договора
                        </div>
                        <div :class="$style.cell" ref="contractNumber">
                            <GnzsInput 
                                tabindex="1" 
                                v-model="number" 
                            />
                        </div>
                    </div>

                    <!-- Наименование -->
                    <div :class="$style.row">
                        <div :class="[$style.cell, nameRequired ? $style.required : '']">
                            Наименование
                        </div>
                        <div :class="$style.cell" data-code="name" >
                            <GnzsInput 
                                tabindex="2" 
                                v-model="contractName" 
                            />
                        </div>
                    </div>

                    <!-- Дата начала -->
                    <div :class="$style.row">
                        <div :class="[$style.cell, dateRequired ? $style.required : '']">
                            Дата начала
                        </div>
                        <DatePicker :class="$style.dateInput"
                                    :value="date" @input="changeDate($event, 'date')"
                                    type="date"
                                    :format="'DD.MM.YY'"
                                    :clearable="false" 
                        >
                            <template v-slot:icon-calendar>
                                <svg :class="$style.calendar">
                                    <use xlink:href="#card-calendar"></use>
                                </svg>
                            </template>
                        </DatePicker>
                    </div>

                    <!-- Дата окончания -->
                    <div :class="$style.row">
                        <div :class="[$style.cell, dateToRequired ? $style.required : '']">
                            Дата окончания
                        </div>
                        <DatePicker :class="$style.dateInput"
                                    :value="dateTo" @input="changeDate($event, 'date_to')"
                                    type="date"
                                    :format="'DD.MM.YY'"
                                    :clearable="false" 
                        >
                            <template v-slot:icon-calendar>
                                <svg :class="$style.calendar">
                                    <use xlink:href="#card-calendar"></use>
                                </svg>
                            </template>
                        </DatePicker>
                    </div>

                    <div :class="$style.row" v-for="(param, index) in settingsContractCFs" :key="index">
                        <div :class="[$style.cell, $style.title]">{{param.title}}</div>
                        <div :class="$style.cell">
                            <GnzsInput  v-if="param.type=='text'" 
                                :value="contractCfs(param.attribute)"
                                :type="'text'"
                                @input="setContractCf({attribute: param.attribute, value: $event})" 
                            />
                            <GnzsInput  v-else-if="param.type=='int'" 
                                :value="contractCfs(param.attribute)"
                                :type="'number'"
                                @input="setContractCf({attribute: param.attribute, value: $event})" 
                            />
                            <GnzsSwitcher v-else-if="param.type=='bool'" 
                                :value="contractCfs(param.attribute)"
                                @input="setContractCf({attribute: param.attribute, value: $event})" 
                            />
                            <DatePicker v-else-if="param.type=='date'"  :value="contractCfs(param.attribute)" @change="setContractCf({attribute: param.attribute, value: $event})" />
                            <GnzsDropdown v-else 
                                :value="contractCfs(param.attribute)" 
                                :isSearchable="typesDataTotal({code:param.code, type:param.type}) > 10"
                                :isChuncked="typesDataTotal({code:param.code, type:param.type}) > 50"
                                :isLoading="isTypesDataloading({code:param.code, type:param.type})"
                                :items="typesData({code:param.code, type:param.type})"
                                :searchedItems="typesDataSearched({code:param.code, type:param.type})"
                                @search="typesDataSearch({code:param.code, type:param.type, query: $event})"
                                @input="setContractCf({attribute: param.attribute, code: param.code, value: $event })"
                                @scrollend="onScrollEnd({code: param.code, type: param.type})"
                                :use-empty-item="false"
                            />
                        </div>
                    </div>
                </div>
                <div :class="$style.actions">
                    <GnzsButton 
                        type="primary" 
                        @click="createContract" 
                        :loading="isCreating"
                        :disabled="!allowCreate"
                    >Создать договор</GnzsButton>
                    <GnzsButton type="cancel" @click="closeModal">Отмена</GnzsButton>
                </div>
            </div>
            <div v-else :class="$style.innerCentered">
                <div>Для создания договора необходимо выбрать Юр. лицо</div>
            </div> 
        </template>
    </Modal>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';

import moment from 'moment';
import Modal from '@/gnzs-controls/Modal';

import GnzsInput from '@/gnzs-controls/Input';
import GnzsButton from '@/gnzs-controls/Button';
import GnzsSwitcher from '@/gnzs-controls/Switcher';
import GnzsDropdown from '@/gnzs-controls/Dropdown';

import DatePicker from 'vue2-datepicker'
import '@/assets/datepicker/ru';
import '@/assets/datepicker/datepicker.css';

// функция для создания методов вычисляемого поля, которое может работать с директивой v-model
const mapFieldModel = function(fieldName){
    return {
        get(){
            return this.$store.getters['contracts/contract'][fieldName];
        },
        set(value){
            this.$store.dispatch('contracts/setContractField', {field: fieldName, value});
        }
    }
}

export default {
    computed: {
        ...mapGetters({
            isCreating: 'contracts/isCreating',
            contractCfs: 'contracts/contractCfs',
            settingsContractCFs: 'settings/contractCFs',
            typesData: 'additionalTypes/itemData',
            typesDataTotal: 'additionalTypes/itemDataTotal',
            isTypesDataloading: 'additionalTypes/isItemDataLoading',
            typesDataSearched: 'additionalTypes/itemDataSearched',
            isError: 'contracts/isError',
            dateRequired: 'settings/contractDateRequired',
            dateToRequired: 'settings/contractDateToRequired',
            nameRequired: 'settings/contractNameRequired',
            numberRequired: 'settings/contractNumberRequired',
            organizationId: 'order/organization1cId'
        }),
        number: { ...mapFieldModel.call(this, 'number') },
        contractName: { ...mapFieldModel.call(this, 'name') },
        date: { ...mapFieldModel.call(this, 'date') },
        dateTo: { ...mapFieldModel.call(this, 'date_to') },

        dateRequiredAndFilled(){
            return this.dateRequired ? moment(this.date).format('DD.MM.YYYY') !== 'Invalid date' : true
        },

        dateToRequiredAndFilled(){
            return this.dateToRequired ? moment(this.dateTo).format('DD.MM.YYYY') !== 'Invalid date' : true
        },

        nameRequiredAndFilled(){
            return this.nameRequired ? this.contractName.length : true
        },

        numberRequiredAndFilled(){
            return this.numberRequired ? this.number.length : true
        },

        allowCreate(){
            return this.nameRequiredAndFilled && this.numberRequiredAndFilled && this.dateRequiredAndFilled && this.dateToRequiredAndFilled;
        }
    },
    methods: {
        ...mapActions({
            setContractCf: 'contracts/setContractCf',
            setContractField: 'contracts/setContractField',
            typesDataSearch: "additionalTypes/searchItems",
            load1ctypeItems: 'additionalTypes/load1ctypeItems',
        }),

        closeModal(){
            this.$refs.modal.close()
        },

        async onScrollEnd({code, type}){
            await this.load1ctypeItems(code, type)
        },
        
        async createContract(){
            await this.$store.dispatch('contracts/create');
            if (!this.isError) this.closeModal();
        },

        changeDate(value, field){
            this.setContractField({field, value})
        },
    },
    mounted(){
        if (!this.organizationId) return
        const inputContractNumber = this.$refs['contractNumber'].querySelector('input');
        inputContractNumber.focus();
    },
    components: {
        Modal,
        GnzsInput,
        GnzsButton,
        GnzsSwitcher,
        GnzsDropdown,
        DatePicker
    }
}
</script>


<style lang="scss" module>
    @import './AddContractModal.scss';
</style>