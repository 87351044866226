import { api1C } from './api';

export const createElement = (data) => api1C.post('additionalDirectories', data);
export const editElement = (data) => api1C.post('additionalDirectories', data, {params: {edit: 1}});

export const load1ctypes = (params) => api1C.get(`additionalDirectoriesList`, { params });

export const load1ctypesItems = ({entityId, entityType}, params) => {
    if (!params?.entity_type){
        let type = entityType
        if (type) params.entity_type = type
    }
    if (!params?.entity_id){
        let id = entityId
        if (id) params.entity_id = id
    }
    return api1C.get(`additionalDirectories`, {
        params,
    });
}


    