/**
 * @author Ilya Orishin. 2021
 * 
 * Функция определения является ли строка JSON строкой
 * @param {string} str - входная строка
 * @returns {bool}
 */
export default function (str){
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}
