<template>
  <GnzsSection
    header="Массовые действия"
    :disabled="!tariffFeatures.includes('mass-actions')"
    :disabledText="disabledProSectionText">
    <template v-slot:description>Функционал настройки массовых действий со сделками</template>

    <div v-if="!isInsideAmo && tariffFeatures.includes('mass-actions')" :class="w.rIc">
      <GnzsHelper side="left" type="service" :value="helpers.serviceUseOnly" />
    </div>

    <div :class="w.table">
      <div :class="[w.tableHeader, $style.tableHeader]" v-if="massActions.length">
        <div>Название действия</div>
        <div>Код действия</div>
      </div>

      <!-- Дополнительные поля сущности -->
      <template>
        <div :class="w.tableRow" v-for="(action, index) in massActions" :key="index">
          <div>
            <GnzsInput
              :value="action.name"
              :class="[action.name.length ? '' : $style.inputInvalid]"
              placeholder="Название действия"
              @input="setMassAction({ index, name: $event })" />
          </div>
          <div>
            <GnzsInput
              :value="action.code"
              :class="[action.code.length ? '' : $style.inputInvalid]"
              placeholder="Код действия"
              @keydown="cyrillicKeyDown"
              @input="setMassAction({ index, code: $event })" />
          </div>
          <div>
            <GnzsButton type="remove" @click="removeMassAction(index)" />
          </div>
        </div>
      </template>
    </div>

    <div :class="$style.appendButtonContainer">
      <GnzsButton type="append" @click="appendMassAction">Добавить действие</GnzsButton>
    </div>
  </GnzsSection>
</template>

<script>
  import { mapActions, mapGetters } from "vuex";
  import GnzsSection from "@/gnzs-controls/Section";
  import GnzsInput from "@/gnzs-controls/Input";
  import GnzsHelper from "@/gnzs-controls/Helper";
  import GnzsButton from "@/gnzs-controls/Button";
  import { cyrillicKeyDown } from "@/utilities/inputUtils";

  export default {
    computed: {
      ...mapGetters({
        isInsideAmo: "isInsideAmo",
        massActions: "settings/massActions",
        helpers: "localSettings/helpers",
        tariffFeatures: "features/getFeatures",
        disabledProSectionText: "localSettings/disabledProSectionText",
      }),
    },
    methods: {
      ...mapActions({
        appendMassAction: "settings/appendMassAction",
        setMassAction: "settings/setMassAction",
        removeMassAction: "settings/removeMassAction",
      }),
      cyrillicKeyDown(e) {
        cyrillicKeyDown(e);
      },
    },
    components: {
      GnzsSection,
      GnzsInput,
      GnzsHelper,
      GnzsButton,
    },
  };
</script>

<style lang="scss" module="w">
  @import "@/assets/scss/Settings/style.scss";
</style>

<style lang="scss" module>
  @import "./style.scss";
</style>
