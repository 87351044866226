import store from '@/store'

export const getHeaders = () => { 
    return {
        widgetId: store.state.widgetId,
        accountId:store.state.amoAccountId,
        token: store.state.token
    }
}

export const apiCoreInterceptor = (config) => {
    const { headers } = config;
    const {widgetId, accountId, token} = getHeaders()
    headers['X-Widget-Id'] = widgetId
    headers['X-Account-Id'] = accountId
    headers['X-Auth-Token'] = token

    return config;
};