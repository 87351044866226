<template>
    <div :class="$style.container">
        <div 
            v-if="!isOpened" 
        >
            <GnzsButton 
                :class="$style.buttonOpen"
                @click="open"
            >Добавить позицию</GnzsButton>
        </div>

        <template v-else>
            <div>
                <SearchInput @closeClick="close" />
            </div>
            <div>
                <SearchResults />
            </div>
        </template>
       
    </div>
</template>



<script>

import GnzsButton from '@/gnzs-controls/Button';
import SearchInput from '../SearchInput/SearchInput';
import SearchResults from '../SearchResults/SearchResults';

import $ from 'jquery'
import {mapActions} from 'vuex';

export default {
    data() {
        return {
            isOpened: false,
            firstOpened: false,
        }
    },
    methods: {
        ...mapActions({
            search: 'products/search'
        }),
        open(){
            this.isOpened = true;

            if(!this.firstOpened){
                this.firstOpened = true;
                this.search()
            }

            setTimeout(() => {
                const container = $('.gnzs-search-tree-container')

                const saved = localStorage.getItem('gnzs-1c-product-table-width')

                if (saved){
                    container.first().css('width', `${saved}px`)
                } else {
                    container.first().css('width', `294px`)
                }
            }, 1)
            
        },
        close(){
            this.isOpened = false;
        }
    },
    components: {
        GnzsButton,
        SearchInput,
        SearchResults,
    }
}
</script>



<style lang="scss" module>
    @import './SearchBlock.scss';
</style>