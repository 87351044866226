<template>
  <GnzsSection :disabled="!tariffFeatures.includes('table-hide-disable-columns')" header="Конструктор табличной части">
    <template v-slot:description>
      Укажите столбы, которые будут отображаться и редактироваться в табличной части заказа
    </template>

    <div :class="$style.paramsHeader">Основные столбцы</div>
    <div :class="w.table">
      <div :class="w.tableHeader">
        <div>Название столбца</div>
        <div :class="$style.checkboxColumn">Отображение</div>
        <div :class="$style.checkboxColumn">Редактирование</div>
        <div></div>
      </div>

      <div :class="w.tableRow" v-for="(row, index) in tableFields" :key="`fc_${index}`">
        <div>{{ row.name }}</div>
        <div :class="$style.cellRequired">
          <div :class="[w.cellInner, $style.checkboxRequireContainer]">
            <GnzsCheckbox
              :value="tableField({ key: row.key, action: 'show' })"
              @input="setTableField({ key: row.key, action: 'show', required: $event })"
              :disabled="tableField({ key: row.key, action: 'disabled_show' })" />
          </div>
        </div>
        <div :class="$style.cellRequired">
          <div :class="[w.cellInner, $style.checkboxRequireContainer]">
            <GnzsCheckbox
              :value="tableField({ key: row.key, action: 'edit' })"
              @input="setTableField({ key: row.key, action: 'edit', required: $event })"
              :disabled="tableField({ key: row.key, action: 'disabled_edit' })" />
          </div>
        </div>
      </div>
    </div>

    <template v-if="tariffFeatures.includes('table-builder')">
      <div :class="$style.paramsHeader">Дополнительные столбцы</div>
      <div :class="w.table">
        <div v-if="tableFieldsCustom.length" :class="w.tableHeader">
          <div>Название столбца</div>
          <div>Реквизит</div>
          <div :class="w.w200">Тип</div>
          <div :class="$style.w100">Редактирование</div>
          <div :class="[w.w200, $style.taCenter]">Обязательное</div>
          <div :class="[w.w200, $style.taCenter]">Суммировать в итог</div>
          <div></div>
        </div>

        <div :class="w.tableRow" v-for="(row, index) in tableFieldsCustom" :key="`cc_${index}`">
          <div>
            <GnzsInput
              :value="tableField({ key: row.key, action: 'name' })"
              @input="setTableField({ key: row.key, action: 'name', required: $event })"
              placeholder="Название столбца" />
          </div>
          <div>
            <GnzsInput
              :value="tableField({ key: row.key, action: 'attribute' })"
              @input="setTableField({ key: row.key, action: 'attribute', required: $event })"
              placeholder="Реквизит 1С" />
          </div>
          <div>
            <GnzsDropdown
              :class="w.w200"
              :items="orderParamTypes"
              :useEmptyItem="false"
              :value="tableField({ key: row.key, action: 'type' })"
              @input="setTableField({ key: row.key, action: 'type', required: $event })"></GnzsDropdown>
          </div>
          <div :class="$style.cellRequired">
            <div :class="[w.cellInner, $style.checkboxRequireContainer]">
              <GnzsCheckbox
                :value="tableField({ key: row.key, action: 'edit' })"
                @input="setTableField({ key: row.key, action: 'edit', required: $event })" />
            </div>
          </div>
          <div :class="$style.cellRequired">
            <div :class="[w.cellInner, $style.checkboxRequireContainer]">
              <GnzsCheckbox
                :value="tableField({ key: row.key, action: 'required' })"
                @input="setTableField({ key: row.key, action: 'required', required: $event })" />
            </div>
          </div>
          <div :class="$style.cellRequired">
            <div :class="[w.cellInner, $style.checkboxRequireContainer]">
              <GnzsCheckbox
                :disabled="tableField({ key: row.key, action: 'type' }) != 'int'"
                :value="tableField({ key: row.key, action: 'sum' })"
                @input="setTableField({ key: row.key, action: 'sum', required: $event })" />
            </div>
          </div>
          <div>
            <GnzsButton type="remove" @click="removeTableField(row.key)" />
          </div>
        </div>
      </div>

      <div :class="$style.appendButtonContainer">
        <GnzsButton type="append" @click="addTableField">Добавить столбец</GnzsButton>
      </div>
    </template>
    <template v-else>
      <div :class="$style.cover">
        <div :class="$style.builderDisabledText">
          Добавление дополнительных столбцов доступно только на расширенном тарифе
        </div>
      </div>
      <div>
        <div :class="$style.appendButtonContainer">
          <GnzsButton type="append">Добавить столбец</GnzsButton>
        </div>
      </div>
    </template>
  </GnzsSection>
</template>

<script>
  import GnzsSection from "@/gnzs-controls/Section";
  import GnzsButton from "@/gnzs-controls/Button";
  import GnzsCheckbox from "@/gnzs-controls/Checkbox";
  import GnzsInput from "@/gnzs-controls/Input";
  import GnzsDropdown from "@/gnzs-controls/Dropdown";
  import { mapActions, mapGetters } from "vuex";

  export default {
    computed: {
      ...mapGetters({
        orderParamTypes: "localSettings/orderParamTypes",
        tableField: "settings/tableField",
        tableFields: "settings/tableFields",
        tableFieldsCustom: "settings/tableFieldsCustom",
        tariffFeatures: "features/getFeatures",
      }),
    },
    methods: {
      ...mapActions({
        addTableField: "settings/addSettingsTableField",
        setTableField: "settings/setTableFieldRequired",
        removeTableField: "settings/removeSettingsTableField",
      }),
    },
    components: {
      GnzsSection,
      GnzsButton,
      GnzsCheckbox,
      GnzsInput,
      GnzsDropdown,
    },
  };
</script>

<style lang="scss" module="w">
  @import "@/assets/scss/Settings/style.scss";
</style>

<style lang="scss" module>
  @import "./style.scss";
</style>
