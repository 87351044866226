var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',{class:[
        _vm.$style.catalogItem,
        {
            [_vm.$style.catalogItem_opened]: _vm.item.isOpened,
            [_vm.$style.catalogItem_searched]: _vm.selectedCatalogId==_vm.item.id
            }
        ],attrs:{"has-childs":_vm.item.tree.length ? 'Y' : 'N',"data-level":_vm.level}},[_c('div',{class:_vm.$style.catalogTitle,attrs:{"title":_vm.item.name},on:{"click":_vm.onItemClick}},[_vm._v(_vm._s(_vm.item.name))]),(_vm.item.isLoadedChilds && _vm.item.tree.length)?_c('ul',{class:_vm.$style.catalogs,attrs:{"data-level":_vm.level+1}},_vm._l((_vm.item.tree),function(childItem){return _c('search-tree-item',{key:childItem.id,attrs:{"item":childItem,"level":_vm.level+1}})}),1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }