<template>
  <GnzsSection header="Движение по воронке">
    <template v-slot:description>
      Настройте движения и перехода сделок между этапами при определенных событиях
    </template>

    <div :class="$style.stopBlock">
      <GnzsCheckbox
        v-model="pipelineEvents.disableReversePipelineMove"
        label="Запретить обратное движение по воронке"></GnzsCheckbox>
    </div>

    <div :class="$style.stopBlock" v-if="!isInsideAmo">
      <GnzsCheckbox
        v-model="pipelineEvents.enableMoveFromClosedStatus"
        label="Разрешить движение из закрытых статусов"></GnzsCheckbox>
      <GnzsHelper side="right" type="service" :value="helpers.serviceUseOnly" />
    </div>

    <div :class="$style.subtitle">События из 1С</div>

    <div :class="w.table">
      <div :class="w.tableHeader" v-if="pipelineEvents1cToAmo.length">
        <div :class="$style.mw215">Событие</div>
        <div :class="$style.mw215">Этапы срабатывания</div>
        <div :class="$style.mw215">Перевести в этап</div>
      </div>
      <div :class="w.tableRow" v-for="(eventRow, index) in pipelineEvents1cToAmo" :key="index">
        <div :class="$style.cell">
          <GnzsDropdown :class="w.w250" v-model="eventRow.type" :items="eventTypes1cToAmo" :use-empty-item="false" />
        </div>
        <div>
          <GnzsDropdownCheckboxes
            :is-searchable="true"
            :class="[w.w250, $style.statusesFrom]"
            v-model="eventRow.statusesFrom"
            :items="amoStatusesWithClosed" />
        </div>
        <div :class="$style.cell">
          <GnzsDropdown
            :is-searchable="true"
            :class="w.w250"
            v-model="eventRow.statusTo"
            :items="amoStatusesAll"
            :use-empty-item="false" />
        </div>
        <div :class="$style.cell">
          <GnzsButton :class="$style.buttonRemove" type="remove" @click="removeRow('events1cToAmo', index)" />
        </div>
      </div>
    </div>

    <GnzsButton :class="$style.buttonAppend" type="append" @click="addRow('events1cToAmo')"
      >Добавить настройку</GnzsButton
    >

    <div :class="$style.subtitle"><span>События в </span><span :class="$style.amocrmTitle">amoCRM</span></div>

    <div :class="w.table">
      <div :class="w.tableHeader" v-if="pipelineEventsAmoTo1c.length">
        <div :class="$style.mw215">Событие</div>
        <div :class="$style.mw215">Этапы срабатывания</div>
        <div :class="$style.mw215">Перевести в этап</div>
      </div>
      <div :class="w.tableRow" v-for="(eventRow, index) in pipelineEventsAmoTo1c" :key="`_${index}`">
        <div :class="$style.cell">
          <GnzsDropdown
            :class="w.w250"
            :value="getAmoEventRowValue(eventRow)"
            :items="eventTypesAmoTo1c"
            :use-empty-item="false"
            @input="onChangeAmoEvent(eventRow, $event)" />
        </div>
        <div :class="$style.cell">
          <GnzsDropdownCheckboxes :class="w.w250" v-model="eventRow.statusesFrom" :items="amoStatusesWithClosed" />
        </div>
        <div :class="$style.cell">
          <GnzsDropdown :class="w.w250" v-model="eventRow.statusTo" :items="amoStatusesAll" :use-empty-item="false" />
        </div>
        <div :class="$style.cell">
          <GnzsButton :class="$style.buttonRemove" type="remove" @click="removeRow('eventsAmoTo1c', index)" />
        </div>
      </div>
    </div>

    <GnzsButton :class="$style.buttonAppend" type="append" @click="addRow('eventsAmoTo1c')"
      >Добавить настройку</GnzsButton
    >
  </GnzsSection>
</template>

<script>
  import { mapGetters } from "vuex";
  import GnzsSection from "@/gnzs-controls/Section";
  import GnzsButton from "@/gnzs-controls/Button";
  import GnzsDropdown from "@/gnzs-controls/Dropdown";
  import GnzsCheckbox from "@/gnzs-controls/Checkbox";
  import GnzsDropdownCheckboxes from "@/gnzs-controls/DropdownCheckboxes";
  import GnzsHelper from "@/gnzs-controls/Helper";

  export default {
    computed: {
      ...mapGetters({
        amoStatusesAll: "amo/allStatuses",
        amoStatuses: "amo/statusesDropdownItems",
        amoStatusesWithClosed: "amo/statusesWithClosedDropdownItems",
        eventTypes1cToAmo: "localSettings/eventTypes1cToAmo",
        eventTypesAmoTo1c: "localSettings/eventTypesAmoTo1c",
        pipelineEvents: "settings/pipelineEvents",
        pipelineEvents1cToAmo: "settings/pipelineEvents1cToAmo",
        pipelineEventsAmoTo1c: "settings/pipelineEventsAmoTo1c",
        helpers: "localSettings/helpers",
        isInsideAmo: "isInsideAmo",
      }),
      amoStatusesDropdown() {
        return this.pipelineEvents.disableReversePipelineMove ? this.amoStatuses : this.amoStatusesWithClosed;
      },
    },
    methods: {
      addRow(sectionType) {
        this.$store.dispatch("settings/addPipelineEvent", sectionType);
      },
      removeRow(sectionType, index) {
        this.$store.dispatch("settings/removePipelineEvent", { sectionType, index });
      },
      getAmoEventRowValue(eventRow) {
        let res = `${eventRow.type}__${eventRow.action}`;
        if (eventRow.content) res += "__" + eventRow.content;

        return res;
      },
      onChangeAmoEvent(eventRow, value) {
        const [type, action, content = ""] = value.split("__");

        this.$store.dispatch("settings/setPipelineAmoEvent", {
          row: eventRow,
          type,
          action,
          content,
        });
      },
    },
    components: {
      GnzsSection,
      GnzsButton,
      GnzsDropdown,
      GnzsCheckbox,
      GnzsDropdownCheckboxes,
      GnzsHelper,
    },
  };
</script>

<style lang="scss" module>
  @import "./style.scss";
</style>

<style lang="scss" module="w">
  @import "@/assets/scss/Settings/style.scss";
</style>
