<template>
    <div :class="$style.container">
        <div :class="$style.container__inner">
            <div v-if="isSignatoriesRequired" :class="$style.controlsWrapper">
                    <div :class="$style.title">Подписант</div>
                    <div :class="$style.dropdownContainer">
                        <GnzsDropdown 
                            :class="$style.dropdown"
                            v-model="selectedSignatoryId"
                            :is-searchable="realizations.length > 10"
                            :items="signatories" 
                            :is-grouped="true"
                            :disabled="isCreatingDocument || !hasOrder1c"
                        />
                    </div>
            </div>
            <div :class="$style.controlsContainer">
                <div :class="$style.controlsWrapper">
                    <div :class="$style.title">Документ</div>
                    <div :class="$style.dropdownContainer">
                        <GnzsDropdown 
                            :class="$style.dropdown"
                            v-model="selectedTemplateId"
                            :is-searchable="dropdownTemplateItems.length > 10"
                            :items="dropdownTemplateItems" 
                            :use-empty-item="false"
                            @input="onTemplateChange"
                            :disabled="isCreatingDocument || !hasOrder1c"
                        />
                    </div>
                </div>
                <div v-if="!isRealizationChangeRequired && !isInvoiceChangeRequired" :class="$style.buttonContainer">
                    <GnzsButton 
                        :class="$style.button"
                        @click="createDocument" 
                        :loading="isCreatingDocument"
                        :disabled="!hasOrder1c"
                    >Создать</GnzsButton>
                </div>
            </div>
            <div v-if="isRealizationChangeRequired" :class="$style.controlsContainer">
                <div :class="$style.controlsWrapper">
                    <div :class="$style.title">Реализация</div>
                    <div :class="$style.dropdownContainer">
                        <GnzsDropdown 
                            :class="$style.dropdown"
                            v-model="selectedRealizationId"
                            :is-searchable="realizations.length > 10"
                            :items="realizations" 
                            :use-empty-item="false"
                            :disabled="isCreatingDocument || !hasOrder1c"
                        />
                    </div>
                </div>
                <div :class="$style.buttonContainer">
                    <GnzsButton 
                        :class="$style.button"
                        @click="createDocument" 
                        :loading="isCreatingDocument"
                        :disabled="!selectedRealizationId"
                    >Создать</GnzsButton>
                </div>
            </div>
            <div v-if="isInvoiceChangeRequired" :class="$style.controlsContainer">
                <div :class="$style.controlsWrapper">
                    <div :class="$style.title">Счета</div>
                    <div :class="$style.dropdownContainer">
                        <GnzsDropdown
                            :class="$style.dropdown"
                            v-model="selectedInvoiceId"
                            :is-searchable="realizations.length > 10"
                            :items="invoices"
                            :use-empty-item="false"
                            :disabled="isCreatingDocument || !hasOrder1c"
                        />
                    </div>
                </div>
                <div :class="$style.buttonContainer">
                    <GnzsButton
                        :class="$style.button"
                        @click="createDocument"
                        :loading="isCreatingDocument"
                        :disabled="!selectedInvoiceId"
                    >Создать</GnzsButton>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import GnzsDropdown from '@/gnzs-controls/Dropdown';
import GnzsButton from '@/gnzs-controls/Button';

export default {
    computed: {
        ...mapGetters({
            hasOrder1c: 'order/hasOrder1c',
            orderId: 'order/orderId',
            isLoadingRealizations: 'realization/isLoadingTitles',
            selectedTid: 'templates/selectedTemplateId',
            selectedRid: 'templates/selectedRealizationId',
            selectedIid: 'templates/selectedInvoiceId',
            selectedSId: 'templates/selectedSignatoryId',
            dropdownTemplateItems: 'templates/dropdownItems',
            getTemplateById: 'templates/getTemplateById',
            isCreatingDocument: 'documents/creating',
            realizations: 'realizations/titles',
            features: 'features/getFeatures',
            defaults: 'settings/defaults',
            signatories: 'signatories/dropdownItems'
        }),
        selectedSignatoryId: {
            get(){
                return this.selectedSId
            },
            set(id){
                this.setSelectedSignatoryId(id);
            }
        },
        selectedTemplateId: {
            get(){
                return this.selectedTid
            },
            set(id){
                this.setSelectedTemplateId(id);
            }
        },
        selectedRealizationId: {
            get(){
                return this.selectedRid
            },
            set(id){
                this.setSelectedRealizationId(id);
            }
        },
        selectedInvoiceId: {
            get(){
                return this.selectedIid
            },
            set(id){
                this.setSelectedInvoiceId(id);
            }
        },
        closingTemplate(){
            return this.getTemplateById(this.selectedTemplateId)?.closing
        },
        invoiceTemplate(){
            return this.getTemplateById(this.selectedTemplateId)?.invoice
        },
        isRealizationChangeRequired(){
            return this.defaults.isPartialRealizationEnabled && this.features.includes('partial-realization') && this.closingTemplate
        },
        isInvoiceChangeRequired(){
            return this.defaults.isInvoicesEnabled && this.features.includes('partial-invoices') && this.invoiceTemplate // TODO CONSTANT + TYPE
        },
        isSignatoriesRequired(){
            return this.defaults.isSignatoriesEnabled && this.features.includes('document-signer')
        }

    },
    methods: {
        ...mapActions({
            create: 'documents/create',
            setSelectedTemplateId: 'templates/setSelectedTemplateId',
            setSelectedRealizationId: 'templates/setSelectedRealizationId',
            setSelectedInvoiceId: 'templates/setSelectedInvoiceId',
            setSelectedSignatoryId: 'templates/setSelectedSignatoryId',
            loadRealizations: 'realizations/loadTitles',
            setRealizationValue: 'realizations/setValue'
        }),
        async onTemplateChange(){
            if (this.closingTemplate){
                await this.loadRealizations()
            }
        },
        createDocument(){
            this.create()
        },
    },
    created(){
        if (this.selectedTemplateId){
            this.onTemplateChange()
        }
    },
    components: {
        GnzsDropdown,
        GnzsButton
    }
}
</script>


<style lang="scss" module>
    @import './CreateDocument.scss';
</style>