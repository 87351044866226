<template>
    <Modal ref="modal">
        <template v-slot>
            <div :class="$style.wrapper">
                <span class="modal-body__close" ref="close">
                <span class="icon icon-modal-close"></span>
                </span>

                <div :class="$style.header">
                    Применить скидку
                </div>

                <div :class = "$style.controls">
                    <GnzsInputDiscount 
                        :viewType="generalDiscount.viewType"
                        @changeViewType="changeViewType"
                    >
                        <template v-slot:discountPercent>
                            <GnzsInput 
                                v-model="generalDiscount.discount"
                            />
                        </template>
                        <template v-slot:discountCurrency>
                            <GnzsInput 
                                v-model="generalDiscount.total_discount"
                            />
                        </template>
                    </GnzsInputDiscount>
                </div>

                <div :class="$style.actions">
                    <GnzsButton 
                        type="primary" 
                        @click="apply"
                        :disabled="!isAppliableDiscount"
                    >Применить</GnzsButton>
                    <GnzsButton type="cancel" @click="closeModal">Отмена</GnzsButton>
                </div>
            </div>
        </template>
    </Modal>
</template>

<script>

import Modal from '@/gnzs-controls/Modal';

import GnzsInput from '@/gnzs-controls/Input';
import GnzsButton from '@/gnzs-controls/Button';
import GnzsInputDiscount from '@/gnzs-controls/InputDiscount';

import {mapActions, mapGetters} from 'vuex';

export default {
    computed: {
        ...mapGetters({
            isAppliableDiscount: 'order/isAppliableDiscount',
            generalDiscount: 'order/generalDiscount',
        })
    },
    methods: {
        ...mapActions({
            applyGeneralDiscount: 'order/applyGeneralDiscount',
            changeViewType: 'order/changeGeneralDiscountViewType',
            setCheckedAll: 'order/setCheckedAll'
        }),
        apply(){
            this.applyGeneralDiscount()
            this.setCheckedAll()
            this.closeModal();
        },
        closeModal(){
            this.$refs.modal.close()
        },
    },
    components: {
        Modal,
        GnzsButton,
        GnzsInput,
        GnzsInputDiscount
    }
}
</script>

<style lang="scss" module>
    @import './AddDiscountModal.scss';
</style>