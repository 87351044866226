import { api1C } from "./api";

export const save1cOrder = (data, params) => api1C.post("order-docflow", data, { params });

export const copy1cOrder = (data, params) => api1C.post("order-copy", data, { params });

export const remove1cOrder = (data, params) => api1C.post("order-remove", data, { params });

// DEPRECATED
export const calcAutoDiscount = (data, params = {}) => api1C.post("auto-discount", data, { params });

export const extraAction = (data) => api1C.post("additional-buttons", data);
