<template>
    <div :class="$style.root">
        <label :class="$style.label">
            <input 
                type="checkbox" 
                :class="$style.checkbox"
                :checked="!!value"
                :disabled="disabled === true"
                @change="$emit('input', $event.target.checked)"
            >
            <span :class="[$style.helper, {[$style.enabled]: !disabled}]"></span>
        </label>
    </div>
</template>

<script>

export default {
    props: {
        name: {
            type: String,
            default: '',
        },
        disabled: {
            default: false
        },
        value: {
            default: false,
        }
    }
}
</script>

<style lang="scss" module>
    @import './style.scss';
</style>