export default {
  title: 'Distribuição de ofertas',

  tokenError: 'O token de autorização expirou !',

  buttons: {
    append: 'Adicionar',
    back: 'Voltar',
    save: 'Salvar',
    cancel: 'Cancelar',
    templateAppend: 'Anexar modelo de configurações',
  },

  breadcrumbs: {
    templateTitle: 'Novo modelo',
  },

  body: {
    sections: {
      main: 'Configurações básicas',
      groupsAndUsers: 'Grupos e usuários para distribuir',
      additional: 'Configurações adicionais',
    },
    templateName: 'Nome do modelo de configurações',
    distributionType: 'Tipo de distribuição',
    usersQueue: {
      settingsDescription: {
        title: 'Marcar grupos ou usuários individuais',
        show: 'Mais',
        hide: 'Esconder',
      },
      description: 'Se você verificar todo o grupo, a integração distribuirá negócios entre seus usuários, pegando dinamicamente as alterações se você adicionar/remover um usuário do grupo. Se apenas usuários individuais forem marcados, a distribuição ocorrerá apenas entre eles.',
    },
    usersPercent: {
      settingsDescription: {
        title: 'Especificar porcentagens de distribuição',
        show: 'Mais',
        hide: 'Esconder',
      },
      description: 'As porcentagens do grupo devem somar 100%. Além disso, para um grupo, você pode escolher o formato de distribuição entre os usuários desse grupo: selecione a opção "distribuir uniformemente" para que dentro desse grupo a distribuição seja uniforme para todos; se você precisar definir uma proporção de distribuição diferente entre os usuários, selecione a opção "distribuir por porcentagem" - nesse caso, a soma das porcentagens dos usuários do grupo também deve ser igual a 100%.',
      group: 'de 100% por grupos',
      distribute: 'distribuir',
    },
    repeatControl: 'Repita o controle de negócios',
    AssignOnlyWorkime: {
      title: {
        label: 'Distribuir apenas durante o horário comercial',
      },
      workTime: {
        time: 'Tempo de trabalho',
        days: 'Dias de trabalho',
        from: 'de',
        to: 'a',
        weekDays: {
          mon: 'Seg',
          tue: 'Ter',
          wed: 'Qua',
          thu: 'Qui',
          fri: 'Sex',
          sat: 'Sab',
          sun: 'Dom',
        },
      },
      DNA: {
        activated: {
          warning: 'Importante: Os usuários na configuração atual devem corresponder aos usuários na programação selecionada, caso contrário não serão distribuídos.',
        },
        notActivated: {
          warning: 'Atenção! você precisa instalar',
          link: 'DNA widget',
        },
      },
    },
    distributionAgain: {
      title: 'Redistribuir se o estágio não mudar',
      across: 'Através',
      min: 'min',
    },
    changeContacts: 'Alterar o proprietário de um contato e uma empresa',
    changeLeadTasks: 'Alterar o proprietário de tarefas abertas para um negócio',
    changeContactTasks: 'Alterar o proprietário de tarefas abertas por contatos',
    changeCompanyTasks: 'Alterar o proprietário das tarefas abertas por empresa',
  },

  assignTypes: [
    { value: 1, title: 'Faz voltas' },
    { value: 2, title: 'Por interesse' },
  ],

  assignPercentTypes: [
    { value: 1, title: 'uniformemente' },
    { value: 2, title: 'por interesse' },
  ],

  repeatLeadsControlTypes: [
    { value: 1, title: 'Ignorar negociações repetidas' },
    { value: 2, title: 'Distribuir transações repetidas no Contato para o responsável' },
    { value: 3, title: 'Alocar transações repetidas na Empresa para o responsável' },
    { value: 4, title: 'Distribuir transações repetidas por Contato ou Empresa para o responsável' },
  ],

  assignOnlyWorktimeTypes: [
    { value: 1, title: 'de acordo com o cronograma geral' },
    { value: 2, title: 'em uma agenda individual' },
  ],
}