import { apiDocflow } from './api';

export const getTemplates = (params) =>
    apiDocflow.get('templates', {
        params,
    });

export const createTemplate = (data) => apiDocflow.post('templates', data);

export const getTemplateById = (id) => apiDocflow.get(`templates/${id}`);

export const updateTemplateById = (id, data) =>
  apiDocflow.put(`templates/${id}`, data);

export const removeTemplateById = (id) => apiDocflow.delete(`templates/${id}`);
