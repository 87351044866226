<template>
    <GnzsSection
        :disabled="!tariffFeatures.includes('partial-invoices')"
        :disabledText="disabledProSectionText"
        header='Использовать несколько счетов по заказу'>
        <template v-slot:description>
            Если включено, то в модальном окне детализации заказа добавится блок для создания нескольких счетов по заказу.
            Доступно только для конфигурации УНФ
        </template>
        <template v-slot:header-control>
            <GnzsSwitcher
                v-model="defaults.isInvoicesEnabled"
            ></GnzsSwitcher>
        </template>
    </GnzsSection>
</template>

<script>
import { mapGetters } from "vuex";

import GnzsSection from "@/gnzs-controls/Section";
import GnzsSwitcher from "@/gnzs-controls/Switcher";

export default {
    name: "SectionInvoices",
    computed: {
        ...mapGetters({
            defaults: "settings/defaults",
            tariffFeatures: 'features/getFeatures',
            disabledProSectionText: 'localSettings/disabledProSectionText',
        }),
    },
    components: {
        GnzsSection,
        GnzsSwitcher,
    },
};
</script>

<style lang="scss" module="w">
    @import '@/assets/scss/Settings/style.scss';
</style>