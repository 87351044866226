<template>
  <GnzsSection header="Значения по умолчанию для менеджеров">
    <template v-slot:description>
      Сопоставление значений по умолчанию для менеджеров. Значения по-умолчанию для менеджеров будут в приоритете над
      значениями по умолчанию для оргнизаций
    </template>

    <div>
      <div :class="w.table">
        <div :class="w.tableHeader" v-if="managerDefaults.length">
          <div>Менеджер</div>
          <div :class="w.w200">Организация и р/c по умолчанию</div>
          <div :class="w.w200">Склад по умолчанию</div>
          <div></div>
        </div>
        <div :class="w.tableRow" v-for="(row, key) in managerDefaults" :key="key">
          <div :title="userName(row.managerId)" :class="$style.managerName">{{ userName(row.managerId) }}</div>
          <GnzsDropdown
            :class="$style.organization"
            :use-empty-item="true"
            :value="row.organizationAccountId"
            :items="organizations"
            :is-grouped="true"
            @input="onOrganizationAccountChangeHandler(...arguments, row.managerId)" />
          <GnzsDropdown
            :class="w.w200"
            :value="row.stockId"
            :use-empty-item="true"
            :items="stocks"
            @input="setManagerDefaultValue({ target: 'stockId', managerId: row.managerId, value: $event })" />
          <div>
            <GnzsButton type="remove" @click="removeManagerDefaultValue(row.managerId)" />
          </div>
        </div>
        <div :class="w.tableRow" v-if="usersAvaliable.length">
          <GnzsDropdown
            :class="$style.orgDropdown"
            :append="true"
            :items="usersAvaliable"
            :useEmptyItem="false"
            :isSearchable="true"
            :emptyItemTitle="'Добавить менеджера'"
            @input="addManager"></GnzsDropdown>
        </div>
      </div>
    </div>
  </GnzsSection>
</template>

<script>
  import GnzsSection from "@/gnzs-controls/Section";
  import GnzsDropdown from "@/gnzs-controls/Dropdown";
  import GnzsButton from "@/gnzs-controls/Button";

  import { mapActions, mapGetters } from "vuex";

  export default {
    computed: {
      ...mapGetters({
        organizations: "organizations/orderItems",
        stocks: "stocks/dropdownItems",
        managerDefaults: "settings/managerDefaults",
        defaults: "settings/defaults",
        users: "amo/users",
      }),
      usersAvaliable() {
        let existed = this.managerDefaults.map((val) => val.managerId);
        return this.users.filter((manager) => !existed.includes(manager.value));
      },
      userName() {
        return (id) => this.users.find((user) => user.value == id)?.title;
      },
    },
    methods: {
      ...mapActions({
        addManager: "settings/addManagerDefaultValue",
        setManagerDefaultValue: "settings/setManagerDefaultsValue",
        removeManagerDefaultValue: "settings/removeManagerDefaultsValue",
      }),
      onOrganizationAccountChangeHandler(organizationAccountId, organizationId, managerId) {
        this.setManagerDefaultValue({ target: "organizationAccountId", managerId, value: organizationAccountId });
        this.setManagerDefaultValue({ target: "organizationId", managerId, value: organizationId });
      },
    },
    components: {
      GnzsSection,
      GnzsDropdown,
      GnzsButton,
    },
  };
</script>

<style lang="scss" module>
  @import "./style.scss";
</style>

<style lang="scss" module="w">
  @import "@/assets/scss/Settings/style.scss";
</style>
