/**
 * @author Ilya Orishin. 2022
 * 
 * Функционал таблицы со скроллом
 */

/**
 * Функция, которая позволяет скроллить таблицу
 * 1. Получиим ширину области видимости и ширину самой таблицы
 * 2. Если двигаем с помощью скролла, установим более высокую скорость движения
 * 3. Если зажата сама таблица или скролл -> начинаем двигать и таблицу и область скролла
 * 4. Если достигли либо минимума, либо максимума -> останавливаемся
 * 
 * @param {state} state - Vuex state
 * @param {Object} data - {wrapper, draggable, minOffset}
 * 
 * @returns {void}
*/
const moveTable = (state, data) => {
    let ww = data.wrapper.width(),
        dw = data.draggable.width(),
        ratio = 1

    if (state.scrollPressed) ratio = -3

    if (state.scrollPressed || state.draggablePressed) {
        state.tableOffset += data.event.movementX*ratio;
        state.scrollOffset = -ww*(state.tableOffset/dw)

        if (state.tableOffset <= data.minOffset) {
            state.tableOffset = data.minOffset;
            return;
        }

        if (state.tableOffset >= state.maxOffset) {
            state.tableOffset = state.maxOffset;
            return;
        }
    }
}

const state = () => ({
    tableOffset: 0,
    scrollOffset: 0,
    maxOffset: 0,
    minOffset: 0,
    scrollWidth: 0,
    draggablePressed: false,
    scrollPressed: false,
    isScrollMounted: false,
    isWrapperMounted: false, // Этот флаг нужен, чтобы корректно отработал расчет ширин
    showTable: true
});


const getters = {
    // Область сдвига
    scrollOffset: state => state.scrollOffset,
    tableOffset: state =>  state.tableOffset,
    showTable: state => state.showTable,
    // offsets
    minOffset: state => state.minOffset,
    scrollWidth: state => state.scrollWidth,
};

const actions = {
    scrollHandler: ({commit}, data) => commit('scrollHandler', data),
    setValue: ({commit}, data) => commit('setValue', data),
    calc: ({commit}, data) => commit('calc', data)
};


const mutations = {
    scrollHandler: (state, data) => moveTable(state, data), // Обработчик любого скролла
    setValue: (state, {key, value}) => state[key] = value,
    calc: (state, {wrapper, draggable}) => {
        if (draggable.width() < wrapper.width()){
            state.showTable = false
        }
        state.minOffset = -1 * (draggable.width() - wrapper.width());
        let ww = wrapper.width(),
            dw = draggable.width();
        state.scrollWidth = ww*(ww/dw)
    }
};


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}