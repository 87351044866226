import { setCompanyManagerData, setContactManagerData, setEntityFields } from "./order";

export const setContragentData = ({
  amoTimeZone,
  type,
  entity,
  orderFieldsSettings,
  amoCfs,
  users,
  groups,
  contacts = [],
  features,
  data,
}) => {
  const managerId = entity?.responsible_user_id;

  if (type == 1) {
    data["is_company"] = 0;
    data["contact_id"] = entity["id"].toString();
    data["contact_short_name"] = entity["name"];

    if (entity?._embedded?.companies?.[0]?.id) {
      data["company_id"] = entity._embedded.companies[0].id.toString();
    }

    setEntityFields(amoTimeZone, orderFieldsSettings, amoCfs, "contact", entity, data);

    if (managerId && features.includes("responsible-user-amo-to-1c"))
      setContactManagerData(data, users, groups, managerId);
  } else if (type == 3) {
    data["is_company"] = 1;
    data["company_id"] = entity["id"].toString();
    data["company_short_name"] = entity["name"];
    data["contacts"] = contacts;
    setEntityFields(amoTimeZone, orderFieldsSettings, amoCfs, "company", entity, data);
    if (managerId && features.includes("responsible-user-amo-to-1c"))
      setCompanyManagerData(data, users, groups, managerId);
  }

  return data;
};
