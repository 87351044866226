<template>
 <GnzsSection 
    :disabled="!tariffFeatures.includes('partners-1c-to-amo')"
     :disabledText="disabledSectionText"
     header="Партнеры"
     >
    <template v-slot:header-control>
        <GnzsSwitcher
            v-model="defaults.isPartnersEnabled"
        ></GnzsSwitcher>
    </template>
    <template v-slot:description>
        Функционал партнеров, контрагентов и соглашений, которых можно выбирать из выпадающего списка в модальном окне заказа.
    </template>
    <div v-if="defaults.isPartnersEnabled">
        <div :class="w.table">
            <div :class="w.tableRow">
                <div>Компания: партнер</div>
                <div>
                    <gnzs-dropdown 
                      :class="w.w200" 
                      v-model="partnersSettings.cf_company_partner" 
                      :isSearchable="true"
                      :items="companyCfs" 
                    />
                </div>
            </div>

            <div :class="w.tableRow">
                <div>Контакт: партнер</div>
                <div>
                    <gnzs-dropdown 
                      :class="w.w200" 
                      v-model="partnersSettings.cf_contact_partner" 
                      :isSearchable="true"
                      :items="contactCfs" 
                    />
                </div>
            </div>

            <div :class="w.tableRow">
                <div>Справочник партнеров</div>
                <div>
                    <gnzs-dropdown 
                      :class="w.w200" 
                      :value="partnerDirectory"
                      :items="directories" 
                      @input="onPartnerDirectoryChange"
                    />
                </div>
            </div>
            
            <div :class="w.tableRow">
                <div>Партнер по умолчанию</div>
                <GnzsDropdown
                    :class="w.w200"
                    v-model="defaults.partnerId"
                    :use-empty-item="true"
                    :items="availablePartners"
                    :isChuncked="true"
                    :isSearchable="true"
                    :searchedItems="searchedItems"
                    :isLoading="isPartnersLoading"
                    :disabled="!partnersSettings.directory_code"
                    @scrollend="loadNext"
                    @search="onSearch"
                />
            </div>
        </div>
    </div>
 </GnzsSection>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import GnzsSection from "@/gnzs-controls/Section";
import GnzsSwitcher from "@/gnzs-controls/Switcher";
import GnzsDropdown from "@/gnzs-controls/Dropdown";

export default {
    computed: {
        ...mapGetters({
            defaults: "settings/defaults",
            partnersSettings: "settings/partners",
            partners: "partners/dropdownItems",
            partnerDirectory: 'settings/partnerDirectory',
            tariffFeatures: 'features/getFeatures',
            disabledSectionText: 'localSettings/disabledSectionText',
            customFields: 'amo/customFields',
            directories: 'additionalTypes/dropDownItems',
            itemData: 'additionalTypes/itemData',
            itemDataSearched: 'additionalTypes/itemDataSearched',
            items: 'additionalTypes/items',
            isItemDataLoading: 'additionalTypes/isItemDataLoading'
        }),
        availablePartners(){
            return this.itemData(this.partnerDirectory)
        },
        searchedItems(){
            return this.itemDataSearched(this.partnerDirectory)
        },
        isPartnersLoading(){
            return this.isItemDataLoading(this.partnerDirectory)
        },
        companyCfs(){
            return this.customFields('company', [1]);
        },
        contactCfs(){
            return this.customFields('contact', [1]);
        }
    },
    components: {
        GnzsSection,
        GnzsSwitcher,
        GnzsDropdown
    },
    methods: {
        ...mapActions({
            setDefaultsField: "settings/setDefaultsField",
            setPartnerDirectory: "settings/setPartnerDirectory",
            loadItemData: 'additionalTypes/load1ctypeItems',
            searchItems: 'additionalTypes/searchItems',
            clearDefaultPartnerId: 'settings/clearDefaultPartnerId'
        }),
        async loadNext(){
            await this.loadItemData(this.partnerDirectory)
        },
        async onSearch(query){
            let params = {
                query,
                code: this.partnerDirectory.code,
                type: this.partnerDirectory.type
            }
            await this.searchItems(params)
        },
        onPartnerDirectoryChange(value){
            this.setPartnerDirectory(value)
            this.clearDefaultPartnerId()
            this.loadItemData(value)
        },
    },
    async beforeMount(){
        if (this.partnerDirectory.code && this.partnerDirectory.type){
            await this.loadItemData(this.partnerDirectory)
        }
    }
};
</script>

<style lang="scss" module="w">
    @import '@/assets/scss/Settings/style.scss';
</style>