/**
 * @author Ilya Orishin. (continue from Artyom Baskakow) 2021
 *
 * Vuex Mutations заказа
 */

import { deepCloneByJson } from "@/utilities/deepClone";
import * as orderUtil from "@/utilities/order";
import * as prodUtil from "@/utilities/orderProduct";
import { initialState } from "./state";

import { paysMutations } from "@/store/substores/paysDetail";
import { massUseMutations } from "@/store/substores/massUse";
import { orderCopyMutations } from "@/store/substores/orderCopy";
import { orderUpdateMutations } from "@/store/substores/orderUpdate";

import Vue from "vue";
import moment from "moment";

export default {
  setRealizationElement(state, data) {
    if (!state.data.realizations) state.data.realizations = [];
    if (!state.data.realizations.length) {
      state.data.realizations.push(data);
    } else {
      state.data.realizations.splice(0, 1, data);
    }
  },

  setCurrentEntity(state, { id, type }) {
    state.currentEntityId = id;
    state.currentEntityType = type;
  },

  setOwnerAgreement(state, { checkCreated, agreements, primary }) {
    if (checkCreated && state.isCreated) return;
    let ownerId = state.data.contact_id;
    if (state.data.is_company) {
      ownerId = state.data.company_id;
    }

    const agreement = agreements.find((a) => a.owner_id == ownerId && a.organization_id == state.data.organization_id);

    if (!agreement) return;

    if (state.data.agreement_id) {
      if (primary) state.data.agreement_id = agreement.id;
    } else {
      state.data.agreement_id = agreement.id;
    }
  },
  /*
  ↓ Проверка, если не нашли прикрепленную сущность, положим её id в массив ошибок
  */
  addErrorLinkedEntity: (state, id) => state.errorLinkedEntities.push(id),
  /*
  ↓ Очищаем список ошибок при закрытии модалки
  */
  clearErrorEntitiesList: (state) => (state.errorLinkedEntities = []),

  setRunningAction: (state, { code, value }) => Vue.set(state.extraActionRunning, code, value),

  replaceData(state, data) {
    Vue.set(state, "data", data);
  },
  setOrderData(state, { data, rootGetters }) {
    data = orderUtil.assignUniqueId(data); //Когда товары пришли с бэка,
    // присвоим каждому уникальный идентификатор в таблице
    data = orderUtil.assignProvisionId(data); //Когда товары пришли с бэка,
    // присвоим каждому provision_id, если его нет

    // Смена типа произвольного поля у продуктов
    const tableFieldCustomWorkers = rootGetters["settings/tableFieldCustomWorkers"];
    data = orderUtil.editSettingsType(tableFieldCustomWorkers, data);

    const missingFields = {
      new_date_and_number_on_order_update: false,
      pay_calendar: [],
      contract_id: "",
      order_description: "",
    };

    Object.entries(missingFields).forEach(([k, v]) => {
      if (!data.hasOwnProperty(k)) data[k] = v; /* eslint-disable-line no-prototype-builtins */
    });

    state.data = data;
    state.pay_calendar_initial = deepCloneByJson(state.data.pay_calendar);

    // Устанавливаем валюту, если её не было в заказе
    /* eslint-disable-next-line no-prototype-builtins */
    if (!state.data.hasOwnProperty("currency_code")) {
      const defaultCurrency = rootGetters["settings/currency"];
      const currency = rootGetters["localSettings/currencies"].find((c) => c.name == defaultCurrency);
      Vue.set(state.data, "currency_code", currency.code);
    }

    /*
    ВОЗМОЖНО БУДЕТ НЕ НУЖНО НА ПРОДАКШЕНЕ В СКОРОМ ВРЕМЕНИ
    Если данные полей пришли в старом формате, переделаем их в новый
    */
    if (state.data?.order_custom_fields.length) {
      if (state.data.order_custom_fields[0]?.name) {
        let array = [];
        state.data.order_custom_fields.forEach((val) => {
          array.push({ attribute: val.name, code: "", type: val.type, value: val.value });
        });
        state.data.order_custom_fields = array;
      }
    }

    /*
    Корректировка, если поля заданы неверно
    */

    if (!state.data?.realizations) {
      state.data.realizations = [];
    }

    if (!Array.isArray(state.data?.order_custom_fields)) {
      state.data.order_custom_fields = [];
    }

    /*
    Если поле удалено или переименовано в настройках, удаляем его из заказа
    */
    let attrs = rootGetters["settings/orderCFs"].map((v) => v.attribute);

    if (state.data?.order_custom_fields.length) {
      state.data.order_custom_fields = state.data.order_custom_fields.filter((v) => attrs.includes(v.attribute));
    }

    /*
    Если договор уже выбран, то его изменение блокируется
    Пока, что не используется до востребования
    */
    if (state.data.contract_id) {
      state.isContractsBlocked = true;
    }

    state.initialData = deepCloneByJson(data);
  },

  assignOrderData(state, data) {
    Vue.set(state, "data", data);
    Vue.set(state, "isCreated", true);
  },

  assignOrderDataPartial(state, data) {
    for (const [key, value] of Object.entries(data)) {
      Vue.set(state.data, key, value);
    }
    Vue.set(state, "isCreated", true);
  },

  dropState(state) {
    state.data = deepCloneByJson(state.initialData);
  },

  resetAll(state) {
    Object.assign(state, initialState());
  },

  setValue(state, { key, value }) {
    Vue.set(state.data, key, value);
  },

  setRootValue(state, { key, value }) {
    Vue.set(state, key, value);
  },

  setProductFieldValue(state, { product, field, value }) {
    product[field] = value;
  },

  setTabMode: (state, value) => (state.tabMode = value),

  setProductTotalPrice(state, { product, totalPrice }) {
    const res = prodUtil.getPriceByTotalPrice(
      +totalPrice,
      +product.count,
      +product.discount,
      +product.total_discount,
      +product.discount_view_type,
      +product.nds,
      +state.data.nds_type
    );

    product.price = res;
    product.total_price = +totalPrice;
  },

  setStock(state, stock) {
    if (stock) {
      state.data.stock_id = stock.id;
      state.data.stock_name = stock.name;
    }
  },

  setProductStock(state, { product, stock }) {
    if (stock) {
      product.stock_id = stock.id;
      product.stock_name = stock.name;
    } else {
      product.stock_id = null;
      product.stock_name = null;
    }
  },

  onChangeMeasure(state, { product, units, prevUnit }) {
    const prevMeasure = units.find((u) => u.value == prevUnit);
    const targetMeasure = units.find((u) => u.value == product.measure);

    const prevNumerator = prevMeasure?.numerator ? +prevMeasure.numerator : 1;
    const prevDenominator = prevMeasure?.denominator ? +prevMeasure.denominator : 1;
    const targetNumerator = targetMeasure?.numerator ? +targetMeasure.numerator : 1;
    const targetDenominator = targetMeasure?.denominator ? +targetMeasure.denominator : 1;

    const price = (((+product.price * prevDenominator) / prevNumerator) * targetNumerator) / targetDenominator;

    product.price = price.toFixed(2);
  },

  setDefaultVatForProducts(state, vat) {
    state.data.products.forEach((p) => {
      if (!p?.nds) p.nds = vat;
    });
  },

  calcProcuct(state, { product }) {
    const price = +product.price > 0 ? parseFloat(product.price) : 0;
    const count = +product.count > 0 ? parseFloat(product.count) : 0;

    if (product.discount_view_type == 0) {
      // если скидка процентами
      product.total_discount = prodUtil.getDiscount(price, count, +product.discount);
    } else {
      product.discount = prodUtil.getDiscountPercentage(price, count, +product.total_discount);
    }

    const autoDiscount = prodUtil.getDiscount(price, count, product?.auto_discount ? +product.auto_discount : 0);

    const ndsType = +state.data.nds_type;

    product.total_nds = prodUtil.getVAT(price, count, product.total_discount, product.nds, ndsType);

    product.total_price =
      ndsType == 2
        ? prodUtil.getTotalPrice(price, count, autoDiscount, product.total_discount, product.total_nds)
        : prodUtil.getTotalPrice(price, count, autoDiscount, product.total_discount, 0);
  },

  setNds(state, nds) {
    state.data.nds_type = nds;
  },

  calcOrderTotal(state) {
    const addToTotalPrice = state.additionalData?.appendCfToLeadPrice?.sum || 0;

    state.data.total_discount = orderUtil.getTotalDiscount(state.data);
    state.data.total_auto_discount = orderUtil.getTotalAutoDiscount(state.data);
    state.data.total_nds = orderUtil.getTotalVAT(state.data);

    state.data.total_price = orderUtil.getTotalPrice(state.data) + addToTotalPrice;
  },

  setProductsNds(state, { nds, productDataItems, defaultNdsVal }) {
    if (productDataItems) {
      state.data.products.forEach(
        (p) => (p.nds = productDataItems[p.id].nds === "" ? defaultNdsVal : productDataItems[p.id].nds.toString())
      );
    } else {
      state.data.products.forEach((p) => (p.nds = nds.toString()));
    }
  },

  deleteProduct(state, uuid) {
    // Перед удалением элемента из массива, удалим его из выделенных элементов
    state.checkedIds = state.checkedIds.filter((val) => val != uuid);
    state.data.products = state.data.products.filter((val) => val.uuid != uuid);
  },

  addProduct(
    state,
    { tableFieldCustomWorkers, defaultsSettings, isOrganizationUseNds, productData, count, price, priceTypeId, stockId }
  ) {
    const orderData = state.data;
    const p = orderUtil.getProductForOrder(
      tableFieldCustomWorkers,
      defaultsSettings,
      isOrganizationUseNds,
      orderData,
      productData,
      count,
      stockId,
      price,
      priceTypeId
    );
    state.data.products.push(p);
  },

  setOrderDataFrom1c(state, save1cResponse) {
    const { id, number, number_print, date, contract_id, exturl } = save1cResponse.data;
    state.data["order_id"] = id;
    state.data["order_number"] = number;
    state.data["order_date"] = date;
    state.data["order_ref"] = exturl;
    state.data["order_number_short"] = number_print;

    /*
    Если договор уже выбран, то его изменение блокируется
    Пока, что не используется до востребования
    */
    if (contract_id) {
      state.isContractsBlocked = true;
    }

    /*
    Если из 1С приходит ключ, который есть в теле заказе, необходимо перезаписать его, кроме массивов
    */
    Object.entries(save1cResponse.data).forEach(([k, v]) => {
      /* eslint-disable-next-line no-prototype-builtins */
      if (state.data.hasOwnProperty(k)) {
        if (k != "products") {
          Vue.set(state.data, k, v);
        } else {
          if (save1cResponse.data?.products_force_update === "1") {
            Vue.set(state.data, k, save1cResponse.data.products);
          } else {
            v.forEach((el, i) => {
              Vue.set(state.data[k], i, { ...el, auto_discount: el?.auto_discount ? el.auto_discount : 0 });
            });
          }
        }
      }
    });
  },

  updateDate(state, value) {
    // Обновление даты в нужном формате
    state.data["order_date"] = moment(value).format("DD.MM.YYYY 0:00:00");
    // if (state.initialData['order_date']?.split(" ")?.[0] == value){
    //     state.data['order_date'] = state.initialData['order_date']
    // } else {
    //     state.data['order_date'] = `${value} 0:00:00`;
    // }
  },

  setOrderCf(state, { payload, rootGetters }) {
    /*
    Установка значений дополнительных полей заказа

    структура атрибута: {attribute, code, sync, value}

    1. Извлекаем attribute (имя дополнительного поля), code (код справочника, если есть), значение value из payload
    2. Находим атрибут по имени, получаем массив оставшихся атрибутов
    3. Если такого атрибута нет ищем его в настройках и создаем
    4. Присваиваем атрибуту значение value и code (для справочников и перечислений)
    5. В массив оставшихся атрибутов ложим обновленный атрибут
    *
    */
    const { attribute, value, code } = payload;
    if (!state.data.order_custom_fields) Vue.set(state.data, "order_custom_fields", []);

    let param = state.data.order_custom_fields.find((val) => val.attribute == attribute);
    let fields = state.data.order_custom_fields.filter((val) => val.attribute != attribute);
    if (!param) {
      let {
        /* eslint-disable-next-line no-unused-vars */
        required,
        /* eslint-disable-next-line no-unused-vars */
        lead_cf_id,
        /* eslint-disable-next-line no-unused-vars */
        customer_cf_id,
        /* eslint-disable-next-line no-unused-vars */
        title,
        ...x
      } = rootGetters["settings/orderCFs"].find((val) => val.attribute == attribute);
      param = { ...x };
    }
    param.value = value;
    if (code) param.code = code;
    fields.push(param);
    Vue.set(state.data, "order_custom_fields", fields);
  },

  addParams(state, params) {
    state.params = Object.assign(state.params, params);
  },

  recalcPrices(state, { priceTypeId, rootGetters }) {
    /*
    1. Если не установлен ИД цены, ничего не делаем
    2. Иначе обходим массив, присваем новый тип цены
    3. Если в ценах присутствует цена с таким ИД, присваиваем новую цену
    */
    if (!priceTypeId) return;

    state.data.products.forEach((product) => {
      product.price_type_id = priceTypeId;
      let data = rootGetters["products/getById"](product.id);
      let new_price = data.prices.find((val) => val.id == priceTypeId)?.cost;
      if (new_price) product.price = new_price;
    });
  },

  changeCustomFieldProduct(state, { uuid, attribute, code, type, value }) {
    if (type == "int") value = +value;
    if (type == "bool") value ? 1 : 0;

    let product = state.data.products.find((p) => p.uuid == uuid);

    let field = product.custom_fields_values.find((cfv) => cfv.attribute == attribute);
    if (field) {
      field.value = value;
    } else {
      product.custom_fields_values.push({
        attribute,
        code,
        type,
        value,
      });
    }
  },

  ...massUseMutations,
  ...paysMutations,
  ...orderCopyMutations,
  ...orderUpdateMutations,
};
