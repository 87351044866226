import { get1cProducts } from "@/api/1c-api";
import { deepCloneByJson } from "@/utilities/deepClone";
import _ from "underscore";

const state = () => ({
  items: {},
  itemToAdd: null, // ссылка на позицию, которую собираемся добавить
  isSearching: false,
  showGallery: false,
  gallery: [],
  searchParams: {
    name: "",
    hide_zero_stock_balance: 0, // 0 false, else (1 e.g.) true
    catalog_id: "", //
    stock_id: "",
    limit: 50,
    offset: 0,
  },
  searchResponse: {
    total_rows: 0,
    offset: 0,
    result: [],
  },
  searchResult: [],
});

const getters = {
  showGallery: (state) => state.showGallery,
  gallery: (state) => state.gallery,
  items: (state) => state.items,
  getById: (state, getters, rootState, rootGetters) => (id) => {
    const closedChoise = rootGetters["features/getFeatures"].includes("no-choice-of-price");
    let item = { ...state.items[id] }; // Фикс для предотвращения цикличности не менять!
    if (closedChoise) {
      const priceType = rootGetters["settings/priceTypeId"];
      item.prices = item.prices.filter((type) => type.id == priceType);
    }

    return item;
  },
  isSearching: (state) => state.isSearching,
  searchResult: (state) => state.searchResult,
  itemToAdd: (state) => state.itemToAdd,
  showEmptyRemains: (state) => state.searchParams.hide_zero_stock_balance,
  searchableStock: (state) => state.searchParams.stock_id,
};

const actions = {
  async getById({ commit, rootGetters }, id = []) {
    try {
      const hide_empty_stocks = rootGetters["settings/defaults"].showEmptyStocks;
      if (_.isArray(id) && id.length) {
        let start = 0,
          offset = 50,
          page = 1;

        while (start < id.length) {
          // обрабатываем случай, когда нужно подгрузить больше 50 товаров
          const ids = id.slice(start, start + offset);
          const respProducts = await get1cProducts({
            id: ids,
            hide_empty_stocks,
          });
          commit("setProducts", respProducts.data.result);

          page++;
          start = offset * (page - 1);
        }
      } else {
        const respProducts = await get1cProducts({ id, hide_empty_stocks });
        commit("setProducts", respProducts.data.result);
      }
    } catch (e) {
      console.debug(`1С ${rootGetters["partnerName"]}: Ошибка получения товаров`, e);
    }
  },

  setSearchName({ commit }, payload) {
    commit("setSearchName", payload);
  },

  setGallery({ commit, rootGetters }, images) {
    const key = rootGetters["settings/auth"].key1c;
    const accountId = rootGetters["amoAccountId"];
    commit("setGalleryImages", { key, accountId, images });
  },

  toggleGallery({ commit }) {
    commit("toggleShowGallery");
  },

  async search({ state, commit, rootGetters }, { needDropResponseProducts = true } = {}) {
    try {
      commit("setSearching", true);
      if (needDropResponseProducts) commit("dropResponseProducts");

      const catalogId = rootGetters["catalogs/selectedItemId"];
      const params = {
        limit: state.searchParams.limit,
        offset: state.searchParams.offset,
        hide_zero_stock_balance: state.searchParams.hide_zero_stock_balance,
        hide_empty_stocks: rootGetters["settings/defaults"].showEmptyStocks,
      };

      const agreementId = rootGetters["order/agreementId"];
      const showAgreementsPrices = rootGetters["settings/defaults"].showAgreementsPrices;

      if (agreementId && showAgreementsPrices) {
        params["agreement_id"] = agreementId;
      }

      if (state.searchParams.stock_id.length) {
        params["stock_id"] = state.searchParams.stock_id;
      }

      if (state.searchParams.name.length) params["name"] = state.searchParams.name;
      if (!_.isEmpty(catalogId)) params["catalog_id"] = catalogId;

      const resProd = await get1cProducts(params);

      commit("setSearchingResponse", resProd.data);
      commit("setSearching", false);

      return resProd.data;
    } catch (e) {
      console.debug(`1С ${rootGetters["partnerName"]}: Ошибка поиска товаров`, e);

      commit("setSearching", false);
    }
  },

  async searchNextPage({ state, commit, dispatch }) {
    if (state.isSearching) return;

    const isAllLoaded = state.searchResponse.result.length < state.searchParams.limit;
    if (isAllLoaded) return;

    commit("setNextOffset");
    dispatch("search", { needDropResponseProducts: false });
  },

  setToAdd({ commit }, payload) {
    commit("setToAdd", payload);
  },

  addProductData({ commit }, payload) {
    commit("addProductData", payload);
  },
  setShowEmptyRemains({ commit }, value) {
    commit("setShowEmptyRemains", value);
  },
  setSearchableStock({ commit }, value) {
    commit("setSearchableStock", value);
  },
};

const mutations = {
  toggleShowGallery(state) {
    state.showGallery = !state.showGallery;
  },

  setGalleryImages(state, { key, accountId, images }) {
    state.gallery = images.map(
      (img) =>
        `https://api1c.gnzs.ru/v2/product-images?Client-Id=${accountId}&Key=${key}&url=${encodeURIComponent(
          img.fullsize_url
        )}`
    );
  },

  setProducts(state, products) {
    products.forEach((p) => {
      state.items[p.id] = p;
    });
  },

  addProductData(state, productData) {
    state.items[productData.id] = productData;
  },

  setSearching(state, flag) {
    state.isSearching = flag;
  },

  setSearchName(state, name) {
    state.searchParams["name"] = name;
  },

  setShowEmptyRemains(state, value) {
    state.searchParams["hide_zero_stock_balance"] = value ? 1 : 0;
  },

  setSearchableStock(state, value) {
    state.searchParams["stock_id"] = value;
  },

  setNextOffset(state) {
    state.searchParams.offset += +state.searchParams.limit;
  },

  setSearchingResponse(state, response) {
    state.searchResponse = response;

    if (state.searchResult?.length) {
      state.searchResult.push(...response.result);
    } else {
      state.searchResult = response.result;
    }
  },

  dropResponseProducts(state) {
    state.searchResult = [];
    state.searchParams.offset = 0;
  },

  setToAdd(state, product) {
    state.itemToAdd = deepCloneByJson(product);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
