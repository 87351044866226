<template>
  <GnzsAlert :class="$style.notification" title="Период активации интеграции 1С закончился" type="error">
    <template v-slot:text>
      Чтобы активировать виджет свяжитесь с нами любым удобным для Вас способом:

      <div :class="$style.linksContainer">
        <a
          :class="$style.link"
          data-code="vk"
          href="https://vk.me/-148384693?ref=widget&amp;ref_source=widget"
          target="_blank">
          <svg><use xlink:href="#gnsz-wa-icon__vk"></use></svg>
        </a>
        <a
          :class="$style.link"
          data-code="fb"
          href="https://m.me/1588894291203474?ref=widget&amp;ref_source=widget"
          target="_blank">
          <svg><use xlink:href="#gnsz-wa-icon__fb"></use></svg>
        </a>
        <a
          :class="$style.link"
          data-code="tg"
          href="tg://resolve?domain=gnzs_bot&amp;ref=widget&amp;ref_source=widget"
          target="_blank">
          <svg><use xlink:href="#gnsz-wa-icon__telegram"></use></svg>
        </a>
        <a :class="$style.link" data-code="wa" :href="waLink" target="_blank">
          <svg><use xlink:href="#gnsz-wa-icon__whatsapp"></use></svg>
        </a>
      </div>
    </template>
  </GnzsAlert>
</template>

<script>
  import { mapGetters } from "vuex";
  import GnzsAlert from "@/gnzs-controls/Alert";

  export default {
    computed: {
      ...mapGetters({
        accountData: "accountData",
      }),
      waLink() {
        const text = "Хочу оплатить интеграцию amoCRM и 1С. Мой ID аккаунта в amoCRM - " + this.accountData.id;
        return encodeURI(`https://api.whatsapp.com/send?phone=79960650732&text=${text}`);
      },
    },
    components: {
      GnzsAlert,
    },
  };
</script>

<style lang="scss" module>
  @import "./style.scss";
</style>
