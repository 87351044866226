import { apiDocflow } from './api';

export const getDocflowSettings = () =>
    apiDocflow.get('settings');

export const saveDocflowSettings = (data) =>
    apiDocflow.put('settings', data);

export const patchDocflowSettings = async (data) => 
    apiDocflow.patch('settings', data);


