<template>
    <GnzsSection header="Использовать хранилище данных">
        <template v-slot:description>
            Выберите хранилище, нажмите кнопку "Подключить" и разрешите доступ приложению.
        </template>
        <template v-slot:header-control>
            <GnzsSwitcher
                v-model="storage.use"
            ></GnzsSwitcher>
        </template>

        <div v-if="storage.use" :class="w.table">
            <div v-if="!storageId" :class="w.tableRow">
                <div :style="w.w200">
                    <GnzsDropdown
                        :items="storages"
                        v-model="storageType"
                    />
                </div>
                <div>
                    <GnzsButton 
                        :disabled="!storageType"
                        @click="createStorage"
                    >Подключить</GnzsButton>
                </div>
            </div>
            <div v-else :class="w.tableRowS">
                <div>
                    <div :class="w.cellFlexStart">
                    <svg :class="w.storageIcon">
                        <use v-if="storageType == 'dropbox'" xlink:href="#gnzs-icon__dropbox"></use>
                        <use v-if="storageType == 'google'" xlink:href="#gnzs-icon__googleDrive"></use>
                        <use v-if="storageType == 'yandex'" xlink:href="#gnzs-icon__yandexDrive"></use>
                    </svg>
                    <span :class="w.account">{{storageMail ? storageMail : storageName}}</span>
                    </div>
                </div>
                <div>
                    <GnzsButton v-if="!storageMail"
                                @click="auth"
                        ><template v-slot>
                            <svg :class="w.signIcon"><use xlink:href="#gnzs-ms-icon__signin"></use></svg>
                            <span>Авторизоваться</span>
                        </template>
                    </GnzsButton>
                    <GnzsButton v-if="!storageMail" type="update" @click="getStorage"/>
                    <GnzsButton type="remove" @click="removeStorage" />
                </div>
            </div>
        </div>
    </GnzsSection>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import GnzsSection from '@/gnzs-controls/Section';
import GnzsDropdown from '@/gnzs-controls/Dropdown';
import GnzsButton from '@/gnzs-controls/Button';
import GnzsSwitcher from '@/gnzs-controls/Switcher';
import { openConfirmModal } from '@/gnzs-controls/Modal/modal.utils';

const mapFieldModel = function(field){
    return {
        get(){
            return this.storage[field]
        },
        set(value){
            this.setStorageField({field, value})
        }
    }
}

export default {
    computed: {
        ...mapGetters({
            storage: 'settings/storage',
            storages: 'localSettings/storages',
            iframeName: 'iframe/iframeName',
            confirmData: 'localSettings/confirmRemoveStorage',
            isInsideAmo: 'isInsideAmo'
        }),
        storageId: { ...mapFieldModel.call(this, 'storageId') },
        storageMail: { ...mapFieldModel.call(this, 'storageMail') },
        storageType: { ...mapFieldModel.call(this, 'storageType') },
        storageName(){
            let name = ''
            switch (this.storageType){
                case 'dropbox': name = 'Dropbox'; break;
                case 'yandex': name = 'Yandex'; break;
                case 'google': name = 'Google'; break;
                default: name = ''; break;
            }

            return name
        }
    },
    methods: {
        ...mapActions({
            createStorage: 'settings/createStorage',
            getStorage: 'settings/getStorage',
            removeStorage: 'settings/removeStorage',
            setStorageField: 'settings/setStorageField',
            auth: 'settings/storageAuth'
        }),
        removeStorage(){
            if (this.isInsideAmo){
                window.parent.postMessage({ 
                    event: `${this.iframeName}:openConfirmModal`,
                    data: {
                        ...this.confirmData,
                        confirmEventName: 'removeStorage'
                    }
                }, "*");
            } else {
                openConfirmModal({
                    ...this.confirmData,
                    onAccept: async () => {
                        await this.removeStorage()
                    }
                })
            }
        }
    },
    components:{
        GnzsSection,
        GnzsButton,
        GnzsDropdown,
        GnzsSwitcher
    }
}
</script>

<style lang="scss" module="w">
    @import '@/assets/scss/Settings/style.scss';
</style>