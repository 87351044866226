<template>
  <GnzsSection header="Поля сделки оплат">
    <template v-slot:description> Укажите сопоставление полей </template>

    <div :class="$style.paramsHeader">Информация об оплате</div>

    <div :class="w.table">
      <div :class="w.tableHeader">
        <div>Поле в 1С</div>
        <div><span>Поле в </span><span :class="w.amocrmTitle">amoCRM</span></div>
        <div>Обязательное</div>
        <div :class="w.taCenter">Не обновлять</div>
      </div>

      <div :class="w.tableRow" v-for="(row, index) in rows" :key="index">
        <div>{{ row.title }}</div>
        <div>
          <GnzsDropdown
            :class="w.w200"
            :value="getSettingsEntityFieldId('lead', row.field)"
            @input="setSettingsEntityFieldId('lead', row.field, $event)"
            :items="getAmoEntityCfs('lead', row.types)"
            :isSearchable="true"
            :emptyItemTitle="row.emptyItemTitle ? row.emptyItemTitle : 'Выбрать'"></GnzsDropdown>
        </div>
        <div :class="$style.cellRequired">
          <div :class="[w.cellInner, $style.checkboxRequireContainer]">
            <GnzsCheckbox
              :value="getSettingsEntityFieldRequired('lead', row.field)"
              @input="setSettingsEntityFieldRequired('lead', row.field, $event)" />
          </div>
        </div>
        <div :class="[w.w150, $style.cellRequired]">
          <div :class="[w.cellInner, $style.checkboxRequireContainer]">
            <GnzsCheckbox
              :value="getSettingsEntityFieldUnupdatable('lead', row.field)"
              @input="setSettingsEntityFieldUnupdatable('lead', row.field, $event)" />
          </div>
        </div>
      </div>
    </div>

    <div :class="$style.paramsHeader">Список платежей</div>

    <div :class="w.table">
      <div :class="w.tableHeader">
        <div>Номер платежа</div>
        <div>Дата платежа</div>
        <div>Сумма платежа</div>
      </div>

      <div v-for="(item, index) in paysInfo" :key="index" :class="w.tableRow">
        <div>{{ index + 1 }} платёж</div>
        <div>
          <GnzsDropdown
            :class="w.w200"
            :value="paysInfoValue(index, 'dateCf')"
            @input="setPaysInfoValue({ index, field: 'dateCf', value: $event })"
            :items="getAmoEntityCfs('lead', ['date'])"
            :isSearchable="true"></GnzsDropdown>
        </div>
        <div>
          <GnzsDropdown
            :class="w.w200"
            :value="paysInfoValue(index, 'sumCf')"
            @input="setPaysInfoValue({ index, field: 'sumCf', value: $event })"
            :items="getAmoEntityCfs('lead', ['numeric'])"
            :isSearchable="true"></GnzsDropdown>
        </div>
        <div v-if="index > 3 && index == paysInfo.length - 1">
          <GnzsButton type="remove" @click="removePayRow" />
        </div>
      </div>
    </div>
    <div :class="$style.appendButtonContainer">
      <GnzsButton type="append" @click="appendPayRow">Добавить платёж</GnzsButton>
    </div>
  </GnzsSection>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";

  import GnzsSection from "@/gnzs-controls/Section";
  import GnzsDropdown from "@/gnzs-controls/Dropdown";
  import GnzsButton from "@/gnzs-controls/Button";
  import GnzsCheckbox from "@/gnzs-controls/Checkbox";

  export default {
    data() {
      return {
        rows: [
          { title: "Оплата", field: "payment_sum", types: ["text", "numeric"] },
          { title: "Оплачено", field: "payment_flag", types: ["checkbox"] },
          { title: "Частичная оплата", field: "partial_payment_flag", types: ["checkbox"] },
          { title: "Дата полной оплаты", field: "payment_full_date", types: ["date"] },
          { title: "Детализация оплат", field: "payment_detail", types: ["textarea"] },
        ],
      };
    },
    computed: {
      ...mapGetters({
        paysInfo: "settings/paysInfo",
        paysInfoValue: "settings/paysInfoValue",
        amoCustomFieldsByTypes: "amo/customFieldsByTypes",
        customFieldsByIds: "amo/customFieldsByIds",
        entityFieldId: "settings/entityFieldId",
        entityFieldRequired: "settings/entityFieldRequired",
      }),
    },
    methods: {
      ...mapActions({
        setPaysInfoValue: "settings/setPaysInfoValue",
        setOrderFieldId: "settings/setOrderFieldId",
        setOrderFieldRequired: "settings/setOrderFieldRequired",
        appendPayRow: "settings/appendPayRow",
        removePayRow: "settings/removePayRow",
      }),
      getSettingsEntityFieldId(entity, field) {
        return this.entityFieldId({ entity, field });
      },
      getSettingsEntityFieldRequired(entity, field) {
        return this.entityFieldRequired({ entity, field });
      },
      getSettingsEntityFieldUnupdatable(entity, field) {
        return this.$store.getters["settings/entityFieldUnupdatable"]({ entity, field });
      },
      setSettingsEntityFieldUnupdatable(entity, field, unupdatable) {
        this.$store.dispatch("settings/setOrderFieldUnupdatable", { entity, field, unupdatable });
      },
      setSettingsEntityFieldId(entity, field, id) {
        const type = Object.values(this.customFieldsByIds[entity]).find((cf) => cf.id == id)?.field_type;
        this.setOrderFieldId({ entity, field, type, id });
      },
      setSettingsEntityFieldRequired(entity, field, required) {
        this.setOrderFieldRequired({ entity, field, required });
      },
      getAmoEntityCfs(entity, types = ["all"]) {
        return types.reduce((cfs, t) => [...cfs, ...this.amoCustomFieldsByTypes[entity][t]], []);
      },
    },
    components: {
      GnzsSection,
      GnzsDropdown,
      GnzsButton,
      GnzsCheckbox,
    },
  };
</script>

<style lang="scss" module>
  @import "./style.scss";
</style>

<style lang="scss" module="w">
  @import "@/assets/scss/Settings/style.scss";
</style>
