<template>
 <GnzsSection 
    :disabled="!tariffFeatures.includes('provisions')"
    :disabledText="disabledSectionText"
    header="Обеспечение"
    >
         <template v-slot:description>
            Доступные статусы обеспечения. Флаги использования статусов при нулевых остатках выставляются только со стороны 1С
        </template>
        <template v-slot:header-control>
                <GnzsSwitcher
                        v-model="defaults.isProvisionEnabled"
                ></GnzsSwitcher>
        </template>
        <div v-if="defaults.isProvisionEnabled">
            <div :class="w.table" v-if="statuses.length">
                <div :class="w.tableHeader">
                    <div :class="w.vaMiddle">Наименование</div>
                    <div :class="w.w150">Используется при нулевых остатках</div>
                </div>
                <div :class="w.tableRow" v-for = "(status, index) in statuses" :key="index">
                    <div>{{ status.title }}</div>
                    <div>
                        <div :class="w.сellInner">
                            <GnzsCheckbox
                                :value="!!status.showOnStockEmpty"
                                :disabled="true"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div :class="$style.paramsHeader" v-else>
                Нет доступных статусов обеспечения
            </div>
        </div>
 </GnzsSection>
</template>
<script>
import { mapGetters } from "vuex";

import GnzsSection from "@/gnzs-controls/Section";
import GnzsSwitcher from "@/gnzs-controls/Switcher";
import GnzsCheckbox from "@/gnzs-controls/Checkbox";

export default {
    computed: {
        ...mapGetters({
            defaults: "settings/defaults",
            statuses: "provisions/provisionStatuses",
            tariffFeatures: 'features/getFeatures',
            disabledSectionText: 'localSettings/disabledSectionText'
        }),
    },
    components: {
        GnzsSection,
        GnzsSwitcher,
        GnzsCheckbox
    }
};
</script>

<style lang="scss" module>
    @import './style.scss';
</style>

<style lang="scss" module="w">
    @import '@/assets/scss/Settings/style.scss';
</style>