import _ from "underscore";
import { getDocuments, createDocument, removeDocumentById } from "@/api/docflow-api";
import { create1cDocuments } from "@/api/1c-api";
import { copyToClipboard } from "@/utilities/copyToClipboard";
import { downloadFileByLink } from "@/utilities/dropbox";
import downloadFile from "@/utilities/downloadFileByLink";
import { sendDocToEmail, sendDropboxDocToEmail } from "@/utilities/amoSendDocToEmail";
import { showError } from "@/utilities/amo";

const state = () => ({
  creating: false, // true когда идет процесс создания документа
  doc2remove: null,
  items: [],
});

const getters = {
  items: (state) => state.items,
  creating: (state) => state.creating,
};

const checkDropboxTokenFilledAndThrowError = ({ rootGetters }) => {
  if (_.isEmpty(rootGetters["settings/dropboxToken"])) {
    let widget = rootGetters["widgetRef"],
      targets = rootGetters["settings/showErrors"], //где отображать ошибки
      leadId = rootGetters["order/leadId"]; // id сделки, чтобы положить ошибку в примечание

    showError({
      widget,
      targets,
      leadId,
      header: `1С ${rootGetters["partnerName"]}`,
      text: "Dropbox-токен не заполнен в настройках интеграции",
    });

    console.debug(`1С ${rootGetters["partnerName"]}: Dropbox-токен не заполнен`);

    return false;
  }

  return true;
};

const actions = {
  async load({ commit, rootGetters }) {
    try {
      const entityId = rootGetters["order/entity"].entityId;
      const resp = await getDocuments(entityId);
      commit("setDocuments", resp.data.rows);
    } catch (e) {
      console.debug(`1С ${rootGetters["partnerName"]}: Ошибка получения документов`, e);
    }
  },

  async create({ commit, dispatch, rootState, rootGetters }) {
    const useFilestorage = rootGetters["settings/storage"].use;
    if (!useFilestorage) {
      if (!checkDropboxTokenFilledAndThrowError({ getters, rootGetters })) return;
    }

    try {
      commit("setCreating", true);

      const selectedTemplateId = rootGetters["templates/selectedTemplateId"];
      const selectedRealizationId = rootGetters["templates/selectedRealizationId"];
      const selectedInvoiceId = rootGetters["templates/selectedInvoiceId"];
      const selectedSignatoryId = rootGetters["templates/selectedSignatoryId"];

      const { isRealizationButtonEnabled, isInvoicesEnabled, isPartialRealizationEnabled, isSignatoriesEnabled } =
        rootGetters["settings/defaults"];
      const template = rootGetters["templates/getTemplateById"](selectedTemplateId);
      const { docType1C: type, name: templateName, id: templateId } = template;

      const { entityId, entityType } = rootGetters["order/entity"];
      const organizationId = rootGetters["order/organizationId"];
      const organizationAccountId = rootGetters["order/organizationAccountId"];
      const amoUserId = rootGetters["amoUserId"];
      const currentUser = rootGetters["amoUser"];

      let resp1C = null;

      const isRealizationRequestRequired =
        (template.closing && isPartialRealizationEnabled) || (template.closing && !isRealizationButtonEnabled);

      const isInvoiceIdRequired = template?.invoice && isInvoicesEnabled;
      const isRealizationIdRequired = template.closing && isPartialRealizationEnabled;
      const isSignatoryIdRequired = isSignatoriesEnabled;
      const iframeName = rootGetters["iframe/iframeName"];

      if (isRealizationRequestRequired) {
        await dispatch("order/updateOrderByEntityData", null, { root: true });
        const orderData = rootState.order.data;

        const payload = {
          lead_id: entityId,
          type,
          realization_custom_fields: {
            company_custom_fields: orderData.company_custom_fields,
            contact_custom_fields: orderData.contact_custom_fields,
            lead_custom_fields: orderData.lead_custom_fields,
          },
          current_user_id: `${currentUser.id}`,
          current_user_name: `${currentUser.name}`,
          current_user_phone: `${currentUser.phone}`,
          current_user_emall: `${currentUser.email}`,
        };

        if (isRealizationIdRequired) {
          payload.realization_id = selectedRealizationId;
        }

        if (isInvoiceIdRequired) {
          payload.invoice_id = selectedInvoiceId;
        }

        if (isSignatoryIdRequired) {
          payload.signatory_id = selectedSignatoryId;
        }

        resp1C = await create1cDocuments(payload);

        if (resp1C.data?.error) {
          let errorText = resp1C.data?.number
            ? `<div>${resp1C.data.number}</div><br><div>${resp1C.data.error}</div>`
            : `<div>${resp1C.data.error}</div>`;
          window.parent.postMessage(
            {
              event: `${iframeName}:showFixHeightModal`,
              data: { header: "Ошибка создания документа", body: errorText },
            },
            "*"
          );
          return;
        }

        dispatch("order/processRealizationElement", resp1C.data.realization, { root: true });
        await dispatch("order/save", true, { root: true });
      } else {
        const payload = {
          lead_id: entityId,
          type,
          current_user_id: `${currentUser.id}`,
          current_user_name: `${currentUser.name}`,
          current_user_phone: `${currentUser.phone}`,
          current_user_emall: `${currentUser.email}`,
        };

        if (isInvoiceIdRequired) {
          payload.invoice_id = selectedInvoiceId;
        }

        if (isSignatoryIdRequired) {
          payload.signatory_id = selectedSignatoryId;
        }

        resp1C = await create1cDocuments(payload);

        if (resp1C.data?.error) {
          let errorText = resp1C.data?.number
            ? `<div>${resp1C.data.number}</div><br><div>${resp1C.data.error}</div>`
            : `<div>${resp1C.data.error}</div>`;
          window.parent.postMessage(
            {
              event: `${iframeName}:showFixHeightModal`,
              data: { header: "Ошибка создания документа", body: errorText },
            },
            "*"
          );
          return;
        }
      }

      if (!resp1C.data.documents.length) {
        let widget = rootGetters["widgetRef"],
          targets = rootGetters["settings/showErrors"], //где отображать ошибки
          leadId = rootGetters["order/leadId"]; // id сделки, чтобы положить ошибку в примечание

        showError({
          widget,
          targets,
          leadId,
          header: `1С ${rootGetters["partnerName"]}`,
          text: "Ошибка создания документа в Dropbox: проверьте наличие свободного места в хранилище и корректность токена в настройках интеграции.",
        });

        throw new Error(`1С ${rootGetters["partnerName"]}: Ошибка создания документа`);
      }

      resp1C.data.documents.forEach(async (element) => {
        const docData1C = element;

        if (docData1C.link.length == "") {
          commit("setCreating", false);
          return;
        }

        await createDocument(entityId, {
          templateId,
          templateName,
          amoEntityType: Number(entityType),
          amoUserId: Number(amoUserId),
          organizationId,
          organizationAccountId,
          gdi: docData1C.link,
          ext: docData1C?.ext ? docData1C.ext : "",
          downloadLink: docData1C?.download_link ? docData1C.download_link : "",
          useFilestorage: useFilestorage ? 1 : 0,
          name: docData1C?.name || null,
          number: docData1C.document_name,
          current_user_id: `${currentUser.id}`,
          current_user_name: `${currentUser.name}`,
          current_user_phone: `${currentUser.phone}`,
          current_user_emall: `${currentUser.email}`,
        });

        await dispatch("load");
      });
    } catch (e) {
      console.debug(`1С ${rootGetters["partnerName"]}: Ошибка создания документа`, e);

      let widget = rootGetters["widgetRef"],
        targets = rootGetters["settings/showErrors"], //где отображать ошибки
        leadId = rootGetters["order/leadId"]; // id сделки, чтобы положить ошибку в примечание

      showError({
        widget,
        targets,
        leadId,
        header: `1С ${rootGetters["partnerName"]}`,
        text: "Ошибка создания документа",
      });
    } finally {
      commit("setCreating", false);
    }
  },

  copyLinkToClipboard(ctx, document) {
    copyToClipboard(document.gdi);
  },

  async sendToEmail({ getters, rootGetters }, document) {
    if (!document.useFilestorage) {
      if (!checkDropboxTokenFilledAndThrowError({ getters, rootGetters })) return;

      try {
        await sendDropboxDocToEmail({
          dropboxToken: rootGetters["settings/dropboxToken"],
          fileLink: document.gdi,
          filename: document.templateName + " " + document.number + document.ext,
          iframeName: rootGetters["iframe/iframeName"],
        });
      } catch (e) {
        // show error
        console.debug(`1С ${rootGetters["partnerName"]}: ошибка прикрепления документа к письму`, e);
      }
    } else {
      try {
        sendDocToEmail({
          fileLink: document.downloadLink,
          filename: document.templateName + " " + document.number + document.ext,
          iframeName: rootGetters["iframe/iframeName"],
        });
      } catch (e) {
        // show error
        console.debug(`1С ${rootGetters["partnerName"]}: ошибка прикрепления документа к письму`, e);
      }
    }
  },

  async download({ getters, rootGetters }, doc) {
    if (!doc.useFilestorage) {
      if (!checkDropboxTokenFilledAndThrowError({ getters, rootGetters })) return;

      try {
        await downloadFileByLink({
          accessToken: rootGetters["settings/dropboxToken"],
          link: doc.gdi,
        });
      } catch (e) {
        console.debug(`1С ${rootGetters["partnerName"]}: ошибка скачивания документа`, e);
      }
    } else {
      if (doc.downloadLink.includes("google")) {
        window.open(doc.downloadLink, "_blank").focus();
      } else {
        downloadFile(doc.downloadLink);
      }
    }
  },

  setDocumentToRemove: ({ commit }, id) => commit("setDocumentToRemove", id),

  async remove({ state, commit, dispatch, rootGetters }) {
    try {
      const entityId = rootGetters["order/entity"].entityId;
      await removeDocumentById(entityId, state.doc2remove.document.id);
      commit("clearDocumentToRemove");
      dispatch("load");
    } catch (e) {
      console.debug(`1С ${rootGetters["partnerName"]}: Ошибка получения документов`, e);
    }
  },
};

const mutations = {
  setDocumentToRemove: (state, id) => (state.doc2remove = id),
  clearDocumentToRemove: (state) => (state.doc2remove = null),
  setDocuments(state, documents) {
    state.items = documents.map((val) => ({
      ...val,
      ext: val.useFilestorage ? `.${val.ext}` : `.${val.gdi.split(".").reverse()[0].split("?")[0]}`, //Получаем extension у файла
    }));
  },

  setCreating(state, creating) {
    state.creating = creating;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
