<template>
  <div :class="$style.section">
    <div :class="$style.wrapper">
      <div>
        <div :class="$style.logo">
          <img :src="partner.logo"  alt=""/>
        </div>

        <div :class="$style.info">
          <div :class="$style.title">{{partner.title}}</div>
          <div :class="$style.descShort">{{partner.short}}</div>
          <div :class="[$style.descFull, activeText ? $style.descFull_min : '']" v-html="partner.long"></div>
          <div @click="activeText = !activeText" :class="$style.more">
            {{activeText ? 'Развернуть описание' : 'Скрыть описание'}}
          </div>
        </div>
      </div>
      <div :class="$style.actions">
        <GnzsButton @click="installLink()" type="primary">Установить</GnzsButton>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import GnzsButton from '@/gnzs-controls/Button';

export default {
  props: {
    partner: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    activeText: true
  }),
  computed: {
    ...mapGetters({
      getPartners: 'partnersSolutions/getPartners'
    }),
  },
  methods: {
    installLink(){
      window.open(this.partner.installLink, '_blank');
    }
  },
  components:{
    GnzsButton
  }
}
</script>

<style lang="scss" module>
@import './style.scss';
</style>