<template>
    <div :class="$style.payswrapper">
        <div :class="$style.mt10">
            <div :class="$style.table">
                <div v-if="payCalendar.length" :class="$style.tableHeader">
                    <div>№</div>
                    <div>Вид оплаты</div>
                    <div>Дата платежа</div>
                    <div>Сумма платежа</div>
                </div>
                <div v-for="(pay, index) in payCalendar" :key="index" :class="$style.tableRow">
                    <div>{{index+1}}.</div>
                    <div>
                        <GnzsDropdown
                            :value="payValue(index, 'pay_type')"
                            :items="payTypes"
                            @input="setPayValue({index, field: 'pay_type', value: $event})"
                        ></GnzsDropdown>
                    </div>
                    <div>
                        <DatePicker 
                            :class="$style.dateInput"
                            :value="PCDateValue(index, 'date')"
                            :format="'DD.MM.YYYY'" 
                            :clearable="false" 
                            type="date"
                            @change="setDatePayValue({index, field: 'date', value: $event})"
                            >
                            <template v-slot:icon-calendar>
                                <svg :class="$style.calendar">
                                    <use xlink:href="#card-calendar"></use>
                                </svg>
                            </template>
                        </DatePicker>
                    </div>
                    <div>
                        <GnzsInput
                            :class="$style.sum"
                            type="number"
                            :value="payValue(index, 'sum')"
                            @input="setPayValue({index, field: 'sum', value: $event})"
                        ></GnzsInput>
                    </div>
                    <div><GnzsButton @click="copyRow(index)" type="copy"></GnzsButton></div>
                    <div><GnzsButton @click="removeRow(index)" type="remove"></GnzsButton></div>
                </div>
            </div>
            <div :class="$style.payControls">
                <GnzsButton @click="addRow" :class="searchBlock.buttonOpen">Добавить оплату</GnzsButton>
            </div>
        </div>
    </div>    
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

import GnzsButton from '@/gnzs-controls/Button';
import GnzsInput from '@/gnzs-controls/Input';
import GnzsDropdown from '@/gnzs-controls/Dropdown';
import moment from 'moment'

import DatePicker from 'vue2-datepicker'
import '@/assets/datepicker/ru';
import '@/assets/datepicker/datepicker.css';

export default {
    computed: {
        ...mapGetters({
            payCalendar: 'order/payCalendar',
            payValue: 'order/PCValue',
            PCDateValue: 'order/PCDateValue',
            payTypes: 'localSettings/payTypes'
        })
    },
    methods: {
        ...mapActions({
            addRowPC: 'order/addRowPC',
            copyRowPC: 'order/copyRowPC',
            removeRow: 'order/removeRowPC',
            setPayValue: 'order/setValuePC',
        }),
        setDatePayValue({index, field, value}){
            this.setPayValue({index, field, value: moment(value).format('DD.MM.YYYY H:mm:ss')})
        },
        addRow(){
            this.addRowPC()
        },
        copyRow(index){
            
            this.copyRowPC(index)
        }
    },
    components: {
        GnzsButton,
        GnzsInput,
        GnzsDropdown,
        DatePicker
    }
}
</script>

<style lang="scss" module>
    @import './PayCalendar.scss';
</style>

<style lang="scss" module="params">
    @import '../Parametes/Parameters.scss';
</style>

<style lang="scss" module="searchBlock">
    @import '../SearchBlock/SearchBlock.scss';
</style>