<template>
    <div :class="[w.topActions, isSettingsChanged ? w.topActions_changed : '']">

        <GnzsButton 
            type="cancel" 
            @click="onCancelClickHandler"
        >Отмена</GnzsButton>

        <GnzsButton 
            type="primary" 
            :disabled="!isSettingsChanged"
            :loading="isSettingsSaving"
            @click="onSaveClickHandler"
        >Сохранить</GnzsButton>

    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import GnzsButton from '@/gnzs-controls/Button';
import checkValidSettings from '@/utilities/checkValidSettings';

export default {
    computed: {
        ...mapGetters({
            isSettingsChanged: 'settings/isChanged',
            isSettingsSaving: 'settings/isSaving',
            settings: 'settings/settings',
            iframeName: 'iframe/iframeName',
            confirmData: 'localSettings/confirmSaveSettings'
        }),
    },
    methods: {
        onCancelClickHandler(){
            this.$store.dispatch('settings/cancelChanges');
        },
        onSaveClickHandler(){
            if (checkValidSettings(this.settings)){
                this.$store.dispatch('settings/saveChanges'); 
            } else {
                window.parent.postMessage({ 
                    event: `${this.iframeName}:openConfirmModal`,
                    data: {
                        ...this.confirmData,
                        confirmEventName: 'saveSettings'
                    }
                }, "*");
            }
            
        }
    },
    components: {
        GnzsButton
    }
}
</script>

<style lang="scss" module="w">
    @import '@/assets/scss/Settings/style.scss';
</style>