<template>
  <label :class="[$style.checkbox]">
    <div :class="$style.body">
      <input
        :class="$style.input"
        type="checkbox"
        :value="value"
        :checked="!!value"
        :disabled="disabled"
        @change="$emit('input', $event.target.checked)"
      />
      <span
        :class="[$style.helper, { [$style.helper_minus]: isSomeChecked }]"
      ></span>
    </div>
    <span :class="$style.text" v-if="label.length">
      {{ label }}
    </span>
  </label>
</template>



<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    isSomeChecked: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>


<style lang="scss" module>
@import "./style.scss";
</style>