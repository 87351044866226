/**
 * @author Ilya Orishin, 2022
 */

import PATHS from '@/router/constants';
import store from '@/store';

/**
 * Инициализация iframe приложения
 * 
 * @param {Object} route - Vue router path
 */
export default async function (route){
    switch (route.name){
        case PATHS.ADVANCED_SETTINGS.name: {
            await store.dispatch('loadAdvancedSettings');
        } break;
        case PATHS.SETTINGS_WOC.name: {
            await store.dispatch('loadAdvancedSettings');
            await store.dispatch('settings/setWocSettings');
        } break;
        case PATHS.ORDER_TAB.name: {
            const id = route.query['entity-id']
            const type = route.query['entity-type']
            const userId = route.query['user-id']
            store.dispatch('setCurrentUserId', userId)
            store.dispatch('order/setCurrentEntity', {id, type})
            await store.dispatch('loadCardTab');
        } break;
        case PATHS.ORDER_MODAL.name: {
            const id = route.query['entity-id']
            const type = route.query['entity-type']
            const userId = route.query['user-id']
            store.dispatch('setCurrentUserId', userId)
            store.dispatch('order/setCurrentEntity', {id, type})
            await store.dispatch('loadOrderModal');
        } break;
        case PATHS.ORDER_UPDATE.name: {
            const id = route.query['entity-id']
            const type = route.query['entity-type']
            const userId = route.query['user-id']
            store.dispatch('setCurrentUserId', userId)
            store.dispatch('order/setCurrentEntity', {id, type})
            await store.dispatch('loadOrderUpdateModal');
        } break;
        case PATHS.SERVICE_TEST.name: {
            await store.dispatch('loadServiceTest');
        } break;
        case PATHS.ORDER_COPY.name: {
            const id = route.query['entity-id']
            const type = route.query['entity-type']
            const userId = route.query['user-id']
            store.dispatch('setCurrentUserId', userId)
            store.dispatch('order/setCurrentEntity', {id, type})
            await store.dispatch('loadCopyModal');
        } break;
        case PATHS.UPDATE_CONTRAGENT.name: {
            const id = route.query['entity-id']
            const type = route.query['entity-type']
            const userId = route.query['user-id']
            store.dispatch('setCurrentUserId', userId)
            await store.dispatch('loadUpdateContragentModal', {id, type});
        } break;
        case PATHS.ADD_DIRECTORY_ELEMENT.name: {
            const code = route.query['code']
            const type = route.query['type']
            const userId = route.query['user-id']
            store.dispatch('setCurrentUserId', userId)
            await store.dispatch('loadAddElementModal', {code, type});
        } break;
        case PATHS.EDIT_DIRECTORY_ELEMENT.name: {
            const code = route.query['code']
            const type = route.query['type']
            const id = route.query['id']
            const userId = route.query['user-id']
            store.dispatch('setCurrentUserId', userId)
            await store.dispatch('loadEditElementModal', {code, type, id});
        } break;
    }
}