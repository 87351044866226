<template>
  <Modal
    :loaded="isOrderModalLoaded"
    :closable="false"
    :width="width"
    :closeByOverlayClick="!!errorOpenOrder.length"
    :declineOnOverlayClick="declineOnOverlayClick"
    extra-class="gnzs-modal-scroller"
    @decline="onOverlayClick">
    <template v-slot>
      <div :class="$style.bigWrapper">
        <div v-if="errorOpenOrder.length && !isOpened">
          {{ errorOpenOrder }}
        </div>
        <template v-if="isOpened && isOrderModalLoaded && !errorOpenOrder.length">
          <OrderHeader />
          <OrderParameters />
          <Tabs :class="$style.tabs" ref="tabs" @click="onTabChange" :tabs="tabs" :overcover="true">
            <div :class="$style.tab" :gnzs-tab-id="0">
              <ProductsTableWrapper />
              <OrderSearchBlock />
            </div>
            <div
              v-if="defaults.isPartialRealizationEnabled && tariffFeatures.includes('partial-realization')"
              :class="$style.tab"
              :gnzs-tab-id="1">
              <RealizationTableWrapper />
            </div>
            <div
              v-if="defaults.isPaysCalendarEnabled && tariffFeatures.includes('pay-calendar')"
              :class="$style.tab"
              :gnzs-tab-id="2">
              <PayCalendar />
            </div>
            <div v-if="defaults.isInvoicesEnabled" :class="$style.tab" :gnzs-tab-id="3">
              <InvoiceTable />
            </div>
            <div v-if="amoAccountId == 28830832" :class="$style.tab" :gnzs-tab-id="4">
              <div>
                <iframe
                  id="dde52b9a-dd58-4303-9c93-f4d68b0175b4"
                  width="100%"
                  :height="`${iframeHeight}px`"
                  :src="calendarUrl">
                </iframe>
              </div>
            </div>
          </Tabs>
          <OrderFooter />
          <div v-if="isDisabledStatus" :class="$style.disabled"></div>
          <div v-if="isDisabledStatus" :class="$style.disabledStatusWarning">
            <div :class="$style.disabledStatusWarning__text">
              На текущем этапе воронки запрещено создавать/редактировать заказ
            </div>
          </div>
        </template>
      </div>
    </template>
  </Modal>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";

  import Modal from "@/gnzs-controls/Modal";

  import Tabs from "@/gnzs-controls/Tabs";
  import OrderHeader from "@/components/Modal/Header/Header";
  import OrderParameters from "@/components/Modal/Parametes/Parametes";
  import OrderSearchBlock from "@/components/Modal/SearchBlock/SearchBlock";
  import OrderFooter from "@/components/Modal/Footer/Footer";
  import InvoiceTable from "@/components/Modal/Invoices/InvoiceTable";
  import PayCalendar from "@/components/Modal/PayCalendar/PayCalendar";
  import ProductsTableWrapper from "@/components/Modal/ProductsTable/ProductsTableWrapper";
  import RealizationTableWrapper from "@/components/Modal/Realizations/RealizationTableWrapper";

  import { closeOrderModalWithConfirm } from "@/utilities/order";

  export default {
    data() {
      return {
        iframeHeight: 190,
      };
    },
    computed: {
      ...mapGetters({
        errorOpenOrder: "order/errorOpenOrder",
        isOpened: "isOpened",
        amoAccountId: "amoAccountId",
        entity: "order/entity",
        tariffFeatures: "features/getFeatures",
        allTabs: "localSettings/orderTabs",
        tabMode: "order/tabMode",
        defaults: "settings/defaults",
        iframeName: "iframe/iframeName",
        isOrderModalLoaded: "isOrderModalLoaded",
        widgetTokens: "widgetTokens",
        isDisabledStatus: "order/isDisabledStatus",
        isOrderChanged: "order/isChanged",
      }),
      calendarUrl() {
        return `https://payments-iframe.gnzs.ru/calendar-1c?token=${this.paysToken}&entity-type=${this.entity.entityType}&entity-id=${this.entity.entityId}&source-id=27`;
      },
      paysToken() {
        const widgetId = 51;
        return this.widgetTokens?.[widgetId];
      },
      declineOnOverlayClick() {
        return this.defaults?.closeOrderByOverlayClick || false;
      },
      tabs() {
        let filteredtabs = [...this.allTabs];
        if (!(this.defaults.isPartialRealizationEnabled && this.tariffFeatures.includes("partial-realization"))) {
          filteredtabs = filteredtabs.filter((t) => t.id != 1);
        }
        if (!(this.defaults.isPaysCalendarEnabled && this.tariffFeatures.includes("pay-calendar"))) {
          filteredtabs = filteredtabs.filter((t) => t.id != 2);
        }
        if (!(this.defaults.isInvoicesEnabled && this.tariffFeatures.includes("partial-invoices"))) {
          filteredtabs = filteredtabs.filter((t) => t.id != 3);
        }
        if (this.amoAccountId != 28830832) {
          filteredtabs = filteredtabs.filter((t) => t.id != 4);
        }
        return filteredtabs;
      },
      width() {
        return this.errorOpenOrder ? "500px" : "90%";
      },
    },
    watch: {
      tabMode(val) {
        this.$refs.tabs.setActiveId(val);
      },
    },
    methods: {
      ...mapActions({
        setRootValue: "order/setRootValue",
        setCheckedAll: "order/setCheckedAll",
      }),
      onTabChange(value) {
        this.setRootValue({ key: "tabMode", value });
        if (value == 4) {
          const frame = document.getElementById("dde52b9a-dd58-4303-9c93-f4d68b0175b4");
          if (frame) {
            frame.src = this.calendarUrl;
          }
        }
      },
      onOverlayClick() {
        if (this.errorOpenOrder) {
          return window.parent.postMessage({ event: `${this.iframeName}:closeModal` }, "*");
        } else {
          closeOrderModalWithConfirm(this.isOrderChanged, this.iframeName);
          this.setCheckedAll(false);
        }
      },
    },
    components: {
      Modal,
      Tabs,
      OrderHeader,
      OrderParameters,
      OrderSearchBlock,
      OrderFooter,
      InvoiceTable,
      PayCalendar,
      ProductsTableWrapper,
      RealizationTableWrapper,
    },
    mounted() {
      window.addEventListener("message", (ev) => {
        if (ev.data.event == "windowHeight") {
          this.iframeHeight = ev.data.data.height;
        }
      });
    },
  };
</script>

<style lang="scss" module>
  @import "./style.scss";
</style>
