<template>
    <div :class="[$style.control, $style.w350, $style.space]">
        <GnzsInput  v-if="param.type=='text'" 
            :value="orderCfs(param.attribute)"
            :type="'text'"
            @input="setOrderCf({attribute: param.attribute, value: $event})" 
        />
        <GnzsInput  v-else-if="param.type=='int'" 
            :value="orderCfs(param.attribute)"
            :type="'number'"
            @input="setOrderCf({attribute: param.attribute, value: $event})" 
        />
        <GnzsSwitcher v-else-if="param.type=='bool'" 
            :value="orderCfs(param.attribute)"
            @input="setOrderCf({attribute: param.attribute, value: $event})" 
        />
        <DatePicker 
            v-else-if="param.type=='date'"
            :class="$style.dateInput"
            :value="orderDateCfs(param.attribute)"
            :format="'DD.MM.YYYY'" 
            :clearable="false" 
            type="date"
            @change="setOrderDateCf({attribute: param.attribute, value: $event})" 
            >
            <template v-slot:icon-calendar>
                <svg :class="$style.calendar">
                    <use xlink:href="#card-calendar"></use>
                </svg>
            </template>
        </DatePicker>
        <GnzsDropdown v-else 
            :value="orderCfs(param.attribute)" 
            :isSearchable="typesDataTotal({code:param.code, type:param.type}) > 10"
            :isChuncked="typesDataTotal({code:param.code, type:param.type}) > 50"
            :isLoading="isTypesDataloading({code:param.code, type:param.type})"
            :items="typesData({code:param.code, type:param.type})"
            :searchedItems="typesDataSearched({code:param.code, type:param.type})"
            @search="typesDataSearch({code:param.code, type:param.type, query: $event})"
            @input="setOrderCf({attribute: param.attribute, code: param.code, value: $event })"
            @scrollend="onScrollEnd({code: param.code, type: param.type})"
            :use-empty-item="false"
            :use-creating="true"
            :use-editing="true"
            @create="openCreateElementModal({code: param.code, type: param.type})"
            @edit="openEditElementModal({code: param.code, type: param.type, id: $event.value})"
        />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import GnzsInput from '@/gnzs-controls/Input';
import GnzsDropdown from '@/gnzs-controls/Dropdown';
import GnzsSwitcher from '@/gnzs-controls/Switcher';
import DatePicker from 'vue2-datepicker'
import openCreateDirectoryElementModal from './../Modals/AddDirectoryElementModal';
import openEditDirectoryElementModal from './../Modals/EditDirectoryElementModal';
import '@/assets/datepicker/ru';
import '@/assets/datepicker/datepicker.css';

export default {
    props: {
        param: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        ...mapGetters({
            orderCfs: 'order/orderCfs',
            amoAccountId: 'amoAccountId',
            orderDateCfs: 'order/orderDateCfs',
            typesData: 'additionalTypes/itemData',
            typesDataTotal: 'additionalTypes/itemDataTotal',
            typesDataSearched: 'additionalTypes/itemDataSearched',
            isTypesDataloading: 'additionalTypes/isItemDataLoading',
        })
    },
    methods: {
        ...mapActions({
            setOrderCf: 'order/setOrderCf',
            setOrderDateCf: 'order/setOrderDateCf',
            load1ctypeItems: 'additionalTypes/load1ctypeItems',
            typesDataSearch: "additionalTypes/searchItems",
            setItem2add: 'additionalTypes/setItem2add',
            setItem2edit: 'additionalTypes/setItem2edit'
        }),
        openCreateElementModal(payload){
            this.setItem2add(payload)
            openCreateDirectoryElementModal()
        },
        openEditElementModal(payload){
            this.setItem2edit(payload)
            openEditDirectoryElementModal()
        },
        async onScrollEnd({code, type}){
            await this.load1ctypeItems({code, type})
        },
    },
    components: {
        GnzsInput,
        GnzsDropdown,
        GnzsSwitcher,
        DatePicker
    }
}
</script>

<style lang="scss" module>
    @import './Parameters.scss';
</style>