const state = () => {
  return {
    partners: [
      {
        id: 1,
        title: "GoСклад",
        logo: "https://api1c.gnzs.ru/v2-dev/gsklad.png",
        installLink: "https://www.amocrm.ru/oauth/?state=%7B%22external_source%22:%22genezis%22%7D&client_id=5cd3ee16-5f4c-4b3f-828f-9f575bcaeb2e",
        short: "Сервис учета товаров/услуг и аналитики продаж",
        long: "- синхронизирован со стандартными товарами в amoCRM: поддерживает работу с любой текущей интеграцией, которая использует в работе штатные товары системы;<br>" +
          "- остатки, списания, поступления. резервы и продажи;<br>" +
          "- полное описание товаров гибко настраивается под любые задачи;<br>" +
          "- формирование любых документов и загрузка документов в сделку;<br>" +
          "- аналитика по продажам - отчеты о проданных товарах, отчеты по клиентам и ответственным;<br>" +
          "- экспорт и импорт из Excel;<br>" +
          "- расчет прибыли, себестоимости и других показателей;\n" +
          "- настройки резервирования и списания позиций со склада;<br>" +
          "- разные партии и артикулы.<br><br>" +
          "Сервис полностью заменит любую складскую программу и не требует дополнительных интеграций и сторонних сервисов."
      }
    ]
  }
}

const getters = {
  getPartners: (state) => state.partners
}

const actions = {

}

const mutations = {

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};