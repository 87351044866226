<template>
    <GnzsSection 
        :disabled="!tariffFeatures.includes('document-signer')" 
        :disabledText="disabledSectionText"
        header='Отображать поле "Подписант"'
        >
        <template v-slot:description>
            Если включено, то во вкладке заказа при печати формы будет отображено поле для выбора подписанта. Для корректной работы необходима доработка функционала на стороне 1С.
        </template>
        <template v-slot:header-control>
            <GnzsSwitcher
                v-model="defaults.isSignatoriesEnabled"
            ></GnzsSwitcher>
        </template>
    </GnzsSection>
</template>

<script>
import { mapGetters } from "vuex";

import GnzsSection from "@/gnzs-controls/Section";
import GnzsSwitcher from "@/gnzs-controls/Switcher";

export default {
    computed: {
        ...mapGetters({
            defaults: "settings/defaults",
            tariffFeatures: 'features/getFeatures',
            disabledSectionText: 'localSettings/disabledSectionText'
        }),
    },
    components: {
        GnzsSection,
        GnzsSwitcher,
    },
};
</script>

<style lang="scss" module="w">
    @import '@/assets/scss/Settings/style.scss';
</style>