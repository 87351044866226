<template>
    <GnzsSection
      header="Отмена позиций заказа"
    >
    <template v-slot:description>
      Функционал отмены позиций товаров в модальном окне заказа. Для использования функционала необходима доработка на стороне 1С
    </template>
    <template v-slot:header-control>
      <GnzsSwitcher
        v-model="defaults.useCancellationPositions"
      ></GnzsSwitcher>
    </template>
    <template v-if="defaults.useCancellationPositions">
      <div :class="w.table">
        <div :class="w.tableRow">
          <div :class="w.switcherTitle">Запретить редактирование</div>
          <div>
            <GnzsSwitcher
              v-model="defaults.isCancellationEditDisabled"
            />
          </div>
        </div>
        <div :class="w.tableRow">
          <div :class="w.switcherTitle">Использовать причины отмены</div>
          <div>
            <GnzsSwitcher
              v-model="defaults.useCancellationReasons"
            />
          </div>
        </div>
      </div>
      </template>
    </GnzsSection>
</template>

<script>
import {mapGetters} from "vuex";

import GnzsSection from "@/gnzs-controls/Section";
import GnzsSwitcher from "@/gnzs-controls/Switcher";

export default {
    computed: {
        ...mapGetters({
            defaults: 'settings/defaults'
        })
    },
    components: {
        GnzsSection,
        GnzsSwitcher
    }
}
</script>

<style lang="scss" module="w">
@import '@/assets/scss/Settings/style.scss';
</style>