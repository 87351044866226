import Vue from 'vue';
import store from '@/store';
import $ from 'jquery'

import EditDirectoryElementModal from './EditDirectoryElementModal.vue';

export default function(){
    let element = $(`<div id="gnzs-1c-amo__edit-directory-element-modal-container"></div>`)
    element.width(`700px`)
    $('body').append(element)

    new Vue({
        store,
        render: h => h(EditDirectoryElementModal)
    }).$mount('#gnzs-1c-amo__edit-directory-element-modal-container');
}