import { API_CORE } from "./api";

/**
 * Получение токена для разработки
 */
export const getDevJwtToken = (xApiToken, widgetId, accountId, userId = null, data = null) => {
  const params = { account_id: accountId, user_id: userId, data };
  const headers = { "X-Api-Token": xApiToken };
  return API_CORE.get(`public/jwt/encode/${widgetId}`, { headers, params }).then((resp) => resp?.data);
};

export const getJwtToOtherWidget = async (widgetId, token) => {
  const headers = {
    "Content-Type": "application/json",
    "X-Auth-Token": token,
  };
  return API_CORE.get(`public/jwt/${widgetId}`, { headers }).then((resp) => resp?.data);
};

export const verifyAndDecodeToken = (widgetId, token, integrationCode = "") => {
  const headers = {
    "X-Auth-Token": token,
    "X-Widget-Id": widgetId,
  };

  if (integrationCode) headers["X-External-Integration-Code"] = integrationCode;

  return API_CORE.get(`/public/jwt/verify/${widgetId}`, { headers }).then((resp) => resp?.data);
};

export const getAccountInfo = (widgetId, accountId, token, integrationCode, force = 0) => {
  const headers = {
    "X-Widget-Id": widgetId,
    "X-Account-Id": accountId,
    "X-Auth-Token": token,
  };

  if (integrationCode) headers["X-External-Integration-Code"] = integrationCode;

  return API_CORE.get(`/amo/account/info?force=${force}`, { headers }).then((resp) => resp?.data);
};

export const getWidgetInfo = (code, widgetId, accountId, token) => {
  const headers = {
    "X-Widget-Id": widgetId,
    "X-Account-Id": accountId,
    "X-Auth-Token": token,
  };
  return API_CORE.get(`/amo/account/widgets/${code}/installation`, { headers }).then((resp) => resp?.data);
};
