<template>
  <div
    id="work_area"
    class="work-area content__settings aside-toggleable aside-toggleable_media-margin-left_0"
    :class="[w.workArea, { 'is-changed': isSettingsChanged }, { 'is-scrolled': pageYOffset >= 5 }]">
    <div :class="[w.listBody, isSettingsChanged ? w.listBody_scrolled : '']">
      <div :class="w.burger" v-if="windowWidth && windowWidth <= 1280" @click="onShowSidebar">
        <span></span>
      </div>
      <div :class="['content__top__preset', isSettingsChanged ? w.topPreset_changed : '']">
        <h2 :class="w.widgetTitle">1С интеграция</h2>
      </div>

      <template v-if="isAmoAuthorized">
        <ActivationHandler
          v-if="isSettingsInitialized"
          :showPayButton="showPayButton"
          :widgetStatus="widgetStatus"
          :class="w.activationBlock" />
        <div v-else :class="w.activationPreload"></div>
      </template>

      <TopActions />
    </div>

    <Icons />

    <div
      id="list_page_holder"
      :class="[isSettingsChanged ? w.holderScrolled : '']"
      class="list list-widget list-widget_without-image-background">
      <div class="list__body clearfix">
        <div v-if="isSettingsInitialized">
          <Activation v-if="showPayButton && !widgetStatus.isActive" />
          <Tabs :tabs="tabs" :class="w.mt10">
            <div :class="w.mt10" :gnzs-tab-id="tabs[0].id">
              <SectionAuth />
              <SectionDefaults />
              <SectionOrganizationDefaultValues />
              <SectionStorage />
            </div>
            <div :class="w.mt10" :gnzs-tab-id="tabs[1].id">
              <SectionBuilder />
              <SectionOrderWork />
              <SectionProductsSearch />
              <SectionOrderSync />
              <SectionRealization />
              <SectionDescription />
              <SectionEvotor />
            </div>
            <div :class="w.mt10" :gnzs-tab-id="tabs[2].id">
              <SectionProvisions />
              <SectionAgreements />
              <SectionContracts />
              <SectionSignatories />
              <SectionPartners v-if="false" />
            </div>
            <div :class="w.mt10" :gnzs-tab-id="tabs[3].id">
              <SectionPartialRealization />
              <SectionInvoices />
              <SectionPaysCalendar v-if="false" />
              <SectionAutoDiscount />
              <SectionIFrame v-if="iframeEnabled" />
            </div>
            <div :class="w.mt10" :gnzs-tab-id="tabs[4].id">
              <SectionOrderFields />
              <SectionPays />
              <SectionDirectories />
            </div>
            <div :class="w.mt10" :gnzs-tab-id="tabs[5].id">
              <SectionPrintForms />
              <SectionPipelineEvents />
            </div>
          </Tabs>
        </div>
        <div v-else>
          <SectionPreload v-if="isAmoAuthorized" />
          <GnzsSection v-else>
            <Alert type="error" title="Нет доступа к авторизации">
              <template v-slot:text>
                <div v-if="isAdmin">
                  Для того, чтобы выдать доступы к авторизации необходимо перейти по
                  <a :href="`https://www.amocrm.ru/oauth?client_id=${partnerData.clientId}`" target="_blank">ссылке</a>
                </div>
                <div v-else>
                  <span>Для того, чтобы выдать доступы к авторизации необходимо перейти по </span>
                  <span :class="w.disabledAuthLink">ссылке</span>
                  <span> (только для администраторов)</span>
                </div>
              </template>
            </Alert>
          </GnzsSection>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from "vuex";

  import TopActions from "@/components/Settings/TopActions/TopActions";
  import SectionPreload from "@/components/Settings/SectionPreload/SectionPreload";
  import SectionAuth from "@/components/Settings/SectionAuth/SectionAuth";
  import SectionDefaults from "@/components/Settings/SectionDefaults/SectionDefaults";
  import SectionOrganizationDefaultValues from "@/components/Settings/SectionOrganizationDefaultValues";
  import SectionOrderWork from "@/components/Settings/SectionOrderWork/SectionOrderWork";
  import SectionProvisions from "@/components/Settings/SectionProvisions/SectionProvisions";
  import SectionAgreements from "@/components/Settings/SectionAgreements/SectionAgreements";
  import SectionContracts from "@/components/Settings/SectionContracts/SectionContracts";
  import SectionPartners from "@/components/Settings/SectionPartners/SectionPartners";
  import SectionOrderFields from "@/components/Settings/SectionOrderFields/SectionOrderFields";
  import SectionPrintForms from "@/components/Settings/SectionPrintForms/SectionPrintForms";
  import SectionOrderSync from "@/components/Settings/SectionOrderSync";
  import SectionPipelineEvents from "@/components/Settings/SectionPipelineEvents/SectionPipelineEvents";
  import SectionRealization from "@/components/Settings/SectionRealization/SectionRealization";
  import SectionDirectories from "@/components/Settings/SectionDirectories/SectionDirectories";
  import SectionEvotor from "@/components/Settings/SectionEvotor/SectionEvotor";
  import SectionAutoDiscount from "@/components/Settings/SectionAutoDiscount/";
  import SectionPaysCalendar from "@/components/Settings/SectionPaysCalendar";
  import SectionPartialRealization from "@/components/Settings/SectionPartialRealization";
  import SectionPays from "@/components/Settings/SectionPays";
  import SectionInvoices from "@/components/Settings/SectionInvoices";
  import SectionIFrame from "@/components/Settings/SectionIFrame";
  import SectionStorage from "@/components/Settings/SectionStorage";
  import SectionSignatories from "@/components/Settings/SectionSignatories";
  import SectionDescription from "@/components/Settings/SectionDescription";
  import SectionProductsSearch from "@/components/Settings/SectionProductsSearch";
  import SectionBuilder from "@/components/Settings/SectionBuilder";
  import Activation from "@/components/Settings/Activation";
  import ActivationHandler from "@/gnzs-controls/ActivationHandler";
  import Tabs from "@/gnzs-controls/Tabs";
  import Icons from "@/gnzs-controls/IconsSprite";
  import Alert from "@/gnzs-controls/Alert";
  import GnzsSection from "@/gnzs-controls/Section";
  import { GNZS_WIDGET_ID } from "@/store/constants";

  export default {
    name: "App",
    data() {
      return {
        pageYOffset: 0,
      };
    },
    methods: {
      ...mapActions({
        showSidebar: "iframe/showSidebar",
      }),
      onScrollHandler() {
        this.pageYOffset = window.pageYOffset;
      },
      onShowSidebar() {
        this.showSidebar();
      },
    },
    computed: {
      ...mapGetters({
        isSettingsChanged: "settings/isChanged",
        isSettingsInitialized: "isSettingsInitialized",
        tabs: "localSettings/tabs",
        windowWidth: "iframe/windowWidth",
        widgetStatus: "widgetStatus",
        isAmoAuthorized: "isAmoAuthorized",
        amoAccountId: "amoAccountId",
        partnerData: "partnerData",
        isAdmin: "isAdmin",
        widgetId: "widgetId",
      }),
      iframeEnabled() {
        return [8211006, 29669059, 23905342, 30364525, 29879194].includes(this.amoAccountId);
      },
      showPayButton() {
        return this.widgetId == GNZS_WIDGET_ID;
      },
    },
    components: {
      SectionPreload,
      SectionAuth,
      SectionAutoDiscount,
      SectionPaysCalendar,
      SectionDefaults,
      SectionOrganizationDefaultValues,
      SectionOrderWork,
      SectionProvisions,
      SectionAgreements,
      SectionContracts,
      SectionPartners,
      SectionOrderFields,
      SectionDirectories,
      SectionPrintForms,
      SectionPays,
      SectionInvoices,
      SectionIFrame,
      SectionStorage,
      SectionSignatories,
      TopActions,
      SectionBuilder,
      SectionOrderSync,
      SectionPipelineEvents,
      SectionRealization,
      SectionDescription,
      SectionEvotor,
      SectionProductsSearch,
      Activation,
      ActivationHandler,
      SectionPartialRealization,
      Tabs,
      Icons,
      Alert,
      GnzsSection,
    },

    async created() {
      window.addEventListener("scroll", this.onScrollHandler);
    },

    beforeDestroy() {
      window.removeEventListener("scroll", this.onScrollHandler);
    },
  };
</script>

<style lang="scss" module="w">
  @import "../../style.scss";
</style>
