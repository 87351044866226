<template>
    <Modal :loaded="isServiceTestLoaded" @decline="onOverlayClick">
        <template v-slot>
            <div>
                <div :class="$style.header">
                    {{header}}
                </div>

                <div :class="$style.body">
                    <div v-if="loading" :class="$style.spinner">
                        <span :class="$style.spinnerIcon"></span>
                    </div>
                    <div v-else-if="!errorText">
                        <div v-for="(text, key) in body" :key="key">{{ `${key}: ${text}` }}
                        </div>
                    </div>
                    <div v-else>
                        <div>{{ errorText }}</div>
                    </div>
                </div>
            </div>
        </template>
    </Modal>
</template>

<script>
import {mapGetters} from 'vuex';

import Modal from '@/gnzs-controls/Modal';

export default {
    computed: {
        ...mapGetters({
            loading: 'settings/serviceTestLoading',
            header: 'settings/serviceTestHeader',
            body: 'settings/serviceTestBody',
            errorText: 'settings/serviceTestErrorText',
            iframeName: 'iframe/iframeName',
            isServiceTestLoaded: 'isServiceTestLoaded'
        })
    },
    methods: {
        onOverlayClick(){
            return window.parent.postMessage({ event: `${this.iframeName}:closeModal` }, "*");
        },
    },
    components: {
        Modal
    }
}
</script>


<style lang="scss" module>
    @import './style.scss';
</style>