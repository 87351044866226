function deepClone(obj) {
    const clObj = {};
    for(const i in obj) {
      if (obj[i] instanceof Object) {
        clObj[i] = deepClone(obj[i]);
        continue;
      }
      clObj[i] = obj[i];
    }
    return clObj;
}

function deepCloneByJson(object){
    return JSON.parse(JSON.stringify(object));
}

export {
    deepClone,
    deepCloneByJson
}