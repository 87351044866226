<template>
    <div>
        <div :class="$style.itemsWrapper">
            <GnzsDropdown 
                :class="$style.realizationsDropdown"
                v-model="activeItem"
                :items="realizations"
            />
        </div>
        <table :class="$style.rtable">
            <thead ref="table" :class="$style.rtableHeader">
                <tr :class="$style.rtableRow">
                    <th :class="p.cell" data-code="number">
                        <div :class="p.cellInner">№</div>
                    </th>
                    <th :class="p.cell" data-code="article">
                        <div :class="p.cellInner">
                            Артикул
                        </div>
                    </th>
                    <th :class="p.cell" data-code="name">
                        <div :class="p.cellInner">
                            Наименование
                        </div>
                    </th>
                    <th v-if="stockWorktype==1 || stockWorktype == 3" :class="p.cell" data-code="stock">
                        <div :class="p.cellInner">
                            Склад
                        </div>
                    </th>
                    <th :class="p.cell" data-code="units">
                        <div :class="p.cellInner">
                            Ед. изм
                        </div>
                    </th>
                    <th :class="p.cell" data-code="count">
                        <div :class="p.cellInner">
                            Кол-во
                        </div>
                    </th>
                    <th :class="p.cell" data-code="price">
                        <div :class="p.cellInner">
                            Цена
                        </div>
                    </th>
                    <template v-if="tariffFeatures.includes('table-builder')">
                        <th v-for="field in tableFieldCustomWorkers" :key="field.key" :class="$style.cell" :data-code="field.attribute">
                            <div :class="$style.cellInner">
                            {{field.name}}
                            </div>
                        </th>
                    </template>
                    <th :class="p.cell" data-code="total-price">
                        <div :class="p.cellInner">
                            Сумма
                        </div>
                    </th>
                </tr>
                
            </thead>

            <tbody :class="$style.rtableBody">
                <RealizationTableRow 
                    v-for="(product, index) in products" 
                    :key="index"
                    :product="product"
                    :index="index"
                />
            </tbody>

        </table>
        <div v-if="isLoading" :class="$style.loader">
            <span class="spinner-icon"></span>
        </div>
    </div>
</template>

<script>
import GnzsDropdown from '@/gnzs-controls/Dropdown';
import RealizationTableRow from './RealizationTableRow';
import {mapActions, mapGetters} from 'vuex';

// функция для создания методов вычисляемого поля, которое может работать с директивой v-model
const mapFieldModel = function(key){
    return {
        get(){
            return this.$store.getters[`realizations/getValue`](key);
        },
        set(value){
            this.$store.dispatch('realizations/setValue', {key, value});
        }
    }
}

export default {
    computed: {
        ...mapGetters({
            defaults: 'settings/defaults',
            stockWorktype: 'settings/stockWorktype',
            realizations: 'realizations/list',
            item: 'realizations/item',
            products: 'realizations/itemProducts',
            isLoading: 'realizations/isLoading',
            tariffFeatures: 'features/getFeatures',
            tableFieldCustomWorkers: 'settings/tableFieldCustomWorkers',
        }),
        activeItem: { ...mapFieldModel.call(this, 'active') }
    },
    methods:{
        ...mapActions({
            changeItem: 'realizations/changeItem'
        })
    },
    components: {
        GnzsDropdown,
        RealizationTableRow
    }
}
</script>

<style lang="scss" module>
    @import './Realizations.scss';
</style>

<style lang="scss" module="p">
    @import '../ProductsTable/ProductsTable.scss';
</style>