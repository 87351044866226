import axios from 'axios';

import { api1CKeyAmoCRMInterceptor } from './interceptors';

export const api1C = axios.create({
    baseURL: 'https://api1c.gnzs.ru/v2/',
    validateStatus(status) {
        return status >= 200 && status < 300;
    },
});

export const api1CDocs = axios.create({
    baseURL: 'https://api1c.gnzs.ru/v2/',
    responseType: 'arraybuffer',
    validateStatus(status) {
        return status >= 200 && status < 300;
    },
});

api1C.interceptors.request.use(api1CKeyAmoCRMInterceptor);
api1CDocs.interceptors.request.use(api1CKeyAmoCRMInterceptor);
