<template>
  <GnzsSection header="Авторизация">
    <div :class="w.guide">
      <a :class="w.guide_ref" target="_blank" :href="partnerData.guide">Инструкция по настройке</a>
    </div>
    <div :class="w.table">
      <div :class="w.tableRow">
        <div>Ключ для 1С</div>
        <div>
          <div :class="w.inputWrapper">
            <GnzsInput :class="[w.input, w.w400]" :clipboardEnabled="true" v-model="key1c" />
            <GnzsButton :class="[w.buttonConnect]" @click="key1c = getMd5()">Сгенерировать ключ</GnzsButton>
          </div>
        </div>
      </div>
      <div :class="[w.tableRow, w.hidden]">
        <div>Ключ для amoCRM</div>
        <div>
          <div :class="w.inputWrapper">
            <GnzsInput :class="[w.input, w.w400]" v-model="keyAmoCrm" />
            <GnzsButton :class="[w.buttonConnect]" @click="keyAmoCrm = getMd5()">Сгенерировать ключ</GnzsButton>
          </div>
        </div>
      </div>
      <div v-if="false" :class="w.tableRow">
        <div>Dropbox (токен)</div>
        <div>
          <div :class="w.inputWrapper">
            <GnzsInput :class="[w.input, w.w400]" v-model="dropboxToken" />
            <GnzsButton :class="[w.buttonConnect]" @click="openDropboxConfirm()">Подключить</GnzsButton>
          </div>
        </div>
      </div>
      <div :class="w.tableRow">
        <div>Адрес сервиса 1С</div>
        <div>
          <div :class="w.inputWrapper">
            <GnzsInput :class="[w.input, w.w400]" v-model="url1c" />
            <GnzsButton :class="[w.buttonConnect]" @click="checkService">Проверить сервис</GnzsButton>
          </div>
        </div>
      </div>
      <div :class="w.tableRow">
        <div>Тип авторизации в 1С</div>
        <div>
          <GnzsDropdown
            :class="[w.w200]"
            v-model="auth1cType"
            :items="auth1cTypes"
            :use-empty-item="false"></GnzsDropdown>
        </div>
      </div>
      <div :class="w.tableRow">
        <div>Логин для входа в 1С</div>
        <div>
          <GnzsInput :class="[w.input, w.w400]" v-model="auth1cLogin" />
        </div>
      </div>
      <div :class="w.tableRow">
        <div>Пароль для входа в 1С</div>
        <div>
          <GnzsInput :class="[w.input, w.w400]" type="password" v-model="auth1cPwd" />
        </div>
      </div>
    </div>
  </GnzsSection>
</template>

<script>
  import GnzsSection from "@/gnzs-controls/Section";
  import GnzsButton from "@/gnzs-controls/Button";
  import GnzsInput from "@/gnzs-controls/Input";
  import GnzsDropdown from "@/gnzs-controls/Dropdown";
  import { mapGetters, mapActions } from "vuex";

  import md5 from "@/utilities/getMd5";
  import { openInfoModal } from "@/gnzs-controls/Modal/modal.utils";

  const mapFieldModel = function (fieldName) {
    return {
      get() {
        return this.$store.getters["settings/auth"][fieldName];
      },
      set(value) {
        this.$store.dispatch("settings/setAuthField", { field: fieldName, value });
      },
    };
  };

  export default {
    computed: {
      ...mapGetters({
        iframeName: "iframe/iframeName",
        auth1cTypes: "localSettings/auth1cTypes",
        dropboxAuthLink: "settings/dropboxAuthLink",
        isInsideAmo: "isInsideAmo",
        serviceTestBody: "settings/serviceTestBody",
        serviceTestErrorText: "settings/serviceTestErrorText",
        serviceTestHeader: "settings/serviceTestHeader",
        partnerData: "partnerData",
      }),
      key1c: { ...mapFieldModel.call(this, "key1c") },
      keyAmoCrm: { ...mapFieldModel.call(this, "keyAmoCrm") },
      dropboxToken: { ...mapFieldModel.call(this, "dropboxToken") },
      url1c: { ...mapFieldModel.call(this, "url1c") },
      auth1cType: { ...mapFieldModel.call(this, "auth1cType") },
      auth1cLogin: { ...mapFieldModel.call(this, "auth1cLogin") },
      auth1cPwd: { ...mapFieldModel.call(this, "auth1cPwd") },
    },
    methods: {
      ...mapActions({
        test: "settings/test",
        save: "settings/saveChanges",
      }),
      getMd5() {
        return md5(String(new Date().getTime()));
      },
      openDropboxConfirm() {
        window.open(this.dropboxAuthLink);
      },
      async checkService() {
        const url = new URL(document.location).origin;
        if (this.isInsideAmo) {
          await this.save();
          window.parent.postMessage(
            {
              event: `${this.iframeName}:openFullScreenModal`,
              url: `${url}/service-test`,
              params: {
                key1c: this.key1c,
              },
            },
            "*"
          );
        } else {
          await this.test();
          if (this.serviceTestErrorText) {
            openInfoModal({
              header: this.serviceTestHeader,
              innerHTML: this.serviceTestErrorText,
            });
          } else {
            let innerHTML = "";
            for (const item in this.serviceTestBody) {
              innerHTML += `${item}\n`;
            }
            openInfoModal({
              header: this.serviceTestHeader,
              innerHTML,
            });
          }
        }
      },
    },
    components: {
      GnzsSection,
      GnzsButton,
      GnzsInput,
      GnzsDropdown,
    },
  };
</script>

<style lang="scss" module="w">
  @import "@/assets/scss/Settings/style.scss";
</style>
