<template>
    <Modal ref="modal">
        <template v-slot>
            <div>
                <span class="modal-body__close" ref="close">
                    <span class="icon icon-modal-close"></span>
                </span>

                <div :class="$style.header">Информация по договору</div>

                <div :class="$style.body">

                    <div :class="$style.infoBlock">
                        <div :class="$style.row" v-for="(item, index) in info" :key="index">
                            <div :class="$style.cell">
                                {{item.title}}
                            </div>
                            <div>{{ value(item.value) }}</div>
                        </div>
                    </div>

                    <div :class="$style.violationBlock">
                        <div :class="$style.row" v-for="(item, index) in violation" :key="index">
                            <div :class="$style.cell">
                                {{item.title}}
                            </div>
                            <div>{{ value(item.value) }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </Modal>
</template>

<script>
import Modal from '@/gnzs-controls/Modal';
import { mapGetters } from 'vuex';

export default {
    data(){
        return {
            info: [
                {title: 'Вид условия договора', value: 'condition_type'},
                {title: 'Срок кредита', value: 'credit_term'},
                {title: 'Предел кредита', value: 'credit_limit'},
            ],
            violation: [
                {title: 'Сумма долга', value: 'loan_sum'},
                {title: 'Сумма по документу', value: 'doc_sum'},
                {title: 'Сумма превышения по кредиту', value: 'overdraft'},     
            ]
        }
    },
    computed: {
        ...mapGetters({
            orderData: 'order/orderData',
            contractById: 'contracts/contractById'
        }),
        contractId(){
            return this.orderData.contract_id
        },
        contractData(){
            return this.contractById(this.contractId)?.data?.violation_info
        }
    },
    methods: {
        value(key){
            return this.contractData?.[key] ? this.contractData[key] : ''
        },
        closeModal(){
            this.$refs.modal.close()
        },
    },
    components:{
        Modal
    }
}
</script>


<style lang="scss" module>
    @import './ContractInfoModal.scss';
</style>