<template>
    <Modal ref="modal">
        <template v-slot>
            <div :class ="$style.wrapper">
                <span class="modal-body__close" ref="close">
                <span class="icon icon-modal-close"></span>
                </span>

                <div :class="$style.header">
                    Применить обеспечение
                </div>

                <GnzsDropdown
                    v-model="selectedProvision"
                    :use-empty-item="false"
                    :items="statuses"
                    :emptyItemTitle="'Выбрать'"
                />

                <div :class="$style.actions">
                    <GnzsButton 
                        type="primary" 
                        @click="check" 
                        :disabled="!selectedProvision"
                    >Применить</GnzsButton>
                    <GnzsButton type="cancel" @click="closeModal">Отмена</GnzsButton>
                </div>
            </div>
        </template>
    </Modal>
</template>

<script>

import Modal from '@/gnzs-controls/Modal';

import GnzsButton from '@/gnzs-controls/Button';
import GnzsDropdown from '@/gnzs-controls/Dropdown';
import {openConfirmModal} from '@/gnzs-controls/Modal/modal.utils';
import $ from 'jquery';

import {mapActions, mapGetters} from 'vuex';

const mapFieldModel = function(key){
    return {
        get(){
            return this.$store.getters[`order/getRootValue`](key);
        },
        set(value){
            this.$store.dispatch('order/setRootValue', {key, value});
        }
    }
}

export default {
    computed: {
        ...mapGetters({
            statuses: 'provisions/provisionStatuses',
            confirmData: 'localSettings/confirmApplyProvision',
            usedOnEmptyStocks: 'provisions/usedOnEmptyStocks',
            emptyCountProductsChecked: 'order/emptyCountProductsChecked'
        }),
        selectedProvision: { ...mapFieldModel.call(this, 'selectedProvision') },
    },
    methods: {
        ...mapActions({
            applyProvision: 'order/applyProvision',
            setCheckedAll: 'order/setCheckedAll',
            calc: 'table/calc'
        }),
        check(){
            if (!this.usedOnEmptyStocks(this.selectedProvision) && this.emptyCountProductsChecked){
                openConfirmModal({
                    ...this.confirmData,
                    onAccept: () => this.apply()
                })
            } else {
                this.apply()
            }
        },
        apply(){
            this.applyProvision()
            this.setCheckedAll(false)
            let wrapper = $(`.gnzs-1c-scroll-table-wrapper`)
            let draggable = $(`.gnzs-1c-scroll-table-draggable`)
            setTimeout(() => this.calc({wrapper, draggable}), 100);
            this.closeModal();
        },
        closeModal(){
            this.$refs.modal.close()
        },
    },
    components: {
        Modal,
        GnzsButton,
        GnzsDropdown
    }
}
</script>

<style lang="scss" module>
    @import './AddProvisionModal.scss';
</style>