<template>
    <tr :class="$style.rtableRow">
        <!-- Номер -->
        <td :class="$style.rcell" data-code="number">
            <div :class="$style.cellInner">{{ index + 1 }}</div>
        </td>
        <!-- Артикул -->
        <td :class="$style.rcell" data-code="article" :title="article">
            <div :class="$style.cellInner">
                {{ article }}
            </div>
        </td>
                <!-- Наименование -->
        <td :class="$style.rcell" data-code="name" :title="product.name">
            <div :class="$style.cellInner_flex">
                <div :class="[$style.cellValue, {[$style.noDataText]: !productData}]">
                    <div :class="$style.cellInner">
                        {{ product.name }}
                    </div>
                    <div v-if="product.content && product.content.length" 
                        :class="$style.cellInner"
                        data-code="content"
                    >
                        {{ product.content }}
                    </div>
                </div>

                <div v-if="!productData" :class="$style.noDataIcon" title="Данные не найдены">
                    <svg>
                        <use xlink:href="#gnsz-1c-icon__info-error"></use>
                    </svg>
                </div>

                <GnzsCellEditor v-else :class="$style.cellEditor" data-code="name">
                    <template v-slot:control>
                        <textarea 
                            ref="prodContent"
                            placeholder="Введите содержание позиции..."
                            :value="prodContent" 
                        ></textarea>
                    </template>
                </GnzsCellEditor>

            </div>
        </td>
                <!-- Склад -->
        <td v-if="stockWorktype == 1" :class="$style.rcell" data-code="stock">
            <GnzsDropdown
                v-if="stocks.length"
                :value="product.stock_id"
                :items="stocks"
                :isGrouped="isGrouped"
                :disabled="true"
                @input="changeStock(product, $event)"
            />
            <div v-else :class="$style.cellInner">
                —
            </div>
        </td>
        <!-- Ед. измерения -->
        <td :class="$style.rcell" data-code="units">
            <GnzsDropdown 
                v-if="units.length"
                :items="units"
                :disabled="true"
                v-model="product.measure"
                @input="changeUnit($event)"
            />
            <div v-else :class="$style.cellInner">
                {{ product.measure ? product.measure : '—' }}
            </div>
        </td>
        <!-- Количество -->
        <td :class="$style.rcell" data-code="count">
            <GnzsInput 
                :value="product.count"
                type="number"
                @input="onChangeCount"
            />
        </td>
        <!-- Цена -->
        <td :class="$style.rcell" data-code="price">
            <GnzsInputHints 
                :value="product.price"
                :disabled="priceDisabled"
                :hints="[]"
                @input="onChangePrice"
            />
        </td>
        <!-- Дополнительные поля -->
        <template v-if="tariffFeatures.includes('table-builder')">
            <RealizationTableRowCustomFields v-for="field in tableFieldCustomWorkers" :field="field" :product="product" :key="field.attribute"/>
        </template>
        <!-- Итоговая цена -->
        <td :class="$style.rcell" data-code="total-price">
            <div :class="$style.cellInner_flexEnd">

                <GnzsCellEditor :class="$style.cellEditor" data-code="total-price" align="right">
                    <template v-slot:control>
                        <input 
                            ref="prodTotalPrice"
                            type="text" 
                            :value="product.total_price" 
                            @keydown="floatKeyDown"
                        />
                    </template>
                </GnzsCellEditor>

                <div :class="$style.price">{{ price }}</div>

                <div v-if="!hasId">
                    <GnzsButton :class="$style.buttonRemove" type="remove" @click="onDeleteRow"></GnzsButton>
                </div>
            </div>
        </td>
    </tr>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import _ from 'underscore';

import GnzsInput from '@/gnzs-controls/Input';
import GnzsInputHints from '@/gnzs-controls/InputHints';
import GnzsDropdown from '@/gnzs-controls/Dropdown';
import GnzsButton from '@/gnzs-controls/Button';
import GnzsCellEditor from '@/gnzs-controls/CellEditor';

import RealizationTableRowCustomFields from './RealizationTableRowCustomFields.vue'

import {getStockDropdownItems} from '@/utilities/orderProduct';
import {floatKeyDown} from '@/utilities/inputUtils';

export default {
    props: {
        product: {
            type: Object
        },
        index: {
            type: Number,
        }
    },
    computed: {
        ...mapGetters({
            stockWorktype: 'settings/stockWorktype',
            isNdsUsed: 'order/isNdsUsed',
            fiatures: 'features/getFeatures',
            defaults: 'settings/defaults',
            activeStatuses: 'provisions/provisionStatuses',
            emptyStockStatuses: 'provisions/emptyStockProvisionStatuses',
            stockCountBySearch: 'settings/stockCountBySearch',
            orderStockId: 'order/stockId',
            orderOrgId: 'order/organization1cId',
            tariffFeatures: 'features/getFeatures',
            organizations: 'organizations/orderItems',
            isChecked: 'order/isChecked',
            hasId: 'realizations/hasId',
            orderData: 'order/orderData',
            tableFieldCustomWorkers: 'settings/tableFieldCustomWorkers',
        }),
        productData() {
            return this.$store.getters['products/getById'](this.product.id);
        },
        priceDisabled(){
            return this.productData?.is_service == '0'
        },
        article(){
            return this.productData?.article || '—';
        },
        prodContent(){
            return _.isEmpty(this.product.content)
                ? this.product.name
                : this.product.content;
        },
        price(){
            let price = 0;

            if (this.product?.count && this.product?.price){
                price = (this.product.count * this.product.price)
                if (this.orderData.nds_type == 2){
                    price += +this.product.total_nds
                }
            }
            
            return price.toFixed(2)
        },
        measure(){
            return this.productData.measure;
        },
        stocks(){
            let orgName = this.organizations.find(val => val.value == this.orderOrgId)?.title
            return getStockDropdownItems(this.productData, this.orderOrgId, orgName);
        },
        units(){
            if(!this.productData) return [];

            let units = this.productData.units;
            if(!units || !units.length) return [];

            if(!units.includes(this.measure)){
                units = [this.measure, ...units];
            }

            return units.map(u => ({title: u, value: u}));
        },
        isGrouped(){
            //Если организация не выбрана не группируем выпадающий список
            return !!this.orderOrgId
        },
    },
    methods: {
        ...mapActions({
            removeProductFromItem: 'realizations/removeProductFromItem',
            changeProductItemCount: 'realizations/changeProductItemCount',
            changeProductItemPrice: 'realizations/changeProductItemPrice'
        }),
        onChangeCount(count){
            this.changeProductItemCount({uuid: this.product.uuid, count})
        },
        onChangePrice(price){
            this.changeProductItemPrice({uuid: this.product.uuid, price})
        },
        floatKeyDown(e){
            floatKeyDown(e);
        },
        onChangeProduct(){
            this.$store.dispatch('order/calcProcuct', this.product);
        },
        onDeleteRow(){
            this.removeProductFromItem(this.product.uuid);
        }
    },
    created(){
        // добавляем поле, т.к. при приходе заказов из 1С оно может отсутствовать
        if(typeof this.product.discount_view_type==='undefined'){
            this.$set(this.product, 'discount_view_type', 0);
        }
    },
    components: {
        GnzsInput,
        GnzsInputHints,
        GnzsDropdown,
        GnzsButton,
        GnzsCellEditor,
        RealizationTableRowCustomFields
    }
}
</script>


<style lang="scss" module>
    @import './Realizations.scss';
</style>