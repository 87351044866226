<template>
    <GnzsSection 
        :disabled="!tariffFeatures.includes('pay-calendar')"
        :disabledText="disabledProSectionText" 
        header='Детализация оплат'>
        <template v-slot:description>
            Если включено, то в модальном окне детализации заказа добавится блок для детализации оплат.
        </template>
        <template v-slot:header-control>
            <GnzsSwitcher
                v-model="defaults.isPaysCalendarEnabled"
            ></GnzsSwitcher>
        </template>
        <div v-if="defaults.isPaysCalendarEnabled">
		<div :class="w.table">
            <div :class="w.tableRow">
                <div :class="w.switcherTitle">Поле сделки графика оплат</div>
                <div>
                    <GnzsDropdown
                        :class="w.w200"
                        v-model="extraCfs.payCalendarCfId" 
                        :items="getAmoEntityCfs('lead', ['textarea'])"
                        :isSearchable="true"
                    />
                </div>
            </div>
		</div>
        </div>
    </GnzsSection>
</template>

<script>

import GnzsDropdown from '@/gnzs-controls/Dropdown';
import GnzsSection from '@/gnzs-controls/Section';
import GnzsSwitcher from '@/gnzs-controls/Switcher';

import { mapGetters } from "vuex";

export default {
    computed: {
        ...mapGetters({
            defaults: "settings/defaults",
            extraCfs: 'settings/extraCfs',
            tariffFeatures: 'features/getFeatures',
            disabledProSectionText: 'localSettings/disabledProSectionText',
            amoCustomFieldsByTypes: 'amo/customFieldsByTypes'
        }),
    },
    methods: {
        getAmoEntityCfs(entity, types = ['all']){
            return types.reduce((cfs, t) => 
                [...cfs, ...this.amoCustomFieldsByTypes[entity][t]], 
                []
            );
        },
    },
    components: {
        GnzsSection,
        GnzsSwitcher,
        GnzsDropdown,
    },
};

</script>

<style lang="scss" module="w">
    @import '@/assets/scss/Settings/style.scss';
</style>
