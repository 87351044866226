import $ from "jquery";
import moment from "moment-timezone";
import _ from "underscore";

import { amoCfTypes, updateLead, updateCustomer, addTextNote, getEntitiesArrayById } from "@/api/amocrm-api";

import { getCfValue, getCurrentEntityTypeName } from "./amo";

import { getDiscount } from "./orderProduct";

import { deepCloneByJson } from "./deepClone";
import { openInfoModal } from "@/gnzs-controls/Modal/modal.utils";
import { openConfirmModal } from "@/gnzs-controls/Modal/modal.utils";

export const closeOrderModalWithConfirm = (isOrderChanged, iframeName) => {
  if (isOrderChanged) {
    openConfirmModal({
      text: "Вы не сохранили внесенные изменения. Оставить окно открытым или закрыть без сохранения?",
      acceptText: "Не закрывать",
      declineText: "Закрыть без сохранения",
      onDecline: () => {
        window.parent.postMessage({ event: `${iframeName}:closeModal` }, "*");
      },
    });
  } else {
    window.parent.postMessage({ event: `${iframeName}:closeModal` }, "*");
  }
};

export const checkRealizationAndShowError = (itemProducts, orderProducts, shippedProducts, productData) => {
  /*
  * Контроль можно ли отправить на реализцию:

  1. Для каждой позиции проверим не превышает ли колчество к реализации, количество доступного
  2. Если превышает - выводим модалку с ошибкой
  3. Если это услгуга, тогда разрешаем отгружать сколько угодно
  */
  productData = Object.values(productData);

  const allow = itemProducts.every((item) => {
    let shipped = shippedProducts[item.id] || 0;
    let count = orderProducts.find((p) => p.uuid == item.uuid).count;
    let isService = productData.find((p) => p.id == item.id)?.is_service;
    return isService == "1" ? true : item.count <= count - shipped;
  });

  if (!allow) {
    showModal("Ошибка проведения реализации", "Количество реализованных товаров превышает количество доступных");
    throw new Error("Количество реализованных товаров превышает количество доступных");
  }
};

export const checkPaysDetailAndShowError = (data) => {
  /*
  * Контроль совпадения сумм календаря оплат и суммы заказа:

  1. Если строк больше чем одна - суммируем иначе берем за сумму значение единственной строки
  2. Если суммы не равны - выводим модалку с ошибкой
  */
  let sum = 0;
  /* eslint-disable-next-line no-prototype-builtins */
  if (!(data.hasOwnProperty("pay_calendar") && Array.isArray(data.pay_calendar))) {
    data.pay_calendar = [];
  }

  let payCalendar = data.pay_calendar,
    totalPrice = data.total_price;

  if (payCalendar.length > 0) {
    sum = payCalendar.reduce((acc, item) => (acc += parseFloat(item.sum)), 0);
  }

  if (sum != totalPrice) {
    showModal("Ошибка сохранения заказа", "Сумма оплат отличается от суммы заказа. Исправьте суммы оплат");
    throw new Error("Сумма оплат отличается от суммы заказа");
  }
};

// очищаем поля в заказе по сущности (контакт или компания)
// fieldPrefix = company_ | contact_
export const clearEntityFields = (fieldPrefix, order) => {
  for (let key in order) {
    if (key.substr(0, fieldPrefix.length) == fieldPrefix) {
      order[key] = key.includes("custom_fields") ? [] : "";
    }
  }
};

// подготавливаем данные кастомного поля для записи в объект заказа
const prepareCfDataForOrder = (amoTimezone, amoCfs, entity, cf) => {
  if (!cf.amo_cf_id || !cf.name) return;
  if (!_.isArray(entity["custom_fields_values"])) return;

  const cfData = amoCfs[cf.amo_cf_id];

  const entityCf = entity["custom_fields_values"].find((f) => f.field_id == cf.amo_cf_id);
  let cfValue = "";
  if (!entityCf) return; // если поле не заполнено, то его не будет в сущности

  switch (cfData.field_type) {
    case amoCfTypes.date:
    case amoCfTypes.date_time:
    case amoCfTypes.birthday:
      cfValue = getCfValue(entity, cf["amo_cf_id"]);
      if (!`${cfValue}`.length) break;
      cfValue = moment.unix(cfValue).tz(amoTimezone).format("YYYYMMDDHHmmss");
      break;

    case amoCfTypes.multiselect:
      cfValue = entityCf.values.reduce((res, v) => [...res, v.value], []).join("; ");
      break;

    case amoCfTypes.smart_address:
      cfValue = entityCf.values.reduce((res, v) => (v.value.length ? [...res, v.value] : [...res]), []).join(", ");

      break;

    case amoCfTypes.legal_entity:
      const res = []; /* eslint-disable-line no-case-declarations */

      entityCf.values.forEach((legalObj) => {
        const orgObj = legalObj.value;
        const org = [orgObj.name];

        if (orgObj.vat_id && orgObj.vat_id.length) org.push(`ИНН: ${orgObj.vat_id}`);
        if (orgObj.kpp && orgObj.kpp.length) org.push(`КПП: ${orgObj.kpp}`);

        res.push(org.join(", "));
      });

      cfValue = res.join("; ");

      break;

    default:
      cfValue = getCfValue(entity, cf["amo_cf_id"]);
      break;
  }

  return {
    name: cf["name"],
    isUpdateNeeded: cf?.sync == 1 ? 0 : 1,
    amo_cf_id: cf["amo_cf_id"].toString(),
    value: cfValue,
  };
};

// заполняем поля и кастомные поля в заказ по сущностям в зависимости от сопоставления в настройках
export const setEntityFields = (tz, orderFieldsSettings, amoCfs, entityType, entity, order) => {
  order[`${entityType}_custom_fields`] = [];

  const sett = orderFieldsSettings[entityType];

  for (let fName in sett) {
    if (fName == "templates") continue;

    if (fName == "custom_fields") {
      for (let cf of sett["custom_fields"]) {
        const cfData = prepareCfDataForOrder(tz, amoCfs[entityType], entity, cf);
        if (!_.isEmpty(cfData)) order[`${entityType}_custom_fields`].push(cfData);
      }
    } else if (entityType != "lead") {
      // сохраняем поля в корне заказа только от контакта и компании
      order[fName] = getCfValue(entity, sett[fName]["id"]);
    }
  }
};

export const setCurrentManagerData = (order, currentUser) => {
  order["current_user_id"] = currentUser.id;
  order["current_user_name"] = currentUser.name;
  order["current_user_phone"] = currentUser.phone;
  order["current_user_email"] = currentUser.email;
};

export const setManagerDataByPrefix = (order, users, groups, managerId, prefix = "") => {
  let user = users.find((u) => u.id == managerId);
  if (!user) user = { name: "", login: "", group_id: "" };
  let group = groups.find((g) => g.id == user.group_id);
  const { name, login } = user;

  let groupId = "",
    groupName = "";

  if (group) {
    (groupId = group.id), (groupName = group.name);
  }

  order[`${prefix}manager_id`] = `${managerId}`;
  order[`${prefix}manager_name`] = name;
  order[`${prefix}manager_email`] = login;
  order[`${prefix}manager_group_id`] = groupId;
  order[`${prefix}manager_group_name`] = groupName;
};

// записываем данные по менеджеру в заказ относительно настроек
export const setManagerData = (order, users, groups, managerId, currentUserId, managerType) => {
  if (managerType == 1) managerId = currentUserId;
  setManagerDataByPrefix(order, users, groups, managerId);
};

// записываем данные по менеджеру в заказ относительно настроек
export const setCompanyManagerData = (order, users, groups, managerId) => {
  setManagerDataByPrefix(order, users, groups, managerId, "company_");
};

// записываем данные по менеджеру в заказ относительно настроек
export const setContactManagerData = (order, users, groups, managerId) => {
  setManagerDataByPrefix(order, users, groups, managerId, "contact_");
};

// получения объекта товара для добавления в заказ
export const getProductForOrder = (
  tableFieldCustomWorkers,
  defaultsSettings,
  isOrganizationUseNds,
  orderData,
  productData,
  count = 1,
  stockId = "",
  price = 0,
  priceTypeId = ""
) => {
  let { nds = 0, stocks = [], measure = "" } = productData;

  // устанавливаем склад
  let stockName = "";
  let isCommonStockForOrder = defaultsSettings.stockWorktype == 0;

  if (isCommonStockForOrder) {
    // если склад на весь заказ
    stockId = orderData.stock_id;
    stockName = orderData.stock_name;
  } else {
    let selectedStockId = stockId.length ? stockId : defaultsSettings.stockId;

    if (selectedStockId.length && stocks.length) {
      const stock = stocks.find((s) => s.id == selectedStockId);
      stockName = stock ? stock.name : "";
      stockId = stock ? stock.id : "";
    }
  }

  // устанавливаем НДС
  if (isOrganizationUseNds) {
    nds = productData.nds && productData.nds.length ? productData.nds : defaultsSettings.vat;
  } else {
    nds = "";
  }

  // устанавливаем цену
  if (price == 0) {
    const selectedPriceTypeId = priceTypeId || defaultsSettings.priceTypeId;

    if (!productData.prices) productData.prices = [];

    if (selectedPriceTypeId.length && productData.prices.length) {
      const pItem = productData.prices.find((p) => p.id == selectedPriceTypeId);
      price = pItem ? pItem.cost : 0;
      priceTypeId = pItem ? pItem.id : "";
    }
  }

  //Добавление дополнительных столбцов
  let customFieldsValues = [];
  for (const item of tableFieldCustomWorkers) {
    let obj = {};
    obj.attribute = item.attribute;
    obj.value = "";
    if (typeof item.type !== "object") {
      obj.type = item.type;
      obj.code = "";
    } else {
      obj.type = item.type.type;
      obj.code = item.type.code;
    }
    customFieldsValues.push(obj);
  }

  // устанавливаем ед. измерения
  if (measure == "" && productData?.units.length) measure = productData?.units[0];

  const res = {
    uuid: uuidv4(), // при добавлении товара в таблицу, присвоим ему уникальный идентификатор
    id: productData.id,
    name: productData.name,
    provision_id: "",
    content: "",
    code: productData.code,
    article: productData.article,
    characteristic_id: productData.characteristic_id,
    characteristic_name: productData.characteristic_name,
    count: count,
    measure: measure,
    price: price, // 1
    price_type_id: priceTypeId, // 1
    total_price: "0",
    discount: "0",
    total_discount: "0",
    cancelled: 0,
    cancellation_reason: "",
    discount_view_type: defaultsSettings.discountViewType,
    stock_id: stockId,
    stock_name: stockName, // 1
    nds: nds,
    total_nds: "0",
    custom_fields_values: customFieldsValues,
  };

  return res;
};

//Функция генерации уникального ИД
export const uuidv4 = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

//Присвоить каждому элементу массива uuid
export const assignUniqueId = (data) => {
  data.products.forEach((value) => {
    value.uuid = uuidv4();
  });
  return data;
};

//Присвоить каждому элементу provision_id, если его нет
export const assignProvisionId = (data) => {
  data.products.forEach((value) => {
    if (!value?.provision_id) value.provision_id = "";
  });
  return data;
};

// Смена типа произвольного поля у продуктов
export const editSettingsType = (tableFieldCustomWorkers, data) => {
  data.products = data.products.map((product) => {
    /* eslint-disable-next-line no-prototype-builtins */
    if (!product.hasOwnProperty("custom_fields_values") || !Array.isArray(product.custom_fields_values)) {
      product.custom_fields_values = [];
    }
    product.custom_fields_values = product.custom_fields_values.map((field) => {
      const fieldCurrent = tableFieldCustomWorkers.find((s) => s.attribute === field.attribute);
      if (fieldCurrent) {
        const fieldPre = field.type;
        if (typeof fieldCurrent.type !== "object") {
          field.type = fieldCurrent.type;
          field.code = "";
        } else {
          field.type = fieldCurrent.type.type;
          field.code = fieldCurrent.type.code;
        }

        if (field.type !== fieldPre) {
          field.value = "";
        }
      }
      return field;
    });
    return product;
  });
  return data;
};

// Расчет цены
export const calcDiscountPrice = (product) => {
  if (product.discount_view_type == 0) {
    return +product.price - (+product.price / 100) * +product.discount;
  }

  return +product.price - +product.total_discount;
};

// создаем объект нового заказа учитывая настройки
export const getNewOrderDataWithDefaultsSettings = ({
  orderData,
  defaultsSettings,
  organizationsDefaults,
  managerDefaults,
  amoUserId,
  organizations = [],
  stocks = [],
  currencies = [],
}) => {
  const data = deepCloneByJson(orderData);

  let orgId = "",
    orgName = "",
    orgAccId = "",
    orgAccName = "",
    agrId = "",
    stockId = "",
    stockName = "",
    ndsType = "",
    partnerId = "";

  let defautlOrg;

  if (defaultsSettings.organizationId.length && organizations.length) {
    orgId = defaultsSettings.organizationId;
    defautlOrg = organizations.find((o) => o.id1C == orgId);
    orgName = defautlOrg?.name;

    if (defautlOrg && !defautlOrg.useNDS) {
      // если организация не использует НДС
      ndsType = 0;
    }
  }

  if (defaultsSettings.organizationAccountId.length && defautlOrg) {
    orgAccId = defaultsSettings.organizationAccountId;
    orgAccName = defautlOrg.accounts.find((a) => a.id1C == orgAccId)?.name;
  }

  if (defaultsSettings?.defaultAgreement?.length && defautlOrg) {
    agrId = defaultsSettings.defaultAgreement;
  }

  if (defaultsSettings.stockId.length && stocks.length) {
    stockId = defaultsSettings.stockId;
    stockName = stocks.find((s) => s.id == stockId)?.name;
  }

  if (ndsType === "" && defaultsSettings.vatType && defaultsSettings.vatType >= 0) {
    ndsType = defaultsSettings.vatType;
  }

  if (defaultsSettings.partnerId.length) {
    partnerId = defaultsSettings.partnerId;
  }

  let currencyCode = 643;

  if (currencies.length) {
    const currency = currencies.find((c) => c.name == defaultsSettings.currency);
    if (currency) currencyCode = currency.code;
  }

  //override defaults if orgSettings exists

  const values = managerDefaults.find((val) => val.managerId == amoUserId);

  if (values) {
    const org = organizations.find((o) => o.id1C == values.organizationId);

    stockId = values.stockId;
    stockName = stocks.find((s) => s.id == stockId)?.name;

    orgId = values.organizationId;
    orgAccId = values.organizationAccountId;

    orgName = org?.name || "";
    orgAccName = org.accounts.find((a) => a.id1C == orgAccId)?.name;
  } else if (organizationsDefaults.length) {
    let values = organizationsDefaults.find((val) => val.orgId == orgId);
    if (values) {
      stockId = values.stockId;
      stockName = stocks.find((s) => s.id == stockId)?.name || "";
      agrId = values.agreementId;
    }
  }

  data.organization_id = orgId;
  data.organization_name = orgName;
  data.organization_account_id = orgAccId;
  data.organization_account_name = orgAccName;
  data.agreement_id = agrId;
  data.partner_id = partnerId;
  data.stock_id = stockId;
  data.stock_name = stockName;
  data.nds_type = ndsType;
  data.currency_code = currencyCode;

  return data;
};

export const closeOrderModal = (iframeName) => {
  window.parent.postMessage({ event: `${iframeName}:closeModal` }, "*");
};

export const getAdditionalCfSum = ({ accountData, defaultsSettings, entity, entityType }) => {
  const result = { sum: 0, cfs: [] };

  const entityTypeName = getCurrentEntityTypeName(entityType, "multiple");

  if (defaultsSettings.appendCfToLeadPrice?.length) {
    defaultsSettings.appendCfToLeadPrice.forEach((cfId) => {
      if (accountData.amoCustomFields[entityTypeName][cfId]) {
        const cfValue = +getCfValue(entity, cfId);
        result.sum += cfValue;
        result.cfs.push({
          name: accountData.amoCustomFields[entityTypeName][cfId].name,
          value: cfValue,
        });
      }
    });
  }

  return result;
};

// Не используется в данный момент
// TODO Доделать этот метод, код вроде правильный, но почему не отрабатывает валится на дате и не записывается price
export const updateOrderFields = async ({
  entityType,
  entityId,
  orderData,
  settingsOrderFields,
  settingsOrderCustomFields,
  itemData,
}) => {
  const type = getCurrentEntityTypeName(entityType);
  const cfKey = `${type}_cf_id`;

  let body = {
    custom_fields_values: [],
  };

  let totalPrice = orderData.total_price;
  const priceField = settingsOrderFields?.[type]?.["order_sum"]?.id;

  if (!priceField) {
    body.price = totalPrice;
  } else {
    body.custom_fields_values.push({ field_id: priceField, values: [{ value: totalPrice }] });
  }

  const fieldsForUpdate = ["order_number", "order_number_short", "order_date"];

  fieldsForUpdate.forEach((fKey) => {
    const fId = settingsOrderFields?.[type]?.[fKey]?.id;

    if (orderData[fKey] && fId) {
      body.custom_fields_values.push({ field_id: fId, values: [{ value: orderData[fKey] }] });
    }
  });

  let orderFieldsForUpdate = settingsOrderCustomFields.filter((val) => val[cfKey]);
  orderFieldsForUpdate.forEach((val) => {
    let field = orderData.order_custom_fields.find((v) => v.attribute == val.attribute);
    if (field?.value) {
      let value = itemData(field.code, field.type).find((k) => k.value == field.value)?.title;
      body.custom_fields_values.push({ field_id: val[cfKey], values: [{ value }] });
    }
  });

  switch (type) {
    case "lead":
      updateLead(entityId, body);
      break;
    case "customer":
      updateCustomer(entityId, body);
      break;
  }
};

// Перенести на бэкенд - ПЕРЕНЕСЕНО!!!
// заполняем поля в карточе сущности
export const updateEntityCardData = ({
  entityType,
  orderData,
  orderFieldsSettings,
  settingsOrderCustomFields,
  itemData,
}) => {
  let totalPrice = orderData.total_price;
  const entityTypeName = getCurrentEntityTypeName(entityType);
  const priceField = orderFieldsSettings?.[entityTypeName]?.["order_sum"]?.id;

  if (priceField) {
    $(`input[name="CFV[${priceField}]"]`).val(totalPrice).trigger("change");
  } else {
    $(`input#lead_card_budget`).val(parseInt(totalPrice)).trigger("change");
    $(`input[name="lead[PRICE]"]`).val(parseInt(totalPrice)).trigger("change");
  }

  const fieldsForUpdate = ["order_number", "order_number_short", "order_date"];

  fieldsForUpdate.forEach((fKey) => {
    const fId = orderFieldsSettings?.[entityTypeName]?.[fKey]?.id;

    if (orderData[fKey] && fId) {
      $(`input[name="CFV[${orderFieldsSettings[entityTypeName][fKey].id}]"]`).val(orderData[fKey]).trigger("change");
    }
  });

  const cfKey = `${entityTypeName}_cf_id`;

  let orderFieldsForUpdate = settingsOrderCustomFields.filter((val) => val[cfKey]);

  orderFieldsForUpdate.forEach((val) => {
    let field = orderData.order_custom_fields.find((v) => v.attribute == val.attribute);
    if (field?.value) {
      let value = field?.code
        ? itemData(field.code, field.type)?.find((k) => k.value == field.value)?.title
        : field.value;
      $(`input[name="CFV[${val[cfKey]}]"]`).val(value).trigger("change");
    }
  });
};

// Не используется, сделано через бэкенд
export const exportProductText = async ({ entityType, entityId, orderData, defaultsSettings }) => {
  const type = defaultsSettings?.exportProductsText?.type;
  const cfId = defaultsSettings?.exportProductsText?.cfId;
  if (!type) return;

  const productsText = orderData.products.map((p) => `${p.name} - ${p.count} ${p.measure}`).join(`;\n`);
  const noteProductsText = `Список товаров в заказе:\n${productsText}`;

  let Etype = "leads";
  if (entityType == 12) {
    Etype = "customers";
  }

  if (type == 1) {
    // выгружаем в примечание
    addTextNote(entityId, noteProductsText, Etype);
  }

  if (type == 2) {
    // выгружаем в поле
    updateLead(entityId, {
      custom_fields_values: [{ field_id: cfId, values: [{ value: productsText }] }],
    });
  }

  if (type == 3) {
    // выгружаем в поле и примечание
    addTextNote(entityId, noteProductsText, Etype);
    updateLead(entityId, {
      custom_fields_values: [{ field_id: cfId, values: [{ value: productsText }] }],
    });
  }
};

// получаем массив незаполненных обязательных полей
export const getEmptyRequiredFields = ({
  amoEntity,
  amoContact,
  amoCompany,
  orderFieldsSettings,
  cardsFields,
  amoCfsByTypes,
  order,
}) => {
  const entities = { lead: amoEntity }; // объект с ссылками на сущности, которые проверяем - lead(customer), contact, company
  const entitiyTypes = ["lead"];

  if (amoContact) {
    entitiyTypes.push("contact");
    entities["contact"] = amoContact;
  }

  if (amoCompany) {
    entitiyTypes.push("company");
    entities["company"] = amoCompany;
  }

  const isFieldEmpty = (entity, fieldId) =>
    !_.isArray(entity.custom_fields_values) ||
    (entity.custom_fields_values.length && !entity.custom_fields_values.find((cf) => cf.field_id == fieldId));
  const emptyFields = [];

  // проверка заказа на заполненность обязательных полей
  orderFieldsSettings?.order_custom_fields
    ?.filter((cf) => cf.required)
    .forEach((cf) => {
      const filled = order.order_custom_fields?.find((v) => v.attribute == cf.attribute)?.value?.length;
      if (!filled) {
        emptyFields.push({ fieldName: cf.title });
      }
    });

  const processField = (entity, entityType, fieldId) => {
    if (isFieldEmpty(entity, fieldId)) {
      const fieldName = amoCfsByTypes[entityType].all.find((cf) => cf.value == fieldId)?.title;
      emptyFields.push({ entityType, fieldName, fieldId });
    }
  };

  entitiyTypes.forEach((entityType) => {
    for (let fKey in orderFieldsSettings[entityType]) {
      const field = orderFieldsSettings[entityType][fKey];

      if (fKey === "custom_fields" && field.length) {
        // если поля находятся в разделе custom_fields

        field.forEach((customField) => {
          if (customField.required) {
            processField(entities[entityType], entityType, customField.amo_cf_id);
          }
        });
      } else if (field.id && field.required) {
        processField(entities[entityType], entityType, field.id);
      }
    }

    cardsFields
      .filter((v) => v.amoType == entityType)
      .forEach((v) => {
        if (v.required) {
          processField(entities[entityType], entityType, v.amoCfId);
        }
      });
  });

  return emptyFields;
};

const isPhone = (phone) => (phone.match(/\d/g) ? phone.match(/\d/g).join("").length > 8 : false);
const isEmail = (email) => /\S+@\S+\.\S+/.test(email);

export const setContactEmailsAndPhones = (order, contact, rootGetters) => {
  let contactFieldIds = {
    contact_phones: rootGetters["settings/entityFieldId"]({ entity: "contact", field: "contact_phone" }),
    contact_emails: rootGetters["settings/entityFieldId"]({ entity: "contact", field: "contact_email" }),
  };

  Object.entries(contactFieldIds).forEach(([k, v]) => {
    let value = [];
    if (Array.isArray(contact.custom_fields_values)) {
      if (k == "contact_phones") {
        value = [];
        const values = contact.custom_fields_values.find((f) => f.field_id == v)?.values;
        if (values) {
          values
            .filter((v) => v?.value && isPhone(v.value))
            .forEach((v) => {
              value.push(v.value);
            });
        }
      } else if (k == "contact_emails") {
        value = [];
        const values = contact.custom_fields_values.find((f) => f.field_id == v)?.values;
        if (values) {
          values
            .filter((v) => v?.value && isEmail(v.value))
            .forEach((v) => {
              value.push(v.value);
            });
        }
      }
    }
    order[k] = value ? value : "";
  });
};

export const getContactsData = async ({ ids, rootGetters }) => {
  let result = [];
  let contactsDataResp = await getEntitiesArrayById({ entitiesType: "contacts", ids });

  const currentUserId = rootGetters["amoUserId"];
  const managerType = rootGetters["settings/defaults"]?.managerType;

  let contactFieldIds = {
    contact_name: rootGetters["settings/entityFieldId"]({ entity: "contact", field: "contact_name" }),
    contact_phone: rootGetters["settings/entityFieldId"]({ entity: "contact", field: "contact_phone" }),
    contact_email: rootGetters["settings/entityFieldId"]({ entity: "contact", field: "contact_email" }),
    contact_phones: rootGetters["settings/entityFieldId"]({ entity: "contact", field: "contact_phone" }),
    contact_emails: rootGetters["settings/entityFieldId"]({ entity: "contact", field: "contact_email" }),
    contact_address: rootGetters["settings/entityFieldId"]({ entity: "contact", field: "contact_address" }),
  };

  contactsDataResp?.forEach((contact) => {
    let managerId = contact.responsible_user_id;
    if (managerType == 1) managerId = currentUserId;

    let contactData = {
      contact_id: `${contact.id}`,
      contact_short_name: contact.name,
    };

    setManagerDataByPrefix(
      contactData,
      rootGetters["accountData"].amoUsers,
      Object.values(rootGetters["accountData"].amoUserGroups),
      managerId,
      "contact_"
    );

    setEntityFields(
      rootGetters["accountData"].amoTimezone,
      rootGetters["settings/getDocflowSettings"].orderFields,
      rootGetters["amo/customFieldsByIds"],
      "contact",
      contact,
      contactData
    );

    Object.entries(contactFieldIds).forEach(([k, v]) => {
      let value = [];
      if (Array.isArray(contact.custom_fields_values)) {
        if (k == "contact_phones") {
          value = [];
          const values = contact.custom_fields_values.find((f) => f.field_id == v)?.values;
          if (values) {
            values
              .filter((v) => v?.value && isPhone(v.value))
              .forEach((v) => {
                value.push(v.value);
              });
          }
        } else if (k == "contact_emails") {
          value = [];
          const values = contact.custom_fields_values.find((f) => f.field_id == v)?.values;
          if (values) {
            values
              .filter((v) => v?.value && isEmail(v.value))
              .forEach((v) => {
                value.push(v.value);
              });
          }
        } else {
          value = contact.custom_fields_values.find((f) => f.field_id == v)?.values?.[0]?.value;
        }
      }
      contactData[k] = value ? value : "";
    });
    result.push(contactData);
  });

  return result;
};

// проверяем заполненность важных данных (выкидываем ошибку и показываем модалку, если что-то не заполнено)
export const checkAllowSaveOrderAndShowError = (
  orderData,
  tableFieldsSettings,
  isOneStockOrder,
  isAgreementRequired,
  isContractRequired,
  showContract,
  isStockRequired,
  isOrganizationRequired
) => {
  if (orderData.products.some((p) => p.count == 0)) {
    showModal("Невозможно отправить заказ в 1С", "Количество товара должно быть больше 0");
    throw new Error("Количество товара должно быть больше 0");
  }

  if (
    (_.isEmpty(orderData.organization_id) || _.isEmpty(orderData.organization_account_id)) &&
    isOrganizationRequired
  ) {
    showModal("Невозможно отправить заказ в 1С", "Необходимо выбрать организацию и расчетный счет");
    throw new Error("Необходимо выбрать организацию и расчетный счет");
  }

  if (isAgreementRequired && _.isEmpty(orderData.agreement_id)) {
    showModal("Невозможно отправить заказ в 1С", "Необходимо выбрать соглашение");
    throw new Error("Необходимо выбрать соглашение");
  }

  if (isContractRequired && showContract && _.isEmpty(orderData.contract_id)) {
    showModal("Невозможно отправить заказ в 1С", "Необходимо выбрать договор");
    throw new Error("Необходимо выбрать договор");
  }

  if (isOneStockOrder && isStockRequired && _.isEmpty(orderData.stock_id)) {
    showModal("Невозможно отправить заказ в 1С", "Необходимо выбрать склад");
    throw new Error("Необходимо выбрать склад");
  }

  orderData.products.forEach((product) => {
    if (product.custom_fields_values) {
      product.custom_fields_values.forEach((pcf) => {
        const pcfSettings = tableFieldsSettings.find((tfs) => tfs.attribute == pcf.attribute);
        if (pcfSettings?.required) {
          if (!pcf.value?.toString()?.length) {
            showModal(
              "Невозможно отправить заказ в 1С",
              `Необходимо заполнить дополнительное поле товара ${product.name}: ${pcfSettings.name}`
            );
            throw new Error(`Необходимо заполнить дополнительное поле товара ${product.name}: ${pcf.name}`);
          }
        }
      });
    }
  });
};

export const checkAllowSaveContract = (settingsFields, fields) => {
  let html = "";
  const requiredFields = settingsFields.filter((v) => v.required);
  requiredFields.forEach((field) => {
    let f = fields.find((v) => v.attribute == field.attribute);
    if (!(f && f?.value)) html += `<li>${field.title}</li>`;
  });

  if (html) {
    showModal(
      "Не заполнены обязательные поля",
      `
        <div style="margin-bottom: 10px;">Чтобы создать договор, необходимо заполнить следующие обязательные поля:</div>
        <ul style="list-style: disc; padding-left: 25px;">${html}</ul>`
    );
    throw new Error("Необходимо заполнить обязательные поля в сущностях");
  }
};

// открытие информационной модалки
export const showModal = (header, innerHTML, fixHeight = false) => openInfoModal({ header, innerHTML, fixHeight });

// проверка на заполненность обязательных кастомных полей (которые в настройках помечены обязательными)
export const showEmptyFieldsModal = (emptyFields) => {
  const getEnityName = (entityType) => {
    switch (entityType) {
      case "lead":
        return "Сделка";
      case "customer":
        return "Покупатель";
      case "contact":
        return "Контакт";
      case "company":
        return "Компания";
    }
  };

  const rowsHtml = emptyFields.reduce((html, el) => {
    const row =
      el.entityType && el.fieldId ? `${getEnityName(el.entityType)}: ${el.fieldName} (${el.fieldId})` : el.fieldName;

    return html + `<li>${row}</li>`;
  }, "");

  showModal(
    "Не заполнены обязательные поля",
    `
        <div style="margin-bottom: 10px;">Чтобы отправить заказ в 1С, необходимо заполнить следующие обязательные поля:</div>
        <ul style="list-style: disc; padding-left: 25px;">${rowsHtml}</ul>
    `
  );
};

export const handleErrors = ({ getters, dispatch }) => {
  if (getters["order/errorLinkedEntities"].length) {
    let rowsHtml = "";
    getters["order/errorLinkedEntities"].forEach((val) => {
      let entity = val.type == "contact" ? "контакт" : "компанию";
      rowsHtml += `<li>Не удалось получить ${entity} по id <b>${val.id}</b></li>`;
    });
    showModal(
      "Не удалось получить прикрепленные сущности",
      `<ul style="list-style: disc; padding-left: 25px;">${rowsHtml}</ul>`
    );
  }

  dispatch("order/clearErrorEntitiesList");
};

export const isNoMoves = (i, direction, items, checked) => {
  /*
  Проверка на доступное перемещение, функция работает в паре с move (order/mutations.js)
  
  1. Проверим direction если вверх, то тогда сдвиг (offset) на 1 элемент влево, иначе вправо,
  если движение идет влево индекс не должен быть равен 0 и не должен быть равен последнему
  при движении вправо (targetIndex).
  2. Обходим рекуррентно массив по заданному направлению, если последний(первый) индекс элемент
  выделен (checked.includes) возвращаем, что движений нет, иначе переходим к следующему по 
  направлению элементу
  3. Если элемент не выделен значит это пустота, возвращаем false.
  */
  let offset = direction === "up" ? -1 : 1,
    targetIndex = direction === "up" ? 0 : items.length - 1;

  if (checked.includes(items[i].uuid)) {
    return i === targetIndex ? true : isNoMoves(i + offset, direction, items, checked);
  }
  return false;
};

// функции калькуляции заказа

export const getTotalPrice = (order) => {
  return order.products
    .filter((p) => !p?.cancelled)
    .reduce((previousValue, currentValue) => {
      return previousValue + parseFloat(currentValue.total_price);
    }, 0);
};

export const getTotalPriceClear = (order) => {
  return order.products
    .filter((p) => !p?.cancelled)
    .reduce((previousValue, currentValue) => {
      return previousValue + parseFloat(currentValue.price) * parseFloat(currentValue.count);
    }, 0);
};

export const getTotalDiscount = (order) => {
  return order.products
    .filter((p) => !p?.cancelled)
    .reduce((previousValue, currentValue) => {
      return previousValue + parseFloat(currentValue.total_discount);
    }, 0);
};

export const getTotalAutoDiscount = (order) => {
  return order.products
    .filter((p) => !p?.cancelled)
    .reduce((previousValue, currentValue) => {
      const ad = currentValue?.auto_discount ? +currentValue.auto_discount : 0;
      const discount = getDiscount(+currentValue.price, currentValue.count, +ad);
      return previousValue + parseFloat(discount);
    }, 0);
};

export const getTotalVAT = (order) => {
  return order.products
    .filter((p) => !p?.cancelled)
    .reduce((previousValue, currentValue) => {
      return previousValue + parseFloat(currentValue.total_nds);
    }, 0);
};
