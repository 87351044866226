export default {
  title: 'Distribution of deals',

  tokenError: 'The authorization token has expired !',

  buttons: {
    append: 'Append',
    back: 'Back',
    save: 'Save',
    cancel: 'Cancel',
    templateAppend: 'Append settings template',
  },

  breadcrumbs: {
    templateTitle: 'New Template',
  },

  body: {
    sections: {
      main: 'Basic settings',
      groupsAndUsers: 'Groups and users to distribute',
      additional: 'Additional settings',
    },
    templateName: 'Name of the settings template',
    distributionType: 'Distribution type',
    usersQueue: {
      settingsDescription: {
        title: 'Tag groups or individual users',
        show: 'More',
        hide: 'Hide',
      },
      description: 'If you check the entire group, the integration will distribute deals among its users, dynamically picking up changes if you add/remove a user from the group. If only individual users are marked, then distribution will occur only between them.',
    },
    usersPercent: {
      settingsDescription: {
        title: 'Specify distribution percentages',
        show: 'More',
        hide: 'Hide',
      },
      description: 'Group percentages must add up to 100%. Also, for a group, you can choose the distribution format between users of this group: select the "distribute evenly" option so that within this group the distribution goes evenly to everyone; if you need to set a different distribution ratio between users, select the "distribute by percentage" option - in this case, the sum of percentages for users within the group should also be equal to 100%.',
      group: 'out of 100% by groups',
      distribute: 'distribute',
    },
    repeatControl: 'Repeat deals control',
    AssignOnlyWorkime: {
      title: {
        label: 'Distribute during business hours only',
      },
      workTime: {
        time: 'Work time',
        days: 'Work days',
        from: 'from',
        to: 'to',
        weekDays: {
          mon: 'Mo',
          tue: 'Tu',
          wed: 'We',
          thu: 'Th',
          fri: 'Fr',
          sat: 'Sa',
          sun: 'Su',
        },
      },
      DNA: {
        activated: {
          warning: 'Important: The users in the current setup must match the users in the selected schedule, otherwise they will not be distributed.',
        },
        notActivated: {
          warning: 'Attention! you need to install',
          link: 'DNA widget',
        },
      },
    },
    distributionAgain: {
      title: "Redistribute if stage doesn't change",
      across: 'Across',
      min: 'min',
    },
    changeContacts: 'Change the owner of a contact and a company',
    changeLeadTasks: 'Change the owner of open tasks for a deal',
    changeContactTasks: 'Change the owner of open tasks by contacts',
    changeCompanyTasks: 'Change the owner of open tasks by company',
  },

  assignTypes: [
    { value: 1, title: 'Take turns' },
    { value: 2, title: 'By interest' },
  ],

  assignPercentTypes: [
    { value: 1, title: 'evenly' },
    { value: 2, title: 'by interest' },
  ],

  repeatLeadsControlTypes: [
    { value: 1, title: 'Ignore repeat trades' },
    { value: 2, title: 'Distribute repeated transactions on the Contact to the responsible' },
    { value: 3, title: 'Allocate repeated transactions in the Company to the responsible' },
    { value: 4, title: 'Distribute repeated transactions by Contact or Company to the responsible' },
  ],

  assignOnlyWorktimeTypes: [
    { value: 1, title: 'according to the general schedule' },
    { value: 2, title: 'on an individual schedule' },
  ],
}