<template>
  <td :class="$style.cell">
    <GnzsInput
      v-if="field.type === 'text'"
      :value="value"
      type="text"
      :disabled="!!(!field.edit || product.cancelled)"
      @input="onChangeCustomFieldProduct" />
    <GnzsInput
      v-else-if="field.type === 'int'"
      :value="value"
      type="number"
      :disabled="!!(!field.edit || product.cancelled)"
      @input="onChangeCustomFieldProduct" />
    <GnzsSwitcher
      v-else-if="field.type === 'bool'"
      :class="$style.marget_x10"
      :value="value"
      :disabled="!!(!field.edit || product.cancelled)"
      @input="onChangeCustomFieldProduct" />
    <DatePicker
      v-else-if="field.type === 'date'"
      :class="CustomField.dateInput"
      :value="dateValue"
      :format="'DD.MM.YYYY'"
      :clearable="false"
      type="date"
      :disabled="!!(!field.edit || product.cancelled)"
      @change="onChangeCustomFieldProduct">
      <template v-slot:icon-calendar>
        <svg :class="CustomField.calendar">
          <use xlink:href="#card-calendar"></use>
        </svg>
      </template>
    </DatePicker>
    <GnzsDropdown
      v-else
      :value="value"
      :disabled="!!(!field.edit || product.cancelled)"
      :isSearchable="typesDataTotal({ code: field.type.code, type: field.type.type }) > 10"
      :isChuncked="typesDataTotal({ code: field.type.code, type: field.type.type }) > 50"
      :isLoading="isTypesDataloading({ code: field.type.code, type: field.type.type })"
      :items="typesData({ code: field.type.code, type: field.type.type })"
      :searchedItems="typesDataSearched({ code: field.type.code, type: field.type.type })"
      @search="typesDataSearch({ code: field.type.code, type: field.type.type, query: $event })"
      @input="onChangeCustomFieldProduct"
      @scrollend="onScrollEnd({ code: field.type.code, type: field.type.type })"
      :use-empty-item="false" />
  </td>
</template>

<script>
  import GnzsInput from "@/gnzs-controls/Input";
  import GnzsDropdown from "@/gnzs-controls/Dropdown";
  import GnzsSwitcher from "@/gnzs-controls/Switcher";
  import DatePicker from "vue2-datepicker";
  import "@/assets/datepicker/ru";
  import "@/assets/datepicker/datepicker.css";
  import moment from "moment";
  import { mapActions, mapGetters } from "vuex";

  export default {
    name: "ProductsTableRowFieldCustom",
    components: {
      GnzsInput,
      GnzsDropdown,
      GnzsSwitcher,
      DatePicker,
    },
    props: {
      field: {
        type: Object,
        required: true,
      },
      product: {
        type: Object,
        required: true,
      },
    },
    computed: {
      ...mapGetters({
        typesData: "additionalTypes/itemData",
        typesDataTotal: "additionalTypes/itemDataTotal",
        typesDataSearched: "additionalTypes/itemDataSearched",
        isTypesDataloading: "additionalTypes/isItemDataLoading",
        tableField: "settings/tableField",
        itemDataName: "additionalTypes/itemDataName",
      }),
      customFieldsValues() {
        return this.product.custom_fields_values.find((v) => v.attribute === this.field.attribute);
      },
      value() {
        return this.customFieldsValues?.value;
      },
      dateValue() {
        return new Date(moment(this.customFieldsValues?.value, "DD.MM.YYYY 0:00:00"));
      },
      key() {
        return this.field.key;
      },
    },
    methods: {
      ...mapActions({
        load1ctypeItems: "additionalTypes/load1ctypeItems",
        typesDataSearch: "additionalTypes/searchItems",
        changeCustomFieldProduct: "order/changeCustomFieldProduct",
      }),
      async onScrollEnd({ code, type }) {
        await this.load1ctypeItems({ code, type });
      },
      onChangeCustomFieldProduct(value) {
        if (this.field.type === "date") {
          value = moment(value).format("DD.MM.YYYY 0:00:00");
        }
        this.changeCustomFieldProduct({
          uuid: this.product.uuid,
          attribute: this.field.attribute,
          code: this.field.code ? this.field.code : "",
          type: this.field.type,
          value,
        });
      },
      valueDirectory() {
        const { type, code } = this;
        const id = this.customFieldsValues.value;
        return this.itemDataName({ code, type, id });
      },
    },
  };
</script>

<style lang="scss" module>
  @import "./ProductsTable.scss";
</style>

<style lang="scss" module="CustomField">
  @import "./../Parametes/Parameters.scss";
</style>
