<template>  
    <div :class="$style.App">
        <Icons />
        <router-view/>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import PATHS from "@/router/constants"
import Icons from '@/gnzs-controls/IconsSprite';
import initApp from '@/init';

export default {
    computed: {
        ...mapGetters({
            localization: 'localization',
        }),
        routes: () => PATHS,
    },
    methods: {
        ...mapActions({
            resizeWindow: 'iframe/resizeWindow',
            iFrameInit: 'iFrameInit'
        })
    },
    async created() {
        await this.iFrameInit();
        window.addEventListener('resize', this.resizeWindow);
        await initApp(this.$route)
    },
    destroyed() {
        window.document.removeEventListener('resize', this.resizeWindow);
    },
    components: {
        Icons
    },
}
</script>

<style lang="scss" module>
    @import './style.scss';
</style>
