import store from '@/store/';

export const getHeaders = () => { 
    return {
        widgetId: store.state.widgetId,
        clientId: store.state.amoAccountId,
        key1c: store.state.settings.docFlowSettings.auth.key1c
    }
}

export const api1CKeyAmoCRMInterceptor = (config) => {
    const { headers } = config;
    const {clientId, widgetId, key1c} = getHeaders()
    headers['client-id'] = clientId || ''
    headers['widget-id'] = widgetId || ''
    headers.key = key1c || ''; 

    return config;
};