<template>
  <GnzsAlert :class="activation.notification" title="Вы используете тариф API" type="error">
    <template v-slot:text>
      Тариф API не предусматривает работу виджета в интерфейсе amoCRM, если у Вас остались вопросы свяжитесь с нами
      любым удобным для Вас способом:

      <div :class="activation.linksContainer">
        <a
          :class="activation.link"
          data-code="vk"
          href="https://vk.me/-148384693?ref=widget&amp;ref_source=widget"
          target="_blank">
          <svg><use xlink:href="#gnsz-wa-icon__vk"></use></svg>
        </a>
        <a
          :class="activation.link"
          data-code="tg"
          href="tg://resolve?domain=gnzs_bot&amp;ref=widget&amp;ref_source=widget"
          target="_blank">
          <svg><use xlink:href="#gnsz-wa-icon__telegram"></use></svg>
        </a>
        <a :class="activation.link" data-code="wa" :href="waLink" target="_blank">
          <svg><use xlink:href="#gnsz-wa-icon__whatsapp"></use></svg>
        </a>
      </div>
    </template>
  </GnzsAlert>
</template>

<script>
  import GnzsAlert from "@/gnzs-controls/Alert";
  import { mapGetters } from "vuex";

  export default {
    computed: {
      ...mapGetters({
        amoAccountId: "amoAccountId",
      }),
      waLink() {
        const text = "Хочу уточнить информацию по тарифу 1С Интеграция - " + this.amoAccountId;
        return encodeURI(`https://api.whatsapp.com/send?phone=79960650732&text=${text}`);
      },
    },
    components: {
      GnzsAlert,
    },
  };
</script>

<style lang="scss" module="activation">
  @import "../Activation/style.scss";
</style>
