<template>
  <div :class="$style.modalWrapper">
    <div :class="$style.modal">
      <div :class="[$style.modalScroller, extraClass]" @click.self="onOverlayClick">
        <div
          v-show="loaded"
          ref="modal-body"
          :style="{ width }"
          :class="[$style.modalBody, $style.modalBodyAnimation, $style.modalBodyRelative]">
          <span v-if="closable" @click="close" :class="$style.modalClose">
            <svg>
              <use xlink:href="#gnzs-ms-icon__modal-close"></use>
            </svg>
          </span>
          <slot></slot>
          <div :class="$style.inner" v-if="text.length">
            <h2 :class="$style.head">{{ text }}</h2>
          </div>
          <div v-if="header.length" :class="$style.header">
            <h2 :class="$style.inner">{{ header }}</h2>
          </div>
          <div v-if="innerHTML.length" :class="[$style.innerHtmlBlock, fixHeight ? $style.fixHeight : '']">
            <div v-html="innerHTML"></div>
          </div>
          <div v-if="type == 'confirm'" :class="$style.buttonsBlock">
            <GnzsButton :class="$style.confirmButton" @click="accept">{{ acceptText }}</GnzsButton>
            <GnzsButton :class="$style.cancelButton" @click="close" type="cancel">{{ declineText }}</GnzsButton>
          </div>
        </div>
      </div>
      <div :class="$style.overlay" @click="onOverlayClick">
        <span v-if="!loaded" :class="$style.spinner"></span>
      </div>
    </div>
  </div>
</template>

<script>
  import $ from "jquery";
  import GnzsButton from "@/gnzs-controls/Button";

  export default {
    props: {
      extraClass: {
        type: String,
        default: "",
      },
      closable: {
        type: Boolean,
        default: true,
      },
      fixHeight: {
        type: Boolean,
        default: false,
      },
      noCloseOnDecline: {
        type: Boolean,
        default: false,
      },
      noCloseOnAccept: {
        type: Boolean,
        default: false,
      },
      declineOnOverlayClick: {
        type: Boolean,
        default: false,
      },
      closeByOverlayClick: {
        type: Boolean,
        default: true,
      },
      innerHTML: {
        type: String,
        default: "",
      },
      header: {
        type: String,
        default: "",
      },
      text: {
        type: String,
        default: "",
      },
      type: {
        type: String,
        default: "blank",
      },
      loaded: {
        type: Boolean,
        default: true,
      },
      width: {
        type: String,
        default: "500px",
      },
      acceptText: {
        type: String,
        default: "Подтвердить",
      },
      declineText: {
        type: String,
        default: "Отменить",
      },
      onAccept: {},
      onDecline: {},
    },
    watch: {
      loaded: function (val) {
        if (val) {
          setTimeout(() => this.centrify(), 0);
        }
      },
    },
    methods: {
      centrify() {
        var modal = $(this.$refs["modal-body"]);

        var h = modal.height();
        var bh = $(window).height();

        h = (bh - h) / 2;
        h = h < 30 ? (h = 30) : h - 30;
        modal.css({
          "margin-top": `${h}px`,
        });
      },
      accept() {
        this.$emit("accept");
        if (this.$props.onAccept) this.$props.onAccept();
        if (this.$props.noCloseOnAccept) return;
        this.$destroy();
        this.$el.parentNode.removeChild(this.$el);
      },
      close() {
        this.$emit("decline");
        if (this.$props.onDecline) this.$props.onDecline();
        if (this.$props.noCloseOnDecline) return;
        this.$destroy();
        this.$el.parentNode.removeChild(this.$el);
      },
      onOverlayClick() {
        if (this.$props.declineOnOverlayClick) {
          this.$emit("decline");
        }
        if (!this.$props.closeByOverlayClick) return;
        if (this.$props.loaded) this.close();
      },
    },
    mounted() {
      window.addEventListener("resize", () => this.centrify());
      setTimeout(() => this.centrify(), 0);
    },
    components: {
      GnzsButton,
    },
  };
</script>

<style lang="scss" module>
  @import "./style.scss";
</style>
