<template>
    <div :class="$style.root">
        <svg v-if="dateIcon" @click = "focus" :class="['svg-card-calendar-dims', $style.dateIcon]">
            <use xlink:href="#card-calendar"></use>
        </svg>
        <input 
            :class="[$style.input, clipboardEnabled ? $style.rightShift : '', dateIcon ? $style.leftShift : '', disabled ? $style.disabled : '']" 
            :type="inputType" 
            :value="value"
            :disabled="disabled == 1"
            :placeholder="placeholder"
            :data-name="name"
            :min="min"
            v-on="inputListeners"
            tabindex=""
            ref="input"
        />
        <button v-if="clipboardEnabled"
            :class="$style.toggleButton"
            @click="copyToClipboard"
        >
            <svg>
                <use xlink:href="#gnzs-1c-icon__clipboard"></use>
            </svg>
            <span v-if="showCopied" :class="$style.copied">Скопировано</span>
        </button>
        <button v-if="type=='password'"
            :class="$style.toggleButton"
            @mousedown="showPass"
        >
            <svg :class="[showPassword ? $style.passShow : '']">
                <use :xlink:href="showPassword ? '#gnzs-ms-icon__eye' : '#gnzs-ms-icon__eye_slash'"></use>
            </svg>
        </button>
    </div>
</template>

<script>

export default {
    data(){
        return {
            showCopied: false,
            showPassword: false
        }
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        value: {
            default: '',
        },
        placeholder: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: 'text',
        },
        min: {
            type: Number,
            default: undefined
        },
        clipboardEnabled: {
            type: Boolean,
            default: false
        },
        dateIcon: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        inputListeners: function () {
            var vm = this
            return Object.assign({},
            this.$listeners,
            {
                // This ensures that the component works with v-model
                input: function (event) {
                    vm.$emit('input', event.target.value)
                }
            })
        },
        inputType(){
            if (this.$props.type != 'password'){
                return this.$props.type
            } else {
                return this.showPassword ? 'text' : 'password'
            }
        }
    },
    methods: {
        focus(){
            this.$refs.input.focus()
        },
        clear(){
            this.$refs.input.value = ''
            this.$emit('input', '')
        },
        hidePass(){
            this.showPassword = false
        },
        showPass(){
            this.showPassword = true
        },
        copyToClipboard(){
            this.showCopied = true;
            const el = document.createElement('textarea');
            el.value = this.$refs.input.value;
            el.setAttribute('readonly', '');
            el.style.position = 'absolute';
            el.style.left = '-9999px';
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
            setTimeout(() => this.showCopied = false, 1000)
        }
    },
    mounted(){
        window.addEventListener('mouseup', () => this.hidePass())
    }
}
</script>


<style lang="scss" module>
    @import './style.scss';
</style>