<template>
    <Modal ref="modal">
        <template v-slot>
            <div :class ="$style.wrapper">
                <span class="modal-body__close" ref="close">
                <span class="icon icon-modal-close"></span>
                </span>

                <div :class="$style.header">
                    Применить тип цены
                </div>

                <GnzsDropdown
                    v-model="selectedPricetype"
                    :use-empty-item="false"
                    :items="pricetypes"
                    :emptyItemTitle="'Выбрать'"
                />

                <div :class="$style.actions">
                    <GnzsButton 
                        type="primary" 
                        @click="apply" 
                        :disabled="!selectedPricetype"
                    >Применить</GnzsButton>
                    <GnzsButton type="cancel" @click="closeModal">Отмена</GnzsButton>
                </div>
            </div>
        </template>
    </Modal>
</template>

<script>

import Modal from '@/gnzs-controls/Modal';

import GnzsButton from '@/gnzs-controls/Button';
import GnzsDropdown from '@/gnzs-controls/Dropdown';

import {mapActions, mapGetters} from 'vuex';

const mapFieldModel = function(key){
    return {
        get(){
            return this.$store.getters[`order/getRootValue`](key);
        },
        set(value){
            this.$store.dispatch('order/setRootValue', {key, value});
        }
    }
}

export default {
    computed: {
        ...mapGetters({
            pricetypes: 'pricetypes/dropdownItems',
        }),
        selectedPricetype: { ...mapFieldModel.call(this, 'selectedPricetype') },
    },
    methods: {
        ...mapActions({
            applyPricetype: 'order/applyPricetype',
            setCheckedAll: 'order/setCheckedAll'
        }),
        apply(){
            this.applyPricetype()
            this.setCheckedAll(false)
            this.closeModal();
        },
        closeModal(){
            this.$refs.modal.close()
        },
    },
    components: {
        Modal,
        GnzsButton,
        GnzsDropdown
    }
}
</script>

<style lang="scss" module>
    @import './AddPricetypeModal.scss';
</style>