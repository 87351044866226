<template>
 <GnzsSection 
     :disabled="!tariffFeatures.includes('agreements-1с-to-amo')" 
     :disabledText="disabledSectionText"
     header="Соглашения"
     >
    <template v-slot:header-control>
        <GnzsSwitcher
            v-model="defaults.isAgreementsEnabled"
        ></GnzsSwitcher>
    </template>
    <template v-slot:description>
        Функционал создания соглашений, которые можно выбирать из выпадающего списка в модальном окне заказа.
    </template>
    <div v-if="defaults.isAgreementsEnabled">
        <div :class="w.table">
            <div :class="w.tableRow">
                <div>Соглашение по умолчанию</div>
                <GnzsDropdown
                    :class="w.w200"
                    :value="defaults.defaultAgreement"
                    :use-empty-item="true"
                    :items="agreements(defaults.organizationId)"
                    @input="onAgreementChangeHandler"
                />
            </div>
            <div :class="w.tableRow">
                <div :class="w.switcherTitle">Не отображать типовые соглашения</div>
                <div>
                    <GnzsSwitcher
                        v-model="defaults.disableCommonAgreements" 
                    />
                </div>
            </div>
            <div :class="w.tableRow">
                <div :class="w.switcherTitle">Отображать цены в зависимости от соглашения</div>
                <div>
                    <GnzsSwitcher
                        v-model="defaults.showAgreementsPrices" 
                    />
                </div>
            </div>
            <div :class="w.tableRow">
                <div :class="w.switcherTitle">Поле "соглашение" обязательное</div>
                <div>
                    <GnzsSwitcher
                        v-model="defaults.isAgreementRequired" 
                    />
                </div>
            </div>
        </div>
    </div>
 </GnzsSection>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

import GnzsSection from "@/gnzs-controls/Section";
import GnzsSwitcher from "@/gnzs-controls/Switcher";
import GnzsDropdown from "@/gnzs-controls/Dropdown";

export default {
    computed: {
        ...mapGetters({
            defaults: "settings/defaults",
            agreements: "agreements/allByOrgId",
            getOrganizationId: "agreements/getOrganizationId",
            getOrganizationAccountId: "organizations/getOrganizationById1c",
            tariffFeatures: 'features/getFeatures',
            disabledSectionText: 'localSettings/disabledSectionText'
        }),
    },
    components: {
        GnzsSection,
        GnzsSwitcher,
        GnzsDropdown
    },
    methods: {
        ...mapActions({
            setDefaultsField: "settings/setDefaultsField",
        }),
        onAgreementChangeHandler(id){
            this.setDefaultsField({field: 'defaultAgreement', value: id})
            if (!this.getOrganizationAccountId){
                let groupValue = this.getOrganizationId(this.defaults.defaultAgreement)
                let value = this.getOrganizationAccountId(groupValue).accounts[0].id1C
                this.setDefaultsField({field: 'organizationAccountId', value});
                this.setDefaultsField({field: 'organizationId', value: groupValue})
            }
        }
    }
};
</script>

<style lang="scss" module="w">
    @import '@/assets/scss/Settings/style.scss';
</style>