import {save1cOrder} from '@/api/1c-api';
import {saveOrder} from '@/api/docflow-api';
import Vue from 'vue';

export const orderUpdateState = {
    orderUpdate: {
        loading: true,
        header: 'Обновление данных в 1С...',
        errorText: ''
    }
}

export const orderUpdateGetters = {
    orderUpdateLoading: state => state.orderUpdate.loading,
    orderUpdateHeader: state => state.orderUpdate.header,
    orderUpdateErrorText: state => state.orderUpdate.errorText
}

export const orderUpdateActions = {
    async updateOrder1c({commit, getters}){
        try{
            commit('setOrderUpdateValue', {key: 'loading', value: true})
            
            await save1cOrder(getters.orderData)
            await saveOrder(getters.entity.entityId, getters.orderData);

            commit('setOrderUpdateValue', {key: 'header', value: 'Обновление данных в 1С'})
        } catch (e){
            commit('setOrderUpdateValue', {key: 'header', value: 'Ошибка обновления данных'})
            commit('setOrderUpdateValue', {key: 'errorText', value: e.message})
            console.debug('Ошибка обновления данных', {e})
        } finally {
            commit('setOrderUpdateValue', {key: 'loading', value: false})
        }
    },
}

export const orderUpdateMutations = {
    setOrderUpdateValue: (state, {key, value}) =>{
        Vue.set(state.orderUpdate, key, value)
    }
}