import store from "./store";
import $ from "jquery";

export default (iframeName) => {
  window.addEventListener("message", async (event) => {
    let eventName = event.data.event;

    switch (eventName) {
      case `${iframeName}:getWindowSize`: {
        let { width, height } = event.data.data;
        store.dispatch("iframe/setWindowSize", { width, height });
        break;
      }

      case `${iframeName}:setOrderData`: {
        let orderData = event.data.data.orderData;
        store.commit("order/assignOrderData", orderData);
        break;
      }

      case `${iframeName}:setOrderDataPartial`: {
        let orderData = event.data.data.orderData;
        store.commit("order/assignOrderDataPartial", orderData);
        break;
      }

      case `${iframeName}:removeStorage`: {
        let modalId = event.data.data.modalId;
        window.parent.postMessage({ event: `${iframeName}:closeConfirm`, data: { modalId } }, "*");
        store.dispatch("settings/removeStorage");
        break;
      }

      case `${iframeName}:removeDocument`:
        {
          let modalId = event.data.data.modalId;
          window.parent.postMessage({ event: `${iframeName}:closeConfirm`, data: { modalId } }, "*");
          await store.dispatch("documents/remove");
        }
        break;

      case `${iframeName}:saveSettings`:
        {
          let modalId = event.data.data.modalId;
          window.parent.postMessage({ event: `${iframeName}:closeConfirm`, data: { modalId } }, "*");
          await store.dispatch("settings/saveChanges");
        }
        break;

      case `dna-iframe:setIframeHeight`: {
        $('iframe[name="dna-iframe"]').css({ height: `${event.data.height + 50}px` });
        break;
      }

      default:
        break;
    }
  });
};
