<template>
    <GnzsSection header="Печатные формы">
        <template v-slot:description>
            Укажите поля сделки, в которые будут записываться ссылки генерируемых печатных форм (формы выгружаются из 1С)
        </template>

        <div :class="w.table" v-if="templates.length">

            <div :class="w.tableHeader">
                <div>Название</div>
                <div>Поле сделки</div>
            </div>
                
            <div :class="w.tableRow" v-for="(temp, index) in templates" :key="index">
                <div>{{ temp.name }}</div>
                <div>
                    <GnzsDropdown 
                        :class="w.w200"
                        :value="getTemplateLeadCf(temp.docType1C)"
                        :items="amoLeadCfs"
                        @input="setTemplateLeadCf(temp.docType1C, $event)"
                    ></GnzsDropdown>
                </div>
            </div>

        </div>
    </GnzsSection>
</template>

<script>

import GnzsSection from '@/gnzs-controls/Section';
import GnzsDropdown from '@/gnzs-controls/Dropdown';

export default {
    computed: {
        templates(){
            return this.$store.getters['templates/items'];
        },
        templatesLeadCfs(){
            return this.$store.getters['settings/templatesLeadCfs'];
        },
        amoLeadCfs(){
            const types = ['text', 'url'];
            return types.reduce((cfs, t) => 
                [...cfs, ...this.$store.getters['amo/customFieldsByTypes']['lead'][t]], 
                []
            );
        }
    },
    methods: {
        getTemplateLeadCf(templateKey){
            return this.templatesLeadCfs[templateKey]
        },
        setTemplateLeadCf(templateKey, cfId){
            this.$store.dispatch('settings/setTemplateEntityCf', {entity: 'lead', templateKey, cfId})
        }
    },
    components: {
        GnzsSection,
        GnzsDropdown
    },
}
</script>


<style lang="scss" module="w">
    @import '@/assets/scss/Settings/style.scss';
</style>