<template>
    <div :class="[$style.alert, type=='error' ? $style.type_error : '', 
                                type=='success' ? $style.type_success : '',
                                type=='warning' ? $style.type_warning : '',
                                ]">
        <div v-if="title && title.length" :class="$style.title">{{title}}</div>
        <div :class="$style.text">
            <slot name="text"></slot>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        type: {
            type: String,
            default: '',
        },
        title: {
            type: String,
            default: ''
        }
    }
}
</script>

<style lang="scss" module>
    @import "./style.scss";
</style>