/**
 * @author Ilya Orishin. 2021
 * 
 * Функционал партнеров
 */

import { getPartners, createPartner } from '@/api/1c-api';
import { contactsQuery, companiesQuery } from '@/api/amocrm-api'
import isJson from '@/utilities/isJson'

import Vue from 'vue'

/**
 * Конвертирует элемент state в формат пригодный для drodown
 * 
 * @param {state} state - vuex state
 * @param {string} key - ключ state
 * @returns {Object} - {title, value}
 */
const values = (state, key) => state[key].map(item => ({ title: item.name, value: item.id}));

/**
 * Проверяет совпадает ли значения кастомного поля сущности с id патнера
 * 
 * @param {Object} field - значения кастомного поля {values: [{value}]}
 * @param {string} partnerId - id партнера
 */
const isPartner = (field, partnerId) => {
    if (field && field?.values?.[0].value){
        let val = field.values[0].value
        if (isJson(val)) val = JSON.parse(val).value
        return val == partnerId
    }
    return false
}

// Вспомогательный массив
const criteria = [ 
    {value: 'company', title: 'Компании', field: 'partner_companies'},
    {value: 'contact', title: 'Контакты', field: 'partner_contacts'}
]

const state = () => ({
    partners: [], // Партнеры
    searchedData: [], // Результаты поиска
    partner_companies: [], // Компании партнера
    partner_contacts: [], // Контакты партнера
    isCreating: false,
    offset: 0,
    limit: 50,
    isFull: false,
    isLoading: false
});

const getters = {
    partners: state => state.partners,
    isPartnerCreating: state => state.isCreating,
    isLoading: state => state.isLoading,
    isFull: state => state.isFull,
    dropdownItems: state => values(state, 'partners'),
    searchedItems: state => values(state, 'searchedData'),
    contragents: state => criteria.map(c => ({ value: c.value, title: c.title, items: values(state, c.field)}))
};

const actions = {

    async load({state, commit, rootGetters}){
        // Загрузка партнеров из справочника партнеров
        if (state.isFull) return
        try{
            commit('setLoading', true)
            const resp = await getPartners({
                limit: state.limit,
                offset: state.offset,
            });
            commit('setItems', resp.data.result);
            commit('setLoading', false)

        } catch(e){
            commit('setLoading', false)
            console.debug(`1С ${rootGetters['partnerName']}: ошибка: `, e);
        }
    },

    /**
     * Поиск по справочнику партнеров
     * 
     * @param {string} query - Поисковая строка
     */
    async search({commit, rootGetters}, query){
        try {
            commit('clearSeaeched')
            commit('setLoading', true)
            const resp = await getPartners({
                query
            });

            commit('setSearchedData', resp.data.result)
            commit('setLoading', false)
        } catch (e) {
            commit('setLoading', false)
            console.debug(`1С ${rootGetters['partnerName']}: ошибка загрузки данных по справочнику`, e)
        }		
    },

    /**
     * Создать партнера
     * 
     * @param {string} payload - данные партнера
     */
    async create({commit, rootGetters}, payload){
        commit('setCreating', true)
        try {
            await createPartner(payload)
        } catch (e){
            console.debug(`1С ${rootGetters['partnerName']}: ошибка: `, e);
        }
        commit('setCreating', false)
    },

    async loadContragentsByPartnerId({state, rootGetters}){
        /*
            Загрузка контрагентов партнера

            1. Получим id партнера, если он пустой - возврат
            2. Проверим формат, если пришёл JSON ({title, value}) разобъем строку на имя и id
            3. Получим id кастомных полей в которых хранится id партнера для компании и контакта
            4. Выполним запрос на компании и контакты в amoCRM с query = id партнера
            5. Проверим что значения кастомного поля действително соответствует id партнера для компаний и контактов
            и после этого положим в соответствующие массивы
        */
        let partnerId = rootGetters['order/partnerId']
        if (!partnerId) return;
        let partnerName = null;

        if (isJson(partnerId)){
            let json = JSON.parse(partnerId)
            partnerName = json.title
            partnerId = json.value
        }

        // Обнулим предыдущие результаты перед новым поиском
        state.partner_companies = []
        state.partner_contacts = []

        const company_cfId = rootGetters['settings/partners'].cf_company_partner,
                contact_cfId = rootGetters['settings/partners'].cf_contact_partner;

        try {
            let query = partnerName ? partnerName : partnerId;
            const companyResp = await companiesQuery(query)
            const contactResp = await contactsQuery(query) 

            companyResp?.data?.forEach(v => {
                let field = v.custom_fields_values?.find(cf => cf.field_id == company_cfId)
                if (isPartner(field, partnerId)) state.partner_companies.push(v)
            })
            contactResp?.data?.forEach(v => {
                let field = v.custom_fields_values?.find(cf => cf.field_id == contact_cfId)
                if (isPartner(field, partnerId)) state.partner_contacts.push(v)
            })

        } catch (e){
            console.debug(`1С ${rootGetters['partnerName']}: ошибка формирования контрагентов: `, e);
        }

    }

};

const mutations = {
    setCreating(state, payload){
        state.isCreating = payload
    },
    setItems(state, data){
        state.partners = state.partners.concat(data)
        state.offset+=state.limit
        if (data.length < state.limit){
            state.isFull = true
        }
    },
    clearSearched(state){
        Vue.set(state, 'searchedData', [])
    },
    setSearchedData(state, data){
        Vue.set(state, 'searchedData', data)
    },
    setLoading(state, value){
        state.isLoading = value
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};