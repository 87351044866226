<template>
  <div :class="$style.sections">
    <Section v-for="partner in getPartners" :key="partner.id" :partner="partner" />
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import Section from './section';

export default {
  computed: {
    ...mapGetters({
      getPartners: 'partnersSolutions/getPartners'
    }),
  },
  components: {
    Section
  }
}
</script>

<style lang="scss" module>
@import './style.scss';
</style>