<template>
    <AddModal @close="closeModal" />
</template>

<script>
import AddModal from '@/components/Modal/Modals/AddDirectoryElementModal/AddDirectoryElementModal.vue'
import {mapGetters} from 'vuex';

export default{
    computed:{
        ...mapGetters({
            iframeName: 'iframe/iframeName'
        })
    },
    methods: {
        closeModal(){
            window.parent.postMessage({ event: `${this.iframeName}:closeModal` }, "*");
        }
    },
    components: {
        AddModal
    }
}
</script>