/**
 * @author Ilya Orishin. 2022
 * 
 * Настройки Хранилища
 */

import Vue from 'vue';

import {
    getStorage,
    createStorage
} from '@/api/1c-api';

export const storageState = {
    use: false,
    storageId: "", // id подключенного хранилища
    storageType: "", // тип хранилища (dropbox, google, yandex)
    storageMail: "", // email пользователя
    storageAuthLink: "", // ссылка для авторизации
}

export const storageGetters = {
    storage: (state) => state.docFlowSettings.storage,
}

export const storageActions = {
    async createStorage({commit, dispatch, state}){
        /*
        Создать хранилище. После создания осуществляется переход по авторизационной ссылке
        */
        try {
            const response = await createStorage(state.docFlowSettings.storage.storageType)
            commit('createStorage', response.data)
            window.open(response.data.additional.authorize_link, '_blank').focus();
            await dispatch('saveChanges')
        } catch (e){
            console.debug('Ошибка создания хранилища', {e});
        }
    },
    
    async getStorage({commit, dispatch, state}){
        /*
        Получить хранилище по id. id берется из настроек
        */
        if (!state.docFlowSettings.storage.storageId) return;
        try {
            const response = await getStorage(state.docFlowSettings.storage.storageId)
            commit('setStorage', response.data)
            await dispatch('saveChanges')
        } catch (e){
            console.debug('Ошибка получения хранилища', {e});
        }
    },

    async removeStorage({commit, dispatch}){
        // Удалить хранилище
        commit('removeStorage')
        await dispatch('saveChanges')
    },

    storageAuth({state}){
        /*
        Перейти по ссылки авторизации в хранилище в новом окне
        */
        window.open(state.docFlowSettings.storage.storageAuthLink, '_blank').focus();
    },

    setStorageField: ({commit}, payload) => commit('setStorageField', payload),
}

export const storageMutations = {
    createStorage(state, data){
        /*
        Создание хранилища
        */
        state.docFlowSettings.storage.storageId = data.id
        state.docFlowSettings.storage.storageType = data.storage_type
        state.docFlowSettings.storage.storageAuthLink = data.additional.authorize_link
    },

    setStorage(state, data){
        /*
        Получение данных по хранилищу
        */
        state.docFlowSettings.storage.storageId = data.id
        state.docFlowSettings.storage.storageType = data.storage_type
        state.docFlowSettings.storage.storageMail = data.email
        state.docFlowSettings.storage.storageAuthLink = data.additional.authorize_link
    },

    removeStorage: state => Vue.set(state.docFlowSettings, 'storage', {...storageState, use: true}), // Удаление

    setStorageField: (state, {field, value}) => state.docFlowSettings.storage[field] = value, // Установить значение
}