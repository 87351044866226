import Vue from "vue";
import {uuidv4} from "@/utilities/order";
import moment from "moment";
import {getInvoices, saveInvoice} from "@/api/1c-api";


/**
 * Инициализация новой реализации
 *
 */
const initInvoice = (order, products) => ({
    id: "",
    uuid: uuidv4(),
    order_id: order.order_id,
    manager_name: order.manager_name,
    manager_email: order.manager_email,
    manager_phone: order.manager_phone,
    current_user_id: '',
    current_user_name: '',
    current_user_phone: '',
    current_user_emall: '',
    nds_type: order.nds_type,
    total_nds: order.total_nds,
    number: 'Новый счет',
    date: moment().format('DD.MM.YYYY'),
    products
})

const state = () => ({
    list: [],
    titles: [],
    items: [],
    active: '',
    failLoading: false,
    isLoading: false,
    isLoadingTitles: false,
    isSaving: false,
})

const getters = {
    list: state => state.items.map(r => ({
        title: `Счет ${r.number} от ${r.date}`,
        value: r.uuid
    })),
    titles: state => state.titles.map(h => ({
        title: h.name,
        value: h.id
    })),
    shipped: state => {
        /*
        Расчёт кол-ва отгруженных товаров по каждой позиции
        Фильтруем только те счета, у которых есть id, чтобы исключить не проведенные
        */

        const result = {}
        state.items.filter(r => r.id).forEach(item => {
            item.products.forEach(p => {
                if (result.hasOwnProperty(p.id)){ // eslint-disable-line no-prototype-builtins
                    result[p.id] +=parseInt(p.count)
                } else {
                    result[p.id] = parseInt(p.count)
                }
            })
        })
        return result;
    },
    item: state => state.items.find(r => r.uuid == state.active),
    itemProducts: (state) => state.items.find(r => r.uuid == state.active)?.products ?? [],
    totalPrice: (state, getters, rootState, rootGetters) => {
        /*
        Расчёт общей стоимости по реализации

        1. Если выбранной реализации нет - возвращаем 0
        2. Получаем текущую реализацию тип ндс, ставку ндс
        3. Считаем сумму по всем товарам (Кол-во * Цена)
        4. Накидываем сверху НДС
        */
        if (!state.active) return 0
        const activeItem = state.items.find(r => r.uuid == state.active)
        const ndsType = rootGetters['order/orderData'].nds_type
        const nds = rootGetters['settings/defaults'].vat

        let sum = activeItem.products.reduce((acc, product) => acc+=+product.count*+product.price, 0)
        let nds_sum = 0
        activeItem.products.forEach(product => {
            if (ndsType == 2){
                if (!product?.nds) product.nds = nds
                if (`${product.nds}`.includes('%')){
                    product.nds = product.nds.replace('%','')/100
                }
                nds_sum+=(+product.count*+product.price)*(+product.nds/100)
            }
        })

        return sum + nds_sum
    },
    failLoading: (state) => state.failLoading,
    isLoading: state => state.isLoading,
    isSaving: state => state.isSaving,
    active: state => state.active,
    hasId: state => !!state.items.find(r => r.uuid == state.active)?.id,
    getValue: state => key => state[key]
};

const actions = {
    async loadTitles({commit, rootGetters}){
        /**
         * Получение списка счета с сервера, только заголовки {id, name}
         *
         */
        try{
            commit('setValue', {key: 'isLoadingTitles', value: true})
            const order_id = rootGetters['order/orderData'].order_id
            const list = true;
            if (!order_id) return;
            const response = await getInvoices({ order_id, list });
            commit('setTitles', response.data.result);
        } catch(e){
            console.debug(`1С ${rootGetters['partnerName']}: Ошибка получения счета`, {e});
        } finally {
            commit('setValue', {key: 'isLoadingTitles', value: true})
        }
    },

    async loadList({commit, rootGetters}){
        /**
         * Получение списка счета с сервера с товарами
         *
         */
        try{
            const order_id = rootGetters['order/orderData'].order_id
            if (!order_id) return;
            const response = await getInvoices({ order_id });
            commit('setList', response.data.result);
        } catch(e){
            console.debug(`1С ${rootGetters['partnerName']}: Ошибка получения счета`, {e});
            commit('setFailLoading')
        }
    },

    newItem({commit, rootGetters}){
        /**
         * Создание нового счета на стороне АМО
         *
         */
        const order = rootGetters['order/orderData'];
        const checked = rootGetters['order/checkedProducts'];

        commit('newItem', {order, checked})
    },

    async spendItem({commit, getters, rootGetters}){
        /**
         * обработчик нажатия кнопки "Провести счет"
         *
         */
        try{
            // Проведение
            const uuid = getters.item.uuid
            commit('setValue', {key: 'isSaving', value: true})
            const {entityId, entityType} = rootGetters['order/entity']
            const params = {
                entity_id: entityId,
                entity_type: entityType
            }
            let item = getters.item
            item.total_price = getters.totalPrice
            
            const currentUser = rootGetters['amoUser']

            item.current_user_id = `${currentUser.id}`
            item.current_user_name = `${currentUser.name}`
            item.current_user_phone = `${currentUser.phone}`
            item.current_user_emall = `${currentUser.email}`

            const response = await saveInvoice(getters.item, params);
            commit('updateItem', {item: response.data, uuid});
        } catch(e){
            console.debug(`1С ${rootGetters['partnerName']}: Ошибка проведения счета`, {e});
            commit('setFailLoading')
        } finally {
            commit('setValue', {key: 'isSaving', value: false})
        }
    },

    changeProductItem: ({commit}, payload) => commit('changeProductItem', payload),
    removeProductFromItem: ({commit}, uuid) => commit('removeProductFromItem', uuid),

    setActive: ({commit}, id) => commit('setActive', id),
    setValue: ({commit}, payload) => commit('setValue', payload),

    dropState: ({commit}) => commit('dropState')
};

const mutations = {
    setActive: (state, id) => state.active = id,
    setTitles: (state, titles) => {
        /*
        Сохранение заголовков списка реализаций в store
        */
        state.titles = titles
    },
    setList: (state, list) => {
        /*
        Сохранение списка реализаций в store, перед сохранением присвоим каждому элементу уникальный идентификатор uuid
        */
        list.forEach(r => r.uuid = uuidv4())
        state.items = list
    },
    updateItem: (state, {item, uuid}) => {
        /*
        Обновление реализации в списке реализаций
        */
        const index = state.items.findIndex(r => r.uuid == uuid)
        if (!(index < 0)){
            Vue.set(state.items, index, item)
        }
    },
    changeProductItem: (state, {uuid, count}) => {
        /*
        Изменение кол-ва отгружаемого товара в активной счета
        */
        const activeItem = state.items.find(r => r.uuid == state.active)
        const product = activeItem.products.find(p => p.uuid == uuid)
        if (product) Vue.set(product, 'count', count)

    },
    removeProductFromItem: (state, uuid) => {
        /*
        Удаление товара в активный счет. Если удален последний, удаляется и сама счет
        */
        const activeItem = state.items.find(r => r.uuid == state.active)
        Vue.set(activeItem, 'products', activeItem.products.filter(p => p.uuid != uuid))
        if (!activeItem.products.length){
            state.list = state.list.filter(r => r.uuid != state.active)
            state.items = state.items.filter(i => i.uuid != state.active)
            state.active = ''
        }
    },
    newItem(state, {order, checked}){
        /*
        Создание и добавление в список нового счета
        */
        const products = checked.map(p => ({
            id: p.id,
            uuid: p.uuid,
            count: p.count,
            article: p.article,
            name: p.name,
            price: p.price,
            stock_id: p.stock_id,
            nds: p.nds,
            total_nds: p.total_nds,
            measure: p.measure,
            stocks: p?.stocks ? p.stocks : [],
            prices: p?.prices ? p.prices : [],
            units: p?.units ? p.units : []
        }))
        const item = initInvoice(order, products)
        Vue.set(state.list, state.list.length, {id: '', uuid: item.uuid, number: item.number})
        Vue.set(state.items, state.items.length, item)
        state.active = item.uuid
    },
    setValue: (state, {key, value}) => state[key] = value,
    setFailLoading: (state) => state.failLoading = true,

    dropState: (state) => {
        Vue.set(state, 'list', [])
        Vue.set(state, 'items', [])
        Vue.set(state, 'active', '')
        Vue.set(state, 'isLoading', false)
        Vue.set(state, 'failLoading', false)
        Vue.set(state, 'isLoading', false)
        Vue.set(state, 'isLoadingTitles', false)
        Vue.set(state, 'isSaving', false)
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}