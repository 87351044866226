import { getDocflowSettings, saveDocflowSettings, getDropboxAuthLink } from "@/api/docflow-api";
import { deepCloneByJson } from "@/utilities/deepClone";
import prepareUpdatedSettingsData from "@/utilities/prepareUpdatedSettingsData";
import md5 from "@/utilities/getMd5";

import { setSettings } from "@/api/1c-api";

const _ = require("underscore");
import Vue from "vue";
import { storageState, storageGetters, storageActions, storageMutations } from "@/store/substores/storage";

import {
  serviceTestState,
  serviceTestActions,
  serviceTestGetters,
  serviceTestMutations,
} from "@/store/substores/serviceTest";

import { uuidv4 } from "@/utilities/order";
import initTableFields from "@/utilities/tableFields";

import moment from "moment";
import { isIncludeDirectories } from "@/utilities/store/helper";

const initPipelineEvents = () => ({
  disableReversePipelineMove: false,
  enableMoveFromClosedStatus: false,
  orderAttachment1cToAmo: {
    type: 1, //  0 - всегда создавать новую сделку; 1 - прикреплять к любой открыткой сделке контрагента; 2 - прикреплять к сделке в определенных этапах
    searchStatuses: [], // [1, 2, 3, ...] (142, 142 - игнорируем)
    tags: "", // tag1, tag2
  },
  events1cToAmo: [
    // пример структуры
    // {
    //     type: '', //  lead_attached, realization_created
    //     statusesFrom: [324, 324, 234],
    //     statusTo: 1,
    // },
  ],
  eventsAmoTo1c: [
    // пример структуры
    // {
    //     type: '', // oder_1c, printed_forms
    //     action: '', // create, delete, ...
    //     content: '', // {название печ. формы}, ...
    //     statusesFrom: [324, 324, 234],
    //     statusTo: 1,
    // },
  ],
});

const initExportProductsText = () => ({
  type: 0, // 0 - не выгружать, 1 - выгружать в примечание, 2 - выгружать в поле сделки, 3 - выгрузать в примечание и в поле
  cfId: 0, // id кастомного поля сделки/покупателя
});

const initSettings = () => ({
  auth: {
    auth1cLogin: "",
    auth1cPwd: "",
    auth1cType: "BASIC",
    dropboxToken: "",
    key1c: "",
    keyAmoCrm: md5(String(new Date().getTime())),
    url1c: "",
  },
  storage: storageState,
  defaults: {
    showImages: false,
    managerType: "0",
    organizationAccountId: "",
    organizationId: "",
    priceTypeId: "",
    stockId: "",
    partnerId: "",
    stockWorktype: "0", // заказ с разных складов
    vat: "20",
    vatType: "1", // сверху
    currency: "руб",
    productCount: 1, // кол-во единиц товаров в позиции
    actionCloseAferSaveOrder: 0, // 0 - не закрывать модалку, 1 - закрыть модалку заказа
    actionCloseAferSaveRealization: 0, // 0 - не закрывать модалку, 1 - закрыть модалку заказа
    disableWorkOrderStatuses: [], // массив статусов в формате pipelineId_statusId, в которых запрещено создавать/редактировать заказ на фронтенде
    disableOrganizationChange: false,
    enableContragentCreating: [],
    exportProductsText: initExportProductsText(), // выгружать текст товаров после сохранения заказа в 1С
    appendCfToLeadPrice: [], // массив ID числовых полей Сделки, которые суммируются с суммой заказа и записываются в бюджет Сделки
    stockCountBySearch: 0, // остаток при подборе: 0 - суммирование всех остатков по всем складам, 1 - по выбранному складу и юр. лицу
    stockFormat: 0, // 0 - свободный остаток, 1 - свободный + фактический остаток
    stockFormatItems: [0],
    discountViewType: 0,
    isRealizationButtonEnabled: false,
    isEvotorButtonEnabled: false,
    isProvisionEnabled: false,
    isAgreementsEnabled: false,
    isContractsEnabled: false,
    isContractCreationEnabled: true,
    isPartnersEnabled: false,
    isAutoDiscountEnabled: false,
    isPaysCalendarEnabled: false,
    isPartialRealizationEnabled: false,
    isInvoicesEnabled: false,
    isContractRequired: false,
    isAgreementRequired: true,
    isDescriptionEnabled: false,
    isStockRequired: true,
    isOrganizationRequired: true,
    defaultAgreement: "",
    disableCommonAgreements: false,
    disableCommonContracts: false,
    showEmptyStocks: 1,
    showEmptyRemains: false,
    showErrors: [],
    showStocks: [],
    checkEmptyContactsData: true,
    updateEntityNames: false,
    updateResponsibleUser: [],
    disablePriceChange: 0,
    disableDiscountChange: 0,
    showAgreementsPrices: false,
    ownerAgreementFirst: false,
    showStockColumn: 0,
    showCodeColumn: false,
    disablePriceChangeOnAdd: false,
    updateDataEnabled: false,
    isProductsEnabled: false,
    isSignatoriesEnabled: false,
    isRequestContractInfo: false,
    linkedContactsType: [0],
    useCancellationPositions: false,
    useCancellationReasons: false,
    isCancellationEditDisabled: false,
    updateDescription: false,
    closeOrderByOverlayClick: false,
    setIndividualContract: false,
  },
  extraCfs: {
    payCalendarCfId: "",
    descriptionCfId: "",
  },
  organizationsDefaults: [
    /*
    {
        orgId: -> organization
        stockId: -> stock
        agreementId: -> agreement
    }
    */
  ],
  managerDefaults: [
    /*
    {
        managerId: -> user
        organizationId: -> organization
        organizationAccountId: -> organization
        stockId: -> stock
    }
    */
  ],
  cardsFields: [
    /*
    {
        amoType: -> company, contact, lead, customer
        amoField: field id (text)
        dirCode: code
        dirType: type
        required: true/false
    }
    */
  ],
  contractFields: {
    // поле type
    // типы-примитивы  text, date, bool, int
    // типы-выпадашки  directory(справочник), enum(перечисление)
    name_required: true,
    number_required: false,
    date_required: false,
    date_to_required: false,
    contract_custom_fields: [],
  },
  orderFields: {
    order_custom_fields: [],
    // поле type
    // типы-примитивы  text, date, bool, int
    // типы-выпадашки  directory(справочник), enum(перечисление)
    company: {
      bank_bik: { id: "", type: "", sync: 0, required: false, unupdatable: false },
      bank_ks: { id: "", type: "", sync: 0, required: false, unupdatable: false },
      bank_name: { id: "", type: "", sync: 0, required: false, unupdatable: false },
      company_account: { id: "", type: "", sync: 0, required: false, unupdatable: false },
      company_email: { id: "", type: "", sync: 0, required: false, unupdatable: false },
      company_inn: { id: "", type: "", sync: 0, required: false, unupdatable: false },
      company_kpp: { id: "", type: "", sync: 0, required: false, unupdatable: false },
      company_name: { id: "", type: "", sync: 0, required: false, unupdatable: false },
      company_ogrn: { id: "", type: "", sync: 0, required: false, unupdatable: false },
      company_phone: { id: "", type: "", sync: 0, required: false, unupdatable: false },
      company_physical_address: { id: "", type: "", sync: 0, required: false, unupdatable: false },
      company_postal_address: { id: "", type: "", sync: 0, required: false, unupdatable: false },
      company_registered_address: { id: "", type: "", sync: 0, required: false, unupdatable: false },
      company_tax_number: { id: "", type: "", sync: 0, required: false, unupdatable: false },
      custom_fields: [],
    },
    contact: {
      contact_address: { id: "", type: "", sync: 0, required: false, unupdatable: false },
      contact_email: { id: "", type: "", sync: 0, required: false, unupdatable: false },
      contact_name: { id: "", type: "", sync: 0, required: false, unupdatable: false },
      contact_phone: { id: "", type: "", sync: 0, required: false, unupdatable: false },
      custom_fields: [], // [{amo_cf_id: xxx, name: "Custom name", required: bool}, ...]
    },
    lead: {
      // order
      order_sum: { id: "", type: "", sync: 0, required: false, unupdatable: false },
      order_date: { id: "", type: "", sync: 0, required: false, unupdatable: false },
      order_number: { id: "", type: "", sync: 0, required: false, unupdatable: false },
      order_number_short: { id: "", type: "", sync: 0, required: false, unupdatable: false },
      order_description: { id: "", type: "", sync: 0, required: false, unupdatable: false },
      // payments
      payment_flag: { id: "", type: "", required: false, unupdatable: false },
      partial_payment_flag: { id: "", type: "", required: false, unupdatable: false },
      payment_full_date: { id: "", type: "", required: false, unupdatable: false },
      payment_detail: { id: "", type: "", required: false, unupdatable: false },
      payment_sum: { id: "", type: "", required: false, unupdatable: false },
      // full realization
      realization_created: { id: "", type: "", sync: 0, required: false, unupdatable: false },
      realization_number: { id: "", type: "", sync: 0, required: false, unupdatable: false },
      realization_date: { id: "", type: "", sync: 0, required: false, unupdatable: false },
      //partial realizations
      realization_sum: { id: "", type: "", required: false, unupdatable: false },
      partial_realization_flag: { id: "", type: "", required: false, unupdatable: false },
      realization_detail: { id: "", type: "", required: false, unupdatable: false },
      // others
      templates: {}, // { Invoice: "xxx", "InvoiceOfPay": "yyy", ... }
      custom_fields: [], // [{amo_cf_id: xxx, name: "Custom name", required: bool}, ...]
    },
    productCatalog: {
      catalog_id: "",
      description: "",
      external_id: "",
      group: "",
      is_set: "",
      price: "",
      sku: "",
    },
  },
  pipelineEvents: initPipelineEvents(),
  partners: {
    cf_company_partner: "",
    cf_contact_partner: "",
    directory_code: "",
    directory_type: "",
  },
  paysInfo: [
    { dateCf: "", sumCf: "" },
    { dateCf: "", sumCf: "" },
    { dateCf: "", sumCf: "" },
    { dateCf: "", sumCf: "" },
  ],
  realizationsInfo: [
    { dateCf: "", numberCf: "" },
    { dateCf: "", numberCf: "" },
    { dateCf: "", numberCf: "" },
    { dateCf: "", numberCf: "" },
  ],
  positionRelations: [],
  iframe: {
    use: false,
    login: "",
    password: "",
    v2: {
      use: false,
      users: [],
    },
  },
  tableFields: initTableFields(),
  source: [],
  extraActions: [],
  massActions: {
    leads: [],
    contacts: [],
    companies: [],
  },
});

const state = () => ({
  isInit: false, // загружены ли настройки
  isSaving: false,
  failLoading: false, // ошибка получения данных
  dropboxAuthLink: "", // ссылка для подвязки приложения дропбокса

  docFlowSettings: {},
  initialDocFlowSettings: {}, // объект, дублирующий начальное состояние docFlowSettings для отслеживания изменений

  ...serviceTestState,
});

const getters = {
  auth: (state) => state.docFlowSettings.auth,
  iframe: (state) => state.docFlowSettings.iframe,
  dropboxToken: (state) => state.docFlowSettings.auth.dropboxToken,
  settings: (state) => state.docFlowSettings,

  extraActions: (state) => state.docFlowSettings.extraActions,

  massActions: (state) => state.docFlowSettings.massActions.leads,

  source: (state) => state.docFlowSettings.source,

  extraCfs: (state) => state.docFlowSettings.extraCfs,

  isDirectoriesExists: (state) => isIncludeDirectories(state.docFlowSettings),

  paysInfo: (state) => state.docFlowSettings.paysInfo,
  realizationsInfo: (state) => state.docFlowSettings.realizationsInfo,
  realizationsInfoValue: (state) => (index, field) =>
    state.docFlowSettings.realizationsInfo[index]?.[field]
      ? state.docFlowSettings.realizationsInfo[index][field]
      : null,
  paysInfoValue: (state) => (index, field) => state.docFlowSettings.paysInfo[index][field],
  positionRelations: (state) => state.docFlowSettings.positionRelations,
  positionRelation: (state) => (index) => state.docFlowSettings.positionRelations[index],

  isRealizationButtonEnabled: (state) => state.docFlowSettings.defaults.isRealizationButtonEnabled,
  isEvotorButtonEnabled: (state) => state.docFlowSettings.defaults.isEvotorButtonEnabled,
  isAutoDiscountEnabled: (state) => state.docFlowSettings.defaults.isAutoDiscountEnabled,
  isPaysCalendarEnabled: (state) => state.docFlowSettings.defaults.isPaysCalendarEnabled,

  dropboxAuthLink: (state) => state.dropboxAuthLink,
  failLoading: (state) => state.failLoading,
  getDocflowSettings: (state) => state.docFlowSettings,
  organizationAccountId: (state) => state.docFlowSettings.defaults.organizationAccountId,

  isSaving: (state) => state.isSaving,
  isChanged: (state) => JSON.stringify(state.docFlowSettings) !== JSON.stringify(state.initialDocFlowSettings),
  isInitialized: (state) => state.isInit,

  orderFields: (state) => state.docFlowSettings.orderFields,
  cardsFields: (state) => state.docFlowSettings.cardsFields,
  templatesLeadCfs: (state) => state.docFlowSettings.orderFields.lead.templates,

  stockWorktype: (state) => +state.docFlowSettings.defaults.stockWorktype,
  stockId: (state) => state.docFlowSettings.defaults.stockId,
  priceTypeId: (state) => state.docFlowSettings.defaults.priceTypeId,
  isOneStockOrder: (state) => +state.docFlowSettings.defaults.stockWorktype == 0,
  vatType: (state) => state.docFlowSettings.defaults.vatType,
  defaults: (state) => state.docFlowSettings.defaults,

  partners: (state) => state.docFlowSettings.partners,
  partnerDirectory(state) {
    return {
      code: state.docFlowSettings.partners.directory_code,
      type: state.docFlowSettings.partners.directory_type,
    };
  },

  currency: (state) => state.docFlowSettings.defaults.currency,
  productCount: (state) => state.docFlowSettings.defaults.productCount,
  actionCloseAferSaveOrder: (state) => state.docFlowSettings.defaults.actionCloseAferSaveOrder,
  actionCloseAferSaveRealization: (state) => state.docFlowSettings.defaults.actionCloseAferSaveRealization,
  disableWorkOrderStatuses: (state) => state.docFlowSettings.defaults.disableWorkOrderStatuses,
  enableContragentCreating: (state) => state.docFlowSettings.defaults.enableContragentCreating,
  exportProductsText: (state) => state.docFlowSettings.defaults.exportProductsText,
  appendCfToLeadPrice: (state) => state.docFlowSettings.defaults.appendCfToLeadPrice,
  stockCountBySearch: (state) => state.docFlowSettings.defaults.stockCountBySearch,
  isEnableExportProductField: (state) =>
    state.docFlowSettings.defaults.exportProductsText.type === 2 ||
    state.docFlowSettings.defaults.exportProductsText.type == 3,

  pipelineEvents: (state) => state.docFlowSettings.pipelineEvents,
  orderAttachment1cToAmo: (state) => state.docFlowSettings.pipelineEvents?.orderAttachment1cToAmo,
  pipelineEvents1cToAmo: (state) => state.docFlowSettings.pipelineEvents?.events1cToAmo,
  pipelineEventsAmoTo1c: (state) => state.docFlowSettings.pipelineEvents?.eventsAmoTo1c,

  orderCFs: (state) => state.docFlowSettings.orderFields.order_custom_fields,

  contractCFs: (state) => state.docFlowSettings.contractFields.contract_custom_fields,

  contractDateRequired: (state) => state.docFlowSettings.contractFields.date_required,
  contractDateToRequired: (state) => state.docFlowSettings.contractFields.date_to_required,
  contractNameRequired: (state) => state.docFlowSettings.contractFields.name_required,
  contractNumberRequired: (state) => state.docFlowSettings.contractFields.number_required,

  getContractFieldsField: (state) => (field) => state.docFlowSettings.contractFields[field],

  showErrors: (state) => state.docFlowSettings.defaults.showErrors,

  entityFieldId(state) {
    return ({ entity, field }) => {
      return state.docFlowSettings.orderFields[entity]?.[field]?.id;
    };
  },

  entityFieldSync(state) {
    return ({ entity, field }) => {
      return state.docFlowSettings.orderFields[entity]?.[field]?.sync;
    };
  },

  entityFieldRequired(state) {
    return ({ entity, field }) => {
      return state.docFlowSettings.orderFields[entity]?.[field]?.required;
    };
  },

  entityFieldUnupdatable(state) {
    return ({ entity, field }) => {
      return state.docFlowSettings.orderFields[entity]?.[field]?.unupdatable;
    };
  },

  entityCustomFields(state) {
    return (entity) => {
      return state.docFlowSettings.orderFields[entity]?.custom_fields;
    };
  },

  CFvalue(state) {
    return (index) => {
      let param = state.docFlowSettings.orderFields.order_custom_fields[index];
      if (!param.code) {
        return param.type;
      } else {
        return { code: param.code, type: param.type };
      }
    };
  },

  contractCFvalue(state) {
    return (index) => {
      let param = state.docFlowSettings.contractFields.contract_custom_fields[index];
      if (!param.code) {
        return param.type;
      } else {
        return { code: param.code, type: param.type };
      }
    };
  },

  CFTypes(state) {
    const orderCFTypes = state.docFlowSettings.orderFields.order_custom_fields.filter((val) => val?.code);
    const tableTypes = state.docFlowSettings.tableFields.filter((val) => val?.type?.code).map((tf) => tf.type);
    return [
      ...new Set([...orderCFTypes, ...tableTypes].map((v) => JSON.stringify({ code: v.code, type: v.type }))),
    ].map((v) => JSON.parse(v));
  },

  contractCFTypes(state) {
    let params = state.docFlowSettings.contractFields.contract_custom_fields.filter((val) => val?.code);
    return params.map((v) => ({ code: v.code, type: v.type }));
  },

  entityCfValue(state) {
    return (entity, index) => state.docFlowSettings.orderFields.order_custom_fields[index]?.[`${entity}_cf_id`];
  },
  organizationsDefaults: (state) => state.docFlowSettings.organizationsDefaults,
  managerDefaults: (state) => state.docFlowSettings.managerDefaults,

  tableFieldsCustom: (state) => state.docFlowSettings.tableFields.filter((v) => v.custom),
  tableFields: (state) => state.docFlowSettings.tableFields.filter((v) => !v.custom),
  tableField: (state) => {
    return ({ key, action }) => {
      return state.docFlowSettings.tableFields.find((v) => v.key === key)?.[action];
    };
  },
  tableFieldForReduce: (state) => state.docFlowSettings.tableFields.filter((v) => v.sum),
  tableFieldCustomWorkers: (state) => {
    return state.docFlowSettings.tableFields.filter(
      (v) => v.custom && v.attribute !== "" && v.name !== "" && v.type !== ""
    );
  },

  ...storageGetters,
  ...serviceTestGetters,
};

const actions = {
  addIframeUserAction({ commit, rootGetters }, id) {
    const amoUsers = rootGetters["amo/users"];
    const user = amoUsers.find((user) => user.value == id);
    commit("addIframeUser", user);
  },

  removeIframeUserAction({ commit }, id) {
    commit("removeIframeUser", id);
  },

  appendExtraAction: ({ commit }) => commit("appendExtraAction"),

  setExtraAction({ commit }, payload) {
    commit("setExtraAction", payload);
  },

  removeExtraAction({ commit }, index) {
    commit("removeExtraAction", index);
  },

  appendMassAction: ({ commit }) => commit("appendMassAction"),

  setMassAction({ commit }, payload) {
    commit("setMassAction", payload);
  },

  removeMassAction({ commit }, index) {
    commit("removeMassAction", index);
  },

  async setWocSettings({ state, commit, dispatch }) {
    if (state.docFlowSettings.defaults.isProductsEnabled) {
      commit("setDefaultsField", { field: "isProductsEnabled", value: false });
      await dispatch("saveChanges");
    }
  },

  async load({ commit, dispatch, rootGetters }) {
    // печатные формы
    const templates = rootGetters["templates/items"];

    try {
      let settingsData = rootGetters["widgetCachedSettings"];

      if (!settingsData || !settingsData?.auth || !settingsData?.defaults) {
        console.debug("load settings...");
        const resp = await getDocflowSettings();
        settingsData = resp.data;
      }

      // добавляем новые поля в объект настроек, чтобы работала реактивность
      prepareUpdatedSettingsData(settingsData);

      commit("initDocFlowSettings", { settings: settingsData, templates });

      if (settingsData.storage.storageId && !settingsData.storage.storageMail) {
        await dispatch("getStorage");
      }
    } catch (e) {
      console.debug(`1С ${rootGetters["partnerName"]}: Ошибка загрузки настроек`, { e });

      commit("setFailLoading");
      commit("initDocFlowSettings", { settings: initSettings(), templates });
    } finally {
      const respDropbox = await getDropboxAuthLink();
      commit("setDropboxAuthLink", respDropbox.data.authUrl);
    }
  },

  async saveChanges({ commit, state, rootGetters }) {
    try {
      commit("setSaving", true);
      commit("prepareForSaving");
      const respSave = await saveDocflowSettings(state.docFlowSettings);

      commit("setSaving", false);

      if (respSave.data == "OK") {
        commit("initDocFlowSettings", { settings: state.docFlowSettings });
      }

      await setSettings(state.docFlowSettings, {});
    } catch (e) {
      commit("setSaving", false);
      console.debug(`1С ${rootGetters["partnerName"]}: Ошибка сохранения настроек`, { e });
    }
  },

  setKey1C: ({ commit }, key) => commit("setKey1C", key),
  appendPayRow: ({ commit }) => commit("appendPayRow"),
  removePayRow: ({ commit }) => commit("removePayRow"),
  appendRealizationRow: ({ commit }) => commit("appendRealizationRow"),
  removeRealizationRow: ({ commit }) => commit("removeRealizationRow"),
  setPaysInfoValue: ({ commit }, payload) => commit("setPaysInfoValue", payload),
  setRealizationsInfoValue: ({ commit }, payload) => commit("setRealizationsInfoValue", payload),

  addPositionRelation: ({ commit }, payload) => commit("addPositionRelation", payload),
  removePositionRelation: ({ commit }, index) => commit("removePositionRelation", index),

  addParams: ({ commit }) => commit("addParams"),
  removeParams: ({ commit }, index) => commit("removeParams", index),
  addContractParams: ({ commit }) => commit("addContractParams"),
  removeContractParams: ({ commit }, index) => commit("removeContractParams", index),
  cancelChanges: ({ commit }) => commit("cancelChanges"),
  setAuthField: ({ commit }, payload) => commit("setAuthField", payload),
  setDefaultsField: ({ commit }, payload) => commit("setDefaultsField", payload),
  setOrderFieldId: ({ commit }, payload) => commit("setOrderFieldId", payload),
  setOrderFieldSync: ({ commit }, payload) => commit("setOrderFieldSync", payload),
  setOrderFieldRequired: ({ commit }, payload) => commit("setOrderFieldRequired", payload),
  setOrderFieldUnupdatable: ({ commit }, payload) => commit("setOrderFieldUnupdatable", payload),
  setOrderFieldEntityCf: ({ commit }, payload) => commit("setOrderFieldEntityCf", payload),
  addEntityCf: ({ commit }, payload) => commit("addEntityCf", payload),
  setTemplateEntityCf: ({ commit }, payload) => commit("setTemplateEntityCf", payload),
  removeEntityCf: ({ commit }, payload) => commit("removeEntityCf", payload),
  setProductCatalog: ({ commit }, payload) => commit("setProductCatalog", payload),
  setOrderAttachment1cToAmoData: ({ commit }, payload) => commit("setOrderAttachment1cToAmoData", payload),
  addPipelineEvent: ({ commit }, payload) => commit("addPipelineEvent", payload),
  setPipelineAmoEvent: ({ commit }, payload) => commit("setPipelineAmoEvent", payload),
  removePipelineEvent: ({ commit }, payload) => commit("removePipelineEvent", payload),
  setEntityCf: ({ commit }, payload) => commit("setEntityCf", payload),
  setOrderCfType: ({ commit }, payload) => commit("setOrderCfType", payload),
  setContractFieldsField: ({ commit }, payload) => commit("setContractFieldsField", payload),
  setContractCfType: ({ commit }, payload) => commit("setContractCfType", payload),
  setPartnerDirectory: ({ commit }, payload) => commit("setPartnerDirectory", payload),
  clearDefaultPartnerId: ({ commit }) => commit("clearDefaultPartnerId"),
  setCardsFieldsDirectoryCfs: ({ commit }, payload) => commit("setCardsFieldsDirectoryCfs", payload),
  addCardsFieldsDirectoryItem: ({ commit }) => commit("addCardsFieldsDirectoryItem"),
  removeCardsFieldsDirectoryItem: ({ commit }, index) => commit("removeCardsFieldsDirectoryItem", index),
  // defaults ---------------
  // defaults for orgs
  setOrgDefaultsValue: ({ commit }, payload) => commit("setOrgDefaultsValue", payload),
  removeOrgDefaultsValue: ({ commit }, orgId) => commit("removeOrgDefaultsValue", orgId),
  addOrganizationDefaultValue: ({ commit }, orgId) => commit("addOrganizationDefaultValue", orgId),
  // defaults for managers
  setManagerDefaultsValue: ({ commit }, payload) => commit("setManagerDefaultsValue", payload),
  removeManagerDefaultsValue: ({ commit }, managerId) => commit("removeManagerDefaultsValue", managerId),
  addManagerDefaultValue: ({ commit }, managerId) => commit("addManagerDefaultValue", managerId),
  // -----------------------
  setDefaultOrderCfDate: ({ commit }, { attribute, value }) => {
    value = moment(value).format("DD.MM.YYYY 0:00:00");
    commit("setDefaultOrderCfValue", { attribute, value });
  },
  setDefaultOrderCfValue: ({ commit }, payload) => commit("setDefaultOrderCfValue", payload),

  setTableFieldRequired: ({ commit }, payload) => commit("setTableFieldRequired", payload),
  addSettingsTableField: ({ commit }) => commit("addSettingsTableField"),
  removeSettingsTableField: ({ commit }, key) => commit("removeSettingsTableField", key),

  ...storageActions,
  ...serviceTestActions,
};

const mutations = {
  addIframeUser(state, user) {
    state.docFlowSettings.iframe.v2.users.push({
      name: user.title,
      id: user.value,
      login: "",
      password: "",
    });
  },

  removeIframeUser(state, id) {
    state.docFlowSettings.iframe.v2.users = state.docFlowSettings.iframe.v2.users.filter((user) => user.id != id);
  },

  appendExtraAction(state) {
    state.docFlowSettings.extraActions.push({ name: "", code: "" });
  },

  setExtraAction(state, payload) {
    if (payload.index == undefined) return;

    ["name", "code"].forEach((field) => {
      if (payload[field]) {
        state.docFlowSettings.extraActions[payload.index][field] = payload[field];
      }
    });
  },

  removeExtraAction(state, index) {
    state.docFlowSettings.extraActions.splice(index, 1);
  },

  appendMassAction(state) {
    state.docFlowSettings.massActions.leads.push({ name: "", code: "" });
  },

  setMassAction(state, payload) {
    if (payload.index == undefined) return;

    ["name", "code"].forEach((field) => {
      if (payload[field]) {
        state.docFlowSettings.massActions.leads[payload.index][field] = payload[field];
      }
    });
  },

  removeMassAction(state, index) {
    state.docFlowSettings.massActions.leads.splice(index, 1);
  },

  prepareForSaving(state) {
    state.docFlowSettings.auth.url1c = state.docFlowSettings.auth.url1c.trim();
    if (!state.docFlowSettings.auth.url1c.endsWith("/")) state.docFlowSettings.auth.url1c += "/";
  },
  initDocFlowSettings(state, { settings, templates = [] }) {
    if (_.isArray(settings.orderFields.lead.templates)) {
      Vue.set(settings.orderFields.lead, "templates", {});
    }

    // инициализируем объект для печатных форм в настройках
    templates.forEach((t) => {
      if (!settings.orderFields.lead.templates[t.docType1C]) {
        Vue.set(settings.orderFields.lead.templates, t.docType1C, "");
      }
    });

    /*
    ВОЗМОЖНО БУДЕТ НЕ НУЖНО НА ПРОДАКШЕНЕ В СКОРОМ ВРЕМЕНИ
    Если данные полей пришли в старом формате, переделаем их в новый
    */
    if (settings.orderFields?.order_custom_fields.length) {
      if (settings.orderFields.order_custom_fields[0]?.name) {
        let array = [];
        settings.orderFields.order_custom_fields.forEach((val) => {
          array.push({ title: val.title, required: val.required, attribute: val.name, code: "", type: val.type });
        });
        settings.orderFields.order_custom_fields = array;
      }
    }

    state.docFlowSettings = settings;
    state.initialDocFlowSettings = deepCloneByJson(settings);
    state.failLoading = false;
    state.isInit = true;
  },

  setKey1C: (state, key) => {
    Vue.set(state.docFlowSettings, "auth", {});
    Vue.set(state.docFlowSettings.auth, "key1c", key);
  },
  setFailLoading: (state) => (state.failLoading = true),
  setDropboxAuthLink: (state, link) => (state.dropboxAuthLink = link),
  setAuthField: (state, { field, value }) => (state.docFlowSettings.auth[field] = value),
  setDefaultsField: (state, { field, value }) => (state.docFlowSettings.defaults[field] = value),
  setSaving: (state, payload) => (state.isSaving = payload),
  cancelChanges: (state) => (state.docFlowSettings = deepCloneByJson(state.initialDocFlowSettings)),

  addParams: (state) => {
    state.docFlowSettings.orderFields.order_custom_fields.push({
      title: "",
      attribute: "",
      type: "text",
      required: false,
      code: "",
    });
  },

  removeParams: (state, index) => state.docFlowSettings.orderFields.order_custom_fields.splice(index, 1),

  addContractParams: (state) => {
    state.docFlowSettings.contractFields.contract_custom_fields.push({
      title: "",
      attribute: "",
      type: "text",
      required: false,
      code: "",
    });
  },

  removeContractParams: (state, index) => state.docFlowSettings.contractFields.contract_custom_fields.splice(index, 1),

  setOrderFieldId(state, { entity, field, type, id }) {
    if (_.isEmpty(state.docFlowSettings.orderFields[entity][field])) {
      state.docFlowSettings.orderFields[entity][field] = {
        id: "",
        type: "",
        sync: 0,
        unupdatable: false,
        required: false,
      };
    }

    if (type) state.docFlowSettings.orderFields[entity][field].type = type;
    state.docFlowSettings.orderFields[entity][field].id = id;
  },

  setOrderFieldSync(state, { entity, field, sync }) {
    if (_.isEmpty(state.docFlowSettings.orderFields[entity][field])) {
      state.docFlowSettings.orderFields[entity][field] = { id: "", sync: 0, unupdatable: false, required: false };
    }
    Vue.set(state.docFlowSettings.orderFields[entity][field], "sync", sync);
  },

  setOrderFieldRequired(state, { entity, field, required }) {
    if (_.isEmpty(state.docFlowSettings.orderFields[entity][field])) {
      state.docFlowSettings.orderFields[entity][field] = { id: "", sync: 0, unupdatable: false, required: false };
    }
    state.docFlowSettings.orderFields[entity][field].required = required;
  },

  setOrderFieldUnupdatable(state, { entity, field, unupdatable }) {
    if (_.isEmpty(state.docFlowSettings.orderFields[entity][field])) {
      state.docFlowSettings.orderFields[entity][field] = { id: "", sync: 0, unupdatable: false, required: false };
    }
    state.docFlowSettings.orderFields[entity][field].unupdatable = unupdatable;
  },

  setOrderFieldEntityCf(state, { entity, index, name, cfId, sync, type, required, unupdatable }) {
    if (typeof type != "undefined")
      Vue.set(state.docFlowSettings.orderFields[entity].custom_fields[index], "type", type);

    if (typeof sync != "undefined")
      Vue.set(state.docFlowSettings.orderFields[entity].custom_fields[index], "sync", sync);

    if (typeof name != "undefined") state.docFlowSettings.orderFields[entity].custom_fields[index].name = name;

    if (typeof cfId != "undefined") state.docFlowSettings.orderFields[entity].custom_fields[index].amo_cf_id = cfId;

    if (typeof required != "undefined")
      state.docFlowSettings.orderFields[entity].custom_fields[index].required = required;

    if (typeof unupdatable != "undefined")
      state.docFlowSettings.orderFields[entity].custom_fields[index].unupdatable = unupdatable;
  },

  addEntityCf(state, entity) {
    state.docFlowSettings.orderFields[entity].custom_fields.push({
      name: "",
      type: "",
      amo_cf_id: "",
      required: false,
      unupdatable: false,
    });
  },

  addPipelineEvent(state, sectionType) {
    if (sectionType == "events1cToAmo") {
      state.docFlowSettings.pipelineEvents[sectionType].push({
        type: "",
        statusesFrom: [],
        statusTo: "",
      });
    }

    if (sectionType == "eventsAmoTo1c") {
      state.docFlowSettings.pipelineEvents[sectionType].push({
        type: "",
        action: "",
        content: "",
        statusesFrom: [],
        statusTo: "",
      });
    }
  },

  setDefaultOrderCfValue(state, { attribute, value }) {
    let field = state.docFlowSettings.orderFields.order_custom_fields.find((v) => v.attribute == attribute);
    Vue.set(field, "default", value);
  },

  setPipelineAmoEvent(state, { row, type, action, content }) {
    row["type"] = type;
    row["action"] = action;
    row["content"] = content;
  },

  removePipelineEvent(state, { sectionType, index }) {
    const allowedSection = ["events1cToAmo", "eventsAmoTo1c"];
    if (!allowedSection.includes(sectionType) && index < 0) return;

    state.docFlowSettings.pipelineEvents[sectionType].splice(index, 1);
  },

  setTemplateEntityCf(state, { entity, templateKey, cfId }) {
    Vue.set(state.docFlowSettings.orderFields[entity].templates, templateKey, cfId);
  },

  removeEntityCf(state, { entity, index }) {
    state.docFlowSettings.orderFields[entity].custom_fields.splice(index, 1);
  },

  setProductCatalog(state, payload) {
    state.docFlowSettings.orderFields.productCatalog = payload;
  },

  setOrderAttachment1cToAmoData(state, { field, value }) {
    state.docFlowSettings.pipelineEvents.orderAttachment1cToAmo[field] = value;
  },

  setEntityCf(state, { entity, index, type, value }) {
    if (value) Vue.set(state.docFlowSettings.orderFields.order_custom_fields[index], `${entity}_cf_type`, type);
    Vue.set(state.docFlowSettings.orderFields.order_custom_fields[index], `${entity}_cf_id`, value);
  },

  setOrderCfType(state, { index, type, code }) {
    Vue.set(state.docFlowSettings.orderFields.order_custom_fields[index], "type", type);
    Vue.set(state.docFlowSettings.orderFields.order_custom_fields[index], "code", code);
  },

  setContractFieldsField(state, { field, value }) {
    Vue.set(state.docFlowSettings.contractFields, field, value);
  },

  setContractCfType(state, { index, type, code }) {
    Vue.set(state.docFlowSettings.contractFields.contract_custom_fields[index], "type", type);
    Vue.set(state.docFlowSettings.contractFields.contract_custom_fields[index], "code", code);
  },

  setPartnerDirectory(state, { code, type }) {
    Vue.set(state.docFlowSettings.partners, "directory_code", code);
    Vue.set(state.docFlowSettings.partners, "directory_type", type);
  },

  clearDefaultPartnerId(state) {
    Vue.set(state.docFlowSettings.defaults, "partnerId", "");
  },

  addCardsFieldsDirectoryItem(state) {
    Vue.set(state.docFlowSettings.cardsFields, state.docFlowSettings.cardsFields.length, {
      amoType: "",
      amoCfId: "",
      directory_type: "",
      directory_code: "",
      required: false,
    });
  },

  removeCardsFieldsDirectoryItem(state, index) {
    state.docFlowSettings.cardsFields.splice(index, 1);
  },

  appendPayRow: (state) => state.docFlowSettings.paysInfo.push({ dateCf: "", sumCf: "" }),
  removePayRow: (state) => state.docFlowSettings.paysInfo.pop(),
  appendRealizationRow: (state) => state.docFlowSettings.realizationsInfo.push({ dateCf: "", numberCf: "" }),
  removeRealizationRow: (state) => state.docFlowSettings.realizationsInfo.pop(),
  setPaysInfoValue(state, payload) {
    state.docFlowSettings.paysInfo.find((v, i) => i == payload.index)[payload.field] = payload.value;
  },
  setRealizationsInfoValue(state, payload) {
    state.docFlowSettings.realizationsInfo.find((v, i) => i == payload.index)[payload.field] = payload.value;
  },

  setCardsFieldsDirectoryCfs(state, payload) {
    const fields = ["amoType", "amoCfId", "amoCfType", "directory_code", "directory_type", "required", "multiple"];
    fields.forEach((field) => {
      /* eslint-disable-next-line no-prototype-builtins */
      if (payload.hasOwnProperty(field))
        Vue.set(state.docFlowSettings.cardsFields[payload.index], field, payload[field]);
    });
  },

  addOrganizationDefaultValue(state, value) {
    state.docFlowSettings.organizationsDefaults.push({ orgId: value, stockId: "", agreementId: "" });
  },

  setOrgDefaultsValue(state, payload) {
    let row = state.docFlowSettings.organizationsDefaults.find((r) => r.orgId == payload.orgId);
    Vue.set(row, payload.target, payload.value);
  },

  removeOrgDefaultsValue(state, orgId) {
    state.docFlowSettings.organizationsDefaults = state.docFlowSettings.organizationsDefaults.filter(
      (val) => val.orgId != orgId
    );
  },

  addManagerDefaultValue(state, value) {
    state.docFlowSettings.managerDefaults.push({
      managerId: value,
      organizationId: "",
      organizationAccountId: "",
      stockId: "",
    });
  },

  setManagerDefaultsValue(state, payload) {
    let row = state.docFlowSettings.managerDefaults.find((r) => r.managerId == payload.managerId);
    Vue.set(row, payload.target, payload.value);
  },

  removeManagerDefaultsValue(state, managerId) {
    state.docFlowSettings.managerDefaults = state.docFlowSettings.managerDefaults.filter(
      (val) => val.managerId != managerId
    );
  },

  setTableFieldRequired(state, { key, action, required }) {
    const id = state.docFlowSettings.tableFields.map((v) => v.key).indexOf(key);
    Vue.set(state.docFlowSettings.tableFields[id], action, required);
  },
  addSettingsTableField(state) {
    state.docFlowSettings.tableFields.push({
      name: "",
      attribute: "",
      key: uuidv4(),
      type: "",
      edit: false,
      sum: false,
      custom: true,
      required: false,
    });
  },
  removeSettingsTableField(state, key) {
    state.docFlowSettings.tableFields = state.docFlowSettings.tableFields.filter((v) => v.key !== key);
  },

  ...storageMutations,
  ...serviceTestMutations,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
