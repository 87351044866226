<template>
    <gnzs-section header="Настройки по-умолчанию">
        <template v-slot:description>
            Укажите настройки, которые будут отображаться по-умолчанию при создании нового заказа 
        </template>

        <div :class="w.table">
            
            <div :class="w.tableRow">
                <div>Тип цены</div>
                <div>
                    <gnzs-dropdown :class="w.w200" v-model="priceTypeId" :items="priceTypes"></gnzs-dropdown>
                </div>
            </div>

            <div :class="w.tableRow">
                <div>Валюта</div>
                <div>
                    <gnzs-dropdown :class="w.w200" v-model="currency" :items="currences"></gnzs-dropdown>
                </div>
            </div>

            <div :class="w.tableRow">
                <div>Кол-во при добавлении товара</div>
                <div>
                    <gnzs-input v-model="productCount" style="width: 60px;" type="number"></gnzs-input>
                </div>
            </div>

            <div :class="w.tableRow">
                <div>Юр. лицо и РС</div>
                <div>
                    <gnzs-dropdown 
                        style="width: 435px;"
                        :value="organizationAccountId" 
                        :is-grouped="true" 
                        :items="organizations"
                        @input="onOrganizationAccountChangeHandler"
                    ></gnzs-dropdown>
                </div>
            </div>

            <div :class="w.tableRow">
                <div>Склад</div>
                <div>
                    <gnzs-dropdown :class="w.w200" v-model="stockId" :items="stocks"></gnzs-dropdown>
                </div>
            </div>

            <div :class="w.tableRow">
                <div>Скидка по умолчанию</div>
                <div>
                    <gnzs-dropdown :class="w.w200" v-model="discountViewType" :use-empty-item="false" :items="discountViewTypes"></gnzs-dropdown>
                </div>
            </div>

            <div :class="w.tableRow">
                <div>Тип НДС по умолчанию</div>
                <div>
                    <gnzs-dropdown 
                        :class="w.w200"
                        v-model="vatType" 
                        :items="vatTypes"
                        :use-empty-item="false"
                    ></gnzs-dropdown>
                </div>
            </div>

            <div :class="w.tableRow">
                <div>Ставка НДС по умолчанию, %</div>
                <div>
                    <gnzs-input v-model="vat" style="width: 60px;" type="number"></gnzs-input>
                </div>
            </div>

            <div :class="w.tableRow">
                <div>Менеджер при отправке из amoCRM</div>
                <div>
                    <gnzs-dropdown 
                        :class="w.w200"
                        v-model="managerType" 
                        :items="managerTypes"
                        :use-empty-item="false"
                    ></gnzs-dropdown>
                </div>
            </div>

            <div :class="w.tableRow">
                <div>Менеджер при отправке из 1С</div>
                <div>
                    <div :class="[w.withHelper, w.w228]">
                        <gnzs-dropdown 
                            :class="w.w200"
                            v-model="managerId" 
                            :items="managers"
                            :use-empty-item="false"
                        ></gnzs-dropdown>
                        <GnzsHelper side="right" :value="helpers.defaultManager"/>
                    </div>
                </div>
            </div>

            <div :class="w.tableRow">
                <div>Отображать уведомления об ошибках интеграции в:</div>
                <div>
                    <gnzsDropdownCheckboxes
                        :class="w.w200"
                        v-model="showErrors" 
                        :items="showErrorsTypes"
                        :use-empty-item="false"
                    ></gnzsDropdownCheckboxes>
                </div>
            </div>

        </div>
    </gnzs-section>
</template>

<script>

import {mapGetters} from 'vuex';

import gnzsSection from '@/gnzs-controls/Section';
import gnzsInput from '@/gnzs-controls/Input';
import gnzsDropdown from '@/gnzs-controls/Dropdown';
import gnzsDropdownCheckboxes from '@/gnzs-controls/DropdownCheckboxes';
import GnzsHelper from '@/gnzs-controls/Helper';

// функция для создания методов вычисляемого поля, которое может работать с директивой v-model
const mapFieldModel = function(fieldName){
    return {
        get(){
            return this.$store.getters['settings/defaults'][fieldName];
        },
        set(value){
            this.$store.dispatch('settings/setDefaultsField', {field: fieldName, value});
        }
    }
}

export default {
    computed: {
        ...mapGetters({
            organizations: 'organizations/settingsItems',
            stocks: 'stocks/dropdownItems',
            priceTypes: 'pricetypes/dropdownItems',
            vatTypes: 'localSettings/vatTypes',
            managerTypes: 'localSettings/managerTypes',
            discountViewTypes: 'localSettings/discountViewTypes',
            showErrorsTypes: 'localSettings/showErrorsTypes',
            currences: 'localSettings/currences',
            helpers: 'localSettings/helpers',
            organizationAccountId: 'settings/organizationAccountId',
            showErrors: 'settings/showErrors',
            defaults: 'settings/defaults',
            users: 'amo/users',
        }),
        priceTypeId: { ...mapFieldModel.call(this, 'priceTypeId') },
        stockId: { ...mapFieldModel.call(this, 'stockId') },
        discountViewType: {...mapFieldModel.call(this, 'discountViewType')},
        vat: { ...mapFieldModel.call(this, 'vat') },
        vatType: { ...mapFieldModel.call(this, 'vatType') },
        managerType: { ...mapFieldModel.call(this, 'managerType') },
        managerId: { ...mapFieldModel.call(this, 'managerId') },
        currency: { ...mapFieldModel.call(this, 'currency') },
        productCount: { ...mapFieldModel.call(this, 'productCount') },
        managers(){
            return this.users.filter(u => !u.is_free)
        }
    },
    methods: {
        onOrganizationAccountChangeHandler(value, groupValue){
            this.$store.dispatch('settings/setDefaultsField', {field: 'organizationAccountId', value});
            this.$store.dispatch('settings/setDefaultsField', {field: 'organizationId', value: groupValue})
            this.$store.dispatch('settings/setDefaultsField', {field: 'defaultAgreement', value: ""})
        }
    },
    components: {
        gnzsSection,
        gnzsDropdown,
        gnzsDropdownCheckboxes,
        gnzsInput,
        GnzsHelper
    }
}
</script>


<style lang="scss" module="w">
    @import '@/assets/scss/Settings/style.scss';
</style>