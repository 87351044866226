<template>
     <GnzsSection 
     :disabled="!tariffFeatures.includes('contacts-amo-to-1с')" 
     :disabledText="disabledSectionText"
     header="Передача контактных лиц"
     >
     <template v-slot:description>
        Функционал передачи связанных контактов для сущностей.
    </template>
        <div :class="w.table">
            <div :class="w.tableRow">
                <div :class="w.w291">Передавать в 1С контакты</div>
                <GnzsDropdownCheckboxes
                    :class="w.w200"
                    :value="defaults.linkedContactsType"
                    :items="linkedContactsTypes"
                    @input="onLinkedContactTypeChange"
                />
            </div>
        </div>
        <div :class="w.table">
            <div v-if="!isInsideAmo && source.length == 0" :class="w.tableRow">
                <div>Не отправлять контакты с пустыми данными</div>
                <div>
                    <div :class="w.withHelper">
                        <GnzsDropdown
                            :class="w.w230"
                            v-model="defaults.checkEmptyContactsData"
                            :items="booleanTypes"
                            :use-empty-item="false"
                        ></GnzsDropdown>
                        <GnzsHelper side="right" type="service" :value="helpers.serviceUseOnly" />
                    </div>
                </div>
            </div>
        </div>
     </GnzsSection>
</template>

<script>
import {mapActions, mapGetters} from "vuex"
import GnzsSection from "@/gnzs-controls/Section";
import GnzsDropdown from "@/gnzs-controls/Dropdown";
import GnzsDropdownCheckboxes from "@/gnzs-controls/DropdownCheckboxes";
import GnzsHelper from '@/gnzs-controls/Helper';

export default {
    computed: {
        ...mapGetters({
            defaults: "settings/defaults",
            linkedContactsTypes: 'localSettings/linkedContactsTypes',
            booleanTypes: 'localSettings/booleanTypes',
            tariffFeatures: 'features/getFeatures',
            helpers: 'localSettings/helpers',
            disabledSectionText: 'localSettings/disabledSectionText',
            isInsideAmo: 'isInsideAmo',
            source: 'settings/source',
        }),
    },
    methods: {
        ...mapActions({
            setDefaultsField: "settings/setDefaultsField"
        }),
        onLinkedContactTypeChange(value){
            this.setDefaultsField({field: 'linkedContactsType', value})
        }
    },
    components: {
        GnzsSection,
        GnzsDropdownCheckboxes,
        GnzsDropdown,
        GnzsHelper
    },
}
</script>

<style lang="scss" module="w">
    @import '@/assets/scss/Settings/style.scss';
</style>