<template>
  <div :class="$style.container">
    <div :class="$style.text">
      {{ item.title }}
    </div>
    <div :class="$style.remove" @click="$emit('click', item)">
        <GnzsCrossClose size="min" />
    </div>
  </div>
</template>

<script>
import GnzsCrossClose from '../CrossClose';

export default {
  props: {
    item: {
      type: Object
    }
  },
  components: {
    GnzsCrossClose,
  }
}
</script>


<style lang="scss" module>

.container{
  position: relative;
  display: flex;
  align-items: center;
  padding: 4px;
  background-color: #dfebf8;
  border: 1px solid #9dc1e7;
  border-radius: 2px;
}

.text{
  margin-right: 5px;
  line-height: 14px;
  font-size: 13px;
  color: #595d64;
  max-width: 220px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.remove{
  display: flex;
  padding: 2px;
  cursor: pointer;
  svg{
      width: 10px;
      height: 10px;
      fill: #0085c8;
  }
  &:hover{
    opacity: .8;
  }
}
</style>