<template>
    <Modal ref="modal">
        <template v-slot>
            <div :class ="$style.wrapper">
                <span class="modal-body__close" ref="close">
                <span class="icon icon-modal-close"></span>
                </span>

                <div :class="$style.header">
                    Применить склад
                </div>

                <GnzsDropdown
                    v-model="stockId"
                    :use-empty-item="false"
                    :items="stocks"
                    :emptyItemTitle="'Выбрать'"
                />

                <div :class="$style.actions">
                    <GnzsButton 
                        type="primary"
                        @click="apply"
                        :disabled="!stockId"
                    >Применить</GnzsButton>
                    <GnzsButton type="cancel" @click="closeModal">Отмена</GnzsButton>
                </div>
            </div>
        </template>
    </Modal>
</template>

<script>
import Modal from '@/gnzs-controls/Modal';

import GnzsButton from '@/gnzs-controls/Button';
import GnzsDropdown from '@/gnzs-controls/Dropdown';
import $ from 'jquery';

import {mapActions, mapGetters} from 'vuex';

export default {
    data(){
        return {
            stockId: ''
        }
    },
    computed: {
        ...mapGetters({
            stocks: 'stocks/dropdownItems',
        }),
    },
    methods: {
        ...mapActions({
            applyStock: 'order/applyStock',
            setCheckedAll: 'order/setCheckedAll',
            calc: 'table/calc'
        }),
        apply(){
            this.applyStock(this.stockId)
            this.setCheckedAll(false)
            let wrapper = $(`.gnzs-1c-scroll-table-wrapper`)
            let draggable = $(`.gnzs-1c-scroll-table-draggable`)
            setTimeout(() => this.calc({wrapper, draggable}), 100);
            this.closeModal();
        },
        closeModal(){
            this.$refs.modal.close()
        },
    },
    components: {
        Modal,
        GnzsButton,
        GnzsDropdown
    }
}
</script>

<style lang="scss" module>
    @import './AddStockModal.scss';
</style>