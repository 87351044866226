export default {
  title: 'Distribución de ofertas',

  tokenError: 'El token de autorización ha caducado !',

  buttons: {
    append: 'Agregar',
    back: 'Atrás',
    save: 'Salvar',
    cancel: 'Cancelar',
    templateAppend: 'Agregar plantilla de configuración',
  },

  breadcrumbs: {
    templateTitle: 'Nueva plantilla',
  },

  body: {
    sections: {
      main: 'Ajustes básicos',
      groupsAndUsers: 'Grupos y usuarios para distribuir',
      additional: 'Ajustes adicionales',
    },
    templateName: 'Nombre de la plantilla de configuración',
    distributionType: 'Tipo de distribución',
    usersQueue: {
      settingsDescription: {
        title: 'Etiquetar grupos o usuarios individuales',
        show: 'Más',
        hide: 'Esconder',
      },
      description: 'Si marca todo el grupo, la integración distribuirá ofertas entre sus usuarios y recogerá cambios dinámicamente si agrega o elimina un usuario del grupo. Si solo se marcan usuarios individuales, la distribución se realizará solo entre ellos.',
    },
    usersPercent: {
      settingsDescription: {
        title: 'Especificar porcentajes de distribución',
        show: 'Más',
        hide: 'Esconder',
      },
      description: 'Los porcentajes de grupo deben sumar 100%. Además, para un grupo, puede elegir el formato de distribución entre los usuarios de ese grupo: seleccione la opción "repartir uniformemente" para que dentro de este grupo la distribución sea uniforme para todos; si necesita establecer una proporción de distribución diferente entre los usuarios, seleccione la opción "distribuir por porcentaje"; en este caso, la suma de los porcentajes de los usuarios dentro del grupo también debe ser igual al 100%.',
      group: 'del 100% por grupos',
      distribute: 'distribuir',
    },
    repeatControl: 'Repita el control comercial',
    AssignOnlyWorkime: {
      title: {
        label: 'Distribuir solo durante el horario comercial',
      },
      workTime: {
        time: 'Tiempo de trabajo',
        days: 'Días de trabajo',
        from: 'de',
        to: 'a',
        weekDays: {
          mon: 'Lu',
          tue: 'Ma',
          wed: 'Mi',
          thu: 'Ju',
          fri: 'Vi',
          sat: 'Sa',
          sun: 'Do',
        },
      },
      DNA: {
        activated: {
          warning: 'Importante: Los usuarios en la configuración actual deben coincidir con los usuarios en el horario seleccionado, de lo contrario, no se distribuirán.',
        },
        notActivated: {
          warning: 'Atención! necesitas instalar',
          link: 'ADN Widget',
        },
      },
    },
    distributionAgain: {
      title: 'Redistribuir si la etapa no cambia',
      across: 'Al otro lado de',
      min: 'min',
    },
    changeContacts: 'Cambiar el propietario de un contacto y una empresa',
    changeLeadTasks: 'Cambiar el dueño de las tareas abiertas por un trato',
    changeContactTasks: 'Cambiar el propietario de las tareas abiertas por contactos',
    changeCompanyTasks: 'Cambiar el propietario de las tareas abiertas por empresa',
  },

  assignTypes: [
    { value: 1, title: 'Turnarse' },
    { value: 2, title: 'Por interés' },
  ],

  assignPercentTypes: [
    { value: 1, title: 'igualmente' },
    { value: 2, title: 'por interés' },
  ],

  repeatLeadsControlTypes: [
    { value: 1, title: 'Ignorar intercambios repetidos' },
    { value: 2, title: 'Distribuir transacciones repetidas en el Contacto al responsable' },
    { value: 3, title: 'Asignar transacciones repetidas en la Compañía al responsable' },
    { value: 4, title: 'Distribuir transacciones repetidas por Contacto o Empresa al responsable' },
  ],

  assignOnlyWorktimeTypes: [
    { value: 1, title: 'según el horario general' },
    { value: 2, title: 'en un horario individual' },
  ],
}