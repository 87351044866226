import { PERSONAL_FEATURES } from "../constants";

const state = () => ({
  features: "",
  tariffId: 0,
});

const getters = {
  getFeatures: (state) => state.features,
  getTariffId: (state) => state.tariffId,
};

const actions = {
  processFeatures({ commit }, payload) {
    commit("setFeatures", payload);
  },
  processTariffId({ commit }, payload) {
    commit("setTariffId", payload);
  },
  setData({ commit }, token) {
    commit("setFeatures", token.gnzsWidgetStatus.tariff.features);
    commit("setTariffId", token.gnzsWidgetStatus.tariff.id);
    commit("setPersonalFeatures", token.account_id);
  },
};

const mutations = {
  setFeatures(state, features) {
    state.features = features.split(",");
  },
  setTariffId(state, id) {
    state.tariffId = id;
  },
  setPersonalFeatures(state, accountId) {
    const pfs = PERSONAL_FEATURES.find((pf) => pf.accountId == accountId);
    if (!pfs) return;
    state.features = state.features.concat(pfs.features);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
