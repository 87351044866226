<template>
  <GnzsSection
    :disabled="!tariffFeatures.includes('contracts-1с-to-amo')"
    :disabledText="disabledSectionText"
    header="Договоры">
    <template v-slot:description>
      Функционал создания договоров, которые можно выбирать из выпадающего списка в модальном окне заказа.
    </template>
    <template v-slot:header-control>
      <GnzsSwitcher v-model="defaults.isContractsEnabled"></GnzsSwitcher>
    </template>
    <template v-if="defaults.isContractsEnabled">
      <div :class="w.table">
        <div :class="w.tableRow">
          <div :class="w.switcherTitle">Не отображать типовые договора</div>
          <div>
            <GnzsSwitcher v-model="defaults.disableCommonContracts" />
          </div>
        </div>
        <div :class="w.tableRow">
          <div :class="w.switcherTitle">Запрашивать информацию о договоре</div>
          <GnzsSwitcher v-model="defaults.isRequestContractInfo" />
        </div>
        <div :class="w.tableRow">
          <div :class="w.switcherTitle">Подстановка индивидульного договора</div>
          <div>
            <div :class="w.withHelperSwitcher">
              <GnzsSwitcher v-model="defaults.setIndividualContract" />
              <GnzsHelper side="right" :value="helpers.setIndividualContract" />
            </div>
          </div>
        </div>
        <div :class="w.tableRow">
          <div :class="w.switcherTitle">Создание договоров</div>
          <GnzsSwitcher v-model="defaults.isContractCreationEnabled" />
        </div>
      </div>
      <div :class="$style.creationParams" v-if="defaults.isContractCreationEnabled">
        <div :class="w.table">
          <div :class="w.tableHeader">
            <div>Поле</div>
            <div>Обязательное</div>
          </div>
          <div :class="w.tableRow">
            <div :class="w.switcherTitle">Договор</div>
            <div :class="w.cellRequired">
              <div :class="[w.cellInner, w.checkboxRequireContainer]">
                <GnzsCheckbox v-model="defaults.isContractRequired"></GnzsCheckbox>
              </div>
            </div>
          </div>
          <div :class="w.tableRow">
            <div :class="w.switcherTitle">Номер договора</div>
            <div :class="w.cellRequired">
              <div :class="[w.cellInner, w.checkboxRequireContainer]">
                <GnzsCheckbox v-model="numberRequired"></GnzsCheckbox>
              </div>
            </div>
          </div>
          <div :class="w.tableRow">
            <div :class="w.switcherTitle">Наименование</div>
            <div :class="w.cellRequired">
              <div :class="[w.cellInner, w.checkboxRequireContainer]">
                <GnzsCheckbox v-model="nameRequired"></GnzsCheckbox>
              </div>
            </div>
          </div>
          <div :class="w.tableRow">
            <div :class="w.switcherTitle">Дата начала</div>
            <div :class="w.cellRequired">
              <div :class="[w.cellInner, w.checkboxRequireContainer]">
                <GnzsCheckbox v-model="dateRequired"></GnzsCheckbox>
              </div>
            </div>
          </div>
          <div :class="w.tableRow">
            <div :class="w.switcherTitle">Дата окончания</div>
            <div :class="w.cellRequired">
              <div :class="[w.cellInner, w.checkboxRequireContainer]">
                <GnzsCheckbox v-model="dateToRequired"></GnzsCheckbox>
              </div>
            </div>
          </div>
        </div>
        <div :class="$style.paramsHeader">Дополнительные параметры</div>
        <div :class="w.table" v-if="contractCFs.length">
          <div :class="w.tableHeader">
            <div>Параметр</div>
            <div>Реквизит 1С</div>
            <div>Тип</div>
            <div>Обязательное</div>
          </div>

          <div :class="w.tableRow" v-for="(param, i) in contractCFs" :key="i">
            <div>
              <GnzsInput v-model="param.title" placeholder="Название" />
            </div>
            <div>
              <GnzsInput v-model="param.attribute" placeholder="Реквизит 1С" />
            </div>
            <div>
              <GnzsDropdown
                :class="w.w200"
                :value="CFvalue(i)"
                @input="setParamType($event, i)"
                :items="contractParamTypes"
                :useEmptyItem="false"></GnzsDropdown>
            </div>
            <div :class="w.cellRequired">
              <div :class="[w.cellInner, w.checkboxRequireContainer]">
                <GnzsCheckbox v-model="param.required" />
              </div>
            </div>
            <div>
              <GnzsButton type="remove" @click="removeParams(i)" />
            </div>
          </div>
        </div>

        <GnzsButton type="append" @click="addParams" :class="$style.appendParamsButton">Добавить параметр</GnzsButton>
      </div>
    </template>
  </GnzsSection>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";

  import GnzsSection from "@/gnzs-controls/Section";
  import GnzsSwitcher from "@/gnzs-controls/Switcher";
  import GnzsInput from "@/gnzs-controls/Input";
  import GnzsButton from "@/gnzs-controls/Button";
  import GnzsDropdown from "@/gnzs-controls/Dropdown";
  import GnzsCheckbox from "@/gnzs-controls/Checkbox";
  import GnzsHelper from "@/gnzs-controls/Helper";

  const mapFieldModel = function (fieldName) {
    return {
      get() {
        return this.getContractFieldsField(fieldName);
      },
      set(value) {
        this.setContractFieldsField({ field: fieldName, value });
      },
    };
  };

  export default {
    computed: {
      ...mapGetters({
        defaults: "settings/defaults",
        tariffFeatures: "features/getFeatures",
        disabledSectionText: "localSettings/disabledSectionText",
        contractParamTypes: "localSettings/orderParamTypes",
        contractCFs: "settings/contractCFs",
        CFvalue: "settings/contractCFvalue",
        getContractFieldsField: "settings/getContractFieldsField",
        helpers: "localSettings/helpers",
      }),
      dateRequired: { ...mapFieldModel.call(this, "date_required") },
      dateToRequired: { ...mapFieldModel.call(this, "date_to_required") },
      nameRequired: { ...mapFieldModel.call(this, "name_required") },
      numberRequired: { ...mapFieldModel.call(this, "number_required") },
    },
    methods: {
      ...mapActions({
        addParams: "settings/addContractParams",
        removeParams: "settings/removeContractParams",
        setContractCfType: "settings/setContractCfType",
        setContractFieldsField: "settings/setContractFieldsField",
      }),
      setParamType(value, index) {
        let type = typeof value == "string" ? value : value.type;
        let code = value?.code ? value.code : "";
        this.setContractCfType({ index, type, code });
      },
    },
    components: {
      GnzsSection,
      GnzsSwitcher,
      GnzsInput,
      GnzsButton,
      GnzsDropdown,
      GnzsCheckbox,
      GnzsHelper,
    },
  };
</script>

<style lang="scss" module="w">
  @import "@/assets/scss/Settings/style.scss";
</style>

<style lang="scss" module>
  @import "./style.scss";
</style>
