<template>
    <EditModal @close="closeModal" />
</template>

<script>
import EditModal from '@/components/Modal/Modals/EditDirectoryElementModal/EditDirectoryElementModal.vue'
import {mapGetters} from 'vuex';

export default{
    computed:{
        ...mapGetters({
            iframeName: 'iframe/iframeName'
        })
    },
    methods: {
        closeModal(){
            window.parent.postMessage({ event: `${this.iframeName}:closeModal` }, "*");
        }
    },
    components: {
        EditModal
    }
}
</script>