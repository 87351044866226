import { getTemplates } from "@/api/docflow-api";
import { MINIMAL_TARIFF_ID } from "../constants";

const state = () => ({
  selectedTemplateId: null, // id выбранного шаблона в выпадающем списке
  selectedRealizationId: null, // id выбранной реализации в выпадающем списке
  selectedInvoiceId: null, // id выбранного счета в выпадающем списке
  selectedSignatoryId: null, // id выбранного подписанта в выпадающем списке
  items: [],
});

const getters = {
  dropdownItems(state) {
    return state.items.map((template) => ({
      value: template.id,
      title: template.name,
    }));
  },
  eventDropdownItems: (state) =>
    state.items.map((template) => ({
      value: `printed_forms__create__${template.docType1C}`, // структура {type}__{action}__{content}
      type: "printed_forms",
      action: "create",
      content: template.docType1C,
      title: "Cоздана печ. форма: " + template.name,
    })),

  items: (state) => {
    return state.items;
  },
  selectedTemplateId: (state) => state.selectedTemplateId,
  selectedSignatoryId: (state) => state.selectedSignatoryId,
  selectedRealizationId: (state) => state.selectedRealizationId,
  selectedInvoiceId: (state) => state.selectedInvoiceId,
  getTemplateById: (state) => (id) => state.items.find((t) => t.id == id),
};

const actions = {
  async load({ commit, rootGetters }) {
    try {
      const resp = await getTemplates();
      let templates = resp.data.rows;
      const tariffId = rootGetters["features/getTariffId"];
      const except = [
        30933542, // Клиент Гаёва по согласованию с Митей, доступны все шаблоны
        28856629, // Клиент Сканрост по согласованию с Митей, доступны все шаблоны
      ];
      if (tariffId == MINIMAL_TARIFF_ID && !except.includes(rootGetters["amoAccountId"])) {
        templates = templates.filter(
          (item) => item.docType1C == "InvoiceOfPay" || item.docType1C == "InvoiceOfPayFacs"
        );
      }

      commit("initTemplates", templates);

      if (templates.length) {
        commit("setSelectedTemplateId", resp.data.rows[0].id);
      }
    } catch (e) {
      console.debug(`1С ${rootGetters["partnerName"]}: Ошибка получения печатных форм`, e);
    }
  },

  setSelectedTemplateId: ({ commit }, id) => commit("setSelectedTemplateId", id),
  setSelectedRealizationId: ({ commit }, id) => commit("setSelectedRealizationId", id),
  setSelectedInvoiceId: ({ commit }, id) => commit("setSelectedInvoiceId", id),
  setSelectedSignatoryId: ({ commit }, id) => commit("setSelectedSignatoryId", id),
};

const mutations = {
  initTemplates(state, templates) {
    state.items = templates;
  },

  setSelectedRealizationId(state, id) {
    state.selectedRealizationId = id;
  },

  setSelectedInvoiceId(state, id) {
    state.selectedInvoiceId = id;
  },

  setSelectedTemplateId(state, id) {
    state.selectedTemplateId = id;
  },

  setSelectedSignatoryId(state, id) {
    state.selectedSignatoryId = id;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
