/**
 * @author Ilya Orishin. 2021
 * 
 * Функционал обеспечения
 */

import {load1cProvisions} from '@/api/1c-api';

const state = () => ({
	items: [], // Список статусов обеспечения
	isCreating: false,
	isLoading: false,
});

const getters = {
	isCreating: state => state.isCreating,

	// проверка, можно ли использовать статус при нулевых остатках
	usedOnEmptyStocks: state => (id) => state.items.find(val => val.value == id).showOnStockEmpty,

    /*
    Все статусы из 1С (Объект) выступает в качестве айтемс к мультисписку.
    Получаем через api в load
    */
    provisionStatuses:(state) => state.items,
	/*
    Получить объект статусов, которые могут быть использованы
    при нулевых остатках. Далее передается в дропдаун в таблицу
    */
    emptyStockProvisionStatuses: (state) => state.items.filter(val => val.showOnStockEmpty)
};

const actions = {

	async load({commit, rootGetters}){
		/*
		Загрузка статусов обеспечения с сервера
		*/
		try{
			commit('setValue', {key: 'isLoading', value: true})
			const resp = await load1cProvisions();
			commit('setItems', resp.data.result);
		} catch(e) {
			console.debug(`1С ${rootGetters['partnerName']}: Ошибка получения статусов обеспечения`, e);
		} finally {
			commit('setValue', {key: 'isLoading', value: false})
		}
	},
};

const mutations = {
	setItems(state, items){
		state.items = items.map(i => ({
			title: i.name,
			value: i.id,
			showOnStockEmpty: parseInt(i.show_on_stock_empty)
		}))
	},
	setValue: (state, {key, value}) => state[key] = value // Установить значение
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
