/**
 * @author Ilya Orishin. 2022
 *
 * Функционал частичной реализации
 */

import Vue from "vue";
import { getRealizationsList, spendRealization } from "@/api/1c-api";

import { checkRealizationAndShowError, calcDiscountPrice, uuidv4, closeOrderModal, showModal } from "@/utilities/order";

const moment = require("moment");

/**
 * Инициализация новой реализации
 *
 */
const initRealization = (order, products, currentUser) => ({
  id: "",
  uuid: uuidv4(),
  order_id: order.order_id,
  manager_name: order.manager_name,
  manager_email: order.manager_email,
  manager_phone: order.manager_phone,
  nds_type: order.nds_type,
  total_nds: order.total_nds,
  number: "Новая реализация",
  date: moment().format("DD.MM.YYYY"),
  isFull: true,
  products,
  current_user_id: `${currentUser.id}`,
  current_user_name: `${currentUser.name}`,
  current_user_phone: `${currentUser.phone}`,
  current_user_emall: `${currentUser.email}`,
});

/*
* Структура:
{
    list -> хранит список всех реализаций в формате {id, number, date, products, ...}
    titles -> хранит список всех реализаций в формате {id, name}
    items -> сюда попадают данные новых реализаций с фронта или подгружаются с сервера уже созданные в формате
    {id, number, order_id, products[]} 
    active -> выбранная реализация
    failLoading -> переманная, который отвечает за ошибку загрузки данных
}
*/
const state = () => ({
  list: [],
  titles: [],
  items: [],
  active: "",
  failLoading: false,
  isLoading: false,
  isLoadingTitles: false,
  isSaving: false,
});

const getters = {
  list: (state) =>
    state.items.map((r) => ({
      title: `Реализация ${r.number} от ${r.date}`,
      value: r.uuid,
    })),
  titles: (state) =>
    state.titles.map((h) => ({
      title: h.name,
      value: h.id,
    })),
  shipped: (state) => {
    /*
        Расчёт кол-ва отгруженных товаров по каждой позиции
        Фильтруем только те реализации, у которых есть id, чтобы исклюить не проведенные
        */

    const result = {};
    state.items
      .filter((r) => r.id)
      .forEach((item) => {
        item.products.forEach((p) => {
          // eslint-disable-next-line no-prototype-builtins
          if (result.hasOwnProperty(p.id)) {
            result[p.id] += +p.count;
          } else {
            result[p.id] = +p.count;
          }
        });
      });
    return result;
  },
  item: (state) => state.items.find((r) => r.uuid == state.active),
  itemProducts: (state) => state.items.find((r) => r.uuid == state.active)?.products ?? [],
  totalPrice: (state, getters, rootState, rootGetters) => {
    /*
        Расчёт общей стоимости по реализации

        1. Если выбранной реализации нет - возвращаем 0
        2. Получаем текущую реализацию тип ндс, ставку ндс
        3. Считаем сумму по всем товарам (Кол-во * Цена)
        4. Накидываем сверху НДС
        */
    if (!state.active) return 0;
    const activeItem = state.items.find((r) => r.uuid == state.active);
    const ndsType = rootGetters["order/orderData"].nds_type;
    const nds = rootGetters["settings/defaults"].vat;

    let sum = activeItem.products.reduce((acc, product) => (acc += +product.count * +product.price), 0);
    let nds_sum = 0;
    activeItem.products.forEach((product) => {
      if (ndsType == 2) {
        if (!product?.nds) product.nds = nds;
        if (`${product.nds}`.includes("%")) {
          product.nds = product.nds.replace("%", "") / 100;
        }
        nds_sum += +product.count * +product.price * (+product.nds / 100);
      }
    });

    return sum + nds_sum;
  },
  failLoading: (state) => state.failLoading,
  isLoading: (state) => state.isLoading,
  isSaving: (state) => state.isSaving,
  active: (state) => state.active,
  hasId: (state) => !!state.items.find((r) => r.uuid == state.active)?.id,
  getValue: (state) => (key) => state[key],
};

const actions = {
  async loadTitles({ commit, rootGetters }) {
    /**
     * Получение списка реализаций с сервера, только заголовки {id, name}
     *
     */
    try {
      commit("setValue", { key: "isLoadingTitles", value: true });
      const order_id = rootGetters["order/orderData"].order_id;
      const list = true;
      if (!order_id) return;
      const response = await getRealizationsList({ order_id, list });
      commit("setTitles", response.data.result);
    } catch (e) {
      console.debug(`1С ${rootGetters["partnerName"]}: Ошибка получения реализаций`, { e });
    } finally {
      commit("setValue", { key: "isLoadingTitles", value: true });
    }
  },
  async loadList({ commit, rootGetters }) {
    /**
     * Получение списка реализаций с сервера с товарами
     *
     */
    try {
      const order_id = rootGetters["order/orderData"].order_id;
      if (!order_id) return;
      const response = await getRealizationsList({ order_id });
      commit("setList", response.data.result);
    } catch (e) {
      console.debug(`1С ${rootGetters["partnerName"]}: Ошибка получения реализаций`, { e });
      commit("setFailLoading");
    }
  },

  newItem({ commit, rootGetters }) {
    /**
     * Создание новой реализации на стороне АМО
     *
     */
    const order = rootGetters["order/orderData"];
    const checked = rootGetters["order/checkedProducts"];
    const currentUser = rootGetters["amoUser"];
    const ndsType = rootGetters["order/orderData"].nds_type;
    const nds = rootGetters["settings/defaults"].vat;

    commit("newItem", { order, checked, currentUser });
    commit("recalcVat", { ndsType, nds });
  },

  async spendItem({ commit, getters, rootGetters }) {
    /**
     * обработчик нажатия кнопки "Провести реализацию"
     *
     */
    try {
      //Проверка можно ли провести реализацию
      checkRealizationAndShowError(
        getters.itemProducts,
        rootGetters["order/products"],
        getters.shipped,
        rootGetters["products/items"]
      );
      // Проведение
      commit("checkFull", { orderPos: rootGetters["order/products"] });
      const uuid = getters.item.uuid;
      commit("setValue", { key: "isSaving", value: true });
      const { entityId, entityType } = rootGetters["order/entity"];
      const params = {
        entity_id: entityId,
        entity_type: entityType,
      };
      const response = await spendRealization(getters.item, params);

      if (response.data?.error) {
        showModal("Ошибка проведения реализации", response.data.error, true);
        return;
      }

      commit("updateItem", { item: response.data, uuid });

      if (rootGetters["settings/actionCloseAferSaveRealization"]) {
        // настройка закрытия модалки после сохранения
        closeOrderModal(rootGetters["iframe/iframeName"]);
      }
    } catch (e) {
      console.debug(`1С ${rootGetters["partnerName"]}: Ошибка проведения реализации`, { e });
      commit("setFailLoading");
    } finally {
      commit("setValue", { key: "isSaving", value: false });
    }
  },

  onChangeNdsType: ({ commit, rootGetters }) => {
    const ndsType = rootGetters["order/orderData"].nds_type;
    const nds = rootGetters["settings/defaults"].vat;
    commit("recalcVat", { ndsType, nds });
  },

  changeProductItemCount: ({ commit, rootGetters }, payload) => {
    const ndsType = rootGetters["order/orderData"].nds_type;
    const nds = rootGetters["settings/defaults"].vat;

    commit("changeProductItemCount", payload);
    commit("recalcVat", { ndsType, nds });
  },
  changeProductItemPrice: ({ commit, rootGetters }, payload) => {
    const ndsType = rootGetters["order/orderData"].nds_type;
    const nds = rootGetters["settings/defaults"].vat;

    commit("changeProductItemPrice", payload);
    commit("recalcVat", { ndsType, nds });
  },
  removeProductFromItem: ({ commit, rootGetters }, uuid) => {
    const ndsType = rootGetters["order/orderData"].nds_type;
    const nds = rootGetters["settings/defaults"].vat;

    commit("removeProductFromItem", uuid);
    commit("recalcVat", { ndsType, nds });
  },

  setActive: ({ commit }, id) => commit("setActive", id),
  setValue: ({ commit }, payload) => commit("setValue", payload),

  dropState: ({ commit }) => commit("dropState"),
};

const mutations = {
  setActive: (state, id) => (state.active = id),
  setTitles: (state, titles) => {
    /*
        Сохранение заголовков списка реализаций в store
        */
    state.titles = titles;
  },
  setList: (state, list) => {
    /*
        Сохранение списка реализаций в store, перед сохранением присвоим каждому элементу уникальный идентификатор uuid
        */
    list.forEach((r) => (r.uuid = uuidv4()));
    state.items = list;
  },
  updateItem: (state, { item, uuid }) => {
    /*
        Обновление реализации в списке реализаций
        */
    const index = state.items.findIndex((r) => r.uuid == uuid);
    if (!(index < 0)) {
      Vue.set(state.items, index, item);
    }
  },
  changeProductItemCount: (state, { uuid, count }) => {
    /*
        Изменение кол-ва отгружаемого товара в активной реализации
        */
    const activeItem = state.items.find((r) => r.uuid == state.active);
    const product = activeItem.products.find((p) => p.uuid == uuid);
    if (product) Vue.set(product, "count", count);
  },
  changeProductItemPrice: (state, { uuid, price }) => {
    /*
        Изменение кол-ва отгружаемого товара в активной реализации
        */
    const activeItem = state.items.find((r) => r.uuid == state.active);
    const product = activeItem.products.find((p) => p.uuid == uuid);
    if (product) Vue.set(product, "price", price);
  },
  recalcVat(state, { ndsType, nds }) {
    if (!state.active) return;
    const activeItem = state.items.find((r) => r.uuid == state.active);
    activeItem.products.forEach((product) => {
      if (!product?.nds) product.nds = nds;
      if (`${product.nds}`.includes("%")) {
        product.nds = product.nds.replace("%", "") / 100;
      }

      let VAT = +product.nds / 100;

      if (ndsType == 0) {
        product.total_nds = 0;
      } else if (ndsType == 1) {
        product.total_nds = (+product.count * +product.price * VAT) / (1 + VAT);
      } else {
        product.total_nds = +product.count * +product.price * VAT;
      }
    });
    activeItem.total_nds = activeItem.products.reduce((acc, product) => (acc += +product.total_nds), 0);
  },
  removeProductFromItem: (state, uuid) => {
    /*
        Удаление товара в активной реализации. Если удален последний, удаляется и сама реализация
        */
    const activeItem = state.items.find((r) => r.uuid == state.active);
    Vue.set(
      activeItem,
      "products",
      activeItem.products.filter((p) => p.uuid != uuid)
    );
    if (!activeItem.products.length) {
      state.list = state.list.filter((r) => r.uuid != state.active);
      state.items = state.items.filter((i) => i.uuid != state.active);
      state.active = "";
    }
  },
  newItem(state, { order, checked, currentUser }) {
    /*
        Создание и добавление в список новой реализации
        */

    const products = [...checked]
      .filter((p) => !p?.cancelled)
      .map((p) => ({
        id: p.id,
        uuid: p.uuid,
        count: p.count,
        article: p.article,
        name: p.name,
        content: p.content,
        price: calcDiscountPrice(p),
        stock_id: p.stock_id,
        nds: p.nds,
        total_nds: p.total_nds,
        measure: p.measure,
        stocks: p?.stocks ? p.stocks : [],
        prices: p?.prices ? p.prices : [],
        units: p?.units ? p.units : [],
        custom_fields_values: p?.custom_fields_values ? p.custom_fields_values : [],
      }));

    const item = initRealization(order, products, currentUser);
    Vue.set(state.list, state.list.length, { id: "", uuid: item.uuid, number: item.number });
    Vue.set(state.items, state.items.length, item);
    state.active = item.uuid;
  },
  setValue: (state, { key, value }) => (state[key] = value),
  setFailLoading: (state) => (state.failLoading = true),

  checkFull: (state, { orderPos }) => {
    const item = state.items.find((r) => r.uuid == state.active);
    orderPos.forEach((op) => {
      const product = item.products.find((rp) => rp.uuid == op.uuid);
      if (!product || +product.count < +op.count || +product.count * +product.price < +op.total_price) {
        item.isFull = false;
      }
    });
  },

  dropState: (state) => {
    Vue.set(state, "list", []);
    Vue.set(state, "items", []);
    Vue.set(state, "active", "");
    Vue.set(state, "isLoading", false);
    Vue.set(state, "failLoading", false);
    Vue.set(state, "isLoading", false);
    Vue.set(state, "isLoadingTitles", false);
    Vue.set(state, "isSaving", false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
