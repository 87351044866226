<template>
  <GnzsSection
    :disabled="!tariffFeatures.includes('order-iframe-v2')"
    :disabledText="disabledProSectionText"
    header="1C iFrame 2.0">
    <template v-slot:description>
      При включении данной настройки вместо модального окна детализации заказа будет открываться iframe в 1С
    </template>
    <template v-slot:header-control>
      <GnzsSwitcher v-model="iframe.v2.use" @input="onUseInput($event)"></GnzsSwitcher>
    </template>
    <div v-if="iframe.v2.use">
      <div
        v-for="(user, index) in iframeSetings.v2.users"
        :key="index"
        :class="['gnzs-iframe-user-slot', $style.userContainer]">
        <div
          :id="`slot_{${user.id}}`"
          :class="[
            { [$style.pointer]: activeSlot != user.id },
            'gnzs-iframe-user-slot-wrapper',
            $style.userTitleWrapper,
          ]"
          @click="setActiveSlot(user.id)">
          <div :class="$style.userAvatarName">
            <div :class="$style.avatar" v-if="avatars[user.id].length">
              <div :class="$style.image">
                <img :src="avatars[user.id]" />
              </div>
            </div>

            <div :class="$style.avatar" v-else>
              <div :class="$style.name">
                <p>{{ user.name?.[0] || "" }}</p>
              </div>
            </div>

            <div :class="$style.userTitle">{{ user.name }}</div>
          </div>
          <GnzsButton v-if="activeSlot == user.id" type="remove" @click="removeActiveUser(user.id)" />
          <svg v-else @click="setActiveSlot(user.id)" :class="['gnzs-iframe-user-slot-dots', $style.dots]">
            <use xlink:href="#gnzs-1c-icon__dots"></use>
          </svg>
        </div>
        <div :class="[w.table, $style.margin]" v-if="activeSlot == user.id">
          <div :class="[w.tableRow, w.w105i]">
            <div>Логин 1С</div>
            <div>
              <div :class="w.inputWrapper">
                <GnzsInput :class="[w.input, w.w200]" v-model="user.login" />
              </div>
            </div>
          </div>
          <div :class="w.tableRow">
            <div>Пароль 1С</div>
            <div>
              <div :class="w.inputWrapper">
                <GnzsInput :class="[w.input, w.w200]" type="password" v-model="user.password" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div :class="w.table" v-if="possibleUsers.length">
        <div :class="w.tableRow">
          <div>
            <GnzsDropdown
              :append="true"
              :class="$style.usersDropdown"
              :items="possibleUsers"
              :useEmptyItem="false"
              :isSearchable="true"
              :emptyItemTitle="'Добавить пользователя'"
              @input="addActiveUser($event)"></GnzsDropdown>
          </div>
        </div>
      </div>
    </div>
  </GnzsSection>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";

  import GnzsSection from "@/gnzs-controls/Section";
  import GnzsDropdown from "@/gnzs-controls/Dropdown";
  import GnzsButton from "@/gnzs-controls/Button";
  import GnzsInput from "@/gnzs-controls/Input";
  import GnzsSwitcher from "@/gnzs-controls/Switcher";
  import $ from "jquery";

  export default {
    data() {
      return {
        activeSlot: 0,
      };
    },
    computed: {
      ...mapGetters({
        iframe: "settings/iframe",
        isInsideAmo: "isInsideAmo",
        tariffFeatures: "features/getFeatures",
        disabledProSectionText: "localSettings/disabledProSectionText",
        helpers: "localSettings/helpers",
        users: "amo/users",
        avatars: "avatars",
        iframeSetings: "settings/iframe",
      }),
      possibleUsers() {
        return this.users.filter((user) => !this.iframeSetings.v2.users.map((u) => u.id).includes(user.value));
      },
    },
    methods: {
      ...mapActions({
        addActiveUser: "settings/addIframeUserAction",
        removeActiveUser: "settings/removeIframeUserAction",
      }),
      setActiveSlot(id) {
        this.activeSlot = id;
        $(`#slot_${id}`).focus();
      },
      onUseInput(value) {
        if (value) this.$set(this.iframe, "use", false);
      },
    },
    mounted() {
      $("body").click((e) => {
        if (
          $(e.target).hasClass("gnzs-iframe-user-slot") ||
          $(e.target).hasClass("gnzs-iframe-user-slot-wrapper") ||
          $(e.target).hasClass("gnzs-iframe-user-slot-dots")
        ) {
          return;
        }
        if (!$(e.target).closest(".gnzs-iframe-user-slot").length) {
          this.activeSlot = 0;
        }
      });
    },
    components: {
      GnzsSection,
      GnzsInput,
      GnzsSwitcher,
      GnzsDropdown,
      GnzsButton,
    },
  };
</script>

<style lang="scss" module="w">
  @import "@/assets/scss/Settings/style.scss";
</style>

<style lang="scss" module>
  .dots {
    width: 18px;
    height: 18px;
    fill: #2e3640;
  }

  .pointer {
    cursor: pointer;
  }

  .usersDropdown {
    width: 220px;
    > button {
      background: #fcfcfc !important;
      border: 1px dashed #a4a6a6 !important;
      border-width: 1px 1px 1px !important;
      color: #93a0a2;

      :global(.is-dark) & {
        background: #153043 !important;
        border: 1px dashed white !important;
      }
    }
  }

  .userContainer {
    padding: 10px;
    border: 1px solid #d4d5d8;
    border-radius: 3px;
    width: fit-content;
    margin-bottom: 10px;
    box-shadow: inset 2px 4px 75px -36px rgba(34, 60, 80, 0.07);
  }

  .userTitleWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 460px;
  }

  .userTitle {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1px;
    color: #2e3640;
  }

  .userAvatarName {
    display: flex;
    gap: 6px;
    align-items: center;
  }

  .margin {
    margin-right: 20px;
  }

  .avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;

    img {
      width: 24px;
      height: 24px;
      border-radius: 100%;
      border: 3px solid white;
    }

    .name {
      width: 24px;
      height: 24px;
      border-radius: 100%;
      border: 3px solid white;
      background-color: #4c8bf7;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-weight: 700;
      text-transform: uppercase;

      p {
        font-size: 16px;
      }
    }
  }
</style>
