/**
 * @author Ilya Orishin. 2022
 * 
 * Проверка, что все дополнительные поля в настройка имеют имя
 * 
 * @param {object} settings - обект настроек
 * 
 * @returns {bool} Валидно/не валидно
 */
export default function(settings){
    const orderCfsValid = settings.orderFields.order_custom_fields.every(cf => cf.attribute != '')
    const companyCfsValid = settings.orderFields.company.custom_fields.every(cf => cf.name != '')
    const contactCfsValid = settings.orderFields.contact.custom_fields.every(cf => cf.name != '')
    const leadCfsValid = settings.orderFields.lead.custom_fields.every(cf => cf.name != '')

    return orderCfsValid && companyCfsValid && contactCfsValid && leadCfsValid
}