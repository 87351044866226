<template>
    <div :class="[$style.container, {[$style.container_loading]: isSearchingProducts}]">
        <div :class="$style.background">
            <div :class="$style.iconSearch">
                <svg class=""><use xlink:href="#gnzs-1c-icon__filter-search"></use></svg>
            </div>

            <input 
                :class="$style.input" 
                type="text" 
                placeholder="Поиск и иерархия номенклатуры" 
                value=""
                @input="onInput"
                ref="input"
            />

            <div ref="container" :class="$style.iconContainer">
                
                <div v-if="selectedCatalog && selectedCatalog.id" :class="$style.options">
                    <div :class="$style.optionsCatalog">
                        {{ selectedCatalog.name }}
                    </div>
                    <div :class="$style.optionsRemove" @click="onOptionCloseClick">
                        <svg class="svg-icon svg-common--cross-close-dims"><use xlink:href="#gnzs-1c-icon__common--cross-close"></use></svg>
                    </div>
                </div>

                <div :class="$style.filter__dropdown">
                    <GnzsDropdown
                        :value="searchableStock"
                        :items="stocks"
                        :class="$style.filter"
                        :useEmptyItem="true"
                        @input="toggleStock"
                        emptyItemTitle="Все склады"
                    ></GnzsDropdown>
                </div>

                <GnzsCheckbox 
                    :value="!!showEmptyRemains"
                    :class="[$style.updateSwitcher, $style.noOverflow]"
                    @input="toggleEmptyRemains"
                    label="Скрывать нулевые остатки"
                ></GnzsCheckbox>

                <div :class="$style.iconClose" @click="onCloseClick">
                    <svg class="svg-icon svg-common--cross-close-dims"><use xlink:href="#gnzs-1c-icon__common--cross-close"></use></svg>
                </div>
                <div :class="$style.iconLoader">
                    <span :class="$style.spinner"></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import GnzsCheckbox from '@/gnzs-controls/Checkbox';
import GnzsDropdown from '@/gnzs-controls/Dropdown';
import {mapActions, mapGetters} from 'vuex';

export default {
    data(){
        return {
            inputTimerId: 0,
            waitingMs: 1500,
            minChars: 3,
        }
    },
    computed: {
        ...mapGetters({
            isSearchingProducts: 'products/isSearching',
            selectedCatalog: 'catalogs/selectedItem',
            showEmptyRemains: 'products/showEmptyRemains',
            defaults: 'settings/defaults',
            searchableStock: 'products/searchableStock',
            stocks: 'stocks/dropdownItems',
            organizationId: 'order/organizationId'
        })
    },
    methods: {
        ...mapActions({
            setShowEmptyRemains: 'products/setShowEmptyRemains',
            setSearchableStock: 'products/setSearchableStock',
            setSearchName: 'products/setSearchName',
            search: 'products/search',
            dropItemSelection: 'catalogs/dropItemSelection'
        }),
        onCloseClick(){
            this.$emit("closeClick");
            this.$refs['input'].value = '';
            this.setSearchName('');
        },
        onInput(e){
            const prodName = e.target.value.trim().toLowerCase();
            this.setSearchName(prodName);

            clearTimeout(this.inputTimerId);

            this.inputTimerId = setTimeout(()=>{
                this.search({name: prodName});
            }, this.waitingMs);

        },
        onOptionCloseClick(){
            this.dropItemSelection();
            this.search();
        },
        toggleStock(value){
            this.setSearchableStock(value)
            this.search()
        },
        toggleEmptyRemains(value){
            this.setShowEmptyRemains(value)
            this.search()
        }
    },
    components: {
        GnzsCheckbox,
        GnzsDropdown
    },
    mounted(){
        if (this.defaults.showEmptyRemains){
            this.setShowEmptyRemains(true)
        }
    }
}
</script>


<style lang="scss" module>
    @import './SearchInput.scss';
</style>