export const onlyPositive = (e) => {
  const keyCodes = [189];

  if (keyCodes.includes(e.keyCode)) {
    e.returnValue = false;
  }
};

export const cyrillicKeyDown = (e) => {
  const key = e.key;
  if (/[А-Яа-я]/i.test(key)) {
    e.returnValue = false;
  }
};

export const floatKeyDownNegative = (e) => {
  const keyCodes = [
    37,
    38,
    39,
    40, // стрелки
    46, // delete
    8, // Backspace
    9, // tab
  ];
  const keys = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "-"];

  if (keyCodes.includes(e.keyCode)) return;
  if (e.metaKey && e.key == "a") return; // ctrl + a

  if (e.key == ".") {
    if (e.target.value.includes(".")) {
      e.returnValue = false;
    } else {
      return;
    }
  }

  if (!keys.includes(e.key)) {
    e.returnValue = false;
  }
};

export const floatKeyDown = (e) => {
  const keyCodes = [
    37,
    38,
    39,
    40, // стрелки
    46, // delete
    8, // Backspace
    9, // tab
  ];
  const keys = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

  if (keyCodes.includes(e.keyCode)) return;
  if (e.metaKey && e.key == "a") return; // ctrl + a

  if (e.key == ".") {
    if (e.target.value.includes(".")) {
      e.returnValue = false;
    } else {
      return;
    }
  }

  if (!keys.includes(e.key)) {
    e.returnValue = false;
  }
};
