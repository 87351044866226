/**
 * @author Ilya Orishin. 2022
 * 
 * Функционал подписантов
 */
import { load1cSignatories } from '@/api/1c-api';

 const state = () => ({
     items: [], // Список подписантов
     isLoading: false,
 });
 
 const getters = {
     /*
     Все статусы из 1С (Объект) выступает в качестве айтемс к мультисписку.
     Получаем через api в load
     */
     signatories: state => state.items,

     dropdownItems: (state) => {
		return [
            {
                title: 'От организации',
                value: 'От организации',
                items: state.items.filter(s => s.organization_id).map(s => ({title: s.name, value: s.id}))
            },
            {
                title: 'От контрагента',
                value: 'От контрагента',
                items: state.items.filter(s => s.owner_id).map(s => ({title: s.name, value: s.id}))
            }
        ]
	},
 };
 
 const actions = {
 
     async load({commit, rootGetters}){
         /*
         Загрузка подписантов с сервера
         */
         try{
             commit('setValue', {key: 'isLoading', value: true})

             let {organization_id, company_id, contact_id} = rootGetters['order/orderData'];
             const owner_id = company_id ? company_id : contact_id;

             const resp = await load1cSignatories({organization_id, owner_id});
             commit('setItems', resp.data.result);
         } catch(e) {
             console.debug(`1С ${rootGetters['partnerName']}: Ошибка получения подписантов`, e);
         } finally {
             commit('setValue', {key: 'isLoading', value: false})
         }
     },
 };
 
 const mutations = {
     setItems: (state, items) => state.items = items,
     setValue: (state, {key, value}) => state[key] = value // Установить значение
 };
 
 export default {
     namespaced: true,
     state,
     getters,
     actions,
     mutations,
 };
 