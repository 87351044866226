import { api1C } from './api';

export const create1cContract = (params) => {
    return api1C.post('contracts', params);
}


export const load1cContracts = (params) => {
    return api1C.get(`contracts`, {
        params,
    });
}
    
