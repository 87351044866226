<template>
  <div :class="$style.wrapper">
    <GnzsFilter
      :class="$style.filter"
      @filter-accept="onFilterAccept"
      @filter-cancel="onFilterCancel"
      @filter-item-close="onFilterCancelItem"
      @filter-reload="onFilterReload"
      :is-changed="isFilterChanged"
      :filter-items="getFilterItems">
      <template v-slot>
        <div>
          <DatePicker
            :value="getFilterField('dateRange')"
            :class="$style.filter__dateInput"
            :format="'DD.MM.YYYY'"
            placeholder="За период"
            range
            type="date"
            locale="ru"
            :append-to-body="false"
            @change="setFilterField({key: 'dateRange', value: $event})"
          >
            <template v-slot:icon-calendar>
              <svg :class="$style.calendar">
                <use xlink:href="#card-calendar"></use>
              </svg>
            </template>
          </DatePicker>
        </div>
        <div>
          <GnzsInput
            :value="getFilterField('entityId')"
            :type="`text`"
            :class="$style.filter__input"
            placeholder="ID сделки"
            @input="setFilterField({key: 'entityId', value: $event})"
          />
        </div>
        <div>
          <GnzsDropdown
            :class="$style.filter__dropdown"
            :value="getFilterField('method')"
            :items="getMetaMethod"
            empty-item-title="Все запросы"
            @input="setFilterField({key: 'method', value: $event})"
          />
        </div>
        <div>
          <GnzsDropdownCheckboxes
            :class="$style.filter__dropdownCheckboxes"
            :value="getFilterField('code')"
            :items="getDropdownCode"
            :use-empty-item="false"
            @input="setFilterField({key: 'code', value: $event})"
          />
        </div>
      </template>
    </GnzsFilter>
    <div :class="$style.table">
      <div :class="$style.tableHeader">
        <div :class="$style.tableRow">
          <div :class="$style.cell" data-code="date">Время</div>
          <div :class="$style.cell" data-code="method">Запрос</div>
          <div :class="$style.cell" data-code="entity">Сущность</div>
          <div :class="$style.cell" data-code="user">Пользователь</div>
          <div :class="$style.cell" data-code="code">Результат</div>
        </div>
      </div>
      <div :class="$style.tableBody">
        <RequestRow v-for="request in requests" :request="request" :key="request.id"/>
        <div ref="pagination"/>
        <Preload :is-active="isPreload"/>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex"
import RequestRow from "./tableRow.vue";
import GnzsFilter from '@/gnzs-controls/Filter'
import GnzsInput from '@/gnzs-controls/Input';
import GnzsDropdown from '@/gnzs-controls/Dropdown';
import GnzsDropdownCheckboxes from '@/gnzs-controls/DropdownCheckboxes';
import Preload from "./preload.vue"
import DatePicker from 'vue2-datepicker'
import '@/assets/datepicker/ru';
import '@/assets/datepicker/datepicker.css';

export default {
  computed: {
    ...mapGetters({
      requests: 'journal/getRequests',
      getFilterField: 'journal/getFilterField',
      getDropdownCode: 'journal/getDropdownCode',
      getMetaMethod: 'journal/getMetaMethod',
      isPreload: 'journal/isPreload',
      isFilterChanged: 'journal/isFilterChanged',
      getFilterItems: 'journal/getFilterItems'
    }),
  },
  data: () => ({
    observer: null
  }),
  methods: {
    ...mapActions({
      initPagination: 'journal/initPagination',
      setFilterField: 'journal/setFilterField',
      acceptFilter: 'journal/acceptFilter',
      cancelFilter: 'journal/cancelFilter',
      onFilterCancelItem: 'journal/onFilterCancelItem',
      onFilterReload: 'journal/onFilterReload'
    }),
    async onFilterAccept(){
      await this.acceptFilter()
    },
    async onFilterCancel(){
      await this.cancelFilter()
    }
  },
  mounted() {
    const options = this.options || {};
    this.observer = new IntersectionObserver(([entry]) => {
      if (entry && entry.isIntersecting && entry.intersectionRect.top > 900 ) {
        this.initPagination()
      }
    }, options);

    this.observer.observe(this.$refs.pagination);
  },
  destroyed() {
    this.observer.disconnect();
  },
  components: {
    RequestRow,
    GnzsFilter,
    DatePicker,
    GnzsInput,
    GnzsDropdownCheckboxes,
    GnzsDropdown,
    Preload
  },
}
</script>

<style lang="scss" module>
@import "./style.scss";
</style>
