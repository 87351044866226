import {testService} from '@/api/1c-api';
import validateUrl from '@/utilities/serviceAddressValidator';

import Vue from 'vue';

export const serviceTestState = {
    serviceTest: {
        loading: true,
        errorText: '',
        header: 'Проверка сервиса...',
        body: {}
    }
}

export const serviceTestGetters = {
    serviceTestLoading: state => state.serviceTest.loading,
    serviceTestHeader: state => state.serviceTest.header,
    serviceTestBody: state => state.serviceTest.body,
    serviceTestErrorText: state => state.serviceTest.errorText
}

export const serviceTestActions = {
    async test({commit, getters}){

        const res = validateUrl(getters.auth.url1c)
        if (!res.valid){
            commit('setServiceTestValue', {key: 'header', value: 'Ошибка доступа в 1С'})
            commit('setServiceTestValue', {key: 'errorText', value: res.error})
            commit('setServiceTestValue', {key: 'loading', value: false})
            return
        }

        try {
            commit('setServiceTestValue', {key: 'loading', value: true})
            const response = await testService()

            if (response.data?.status !== '200 Ok'){
                commit('setServiceTestValue', {key: 'header', value: 'Ошибка доступа в 1С'})
                commit('setServiceTestValue', {key: 'errorText', value: response.data.message})
            } else {
                commit('setServiceTestValue', {key: 'header', value: 'Сервис успешно подключен'})
                let body = {}
                Object.entries(response.data).forEach(([k,v]) => {
                    if (k !== 'status'){
                        body[k] = v
                    }
                })
                commit('setServiceTestValue', {key: 'body', value: body})
            }
        } catch (e){
            console.debug('Ошибка проверки сервиса',{e})
        } finally {
            commit('setServiceTestValue', {key: 'loading', value: false})
        }
    },
}

export const serviceTestMutations = {
    setServiceTestValue: (state, {key, value}) =>{
        Vue.set(state.serviceTest, key, value)
    }
}