import {realization1c} from '@/api/1c-api';
import {showModal} from '@/utilities/order';

const state = () => ({
    saving: false,
});

const getters = {
    savingRealization: (state) => state.saving
};

const actions = {
    async saveRealization({dispatch, commit, rootState, rootGetters}) {
        commit('setSaving1c', true);
        try {
            await dispatch('order/updateOrderByEntityData', null, {root: true});
            const orderData  = rootState.order.data;

            const currentUser = rootGetters['amoUser']

            const resp1c = await realization1c({
                lead_id: orderData.lead_id,
                company_custom_fields: orderData.company_custom_fields,
                contact_custom_fields: orderData.contact_custom_fields,
                lead_custom_fields: orderData.lead_custom_fields,
                current_user_id: `${currentUser.id}`,
                current_user_name: `${currentUser.name}`,
                current_user_phone: `${currentUser.phone}`,
                current_user_emall: `${currentUser.email}`,
            });
            if (!resp1c.status == 200) throw 'Ошибка проведения реализации в 1С';

            dispatch('order/processRealizationElement', resp1c.data, {root: true});
            await dispatch('order/save', true, {root: true});
        } catch (e) {
            console.debug(`1С ${rootGetters['partnerName']}: ошибка сохранения заказа`, {e});

            if (e.response?.data?.error){
                showModal('Ошибка проведения реализации', `<div>${e.response.data.error}</div>`)
            }

            const errorDataMsg = e.response?.data?.message?.length
                ? ' - ' + e.response?.data?.message?.length
                : '';

            const errorText = e.response // eslint-disable-line no-unused-vars
                ? e.response.data.body?.request?.error.length 
                    ? e.response.data.body?.request?.error
                    : `[${e.response.status}]: ${e.response.statusText} ${errorDataMsg}`
                : e.message;

        } finally {
            commit('setSaving1c', false);
        }
      
    },
};

const mutations = {
    setSaving1c(state, payload = true){
        state.saving = payload;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}