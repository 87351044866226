<template>
    <div :style="{background: partnerData.background }" :class="$style.header" v-once>
        <svg :class="$style.headerLogo">
            <use :xlink:href="partnerData.logo"></use>
        </svg>
        <span :class="$style.headerTitle">{{ partnerData.name.toUpperCase() }}: 1С интеграция</span>

        <a :class="$style.instruction" :href="partnerData.guide" target="_blank">
            Инструкция
        </a>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    computed: {
        ...mapGetters({
            partnerData: 'partnerData',
        }),
    }
}
</script>

<style lang="scss" module>
    @import './style.scss';
</style>