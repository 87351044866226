/**
 * @author Ilya Orishin. 2023
 * 
 * Заполнение связей справочников
 */

import { getCfValue } from "./amo";
import arrToQueryParams from "./arrToQueryParams";

/**
 * Функция получения взаимосвязей одних справочников от других Возвращает строку url двумерный массив
 * 
 * @param {string} qs - строка url
 * @param {object} relations - массив связей справочников
 * @param {object} amoCompany - компания
 * @param {object} amoContact - контакт
 * @param {object} orderCfs - дополнительные поля заказа
 * 
 * @returns {string} qs - строка url
 * 
 */
export default function(qs, relations, amoCompany, amoContact, orderCfs){
    
    if (!relations) return qs

    const qps = []

    relations.forEach(relation => {
        if (amoContact && relation?.cf1){
            const id = getCfValue(amoContact, +relation.cf1)
            if (id){
                qps.push({
                    code: relation.code,
                    type: relation.type,
                    id
                })
            }
        } else if (amoCompany && relation?.cf3){
            const id = getCfValue(amoCompany, +relation.cf3)
            if (id){
                qps.push({
                    code: relation.code,
                    type: relation.type,
                    id
                })
            }
        } else {
            const id = orderCfs.find(e => e.code == relation.code && e.type == relation.type)?.value
            if (id){
                qps.push({
                    code: relation.code,
                    type: relation.type,
                    id
                })
            }
        }
    })

    qs = arrToQueryParams(qps, 'relations', qs)
    return qs
}