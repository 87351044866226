// Универсальный модуль для iframe
const state = () => ({
  iframeName: null,
  windowWidth: null,
  windowHeight: null,
  asaidIsActive: false,
})

const getters = {
  iframeName: (state) => state.iframeName,
  parentIframeName: (state) => state.parentIframeName,
  windowWidth: (state) => state.windowWidth,
  asaidIsActive: (state) => state.asaidIsActive,
  isSmallSizeWindow: (state) => state.windowWidth <= 1100 && state.windowWidth !== null,
}

const actions = {
  setIframeName: ({ commit }, name) => commit('setIframeName', name),
  setParentIframeName: ({ commit }, name) => commit('setParentIframeName', name),
  setWindowSize: ({ commit }, { width, height }) => {
    const toggleValue = width <= 1100 ? false : true;
    commit("setAsaidToggle", toggleValue)
    commit("setWindowWidth", width);
    commit("setWindowHeight", height);
  },

  resizeWindow: ({ state, dispatch }) => {
    if (window.self === window.top) {
      const width = document.documentElement.clientWidth;
      const height = document.documentElement.clientHeight;
      dispatch("setWindowSize", { width, height });
    } else {
      window.parent.postMessage({ event: `${state.iframeName}:getWindowSize` }, "*");
    }
  },
  showSidebar: ({ state }) => window.parent.postMessage({ event: `${state.iframeName}:showSidebar` }, "*"),

  asaidToggle: ({ state, commit }) => {
    const toggleValue = state.asaidIsActive ? false : true;
    commit("setAsaidToggle", toggleValue);
  },

  openConfirmModal: ({ state }, { templateName, templateId }) => window.parent.postMessage({
    event: `${state.iframeName}:openConfirmModal`,
    data: { elementName: templateName, elementId: templateId },
  }, "*"),
}

const mutations = {
  setIframeName: (state, name) => state.iframeName = name,
  setParentIframeName: (state, name) => state.parentIframeName = name,
  setWindowWidth: (state, width) => state.windowWidth = width,
  setWindowHeight: (state, height) => state.windowHeight = height,
  setAsaidToggle: (state, toggleValue) => state.asaidIsActive = toggleValue,
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
}