<template>
  <Modal ref="modal">
    <template v-slot>
      <div :class="$style.container">
        <span class="modal-body__close" ref="close">
          <span class="icon icon-modal-close"></span>
        </span>

        <div :class="$style.header">Добавление позиции</div>
        <div :class="$style.productName">
          {{ productData.name }}
        </div>
        <div :class="$style.body">
          <!-- Количество -->
          <div :class="$style.row">
            <div :class="$style.cell">Количество</div>
            <div :class="$style.cell" data-code="count" ref="controlCount">
              <GnzsInput type="number" min="1" tabindex="" v-model="count" @keydown="onCountKeyDown" />
            </div>
          </div>
          <!-- Цена -->
          <div :class="$style.row">
            <div :class="$style.cell">Цена</div>
            <div :class="$style.cell">
              <GnzsInputHints
                v-model="price"
                :hints="hintPrices"
                :disabled="defaults.disablePriceChangeOnAdd"
                @input="onChangePrice"
                @keydown="onPriceKeyDown"
                @hintClick="onPriceTypeClick" />
            </div>
          </div>
          <!-- Склад -->
          <div v-if="!isOneStockOrder && stocks.length" :class="$style.row">
            <div :class="$style.cell">Склад</div>
            <div :class="$style.cell">
              <GnzsDropdown v-model="stockId" :use-empty-item="false" :items="stocks" />
            </div>
          </div>
        </div>
        <div :class="$style.actions">
          <GnzsButton type="primary" @click="onAddProductHandler">Добавить</GnzsButton>
          <GnzsButton type="cancel" @click="closeModal">Отмена</GnzsButton>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
  import { mapActions, mapGetters } from "vuex";

  import Modal from "@/gnzs-controls/Modal";

  import GnzsInput from "@/gnzs-controls/Input";
  import GnzsInputHints from "@/gnzs-controls/InputHints";
  import GnzsDropdown from "@/gnzs-controls/Dropdown";
  import GnzsButton from "@/gnzs-controls/Button";

  import { getPricesHints, getStockDropdownItems } from "@/utilities/orderProduct";
  import { floatKeyDown } from "@/utilities/inputUtils";

  export default {
    data() {
      return {
        count: 1,
        price: 0,
        priceTypeId: "",
        stockId: "",
      };
    },
    computed: {
      ...mapGetters({
        defaults: "settings/defaults",
        productData: "products/itemToAdd",
        isOneStockOrder: "settings/isOneStockOrder",
        defaultStockId: "settings/stockId",
        defaultPriceTypeId: "settings/priceTypeId",
        productCount: "settings/productCount",
        agreementPtId: "agreements/getPriceTypeId",
        tariffFeatures: "features/getFeatures",
      }),
      hintPrices() {
        if (!this.productData) return [];

        const filterPrices =
          this.defaults.isAgreementsEnabled &&
          this.defaults.showAgreementsPrices &&
          this.tariffFeatures.includes("prices-for-agreement");

        let hints = getPricesHints(this.productData);

        if (filterPrices) {
          const ptId = this.agreementPtId(this.agreementId);
          if (ptId) {
            hints = hints.filter((h) => h.id == ptId);
          }
        }

        return hints;
      },
      stocks() {
        return getStockDropdownItems(this.productData);
      },
    },
    methods: {
      ...mapActions({
        addProductData: "products/addProductData",
        addProduct: "order/addProduct",
      }),
      addProductToOrder(payload) {
        this.addProductData(payload.productData);
        this.addProduct(payload);
      },
      closeModal() {
        this.$refs.modal.close();
      },
      onCountKeyDown(e) {
        if (e.key == "Enter") {
          this.addProductToOrder();
        }
      },
      onChangePrice() {
        this.priceTypeId = "";
      },
      onPriceKeyDown(e) {
        floatKeyDown(e);
        if (e.key == "Enter") {
          this.addProductToOrder();
          return;
        }
      },
      onPriceTypeClick(priceObj) {
        this.priceTypeId = priceObj.id;
      },
      onAddProductHandler() {
        this.addProductToOrder({
          productData: this.productData,
          count: this.count,
          price: this.price,
          priceTypeId: this.priceTypeId,
          stockId: this.stockId,
        });
        this.closeModal();
      },
    },
    created() {
      this.stockId = this.defaultStockId;
      this.count = this.productCount;

      if (this.defaultPriceTypeId.length) {
        const priceData = this.productData.prices.find((p) => p.id == this.defaultPriceTypeId);

        if (priceData) {
          this.priceTypeId = priceData.id;
          this.price = priceData.cost;
        }
      }
    },
    mounted() {
      const inputCount = this.$refs["controlCount"].querySelector("input");
      inputCount.focus();
    },
    components: {
      Modal,
      GnzsInput,
      GnzsInputHints,
      GnzsDropdown,
      GnzsButton,
    },
  };
</script>

<style lang="scss" module>
  @import "./AddProductModal.scss";
</style>
