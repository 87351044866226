<template>
    <div>
        <div style="padding: 10px">
            <div style="display: flex; gap: 30px; height: 20px;">
            <div :class="$style.preloaderAnimation" style="padding: 7px 10px; width: 138px;">&nbsp;</div>
            <div :class="$style.preloaderAnimation" style="padding: 7px 10px; width: 116px;">&nbsp;</div>
            <div :class="$style.preloaderAnimation" style="padding: 7px 10px; width: 134px;">&nbsp;</div>
            <div :class="$style.preloaderAnimation" style="padding: 7px 10px; width: 112px;">&nbsp;</div>
            <div :class="$style.preloaderAnimation" style="padding: 7px 10px; width: 182px;">&nbsp;</div>
            </div>
        </div>
    <GnzsSection>
        <template v-slot:header>
            <div :class="$style.preloaderAnimation" style="height: 23px; width: 100px;">&nbsp;</div>
        </template>
        <div :class="w.table">
            <div :class="w.tableRow">
                <div><div :class="$style.preloaderAnimation" style="height: 19px; width: 170px;">&nbsp;</div></div>
                <div><div style="display: flex; gap: 5px; align-items: center;">
                    <div :class="$style.preloaderAnimation" style="border-radius: 3px; height: 32px; width: 400px;">&nbsp;</div>
                    <div :class="$style.preloaderAnimation" style="border-radius: 3px; height: 24px; width: 140px;">&nbsp;</div>
                </div></div>
            </div>
            <div :class="w.tableRow">
                <div><div :class="$style.preloaderAnimation" style="height: 19px; width: 180px;">&nbsp;</div></div>
                <div><div style="display: flex; gap: 5px; align-items: center;">
                <div :class="$style.preloaderAnimation" style="border-radius: 3px; height: 32px; width: 400px;">&nbsp;</div>
                <div :class="$style.preloaderAnimation" style="border-radius: 3px; height: 24px; width: 85px;">&nbsp;</div>
                </div></div>
            </div>
            <div v-if="false" :class="w.tableRow">
                <div><div :class="$style.preloaderAnimation" style="height: 19px; width: 190px;">&nbsp;</div></div>
                <div><div style="display: flex; gap: 5px; align-items: center;">
                <div :class="$style.preloaderAnimation" style="border-radius: 3px; height: 32px; width: 400px;">&nbsp;</div>
                <div :class="$style.preloaderAnimation" style="border-radius: 3px; height: 24px; width: 118px;">&nbsp;</div>
                </div></div>
            </div>
            <div :class="w.tableRow">
                <div><div :class="$style.preloaderAnimation" style="height: 19px; width: 200px;">&nbsp;</div></div>
                <div><div :class="$style.preloaderAnimation" style="border-radius: 3px; height: 32px; width: 200px;">&nbsp;</div></div>
            </div>
            <div :class="w.tableRow">
                <div><div :class="$style.preloaderAnimation" style="height: 19px; width: 200px;">&nbsp;</div></div>
                <div><div :class="$style.preloaderAnimation" style="border-radius: 3px; height: 32px; width: 400px;">&nbsp;</div></div>
            </div>
            <div :class="w.tableRow">
                <div><div :class="$style.preloaderAnimation" style="height: 19px; width: 200px;">&nbsp;</div></div>
                <div><div :class="$style.preloaderAnimation" style="border-radius: 3px; height: 32px; width: 400px;">&nbsp;</div></div>
            </div>
        </div>
    </GnzsSection>

    <GnzsSection>
        <template v-slot:header>
            <div :class="$style.preloaderAnimation" style="height: 23px; width: 202px;">&nbsp;</div>
            <div :class="$style.preloaderAnimation" style="margin-top: 5px; height: 19px; width: 600px;">&nbsp;</div>
        </template>
        <div :class="w.table">
            <div :class="w.tableRow">
                <div><div :class="$style.preloaderAnimation" style="height: 19px; width: 100px;">&nbsp;</div></div>
                <div><div :class="$style.preloaderAnimation" style="border-radius: 3px; height: 32px; width: 200px;">&nbsp;</div></div>
            </div>
            <div :class="w.tableRow">
                <div><div :class="$style.preloaderAnimation" style="height: 19px; width: 90px;">&nbsp;</div></div>
                <div><div :class="$style.preloaderAnimation" style="border-radius: 3px; height: 32px; width: 200px;">&nbsp;</div></div>
            </div>
            <div :class="w.tableRow">
                <div><div :class="$style.preloaderAnimation" style="height: 19px; width: 210px;">&nbsp;</div></div>
                <div><div :class="$style.preloaderAnimation" style="border-radius: 3px; height: 32px; width: 60px;">&nbsp;</div></div>
            </div>
            <div :class="w.tableRow">
                <div><div :class="$style.preloaderAnimation" style="height: 19px; width: 90px;">&nbsp;</div></div>
                <div><div :class="$style.preloaderAnimation" style="border-radius: 3px; height: 32px; width: 435px;">&nbsp;</div></div>
            </div>
            <div :class="w.tableRow">
                <div><div :class="$style.preloaderAnimation" style="height: 19px; width: 70px;">&nbsp;</div></div>
                <div><div :class="$style.preloaderAnimation" style="border-radius: 3px; height: 32px; width: 200px;">&nbsp;</div></div>
            </div>
            <div :class="w.tableRow">
                <div><div :class="$style.preloaderAnimation" style="height: 19px; width: 180px;">&nbsp;</div></div>
                <div><div :class="$style.preloaderAnimation" style="border-radius: 3px; height: 32px; width: 200px;">&nbsp;</div></div>
            </div>
            <div :class="w.tableRow">
                <div><div :class="$style.preloaderAnimation" style="height: 19px; width: 220px;">&nbsp;</div></div>
                <div><div :class="$style.preloaderAnimation" style="border-radius: 3px; height: 32px; width: 60px;">&nbsp;</div></div>
            </div>
            <div :class="w.tableRow">
                <div><div :class="$style.preloaderAnimation" style="height: 19px; width: 120px;">&nbsp;</div></div>
                <div><div :class="$style.preloaderAnimation" style="border-radius: 3px; height: 32px; width: 200px;">&nbsp;</div></div>
            </div>
            <div :class="w.tableRow">
                <div><div :class="$style.preloaderAnimation" style="height: 19px; width: 360px;">&nbsp;</div></div>
                <div><div :class="$style.preloaderAnimation" style="border-radius: 3px; height: 32px; width: 200px;">&nbsp;</div></div>
            </div>
            <div :class="w.tableRow">
                <div><div :class="$style.preloaderAnimation" style="height: 19px; width: 400px;">&nbsp;</div></div>
                <div><div :class="$style.preloaderAnimation" style="border-radius: 3px; height: 32px; width: 23px;">&nbsp;</div></div>
            </div>
        </div>
    </GnzsSection>

    <GnzsSection>
        <template v-slot:header>
            <div :class="$style.preloaderAnimation" style="height: 23px; width: 330px;">&nbsp;</div>
            <div :class="$style.preloaderAnimation" style="margin-top: 5px; height: 19px; width: 380px;">&nbsp;</div>
        </template>
        <div :class="w.table">
            <div :class="w.tableHeader">
                <div><div :class="$style.preloaderAnimation" style="height: 15px; width: 120px;">&nbsp;</div></div>
                <div><div :class="$style.preloaderAnimation" style="height: 15px; width: 150px;">&nbsp;</div></div>
                <div><div :class="$style.preloaderAnimation" style="height: 15px; width: 190px;">&nbsp;</div></div>
                <div></div>
            </div>
            <div :class="w.tableRow">
                <div><div :class="$style.preloaderAnimation" style="height: 19px; width: 150px;">&nbsp;</div></div>
                <div><div :class="$style.preloaderAnimation" style="border-radius: 3px; height: 32px; width: 200px;">&nbsp;</div></div>
                <div><div :class="$style.preloaderAnimation" style="border-radius: 3px; height: 32px; width: 200px;">&nbsp;</div></div>
                <div><div :class="$style.preloaderAnimation" style="height: 20px; width: 17px;">&nbsp;</div></div>
            </div>
            <div :class="w.tableRow">
                <div><div :class="$style.preloaderAnimation" style="height: 19px; width: 180px;">&nbsp;</div></div>
                <div><div :class="$style.preloaderAnimation" style="border-radius: 3px; height: 32px; width: 200px;">&nbsp;</div></div>
                <div><div :class="$style.preloaderAnimation" style="border-radius: 3px; height: 32px; width: 200px;">&nbsp;</div></div>
                <div><div :class="$style.preloaderAnimation" style="height: 20px; width: 17px;">&nbsp;</div></div>
            </div>
            <div :class="w.tableRow">
                <div><div :class="$style.preloaderAnimation" style="height: 19px; width: 213px;">&nbsp;</div></div>
                <div><div :class="$style.preloaderAnimation" style="border-radius: 3px; height: 32px; width: 200px;">&nbsp;</div></div>
                <div><div :class="$style.preloaderAnimation" style="border-radius: 3px; height: 32px; width: 200px;">&nbsp;</div></div>
                <div><div :class="$style.preloaderAnimation" style="height: 20px; width: 17px;">&nbsp;</div></div>
            </div>
        </div>
    </GnzsSection>
    </div>
</template>

<script>
import GnzsSection from '@/gnzs-controls/Section';

export default {
    components: {
        GnzsSection
    }
}
</script>


<style lang="scss" module="w">
    @import '@/assets/scss/Settings/style.scss';
</style>

<style lang="scss" module>
    @import './style.scss';
</style>