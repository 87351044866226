<template>
    <Modal ref="modal" :width="modalWidth" :loaded="isModalLoaded" @decline="onDecline">
        <template v-slot>
            <div v-if="errorOpenModal">
                <div :class="$style.header">
                    Ошибка
                </div>

                <div>{{ errorOpenModal }}</div>
            </div>
            <div v-if="isModalLoaded && !errorOpenModal" :class="$style.wrapper">
                <span class="modal-body__close" ref="close">
                <span class="icon icon-modal-close"></span>
                </span>

                <div :class="$style.header">
                    Создать элемент справочника
                </div>

                <div>
                    <Tabs :tabs="tabs">
                        <div :gnzs-tab-id="tabs[0].id">
                            <div :class="$style.gridWrapper">
                                <div :class="$style.row">
                                    <div :class="$style.title">Наименование</div>
                                    <div :class="$style.nameWrapper"><GnzsInput :value="itemName" @input="changeItemName" /></div>
                                </div>
                                <div :class="$style.row" v-for="(item, index) in requisites" :key="index">
                                    <div :class="$style.title">{{ item.name }}</div>
                                    <CustomField :param="item" />
                                </div>
                            </div>
                        </div>

                        <div v-for="(item, index) in tabs.slice(1)" :key="`j_${index}`" :gnzs-tab-id="item.id">
                            <table :class="$style.table">
                                <thead :class="$style.tableHeader">
                                <tr :class="$style.tableRow">
                                    <th v-for="(column, tIndex) in item.columns" :key="`t_${tIndex}`" :class="$style.cell">
                                        <div :class="$style.cellInner">
                                            {{ column.name }}
                                        </div>
                                    </th>
                                </tr>
                                </thead>

                                <tbody :class="$style.tableBody">
                                    <tr v-for="(row, rowIndex) in item2addTable(item.attribute)" :key="`k_${rowIndex}`" :class="$style.tableRow">
                                        <td v-for="(column, columnIndex) in row" :key="`u_${columnIndex}`" :class="$style.cell" data-code="number">
                                            <div :class="$style.cellInnerFlex">
                                                <TableCustomField :param="column" :table="item.attribute" :index="rowIndex" />
                                                <GnzsButton v-if="columnIndex == row.length - 1" :class="$style.buttonRemove" @click="removeItemTableRow({attribute: item.attribute, index: rowIndex})" type="remove"></GnzsButton>
                                            </div>
                                        </td>
                                    </tr>

                                </tbody>

                            </table>

                            <GnzsButton 
                                :class="$style.buttonOpen"
                                @click="addTableRow(item.name)"
                            >Добавить строку</GnzsButton>
                        </div>
                    </Tabs>
                </div>

                <div :class="$style.actions">
                    <GnzsButton 
                        type="primary" 
                        :loading="isCreating"
                        @click="apply"
                        :disabled="!isCreateEnabled"
                    >Создать</GnzsButton>
                    <GnzsButton type="cancel" @click="closeModal">Отмена</GnzsButton>
                </div>
            </div>
        </template>
    </Modal>
</template>

<script>

import Modal from '@/gnzs-controls/Modal';
import Tabs from '@/gnzs-controls/Tabs'
import GnzsButton from '@/gnzs-controls/Button';
import GnzsInput from '@/gnzs-controls/Input';
import CustomField from './CustomField.vue';
import {mapActions, mapGetters} from 'vuex';
import TableCustomField from './TableCustomField.vue';

export default {
    computed: {
        ...mapGetters({
            isCreating: 'additionalTypes/isCreating',
            isAppliableDiscount: 'order/isAppliableDiscount',
            generalDiscount: 'order/generalDiscount',
            rules: 'additionalTypes/newItemRules',
            item2add: 'additionalTypes/item2add',
            item2addTable: 'additionalTypes/item2addTable',
            itemName: 'additionalTypes/itemName',
            isModalLoaded: 'additionalTypes/isAddModalLoaded',
            errorOpenModal: 'additionalTypes/errorOpenModal',
            isCreateEnabled: 'additionalTypes/isCreateEnabled'
        }),
        tableParts(){
            return this.rules(this.item2add).filter(crf => crf.type == 'table')
        },
        requisites(){
            return this.rules(this.item2add).filter(crf => crf.type != 'table')
        },
        tabs(){
            const tables = this.tableParts.map(tp => ({...tp, id: tp.attribute, title: tp.name}))
            return [{id: 'requisites', title: 'Реквизиты'}, ...tables]
        },
        modalWidth(){
            return this.errorOpenModal ? '400px' : '800px';
        }
    },
    methods: {
        ...mapActions({
            addTableRow: 'additionalTypes/addItemTableRow',
            setNewItem: 'additionalTypes/setNewItem',
            removeItemTableRow: 'additionalTypes/removeItemTableRow',
            createElement: 'additionalTypes/create',
            changeItemName: 'additionalTypes/changeItemName',
            onModalOpened: 'additionalTypes/onModalOpened'
        }),
        async apply(){
            await this.createElement()
            this.closeModal();
        },
        closeModal(){
            this.$refs.modal.close()
        },
        onDecline(){
            this.$emit('close')
        }
    },
    mounted(){
        this.onModalOpened()
    },
    components: {
        Modal,
        GnzsButton,
        GnzsInput,
        Tabs,
        CustomField,
        TableCustomField
    }
}
</script>

<style lang="scss" module>
    @import './AddDirectoryElementModal.scss';
</style>