<template>
  <div :class="$style.container">
    <button
      v-for="item in items"
      :key="item.value"
      :class="[$style.button, { [$style.button_active]: item.value == value }]"
      @click="onClick(item.value)"
    >
      {{ item.title }}
    </button>
  </div>
</template>

<script>
export default {
  name: "NavToggle",
  props: {
    items: {
      type: Array,
      default: () => [], // [ {title: 'День', value: 'day'}, ... ]
    },
    value: {
      type: String,
      default: '',
    }
  },
  methods: {
    onClick(value){
      this.$emit("input", value);
    }
  }
}
</script>

<style lang="scss" module>
.container{
  display: inline-flex;
  height: inherit;
  border-radius: 4px;
}

.button{
  padding: 8px 10px;
  display: flex;
  align-items: center;
  background: none;
  font-size: 14px;
  position: relative;
  font-weight: 700;
  line-height: 14px;
  outline: 0;
  color: black;
  transition: color .2s ease;
  cursor: pointer;
  border-top: 1px solid #dbdedf;
  border-right: 1px solid #dbdedf;
  border-bottom: 1px solid #dbdedf;
  &:first-child{
    border-radius: 3px 0 0 3px;
    border-left: 1px solid #dbdedf;
  }

  &:last-child{
    border-radius: 0 3px 3px 0;
  }

  &_active{
    background: #ececec;
  }

  &:active{
    transform: translateY(1px);
  }
}
</style>