<template>
    <Modal ref="modal" width="500px" :loaded="isModalLoaded" @decline="onDecline">
        <template v-slot>
            <div v-if="errorOpenModal">
                <div :class="$style.header">
                    Ошибка
                </div>

                <div>{{ errorOpenModal }}</div>
            </div>
            <div v-if="isModalLoaded && !errorOpenModal" :class="$style.wrapper">
                <span class="modal-body__close" ref="close">
                <span class="icon icon-modal-close"></span>
                </span>

                <div :class="$style.header">
                    Редактировать элемент справочника
                </div>

                <div>
                    <div v-if="item2edit" :class="$style.gridWrapper">
                            <div :class="$style.row">
                                <div :class="$style.title">id</div>
                                <div :class="$style.nameWrapper"><GnzsInput :value="item2edit.id" :disabled="true" /></div>
                            </div>
                            <div :class="$style.row">
                                <div :class="$style.title">Код</div>
                                <div :class="$style.nameWrapper"><GnzsInput :value="item2edit.code" :disabled="true" /></div>
                            </div>
                            <div :class="$style.row">
                                <div :class="$style.title">Тип</div>
                                <div :class="$style.nameWrapper"><GnzsInput :value="item2edit.type" :disabled="true" /></div>
                            </div>
                            <div :class="$style.row">
                                <div :class="$style.title">Наименование</div>
                                <div :class="$style.nameWrapper"><GnzsInput :value="item2edit.name" @input="editElementName" /></div>
                            </div>
                    </div>
                    <div v-else>Произошла ошибка редактирования элемента</div>
                </div>

                <div :class="$style.actions">
                    <GnzsButton 
                        type="primary" 
                        :loading="isSaving"
                        @click="apply"
                        :disabled="!item2editChanged"
                    >Сохранить</GnzsButton>
                    <GnzsButton type="cancel" @click="closeModal">Отмена</GnzsButton>
                </div>
            </div>
        </template>
    </Modal>
</template>

<script>
import GnzsButton from '@/gnzs-controls/Button';
import GnzsInput from '@/gnzs-controls/Input';
import Modal from '@/gnzs-controls/Modal';
import {mapActions, mapGetters} from 'vuex';

export default {
    computed: {
        ...mapGetters({
            item2edit: 'additionalTypes/item2edit',
            isSaving: 'additionalTypes/isSaving',
            errorOpenModal: 'additionalTypes/errorOpenModal',
            isModalLoaded: 'additionalTypes/isEditModalLoaded',
            item2editChanged: 'additionalTypes/item2editChanged'
        })
    },
    methods: {
        ...mapActions({
            editElement: 'additionalTypes/edit',
            editElementName: 'additionalTypes/editElementName'
        }),
        async apply(){
            await this.editElement()
            this.closeModal();
        },
        closeModal(){
            this.$refs.modal.close()
        },
        onDecline(){
            this.$emit('close')
        }
    },
    components: {
        GnzsButton,
        GnzsInput,
        Modal
    }
}
</script>

<style lang="scss" module>
    @import './EditDirectoryElementModal.scss';
</style>