<template>
  <div>
    <div :class="$style.tableRow" @click="onRequestInfoClick">
      <div :class="$style.tableRowcell" data-code="date">
        <div :class="$style.detailInfo__wrapper">
          <div>{{ request.date }}</div>
        </div>
      </div>
      <div :class="$style.tableRowcell" data-code="method">{{ request.method }}</div>
      <div :class="$style.tableRowcell" data-code="entity">
        <div :class="$style.tableColumInfo" v-if="request.entity_id">
          <a ref="link" :class="$style.tableColumInfo__link" :href="entityType.link" target="_blank">{{entityType.name}} {{request.entity_id}}</a>
        </div>
      </div>
      <div :class="$style.tableRowcell" data-code="user">
        <template v-if="user">
          <span :class="$style.user">{{user.name}}</span>
        </template>
      </div>
      <div :class="$style.tableRowcell" data-code="code">
        <div :class="[$style.code, $style[`code_${code.color}`]]">{{ code.name }}</div>
      </div>
    </div>
    <div :class="$style.info" v-if="request.infoActive">
      <NavToggle @input="tabClick" :items="tabs" :value="tabsActive"/>
      <template v-if="tabsActive === 'log'">
        <div :class="$style.infoLog">
          <div :class="$style.infoLog__row" v-for="item in request.log" :key="item.id">
            <div>{{ item.time }}</div><div>{{ item.message }}</div>
          </div>
        </div>
      </template>
      <template v-if="tabsActive === 'body'">
        <div :class="$style.jsonEditor">
          <JsonViewer theme="json-theme" :expand-depth="10" :value="body"/>
        </div>
      </template>
      <template v-if="tabsActive === 'result'">
        <div :class="$style.jsonEditor">
          <JsonViewer theme="json-theme" :expand-depth="10" :value="result"/>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import JsonViewer from 'vue-json-viewer'
import NavToggle from '@/gnzs-controls/NavToggle'
import './json-theme.scss';

export default {
  data() {
    return {
      tabsActive: "log",
      tabs: [
        {value: "log", title: "Лог действий"},
        {value: "body", title: "Тело запроса"},
        {value: "result", title: "Ответ"}
      ],
    }
  },
  props: {
    request: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters({
      accountData: 'accountData',
    }),
    code() {
      return {
        color: this.$props.request.code < 300 ? 'success' : 'error',
        name: this.$props.request.code < 300 ? 'Обработан' : 'Ошибка'
      }
    },
    user(){
      if(this.request.user_id){
        return this.accountData.amoUsers.find(v => v.id == this.request.user_id)
      }
      return null
    },
    entityType(){
      let path = `https://${this.accountData.amoSubdomain}.amocrm.${this.accountData.amoTopLevelDomain}`
      if(this.request.entity_type == 1){
        return {
          name: 'Контакт',
          link: `${path}/contacts/detail/${this.request.entity_id}`
        }
      }
      if(this.request.entity_type == 2){
        return {
          name: 'Сделка',
          link: `${path}/leads/detail/${this.request.entity_id}`
        }
      }
      if(this.request.entity_type == 3){
        return {
          name: 'Компания',
          link: `${path}/companies/detail/${this.request.entity_id}`
        }
      }
      return null
    },
    body(){
      return this.request.body ? JSON.parse(this.request.body) : {}
    },
    result(){
      return this.request.result ? JSON.parse(this.request.result) : {}
    }
  },
  methods: {
    ...mapActions({
      actionRequestInfo: 'journal/actionRequestInfo'
    }),
    async onRequestInfoClick(e) {
      if(this.$refs?.link && this.$refs.link.contains(e.target)) return;
      await this.actionRequestInfo({request_id: this.request.id})
    },
    tabClick(value){
      this.tabsActive = value
    }
  },
  components: {
    JsonViewer,
    NavToggle
  },
}
</script>

<style lang="scss" module>
@import "./style.scss";
</style>

<style lang="scss" module="w">
@import './style.scss';
</style>
