<template>
  <div :class="$style.footer">
    <div :class="$style.wrapper" v-if="tabMode != 1">
      <div :class="$style.leftBlockWrapper">
        <div v-if="defaults.isDescriptionEnabled">
          <div :class="$style.commentTitle">Комментарий</div>
          <textarea :class="$style.description" v-model="orderDescription"></textarea>
        </div>
        <div
          v-if="isRealizationButtonEnabled && !defaults.isPartialRealizationEnabled"
          :class="$style.realizationButton">
          <GnzsButton
            @click="saveRealization"
            v-if="isRealizationButtonEnabled"
            type="primary"
            :loading="processing"
            :disabled="!isOrder1cSaved"
            >Провести реализацию</GnzsButton
          >
          <div v-for="(realization, index) in realizationsWrapped" :key="index" :class="$style.realizations">
            {{ "Номер: " + realization.number + ", дата: " + realizationDate(realization) }}
          </div>
        </div>
      </div>
      <div :class="$style.content">
        <div :class="$style.row">
          <div>Итого:</div>
          <div>{{ totalPriceDirty | price }} {{ currencyText }}</div>
        </div>
        <div :class="$style.row">
          <div>Скидка:</div>
          <div>{{ totalDiscount | price }} {{ currencyText }}</div>
        </div>
        <div v-if="defaults.isAutoDiscountEnabled && tariffFeatures.includes('auto-discount')" :class="$style.row">
          <div>Авт. скидка:</div>
          <div>{{ autoDiscount | price }} {{ currencyText }}</div>
        </div>
        <div :class="$style.row">
          <div>{{ ndsTypeText }}</div>
          <div>{{ totalNds | price }} {{ currencyText }}</div>
        </div>
        <div :class="$style.row" v-for="cf in appendCfToLeadPrice.cfs" :key="cf.id">
          <div :title="cf.name">{{ cf.name }}</div>
          <div>{{ cf.value | price }} {{ currencyText }}</div>
        </div>
        <div v-for="(field, index) in tableFieldForReduce" :key="index" :class="$style.row">
          <div>{{ field.name }}:</div>
          <div>{{ sumByCustomField(field.name) }}</div>
        </div>
        <div :class="$style.row">
          <div>Итого к оплате:</div>
          <div>{{ totalPrice | price }} {{ currencyText }}</div>
        </div>
      </div>
    </div>
    <div :class="$style.wrapper" v-else>
      <div :class="$style.leftBlockWrapper"></div>
      <div :class="$style.row">
        <div>{{ totalText }}</div>
        <div>{{ realizationTotalPrice | price }} {{ currencyText }}</div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";
  import GnzsButton from "@/gnzs-controls/Button";

  const mapFieldModel = function (key) {
    return {
      get() {
        return this.$store.getters[`order/getValue`](key);
      },
      set(value) {
        this.$store.dispatch("order/setValue", { key, value });
      },
    };
  };

  export default {
    data() {
      return {
        processing: false,
      };
    },
    computed: {
      ...mapGetters({
        ndsTypeText: "order/ndsTypeText",
        totalNds: "order/totalNds",
        totalDiscount: "order/totalDiscount",
        autoDiscount: "order/totalAutoDiscount",
        totalPrice: "order/totalPrice",
        totalPriceDirty: "order/totalPriceDirty",
        currency: "settings/currency",
        currencies: "localSettings/currencies",
        isRealizationButtonEnabled: "settings/isRealizationButtonEnabled",
        defaults: "settings/defaults",
        orderCurrency: "order/orderCurrency",
        isOrder1cSaved: "order/hasOrder1c",
        realizations: "order/realizations",
        processingRealization: "realization/savingRealization",
        isOrderChanged: "order/isChanged",
        appendCfToLeadPrice: "order/appendCfToLeadPrice",
        tabMode: "order/tabMode",
        isNdsUsed: "order/isNdsUsed",
        realizationPrice: "realizations/totalPrice",
        tariffFeatures: "features/getFeatures",
        tableFieldForReduce: "settings/tableFieldForReduce",
        sumByCustomField: "order/sumByCustomField",
      }),
      realizationTotalPrice() {
        return this.realizationPrice.toFixed(2);
      },
      realizationsWrapped() {
        return this.realizations.filter((r) => r);
      },
      orderDescription: { ...mapFieldModel.call(this, "order_description") },
      currencyText() {
        return this.currencies.find((c) => c.code == this.orderCurrency)?.name || "руб";
      },
      totalText() {
        return this.isNdsUsed ? "Итого с НДС:" : "Итого:";
      },
    },
    methods: {
      ...mapActions({
        saveRealization1c: "realization/saveRealization",
        saveOrder1c: "order/save1c",
        setRootValue: "order/setRootValue",
      }),
      async saveRealization() {
        this.processing = true;
        this.setRootValue({ key: "disableModalClose", value: true });
        await this.saveOrder1c();
        await this.saveRealization1c();
        this.setRootValue({ key: "disableModalClose", value: false });
        this.processing = false;
      },
      realizationDate(realization) {
        if (!realization?.date) return "";
        return realization.date.split(" ")[0];
      },
    },
    components: {
      GnzsButton,
    },
  };
</script>

<style lang="scss" module>
  @import "./Footer.scss";
</style>
