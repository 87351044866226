/**
 * @author Ilya Orishin. (continue from Artyom Baskakow) 2021
 */

import moment from "moment";
import { create1cContract, load1cContracts } from "@/api/1c-api";
import { beatArrayByConditions } from "@/utilities/contracts";

import { showError } from "../../utilities/amo";
import * as orderUtil from "../../utilities/order";

import Vue from "vue";

const initinalContract = () => ({
  name: "",
  number: "",
  date: "",
  date_to: "",
  organization_id: "",
  organization_account_id: "",
  is_company: "",

  contact_id: "",
  contact_email: "",
  contact_phone: "",
  contact_name: "",
  contact_short_name: "",
  contact_address: "",
  contact_manager_id: "",
  contact_manager_name: "",
  contact_manager_email: "",
  contact_custom_fields: [],
  contact_1c_code: "",

  company_id: "",
  company_inn: "",
  company_kpp: "",
  company_name: "",
  company_phone: "",
  company_email: "",
  company_short_name: "",
  company_ogrn: "",
  company_account: "",
  company_physical_address: "",
  company_registered_address: "",
  company_postal_address: "",
  company_tax_number: "",
  company_manager_id: "",
  company_manager_name: "",
  company_manager_email: "",
  company_custom_fields: [],
  company_1c_code: "",
  bank_name: "",
  bank_bik: "",
  bank_ks: "",

  current_user_id: "",
  current_user_name: "",
  current_user_phone: "",
  current_user_email: "",

  manager_id: "",
  manager_name: "",
  manager_email: "",
  custom_fields: [],

  contacts: [],
});

const state = () => ({
  items: [], // Все договоры
  isCreating: false,
  isLoading: false,
  isCreatingError: false,
  contract: initinalContract(),
});

const getters = {
  items: (state) => state.items,
  isCreating: (state) => state.isCreating,

  dropdownItems: (state) => {
    return beatArrayByConditions(
      {
        Типовые: (val) => val.owner_id == 0,
        Индивидуальные: (val) => val.owner_id != 0,
      },
      state.items
    );
  },

  individualContracts: (state) =>
    state.items
      .filter((c) => c.owner_id != 0)
      .map((i) => ({
        title: i.name,
        value: i.id,
      })),

  contractById: (state) => (id) => state.items.find((c) => c.id == id),

  contract: (state) => state.contract,

  isError: (state) => state.isCreatingError,

  contractCfs(state) {
    // Значение дополнительного поля договора
    return (attribute) => state.contract.custom_fields.find((val) => val.attribute == attribute)?.value;
  },
};

const actions = {
  // Загрузка договоров в зависимости от организации и контрагента
  async load({ state, commit, rootGetters }) {
    try {
      state.isLoading = true;

      const { organization_id, company_id, contact_id } = rootGetters["order/orderData"];

      let entity_id = company_id ? company_id : contact_id;

      const resp = await load1cContracts({
        organization_id,
        entity_id,
        limit: 100,
        offset: 0,
      });

      commit("setItems", resp.data.result);
    } catch (e) {
      console.debug(`1С ${rootGetters["partnerName"]}: ошибка загрузки договоров`, e);
    } finally {
      state.isLoading = false;
    }
  },

  async getContractData({ state, commit, rootGetters }) {
    const isRequestContractInfo = rootGetters["settings/defaults"].isRequestContractInfo;
    if (!isRequestContractInfo) return;
    // Загрузить дополнительную информацию по договору
    try {
      const { contract_id } = rootGetters["order/orderData"],
        contract_data = 1;
      if (state.items.find((c) => c.id == contract_id)?.data) return;

      const resp = await load1cContracts({
        contract_id,
        contract_data,
      });

      commit("setContractData", resp.data);
    } catch (e) {
      console.debug(`1С ${rootGetters["partnerName"]}: ошибка загрузки договоров`, e);
    }
  },

  setContractField: ({ commit }, payload) => commit("setContractField", payload),

  setContractCf: ({ commit, rootGetters }, payload) => commit("setContractCf", { payload, rootGetters }),

  async create({ state, commit, rootGetters, dispatch }) {
    // Создание договора
    try {
      state.isCreating = true;

      const fields = rootGetters["settings/contractCFs"];

      orderUtil.checkAllowSaveContract(fields, state.contract.custom_fields);

      const orderData = rootGetters["order/orderData"];

      const currentUser = rootGetters["amoUser"];

      (state.contract.current_user_id = `${currentUser.id}`),
        (state.contract.current_user_name = `${currentUser.name}`),
        (state.contract.current_user_phone = `${currentUser.phone}`),
        (state.contract.current_user_emall = `${currentUser.email}`),
        (state.contract.date = moment(state.contract.date).format("YYYYMMDD"));
      state.contract.date_to = moment(state.contract.date_to).format("YYYYMMDD");

      commit("setContragentData", orderData);

      const resp1c = await create1cContract(state.contract);

      if (resp1c.data?.error) {
        orderUtil.showModal("Ошибка сохранения договора в 1С", `<div>${resp1c.data.error}</div>`, true);
        state.isCreatingError = true;
        return;
      }

      commit("resetData", rootGetters["amoUserId"]);

      dispatch("load");
      dispatch("order/setValue", { key: "contract_id", value: resp1c.data.id }, { root: true });

      state.isCreatingError = false;
    } catch (e) {
      console.debug(`1С ${rootGetters["partnerName"]}: ошибка создания договора`, e);

      state.isCreatingError = true;

      let widget = rootGetters["widgetRef"],
        targets = rootGetters["settings/showErrors"], //где отображать ошибки
        leadId = rootGetters["order/leadId"]; // id сделки, чтобы положить ошибку в примечание

      showError({
        widget,
        targets,
        leadId,
        header: `1С ${rootGetters["partnerName"]}`,
        text: "Ошибка создания договора",
      });
    } finally {
      state.isCreating = false;
    }
  },
};

const mutations = {
  setItems: (state, items) => (state.items = items || []),

  setContractField: (state, { field, value }) => (state.contract[field] = value),

  setContractData: (state, { id, data }) => {
    // Запись полей договора
    let contract = state.items.find((c) => c.id == id);
    if (contract) Vue.set(contract, "data", data);
  },

  resetData: (state) => (state.contract = initinalContract()),

  setContragentData(state, orderData) {
    /*
     Запись полей контрагента по договору, исключая стандартные поля
    */
    const except = ["name", "number", "date", "date_to", "custom_fields"];
    Object.keys(state.contract).forEach((key) => {
      if (!except.includes(key)) {
        state.contract[key] = orderData[key];
      }
    });
  },

  setContractCf(state, { payload, rootGetters }) {
    /*
      Установка значений дополнительных полей договора

      структура атрибута: {attribute, code, value}

      1. Извлекаем attribute (имя дополнительного поля), code (код справочника, если есть), значение value из payload
      2. Находим атрибут по имени, получаем массив оставшихся атрибутов
      3. Если такого атрибута нет ищем его в настройках и создаем
      4. Присваиваем атрибуту значение value и code (для справочников и перечислений)
      5. В массив оставшихся атрибутов ложим обновленный атрибут
    *
    */
    const { attribute, value, code } = payload;

    let param = state.contract.custom_fields.find((val) => val.attribute == attribute);
    let fields = state.contract.custom_fields.filter((val) => val.attribute != attribute);
    if (!param) {
      let { required, title, ...x } = rootGetters["settings/contractCFs"].find((val) => val.attribute == attribute); // eslint-disable-line no-unused-vars
      param = { ...x };
    }
    param.value = value;
    if (code) param.code = code;
    fields.push(param);
    Vue.set(state.contract, "custom_fields", fields);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
