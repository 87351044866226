<template>
    <div :class="[
        $style.root, 
        {
            [$style.root_disabled]: disabled,
            [$style[`theme_${theme}`]]: theme.length,
        }
    ]">

        <div :class="$style.container" ref="container" tabindex="-1" @focusout="closeList">

            <div v-if="isSearchable" :class="$style.search">
                <svg :class="$style.searchIcon" viewBox="0 0 16 15" xmlns="http://www.w3.org/2000/svg">
                    <path id="deSearch" d="M818.493 273.994a6.5 6.5 0 116.5-6.5 6.5 6.5 0 01-6.5 6.5zm0-11.007a4.506 4.506 0 104.5 4.506 4.5 4.5 0 00-4.5-4.506zm9.516 12.124l-.893.9-3.108-2.633 1.381-1.388z" transform="translate(-812 -261)" />
                </svg>
                <input
                    v-model="search"
                    :class="$style.searchInput"
                    type="text"
                    placeholder="Поиск"
                    ref="searchInput"
                    @input="toggleSearch"
                />
            </div>

            <ul v-if="!isGrouped" :class="['custom-scroll', $style.list, isChuncked ? $style.mw350 :'']" @scroll="handleScroll" ref="list" tabindex="-1" >
                
                <li v-if="useCreating" :class="[$style.listItem, $style.createItem]" @click="create">
                    <svg :class="$style.createItem__icon"><use xlink:href="#gnzs-1c-icon__create"></use></svg>
                    <span :class="[$style.listItemInner, $style.createItem__inner]" :data-value="-1">Добавить элемент</span>
                </li>

                <li v-if="useEmptyItem && isSearchEmpty" :class="$style.listItem" @click="onListItemClickHandler({value: '',title: emptyItemTitle})">
                    <span :class="$style.listItemInner" :data-value="-1">{{emptyItemTitle}}</span>
                </li>
                
                <li 
                    :class="[
                        $style.listItem, 
                        {[$style.listItem_selected]: item.value == selectedValue}
                    ]"
                    v-for="(item, index) in searchItems" 
                    :key="index" 
                    @click="onListItemClickHandler(item)"
                >
                    <span :class="$style.listItemInner" :data-value="item.value">{{ item.title }}</span>
                    <span v-if="item.info && item.info.length" :class="$style.listItemInfo" >{{ item.info }}</span>
                    <div v-if="useEditing" @click="onEditItemClickHandler(item)" :class="$style.edit"><svg><use xlink:href="#gnzs-1c-icon__edit-pen"></use></svg></div>
                </li>

               <li v-if="isLoading" :class="$style.spinner">
                    <span class="spinner-icon"></span>
                </li>
            </ul>

            <ul v-else :class="['custom-scroll', $style.list]" @scroll="handleScroll" ref="list" tabindex="-1">
                <li v-if="useCreating" :class="[$style.listItem, $style.createItem]" @click="create">
                    <svg><use xlink:href="#gnzs-1c-icon__create-plus"></use></svg>
                    <span :class="$style.listItemInner" :data-value="-1">Добавить элемент</span>
                </li>

                <li v-if="useEmptyItem && isSearchEmpty" :class="$style.listItem" @click="onListItemClickHandler({value: '', title: emptyItemTitle}, {value: ''})">
                    <span :class="$style.listItemInner" :data-value="-1">{{emptyItemTitle}}</span>
                </li>

                <template v-if="search.length">
                    <li 
                        :class="[
                            $style.listItem, 
                            {[$style.listItem_selected]: item.value == selectedValue}
                        ]"
                        v-for="(item, index) in searchItems" 
                        :key="`_${index}`" 
                        @click="onListItemClickHandler(item, item.group)"
                    >
                        <span :class="$style.listItemInner" :data-value="item.value">{{ item.title }}</span>
                        <span v-if="item.info && item.info.length" :class="$style.listItemInfo" >{{ item.info }}</span>
                        <div v-if="useEditing" @click="onEditItemClickHandler(item, item.group)" :class="$style.edit"><svg><use xlink:href="#gnzs-1c-icon__edit-pen"></use></svg></div>
                    </li>
                </template>
                <template v-else>
                    <li
                        :class="$style.groupItem"
                        v-for="(group, index) in items" 
                        :key="index" 
                    >
                        <span :class="$style.groupTitle" :data-value="group.value">{{group.title}}</span>

                        <ul :class="$style.groupInnerList">
                            <li 
                                :class="[
                                    $style.listItem,
                                    {[$style.listItem_selected]: item.value == selectedValue}
                                ]"
                                v-for="(item, index) in group.items" 
                                :key="index" 
                                @click="onListItemClickHandler(item, group)"
                            >
                                <span :class="$style.listItemInner" :data-value="item.value">{{ item.title }}</span>
                                <span v-if="item.info && item.info.length" :class="$style.listItemInfo" >{{ item.info }}</span>
                                <div v-if="useEditing" @click="onEditItemClickHandler(item, group)" :class="$style.edit"><svg><use xlink:href="#gnzs-1c-icon__edit-pen"></use></svg></div>
                            </li>
                        </ul>
                    </li>
                </template>


            <li v-if="isLoading" :class="$style.spinner">
                    <span class="spinner-icon"></span>
                </li>
            </ul>

        </div>

        <button type="button" :class="[$style.button, append ? $style.button_append : '']" @click="onButtonClickHandler">
            <div :class="$style.buttonInner">{{selectedTitle}}</div>
            <span :class="[append? $style.icon_append : $style.icon]"></span>
        </button>
        <input 
            :class="$style.input" 
            :data-name="name" 
            type="hidden" 
            ref="input-control" 
            :value="value"
        />
    </div>
</template>

<script>

export default {
    data(){
        return {
            search: '',
            isEdit: false
        }
    },
    props: {
        name: String,
        disabled: Boolean,
        theme: {
            type: String,
            default: '', // card
        },
        useEmptyItem: {
            type: Boolean,
            default: true,
        },
        emptyItemTitle: {
            type: String,
            default: 'Выбрать',
        },
        isGrouped: {
            type: Boolean,
            default: false,
        },
        value: {
            default: '',
        },
        items: {
            type: Array,
            default: () => [],
        },
        searchedItems: {
            type: Array,
            default: () => []
        },
        isSearchable: {
            type: Boolean,
            default: false,
        },
        isChuncked: {
            type: Boolean,
            default: false
        },
        isLoading: {
            type: Boolean,
            default: false
        },
        append: {
            type: Boolean,
            default: false
        },
        useCreating: {
            type: Boolean,
            default: false
        },
        useEditing: {
            type: Boolean,
            default: false         
        }
    },
    computed: {
        selectedValue(){
            if (this.$props.isChuncked){
                try {
                    return JSON.parse(this.$props.value).value
                } catch {
                    return ''
                }
            }
            return this.$props.value;
        },
        selectedTitle(){
            if (this.$props.isChuncked){
                try {
                    return JSON.parse(this.value).title
                } catch {
                    return this.emptyItemTitle
                }
            }

            if(!this.items.length) return this.emptyItemTitle;

            if(this.isGrouped){
                for(let g of this.items){
                    const item = g.items.find(item => item.value == this.selectedValue);

                    if(item) return item.title
                }

                return this.emptyItemTitle;
            }
            let elem;
            if (typeof this.selectedValue != 'string'){
                elem = this.items.find(item => JSON.stringify(item.value) === JSON.stringify(this.selectedValue));
            }
            else {
                elem = this.items.find(item => item.value == this.selectedValue)
            }
            if(elem) return elem.title;
            return this.emptyItemTitle;
        },
        isSearchEmpty(){
            return !this.search.length;
        },
        searchItems(){
            if (this.$props.isChuncked && this.search.length){
                return this.$props.searchedItems
            }

            const s = this.search.trim().toLowerCase();

            if (this.isGrouped){
                return this.search.length 
                    ? this.items.map(el => el.items.map(it => ({
                    ...it,
                    group: el.title }))).flat().filter(i => i.title.toLowerCase().includes(s))
                    : this.items.map(el => el.items.map(it => ({
                    ...it,
                    group: el.title
                }))).flat()
            }

            return this.search.length 
                ? this.items.filter(i => i.title.toLowerCase().includes(s))
                : this.items;
        }
    },
    methods: {
        openList(){
            this.$refs['container'].classList.add(this.$style.container_opened);
            this.$refs['container'].focus();
            
            if(this.isSearchable){
                this.$refs['searchInput'].focus();
            }
        },
        create(){
            this.$emit('create')
            this.closeList();
        },
        toggleSearch(){
            if (this.$props.isChuncked){
                this.$emit('search', this.search)
            }
        },
        closeList(e){
            const isTargetChild =  e && e.relatedTarget && this.$refs['container'] === e.relatedTarget.closest(`.${this.$style.container}`);
            if(isTargetChild) return;

            this.$refs['container'].classList.remove(this.$style.container_opened);
            this.search = '';
        },
        onButtonClickHandler(){
            if(!this.disabled) {
                this.openList();
            }
        },
        onEditItemClickHandler(item, group){
            this.isEdit = true
            if(this.isGrouped){
                this.$emit('edit', item, group)
            } else {
                this.$emit('edit', item)
            }
            this.closeList();
        },
        onListItemClickHandler(item, group){
            if (this.isEdit) {
                this.isEdit = false
                return
            }
            this.$refs['input-control'].value = item.value;

            if (this.$props.isChuncked){
                this.$emit('input', JSON.stringify(item))
                this.$emit('click', JSON.stringify(item))
            } else {
                if(this.isGrouped){
                    this.$refs['input-control'].dataset.groupValue = group.value;
                    this.$emit('input', item.value, group.value);
                    this.$emit('click', item.value, group.value);
                } else {
                    this.$emit('input', item.value);
                    this.$emit('click', item.value);
                }
            }

            this.closeList();
        },
        handleScroll(event){
            if (!this.$props.isChuncked || this.$props.isLoading) return;
            const containerHeight = event.target.clientHeight;
            const height = event.target.scrollHeight;
            const top = event.target.scrollTop;
            const bottom = height - (containerHeight + top);
            if (bottom < 50) this.$emit('scrollend')
        },
    }
}
</script>


<style lang="scss" module>
    @import './style.scss';
</style>