<template>
    <div>
        <div :class="$style.row">
            <div :class="$style.title">Партнер</div>
            <div :class="[$style.control, $style.wfit, $style.space]" code="partners">
                <GnzsDropdown 
                        :value="partnerId"
                        :isChuncked="true"
                        :isSearchable="true"
                        :isLoading="isLoadingPartners"
                        :searchedItems="searchedPartners"
                        :items="dropdownItems"
                        @scrollend="loadPartners"
                        @search="searchPartners"
                        @input="onPartnerChangeHandler"
                        field-name="partners"
                    />
                <GnzsButton type="append" @click="addPartner">Создать</GnzsButton>
            </div>
        </div>
        <div :class="$style.row">
            <div :class="$style.title">Контрагент</div>
            <div :class="[$style.control, $style.wfit, $style.space]" code="contragents">
                <GnzsDropdown 
                    :value="contragentId" 
                    :items="contragents"
                    :is-grouped="true"
                    :use-empty-item="false"
                    @input="onContragentChangeHandler"
                    :disabled="!partnerId"
                />
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

import GnzsDropdown from '@/gnzs-controls/Dropdown';
import GnzsButton from '@/gnzs-controls/Button';
import openAddPartnerModal from '../Modals/AddPartnerModal';
import isJson from '@/utilities/isJson';

// функция для создания методов вычисляемого поля, которое может работать с директивой v-model
const mapFieldModel = function(key){
    return {
        get(){
            return this.$store.getters[`order/getValue`](key);
        },
        set(value){
            this.$store.dispatch('order/setValue', {key, value});
        }
    }
}

export default {
    data(){
        return {
            filter: 0
        }
    },
    computed: {
        ...mapGetters({
            isLoadingPartners: 'partners/isLoading',
            searchedPartners: 'partners/searchedItems',
            dropdownItems: 'partners/dropdownItems',
            contragents: 'partners/contragents',
        }),
        partnerId: { ...mapFieldModel.call(this, 'partner_id') },
        contragentId: { ...mapFieldModel.call(this, 'contragent_id') },
    },
    methods: {
        ...mapActions({
            setValue: 'order/setValue',
            loadPartners: 'partners/load',
            searchPartners: 'partners/search',
            loadContragentsByPartnerId: 'partners/loadContragentsByPartnerId',
        }),
       onPartnerChangeHandler(id){
            this.setValue({key: 'partner_id', value: id})
            this.setValue({key: 'agreement_id', value: ''})
            const value = isJson(id) ? JSON.parse(id).value : id
            this.filter = (value) ? 2 : 0;
            this.loadContragentsByPartnerId()
        },
        addPartner(){
            openAddPartnerModal();
        },
        onContragentChangeHandler(id){
            this.$emit('change', id)
        }
    },
    components:{
        GnzsDropdown,
        GnzsButton
    }
}
</script>

<style lang="scss" module>
    @import './Parameters.scss';
</style>