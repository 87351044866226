<template>
    <GnzsSection 
        :disabled="!tariffFeatures.includes('partial-realization')"
        :disabledText="disabledProSectionText" 
        header='Частичная реализация'>
        <template v-slot:description>
            Если включено, то в модальном окне детализации заказа добавится блок для проведения частичной реализации.
        </template>
        <template v-slot:header-control>
            <GnzsSwitcher
                v-model="defaults.isPartialRealizationEnabled"
            ></GnzsSwitcher>
        </template>
        <div v-if="defaults.isPartialRealizationEnabled" :class="w.table">
            <div :class="w.tableRow">
                <div>После проведения реализации</div>
                <div>
                    <GnzsDropdown 
                        :class="w.w200"
                        v-model="actionCloseAferSaveRealization" 
                        :items="actionsCloseAferSaveOrder"
                        :use-empty-item="false"
                    ></GnzsDropdown>
                </div>
            </div>
        </div>
        <div v-if="defaults.isPartialRealizationEnabled">

            <div :class="$style.paramsHeader">Информация о реализациях</div>

            <div :class="w.table">

                <div :class="w.tableHeader">
                    <div>Поле в 1С</div>
                    <div><span>Поле в </span><span :class="w.amocrmTitle">amoCRM</span></div>
                    <div>Обязательное</div>
                </div>

                <div :class="w.tableRow" v-for="(row, index) in rows" :key="index">
                    <div>{{ row.title }}</div>
                    <div>
                        <GnzsDropdown 
                            :class="w.w200"
                            :value="getSettingsEntityFieldId('lead', row.field)" 
                            @input="setSettingsEntityFieldId('lead', row.field, $event)" 
                            :items="getAmoEntityCfs('lead', row.types)"
                            :isSearchable="true"
                            :emptyItemTitle="row.emptyItemTitle ? row.emptyItemTitle : 'Выбрать'"
                        ></GnzsDropdown>
                    
                    </div>
                    <div :class="$style.cellRequired">
                        <div :class="[w.cellInner, $style.checkboxRequireContainer]">
                            <GnzsCheckbox 
                                :value="getSettingsEntityFieldRequired('lead', row.field)" 
                                @input="setSettingsEntityFieldRequired('lead', row.field, $event)" 
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div :class="$style.paramsHeader">Список реализаций</div>

            <div :class="w.table">

                <div :class="w.tableHeader">
                    <div>Порядковый номер</div>
                    <div>Дата реализации</div>
                    <div>Номер реализации</div>
                </div>
                
                <div v-for="(item,index) in realizationsInfo" :key="index" :class="w.tableRow">
                    <div>{{index+1}} реализация</div>
                    <div>
                        <GnzsDropdown 
                            :class="w.w200"
                            :value="realizationsInfoValue(index, 'dateCf')"
                            @input="setRealizationsInfoValue({index, field: 'dateCf', value: $event})"
                            :items="getAmoEntityCfs('lead', ['date'])"
                            :isSearchable="true"
                        ></GnzsDropdown>
                    </div>
                    <div>
                        <GnzsDropdown 
                            :class="w.w200"
                            :value="realizationsInfoValue(index, 'numberCf')"
                            @input="setRealizationsInfoValue({index, field: 'numberCf', value: $event})"
                            :items="getAmoEntityCfs('lead', ['numeric', 'text'])"
                            :isSearchable="true"
                        ></GnzsDropdown>
                    </div>
                    <div v-if="index > 3 && index == realizationsInfo.length-1">
                        <GnzsButton type="remove" @click="removeRealizationRow" />
                    </div>
                </div>
            </div>
            <div :class="$style.appendButtonContainer">
                <GnzsButton type="append" @click="appendRealizationRow">Добавить реализацию</GnzsButton>
            </div>
        </div>
    </GnzsSection>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import GnzsSection from "@/gnzs-controls/Section";
import GnzsSwitcher from "@/gnzs-controls/Switcher";
import GnzsDropdown from '@/gnzs-controls/Dropdown';
import GnzsButton from '@/gnzs-controls/Button';
import GnzsCheckbox from '@/gnzs-controls/Checkbox';

// функция для создания методов вычисляемого поля, которое может работать с директивой v-model
const mapFieldModel = function(fieldName){
    return {
        get(){
            return this.$store.getters['settings/defaults'][fieldName];
        },
        set(value){
            this.$store.dispatch('settings/setDefaultsField', {field: fieldName, value});
        }
    }
}

export default {
    data(){
        return {
            rows: [
                { title: 'Сумма реализаций', field: 'realization_sum', types: ['text', 'numeric'] },
                { title: 'Частичная реализация', field: 'partial_realization_flag', types: ['checkbox'] },
                { title: 'Детализация реализаций', field: 'realization_detail', types: ['textarea'] }
            ]
        }
    },
    computed: {
        ...mapGetters({
            defaults: "settings/defaults",
            tariffFeatures: 'features/getFeatures',
            disabledProSectionText: 'localSettings/disabledProSectionText',
            actionsCloseAferSaveOrder: 'localSettings/actionsCloseAferSaveOrder',
            amoCustomFieldsByTypes: 'amo/customFieldsByTypes',
            realizationsInfo: 'settings/realizationsInfo',
            entityFieldId: 'settings/entityFieldId',
            entityFieldRequired: 'settings/entityFieldRequired',
            realizationsInfoValue: 'settings/realizationsInfoValue'
        }),
        actionCloseAferSaveRealization: { ...mapFieldModel.call(this, 'actionCloseAferSaveRealization') },
    },
    methods: {
        ...mapActions({
            setRealizationsInfoValue: 'settings/setRealizationsInfoValue',
            setOrderFieldId: 'settings/setOrderFieldId',
            setOrderFieldRequired: 'settings/setOrderFieldRequired',
            appendRealizationRow: 'settings/appendRealizationRow',
            removeRealizationRow: 'settings/removeRealizationRow'
        }),
        getSettingsEntityFieldId(entity, field){
            return this.entityFieldId({entity, field});
        },
        getSettingsEntityFieldRequired(entity, field){
            return this.entityFieldRequired({entity, field});
        },
        setSettingsEntityFieldId(entity, field, id){
            this.setOrderFieldId({entity, field, id})
        },
        setSettingsEntityFieldRequired(entity, field, required){
            this.setOrderFieldRequired({entity, field, required})
        },
        getAmoEntityCfs(entity, types = ['all']){
            return types.reduce((cfs, t) => 
                [...cfs, ...this.amoCustomFieldsByTypes[entity][t]], 
                []
            );
        },
    },
    components: {
        GnzsSection,
        GnzsSwitcher,
        GnzsDropdown,
        GnzsButton,
        GnzsCheckbox
    },
};
</script>

<style lang="scss" module="w">
    @import '@/assets/scss/Settings/style.scss';
</style>

<style lang="scss" module>
    @import './style.scss';
</style>
