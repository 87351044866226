<template>
 <GnzsSection 
     header="Значения по умолчанию для организаций"
     >
    <template v-slot:description>
        Сопоставление значений по умолчанию для организаций.
    </template>

    <div>
        <div :class="[$style.mb10, w.table]">
            <div :class="w.tableRow">
                <div :class="w.switcherTitle">Соглашение контрагента в приоритете</div>
                <div><GnzsSwitcher v-model="defaults.ownerAgreementFirst"></GnzsSwitcher></div>
            </div>
        </div>
        <div :class="w.table">
            <div :class="w.tableHeader" v-if="organizationsDefaults.length">
                <div>Организация</div>
                <div :class="w.w200">Склад по умолчанию</div>
                <div v-if="defaults.isAgreementsEnabled && features.includes('agreements-1с-to-amo')" :class="w.w200">Соглашение по умолчанию</div>
                <div></div>
            </div>
            <div :class="w.tableRow" v-for="(row, key) in organizationsDefaults" :key="key">
                <div>{{orgName(row.orgId)}}</div>
                <GnzsDropdown
                    :class="w.w200"
                    :value="row.stockId"
                    :use-empty-item="true"
                    :items="stocks"
                    @input="setOrgDefaultValue({target: 'stockId', orgId: row.orgId, value: $event})"
                />
                <GnzsDropdown
                    v-if="defaults.isAgreementsEnabled && features.includes('agreements-1с-to-amo')"
                    :class="w.w200"
                    :use-empty-item="true"
                    :value="row.agreementId"
                    :items="agreements(row.orgId)"
                    @input="setOrgDefaultValue({target: 'agreementId', orgId: row.orgId, value: $event})"
                />
                <div>
                    <GnzsButton type="remove" @click="removeOrgDefaultValue(row.orgId)" />
                </div>
            </div>
                <div :class="w.tableRow" v-if="orgsAvaliable.length">
                    <GnzsDropdown 
                        :class="$style.orgDropdown"
                        :append="true"
                        :items="orgsAvaliable"
                        :useEmptyItem="false"
                        :isSearchable="true"
                        :emptyItemTitle="'Добавить организацию'"
                        @input="addOrg"
                    ></GnzsDropdown>
                </div>
        </div>
    </div>

 </GnzsSection>
</template>

<script>
import GnzsSection from '@/gnzs-controls/Section'
import GnzsDropdown from '@/gnzs-controls/Dropdown'
import GnzsButton from '@/gnzs-controls/Button'
import GnzsSwitcher from '@/gnzs-controls/Switcher'

import { mapActions, mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters({
            organizations: 'organizations/orderItems',
            agreements: 'agreements/allByOrgId',
            stocks: 'stocks/dropdownItems',
            organizationsDefaults: 'settings/organizationsDefaults',
            defaults: 'settings/defaults',
            features: 'features/getFeatures',
        }),
        orgsAvaliable(){
            let existed = this.organizationsDefaults.map(val => val.orgId)
            return this.organizations.filter(org => !existed.includes(org.value))
        },
        orgName(){
            return (id) => this.organizations.find(org => org.value == id)?.title
        }
    },
    methods: {
        ...mapActions({
            addOrg: 'settings/addOrganizationDefaultValue',
            setOrgDefaultValue: 'settings/setOrgDefaultsValue',
            removeOrgDefaultValue: 'settings/removeOrgDefaultsValue'
        }),
    },
    components: {
        GnzsSection,
        GnzsDropdown,
        GnzsButton,
        GnzsSwitcher
    }
}
</script>

<style lang="scss" module>
    @import "./style.scss";
</style>

<style lang="scss" module="w">
    @import '@/assets/scss/Settings/style.scss';
</style>
