<template>

    <div :class="$style.helper">

        <div :class="$style.helper__button">
            <GnzsIconsSprite/>
            <svg v-if="type == 'default'"><use xlink:href="#gnzs-kanban-icon__hint"/></svg>
            <svg v-if="type == 'service'"><use xlink:href="#gnzs-1c-icon__admin-colored"/></svg>
        </div>
        <div :class="$style.helper__modal">{{this.value}}</div>

    </div>

</template>

<script>

import GnzsIconsSprite from '@/gnzs-controls/IconsSprite';

export default {

    components: {
        GnzsIconsSprite,
    },

    props: {
        value: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: 'default'
        },
        side: {
            type: String,
            default: 'top',
        }
    },

    mounted() {
        let modal = this.$el.lastElementChild
        if (this.side === 'left') return modal.classList.add(this.$style.helper__left)
        if (this.side === 'top') return modal.classList.add(this.$style.helper__top)
        if (this.side === 'bottom') return modal.classList.add(this.$style.helper__bottom)
        if (this.side === 'right') return modal.classList.add(this.$style.helper__right)
    },

}

</script>

<style lang="scss" module>
    @import './style.scss';
</style>