export default {
  ADVANCED_SETTINGS: {
    name: "settings",
    path: "/settings",
  },
  SETTINGS_WOC: {
    name: "settingsWoc",
    path: "/settings-woc",
  },
  ORDER_TAB: {
    name: "orderTab",
    path: "/tab",
  },
  ORDER_MODAL: {
    name: "orderModal",
    path: "/modal",
  },
  ORDER_UPDATE: {
    name: "orderUpdate",
    path: "/order-update",
  },
  SERVICE_TEST: {
    name: "serviceTest",
    path: "/service-test",
  },
  ORDER_COPY: {
    name: "OrderCopy",
    path: "/order-copy",
  },
  UPDATE_CONTRAGENT: {
    name: "UpdateContragent",
    path: "/update-contragent",
  },
  ADD_DIRECTORY_ELEMENT: {
    name: "addDirectoryElement",
    path: "/add-directory-element",
  },
  EDIT_DIRECTORY_ELEMENT: {
    name: "editDirectoryElement",
    path: "/edit-directory-element",
  }
};