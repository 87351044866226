<template>
    <GnzsSection
    :disabled="!tariffFeatures.includes('auto-discount')"
    :disabledText="disabledProSectionText" 
    header='Функционал автоматической скидки'>
        <template v-slot:description>
            Если включено, то в модальном окне детализации заказа добавится столбец "Авт. скидка", скидка будет рассчитана автоматически после отправки заказа в 1С.
        </template>
        <template v-slot:header-control>
            <GnzsSwitcher
                v-model="defaults.isAutoDiscountEnabled"
            ></GnzsSwitcher>
        </template>
    </GnzsSection>
</template>

<script>
    import { mapGetters } from "vuex";

    import GnzsSection from "@/gnzs-controls/Section";
    import GnzsSwitcher from "@/gnzs-controls/Switcher";

    export default {
        computed: {
            ...mapGetters({
                defaults: "settings/defaults",
                tariffFeatures: 'features/getFeatures',
                disabledProSectionText: 'localSettings/disabledProSectionText',
            }),
        },
        components: {
            GnzsSection,
            GnzsSwitcher,
        },
    };
</script>

<style lang="scss" module="w">
    @import '@/assets/scss/Settings/style.scss';
</style>
