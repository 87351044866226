<template>
  <div :class="$style.container" @click="$emit('click', $event.target)">
    <svg viewBox="0 0 6.21 6.21" preserveAspectRatio="xMidYMin slice" xmlns="http://www.w3.org/2000/svg" :fill="color" :size="size">
      <path d="M5.5 6.21l-2.4-2.4-2.39 2.4L0 5.5l2.4-2.4L0 .71.71 0 3.1 2.4 5.5 0l.71.71-2.4 2.39 2.4 2.4-.71.71z"></path>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    color: {
        type: String,
        default: '#0085c8',
    },
    size: {
      type: String,
      default: 'med', // med, min
    }
  }
}
</script>

<style lang="scss" module>
.container{
  display: flex;

  svg{
    &[size="med"] {
      width: 12px;
      height: 12px;
    }
    
    &[size="min"] {
      width: 10px;
      height: 10px;
    }
  }

  &:hover{
    cursor: pointer;
  }
}
</style>