import {get1cStocks} from '@/api/1c-api';

const state = () => ({
    items: [],
    itemsById: {},
});


const getters = {
    itemsById: state => state.itemsById,
    items: state => state.items,
    dropdownItems(state){
        return state.items.map(stock => ({
            value: stock.id,
            title: stock.name,
        }))
    },
    isEmpty: (state) => !state.items.length,
};


const actions = {
    async load({commit, rootGetters}){
        try{
            const res = [];
            let offset = 0;
            const limit = 100;

            let load = true

            while (load){
                const respStocks = await get1cStocks({limit, offset});
                res.push(...respStocks.data.result)
                offset += limit;

                if(respStocks.data.result.length<limit) load = false;
            }

            commit('initStocks', res);

        } catch(e){
            console.debug(`1С ${rootGetters['partnerName']}: Ошибка получения складов`, e);
        }
    }
};


const mutations = {
    initStocks(state, stocks){
        state.items = stocks;
        state.itemsById = {};
        stocks.forEach(s => state.itemsById[s.id] = s);
    }
};



export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}