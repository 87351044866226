export default () => [
  {
    name: "№",
    key: "id",
    show: true,
    edit: true,
    custom: false,
    disabled_show: true,
    disabled_edit: true,
    required: false,
  },
  {
    name: "Артикул",
    key: "article",
    show: true,
    edit: true,
    custom: false,
    disabled_show: false,
    disabled_edit: true,
    required: false,
  },
  {
    name: "Код",
    key: "code",
    show: false,
    edit: false,
    custom: false,
    disabled_show: false,
    disabled_edit: true,
    required: false,
  },
  {
    name: "Наименование",
    key: "name",
    show: true,
    edit: true,
    custom: false,
    disabled_show: true,
    disabled_edit: true,
    required: false,
  },
  {
    name: "Кол-во",
    key: "count",
    show: true,
    edit: true,
    custom: false,
    disabled_show: false,
    disabled_edit: false,
    required: false,
  },
  {
    name: "Единица измерения",
    key: "unit",
    show: true,
    edit: true,
    custom: false,
    disabled_show: false,
    disabled_edit: false,
    required: false,
  },
  {
    name: "Цена",
    key: "price",
    show: true,
    edit: true,
    custom: false,
    disabled_show: false,
    disabled_edit: false,
    required: false,
  },
  {
    name: "Скидка",
    key: "discount",
    show: true,
    edit: true,
    custom: false,
    disabled_show: false,
    disabled_edit: false,
    required: false,
  },
  {
    name: "НДС",
    key: "vat",
    show: true,
    edit: true,
    custom: false,
    disabled_show: false,
    disabled_edit: false,
    required: false,
  },
  {
    name: "Сумма",
    key: "sum",
    show: true,
    edit: true,
    custom: false,
    disabled_show: true,
    disabled_edit: true,
    required: false,
  },
];
