/**
 * @author Ilya Orishin. 2021
 * 
 * Функционал массовых действий
 */

import { isNoMoves } from '@/utilities/order';
import { getStockCount } from '@/utilities/orderProduct';

import Vue from 'vue';
import { state } from '../modules/order/state';

export const massUseState = {
    // Текущее выбранное обеспечение, используется в модалке массового применения обеспечения
    selectedProvision: null,
    selectedPricetype: null,
    selectedVat: 0,

    checkedIds: [], // Выделенные товары (массовые манипуляции)

    generalDiscount: {
        viewType: 0, // 0 проценты, 1 числа
        discount: null,
        total_discount: null
    }
}

export const massUseGetters = {
    isSomeChecked: (_, getters) => getters.someChecked != 0 && getters.someChecked != getters.products.length  ,
    isAllChecked: (_, getters) => getters.someChecked != 0 && getters.someChecked == getters.products.length,
    checkedProducts: (state, getters) => getters.products.filter(p => state.checkedIds.includes(p.uuid)),

    isAppliableDiscount(state){
        /*
        Проверка, можно ли применить скидку (Если товары есть и установлено значение скидки)
        */
        return state.data.products.length && (state.generalDiscount.discount || state.generalDiscount.total_discount)
    },

    emptyCountProductsChecked(state, getters, rootState, rootGetters){
        /*
        Проверка, есть ли среди выделенных товаров с нулевым остатком. Обходим массив
        1. Если элемент выделен, считаем его остаток
        2. По дефолту возвращаем false, если встретился нулевой остаток, возвращаем true
        */
        let result = false;
        state.data.products.forEach(product => {
            if (state.checkedIds.includes(product.uuid)){
                let productData = rootGetters['products/getById'](product.id),
                    orderOrgId = rootGetters['order/organization1cId'],
                    orderStockId = rootGetters['order/stockId'],
                    stockCountBySearch = rootGetters['settings/stockCountBySearch'],
                    defaults = rootGetters['settings/defaults'];

                const stockParams = {stockCountBySearch, stockFormat: defaults.stockFormatItems}
                let count = getStockCount(productData, stockParams,
                                                          orderStockId, orderOrgId)
                if (count == 0 || count == '—') result = true;
            }
        })
        return result;
    },
}

export const massUseActions = {
    applyGeneralDiscount({commit, dispatch}){
        /*
        Применение общей скидки
        */
        commit('applyGeneralDiscount')
        dispatch('calcOrder');
    },

    applyStock({state, commit, rootGetters}, stockId){
        /*
        Применение склада. Проверяем для каждого товара есть ли склад в списке, если есть, то применяем
        */
        const productData = rootGetters['products/getById']
        const stocks = rootGetters['stocks/itemsById'];
        const stock = stocks[stockId];

        state.data.products.filter(product => !product?.cancelled).forEach(product => {
            if (state.checkedIds.includes(product.uuid)){
                let stockInList = productData(product.id).stocks.find(v => v.id == stock?.id)
                if (stockInList) commit('setProductStock', {product, stock})
            }
        });
    },
    setCheckedAll({commit, getters}, value){
        /*
        Выделить все товары в списке
        */
        getters.products.forEach(item => {
            commit('setChecked', {uuid: item.uuid, value})
        })
    },

    setCheckedByProvision({commit, getters}, value){
        commit('clear')
        getters.products.filter(item => !item?.cancelled).forEach(item => {
            if (item.provision_id == value) commit('setChecked', {uuid: item.uuid, value})
        })
    },

    // Применить НДС
    applyVat({commit, dispatch}){
        commit('applyVat')
        dispatch('calcOrder');
    }, 

    // Движение товара
    moveUp: ({commit}) => commit('move','up'),
    moveDown: ({commit}) => commit('move','down'),
    //
    applyProvision: ({commit, rootGetters}) => commit('applyProvision', rootGetters), // Применить обеспечение
    applyPricetype: ({dispatch, commit, rootGetters}) => {
        commit('applyPricetype', rootGetters)
        dispatch('calcOrder');
    },
    removeProducts: ({commit}) => commit('removeProducts'), // Удалить
    changeGeneralDiscountViewType: ({commit}) => commit('changeGeneralDiscountViewType'),
    setDefaultVat: ({commit}, value) => commit('setDefaultVat', value),
    setGeneralDiscountType({commit, rootGetters}){
        const defaults = rootGetters['settings/defaults'];
        commit('setGeneralDiscountType', defaults.discountViewType)
    },
    setChecked: ({commit}, {uuid, value}) => commit('setChecked', {uuid, value}),
}

export const massUseMutations = {
    clear(){
        state.checkedIds = []
    },

    setDefaultVat(state, value){
        state.selectedVat = value
    },

    applyVat(state){
        state.data.products.filter(product => !product?.cancelled).forEach(product => {
            if (state.checkedIds.includes(product.uuid)){
                product.nds = state.selectedVat
            }
        })
    },

    applyGeneralDiscount(state){
        /*
        Обходим массив, если элемент выделен, применяем скидку в зависимости от ее типа
        */
        state.data.products.filter(product => !product?.cancelled).forEach(product => {
            if (state.checkedIds.includes(product.uuid)){
                product.discount_view_type = state.generalDiscount.viewType
                if (state.generalDiscount.viewType == 0){
                    product.discount = state.generalDiscount.discount
                } else {
                    product.total_discount = state.generalDiscount.total_discount
                }
            }
        })
    },

    setGeneralDiscountType: (state, type) => state.generalDiscount.viewType = type,

    changeGeneralDiscountViewType(state){
        // смена типа цены (скидка или валюта)
        let type = state.generalDiscount.viewType
        state.generalDiscount.viewType = (type == 0) ? 1 : 0
    },

    move(state, direction){
        /* 
        Множественное перемещение элементов, с заполнением пропусков
        [--**--*--*-*-] ->
        [---**--*--*-*] ->
        [----**--*-***] ->
        [-----**--****] ->
        [-------******]

        1. Проверим direction если вверх, то тогда установим сдвиг (offset) на 1 элемент влево, 
        иначе вправо.
        2. Установим индекс с которого будем начинать отсчет (targetIndex), при перемещении элементов
        вверх, движемся от первого элемента к последнему, при перемещении вниз от последнеого к первому.
        3. Установим breakpoint, при перемещении элементов вверх, индекс должен быть не больше последнего,
        при перемещении вниз больше либо равен нулю. 
        4. Обходим массив с нужными параметрами, если не находимся на граница (index !== targetIndex), при
        этом индекс текущего элемента находится в списке выделенных (checkedIds), и есть место для движения
        (не выделенные элементы, т.е. пустоты сверху или снизу) то передвигаем элемент (5).
        5. Для сохранения реактивности, бросим в temp предыдущий\следующий элемент массива, поставим на его
        место текущий, заменим текущий временным (из temp)
        */

        let offset = (direction == 'up') ? -1 : 1,
            targetIndex = (direction == 'up') ? 0 : state.data.products.length-1,
            breakpoint = (direction == 'up') 
                ? (i) => i < state.data.products.length 
                : (i) => i >= 0;

        for (let index = targetIndex; breakpoint(index) == true; index-=offset) {
            if (index !== targetIndex && state.checkedIds.includes(state.data.products[index].uuid) 
                && !isNoMoves(index + offset, direction, state.data.products, state.checkedIds)) {
                let temp = state.data.products[index + offset];
                Vue.set(state.data.products, index + offset, state.data.products[index]);
                Vue.set(state.data.products, index, temp);
            }
        }
    },

    applyPricetype(state, rootGetters){
        /*
        Обходим массив, если элемент выделен и присутствует нужный тип цены применяем тип и цену
        */
        state.data.products.filter(product => !product?.cancelled).forEach(product => {
            if (state.checkedIds.includes(product.uuid)){
                let data = rootGetters['products/getById'](product.id)
                let price = data.prices.find(val => val.id == state.selectedPricetype)
                if (price){
                    product.price_type_id = price.id
                    product.price = price.cost
                }
            }
        })
    },

    applyProvision(state, rootGetters){
        /* 
        1. Проверим используется ли статус при нулевых остатках
        2. Проверим выделены ли позиции с нулевыми остатками
        3. Обходим массив, проверяем если продукт выделен...
        4. ...и при этом продукты с нулевыми остатками существуют,
        а также выбран статус, который не используется при нулевых остатках -> проверяем остаток
        5. Если остаток ненулевой применяем статус
        6. Если условия из пункта 4 не сошлись -> просто применяем статус
        */
        let usedOnEmptyStocks = rootGetters['provisions/provisionStatuses']
                    .find(val => val.value == state.selectedProvision).showOnStockEmpty,
            emptyCountProductsChecked = rootGetters['order/emptyCountProductsChecked']

                    
        state.data.products.filter(product => !product?.cancelled).forEach(product => {
            if (state.checkedIds.includes(product.uuid)){
                if (!usedOnEmptyStocks && emptyCountProductsChecked){
                    let productData = rootGetters['products/getById'](product.id),
                        orderOrgId = rootGetters['order/organization1cId'],
                        orderStockId = rootGetters['order/stockId'],
                        stockCountBySearch = rootGetters['settings/stockCountBySearch'],
                        defaults = rootGetters['settings/defaults'];

                    const stockParams = {stockCountBySearch, stockFormat: defaults.stockFormatItems}
                    let count = getStockCount(productData, stockParams,
                                                              orderStockId, orderOrgId)

                    if (count != 0 && count != '—') Vue.set(product, 'provision_id', state.selectedProvision)
                } else {
                    Vue.set(product, 'provision_id', state.selectedProvision)
                }
            }
        })    
    },

    removeProducts(state){
        // удаляем выделенные товары и очищаем массив выделенных
        state.data.products = state.data.products.filter(val => !state.checkedIds.includes(val.uuid)) 
        state.checkedIds = []
    },

    setChecked(state, {uuid, value}){
        /*
        если value true -> положить, иначе выложить, перед вложением проверяем есть ли он 
        в списке, чтобы избежать дубликатов
        */
        if (value && !state.checkedIds.includes(uuid)){
            state.checkedIds.push(uuid)      
        } 
        else {
            state.checkedIds = state.checkedIds.filter(val => val != uuid)  
        } 
    },
}
