<template>
    <Modal ref="modal" :loaded="isUpdateModalLoaded" @decline="onOverlayClick">
        <template v-slot>
            <div :class="$style.header">
                {{header}}
            </div>
            <div :class="$style.body">
                <div v-if="loading" :class="$style.spinner">
                    <span :class="$style.spinnerIcon"></span>
                </div>
                <div v-else-if="!errorText">
                    <span>Данные заказа успешно обновлены</span>
                </div>
                <div v-else>
                    <div>{{ errorText }}</div>
                </div>
            </div>
        </template>
    </Modal>
</template>

<script>
import {mapGetters} from 'vuex';

import Modal from '@/gnzs-controls/Modal';

export default {
    computed: {
        ...mapGetters({
            loading: 'order/orderUpdateLoading',
            header: 'order/orderUpdateHeader',
            errorText: 'order/orderUpdateErrorText',
            isUpdateModalLoaded: 'isUpdateModalLoaded',
            iframeName: 'iframe/iframeName'
        })
    },
    methods: {
        onOverlayClick(){
            return window.parent.postMessage({ event: `${this.iframeName}:closeModal` }, "*");
        },
    },
    components: {
        Modal
    }
}
</script>

<style lang="scss" module>
    @import './style.scss';
</style>