<template>
  <div>
    <ProductsTableOptions ref="options" :clipped="clipped" v-if="someChecked" :bounds="{ width, height }" />
    <table :class="$style.table">
      <thead ref="table" :class="$style.tableHeader">
        <tr :class="$style.tableRow">
          <th :class="$style.cell" :data-code="defaults.showImages ? 'number-image' : 'number'">
            <div :class="$style.checkBoxWrapper">
              <GnzsCheckbox
                :value="isAllChecked || isSomeChecked"
                :class="$style.optionsChecker"
                :isSomeChecked="isSomeChecked"
                @input="setCheckedAll">
              </GnzsCheckbox>
              <div v-if="tableField({ key: 'id', action: 'show' })" :class="$style.counter">№</div>
            </div>
          </th>
          <th v-if="tableField({ key: 'article', action: 'show' })" :class="$style.cell" data-code="article">
            <div :class="$style.cellInner">Артикул</div>
          </th>
          <th v-if="tableField({ key: 'code', action: 'show' })" :class="$style.cell" data-code="code">
            <div :class="$style.cellInner">Код</div>
          </th>
          <th v-if="tableField({ key: 'name', action: 'show' })" :class="$style.cell" data-code="name">
            <div :class="$style.cellInner">Наименование</div>
          </th>
          <th v-if="stockWorktype == 1" :class="$style.cell" data-code="stock">
            <div :class="$style.cellInner">Склад</div>
          </th>
          <th v-if="stockWorktype == 1 && defaults.showStockColumn" :class="$style.cell" data-code="count">
            <div :class="$style.cellInner">Остаток</div>
          </th>
          <th v-if="tableField({ key: 'unit', action: 'show' })" :class="$style.cell" data-code="units">
            <div :class="$style.cellInner">Ед. изм</div>
          </th>
          <th v-if="tableField({ key: 'count', action: 'show' })" :class="$style.cell" data-code="count">
            <div :class="$style.cellInner">Кол-во</div>
          </th>
          <th v-if="tableField({ key: 'price', action: 'show' })" :class="$style.cell" data-code="price">
            <div :class="$style.cellInner">Цена</div>
          </th>
          <th
            v-if="defaults.isProvisionEnabled && tariffFeatures.includes('provisions')"
            :class="$style.cell"
            data-code="provision">
            <div :class="$style.cellInner">Обеспечение</div>
          </th>
          <th v-if="tableField({ key: 'discount', action: 'show' })" :class="$style.cell" data-code="discount">
            <div :class="$style.cellInner">Скидка</div>
          </th>
          <th
            v-if="defaults.isAutoDiscountEnabled && tariffFeatures.includes('auto-discount')"
            :class="$style.cell"
            data-code="discount">
            <div :class="$style.cellInner">Авт. Скидка</div>
          </th>
          <th v-if="isNdsUsed && tableField({ key: 'vat', action: 'show' })" :class="$style.cell" data-code="nds">
            <div :class="$style.cellInner">НДС</div>
          </th>
          <th v-if="defaults.isPartialRealizationEnabled" :class="$style.cell" data-code="count">
            <div :class="$style.cellInner">Отгружено</div>
          </th>
          <th v-if="defaults.isPartialRealizationEnabled" :class="$style.cell" data-code="count">
            <div :class="$style.cellInner">Осталось к отгрузке</div>
          </th>
          <template v-if="tariffFeatures.includes('table-builder')">
            <th
              v-for="field in tableFieldCustomWorkers"
              :key="field.key"
              :class="$style.cell"
              :data-code="field.attribute">
              <div :class="$style.cellInner">
                {{ field.name }}
              </div>
            </th>
          </template>
          <th v-if="defaults.useCancellationPositions" :class="$style.cell" data-code="cancelled">
            <div :class="$style.cellInner">Отменён</div>
          </th>
          <th
            v-if="defaults.useCancellationPositions && defaults.useCancellationReasons"
            :class="$style.cell"
            data-code="reason">
            <div :class="$style.cellInner">Причина отмены</div>
          </th>
          <th v-if="tableField({ key: 'sum', action: 'show' })" :class="$style.cell" data-code="total-price">
            <div :class="$style.cellInner">Сумма</div>
          </th>
        </tr>
      </thead>

      <tbody :class="$style.tableBody">
        <ProductsTableRow v-for="(product, index) in orderProducts" :key="index" :product="product" :index="index" />
      </tbody>
    </table>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from "vuex";
  import ProductsTableRow from "./ProductsTableRow";
  import ProductsTableOptions from "./ProductsTableOptions";
  import GnzsCheckbox from "@/gnzs-controls/Checkbox";
  import $ from "jquery";

  export default {
    data() {
      return {
        clipped: false,
      };
    },
    props: {
      space: {
        type: Number,
        default: -1,
      },
    },
    computed: {
      ...mapGetters({
        stockWorktype: "settings/stockWorktype",
        orderProducts: "order/products",
        isNdsUsed: "order/isNdsUsed",
        defaults: "settings/defaults",
        someChecked: "order/someChecked",
        isAllChecked: "order/isAllChecked",
        isSomeChecked: "order/isSomeChecked",
        tariffFeatures: "features/getFeatures",
        tableField: "settings/tableField",
        tableFieldCustomWorkers: "settings/tableFieldCustomWorkers",
        scrollWidth: "table/scrollWidth",
        scrollOffset: "table/scrollOffset",
      }),
      width() {
        const w = this.$refs?.table?.offsetWidth ? this.$refs?.table?.offsetWidth + 1 + "px" : "1000px";
        return this.clipped ? (this.space < 0 ? w : this.space + "px") : w;
      },
      height() {
        return this.$refs?.table?.offsetWidth ? this.$refs?.table?.offsetHeight + 2 + "px" : "50px";
      },
    },
    methods: {
      ...mapActions({
        setCheckedAll: "order/setCheckedAll",
      }),
      handleScroll() {
        this.clipped = this.$refs?.table?.getBoundingClientRect().top <= 0 ? true : false;
      },
    },
    components: {
      ProductsTableRow,
      ProductsTableOptions,
      GnzsCheckbox,
    },
    created() {
      $(".gnzs-modal-scroller").first().on("scroll", this.handleScroll);
    },
  };
</script>

<style lang="scss" module>
  @import "./ProductsTable.scss";
</style>
