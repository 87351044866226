/**
 * @author Ilya Orishin. (continue from Artyom Baskakow) 2023
 * 
 * Функционал причин отмены
 */

import { load1cCancellationReasons } from '@/api/1c-api';

 const state = () => ({
    items: [], // Причины отмены
 });
 
 const getters = {
    items: state => state.items,
    dropdownItems: state => state.items.map(cr => ({
        title: cr.name,
        value: cr.id
    }))
 };
 
 const actions = {
     // Загрузка всех причин отмены
     async load({commit, rootGetters}){
         try {
             const resp = await load1cCancellationReasons();
 
             commit('setItems', resp.data.result)
         } catch (e) {
             console.debug(`1С ${rootGetters['partnerName']}: ошибка загрузки типовых соглашений`, e)
         }
     },
 };
 
 const mutations = {
    setItems: (state, items) => state.items = items || [],
 };
 
 export default {
     namespaced: true,
     state,
     getters,
     actions,
     mutations,
 };
 