/**
 * @author Ilya Orishin, 2022
 * 
 * Получить строковый вариант числа
 *
 * Функция позволяет получить строковый вариант номера (1 штука, 2 штуки, 5 штук)
 *
 * @param {number} number - число
 * @param {namesWords} namesWords - массив строк [штука, штуки, штук]
 * @return {string} вариант окончания
 */
export default function(number, namesWords){
    const numberStr = `${number}`
    const last2s = numberStr.substring(numberStr.length-2, numberStr.length)
    if (last2s > 10 && last2s < 15){
        return namesWords[2]
    } else {
        const rem = number % 10
        if (rem == 0) return namesWords[2]
        if (rem == 0) return namesWords[1]
        else if (rem > 1 && rem <= 4) return namesWords[1]
        else if (rem > 4 && rem < 10) return namesWords[2]
    }
    return namesWords[0]
}
