const state = {
  CARD_TAB_AREAS: ["lcard"], // на каких страницах подгружать вкладку с документами
  CARD_TAB_NAME: "(dev) Заказ 1С", // название вкладки 1С
  CARD_TAB_ID: "gnzs-1c-integration-tab_dev", // id элемента вкладки,

  errorOrderlSaveLead: "Для формирования заказа необходимо сохранить изменения в сделке",
  errorOrderLinkContact: "Для формирования заказа необходимо прикрепить к сделке компанию или контакт",
  errorOrderCustomerContact: "Для формирования заказа необходимо прикрепить к покупателю компанию или контакт",
  errorWorkOrderOnThisStatus: "На текущем этапе воронки запрещено создавать/редактировать заказ",

  partnerTypes: [
    { value: "val1", title: "Юр. лицо" },
    { value: "val2", title: "Физ. лицо" },
    { value: "val3", title: "Гос. организация" },
  ],

  discountViewTypes: [
    { value: 0, title: "В процентах" },
    { value: 1, title: "В валюте" },
  ],

  payTypes: [
    { title: "Аванс (до обеспечения)", value: 0 },
    { title: "Предоплата (до отгрузки)", value: 1 },
    { title: "Кредит (плановый)", value: 2 },
    { title: "Кредит (после отгрузки)", value: 3 },
  ],

  linkedContactsTypes: [
    { title: "компании", value: 0 },
    { title: "сделки", value: 1 },
  ],

  syncTypes: [
    { value: 0, title: "Из amoCRM в 1С" },
    { value: 1, title: "Из 1С в amoCRM" },
  ],

  updateResponsibleUserCards: [
    { value: 0, title: "Сделки" },
    { value: 1, title: "Контрагента" },
  ],

  orderTabs: [
    {
      id: "0",
      title: "Заказ",
    },
    {
      id: "1",
      title: "Реализация",
    },
    {
      id: "2",
      title: "Условия оплаты",
    },
    {
      id: "3",
      title: "Счета",
    },
    {
      id: "4",
      title: "Платёжный календарь",
    },
  ],

  // start Данные для модальных окон подтверждения. Функция модалки теперь есть в utilities/amo
  confirmApplyProvision: {
    text: "Среди выделенных товаров присутствуют товары с нулевыми остатками. Данный статус не будет к ним применен. Продолжить?",
    acceptText: "Да",
    declineText: "Нет",
  },

  confirmRemoveStorage: {
    text: "Вы собираетесь удалить хранилище. Вы уверены?",
    acceptText: "Да",
    declineText: "Нет",
  },

  confirmRemoveDocument: {
    text: "Вы действительно хотите удалить документ?",
    declineText: "Отмена",
    acceptText: "Удалить",
  },

  confirmSaveSettings: {
    text: "Пустые атрибуты полей могут нарушить работу синхронизации данных. Вы уверены?",
    declineText: "Нет",
    acceptText: "Да",
  },

  storages: [
    { title: "Dropbox", value: "dropbox" },
    { title: "Яндекс Диск", value: "yandex" },
    { title: "Google Drive", value: "google" },
  ],

  tabs: [
    {
      id: "0",
      title: "Основные",
    },
    {
      id: "1",
      title: "Заказ",
    },
    {
      id: "2",
      title: "Расширенный тариф",
    },
    {
      id: "3",
      title: "Профессиональный тариф",
    },
    {
      id: "4",
      title: "Поля",
    },
    {
      id: "5",
      title: "Дополнительно",
    },
    {
      id: "6",
      title: "Журнал",
    },
    {
      id: "7",
      title: "Другие интеграции",
    },
  ],

  confirmRemoveProducts: {
    text: "Вы собираетесь удалить один или несколько товаров. Вы уверены?",
    acceptText: "Да",
    declineText: "Нет",
  },

  confirmCloseOrder: {
    text: "Вы не сохранили внесенные изменения. Оставить окно открытым или закрыть без сохранения?",
    declineText: "Закрыть без сохранения",
    acceptText: "Не закрывать",
  },
  // end

  auth1cTypes: [
    { value: "BASIC", title: "Базовый" },
    { value: "NTLM", title: "NTLM" },
  ],

  stockWorkTypes: [
    { value: 0, title: "Заказ с одного склада" },
    { value: 1, title: "Заказ с разных складов" },
    { value: 2, title: "Не использовать склады" },
  ],

  showStockColumnTypes: [
    { value: 0, title: "Нет" },
    { value: 1, title: "Да" },
  ],

  booleanTypes: [
    { value: false, title: "Нет" },
    { value: true, title: "Да" },
  ],

  vatTypes: [
    { value: 0, title: "Без НДС" },
    { value: 1, title: "НДС в том числе" },
    { value: 2, title: "НДС сверху" },
  ],

  managerTypes: [
    { value: 0, title: "Ответственный по сделке" },
    { value: 1, title: "Текущий в системе" },
  ],

  cardItems: [
    { value: "lead", title: "Сделка" },
    { value: "customer", title: "Покупатель" },
    { value: "company", title: "Компания" },
    { value: "contact", title: "Контакт" },
  ],

  orderAttachTypes: [
    { value: 0, title: "Всегда создавать новую сделку" },
    { value: 1, title: "Прикреплять к открытой сделке контрагента" },
    { value: 2, title: "Прикреплять к сделке в определенных этапах" },
    { value: 3, title: "Не создавать сделку" },
  ],

  eventTypes1cToAmo: [
    { value: "lead_attached", title: "Прикреплен заказ к сделке" },
    { value: "lead_created", title: "Создана новая сделка" },
    { value: "realization_created", title: "Создана реализация" },
    { value: "partial_realization_created", title: "Создана частичная реализация" },
    { value: "full_payment", title: "Полная оплата" },
    { value: "partial_payment", title: "Частичная оплата" },
  ],

  eventTypesAmoTo1c: [
    {
      value: "oder_1c__create", // структура {type}__{action}__{content}
      type: "oder_1c",
      action: "create",
      content: "",
      title: "Создан заказ в 1с из amoCRM",
    },
    {
      value: "realization__create", // структура {type}__{action}__{content}
      type: "realization",
      action: "create",
      content: "",
      title: "Создана реализация",
    },
    {
      value: "partial_realization__create", // структура {type}__{action}__{content}
      type: "partial_realization",
      action: "create",
      content: "",
      title: "Создана частичная реализация",
    },
  ],

  currences: [
    { value: "руб", title: "Рубль (руб)" },
    { value: "бел. руб", title: "Белорусский рубль (руб)" },
    { value: "тг", title: "Тенге (тг)" },
    { value: "грн", title: "Гривна (грн)" },
    { value: "lei", title: "Лей (lei)" },
    { value: "usd", title: "Доллар (usd)" },
    { value: "eur", title: "Евро (eur)" },
  ],

  currencies: [
    {
      code: 643,
      name: "руб",
      isoCode: "RUB",
      sign: "₽",
    },
    {
      code: 933,
      name: "бел. руб",
      isoCode: "BYN",
      sign: "₽",
    },
    {
      code: 840,
      name: "usd",
      isoCode: "USD",
      sign: "$",
    },
    {
      code: 978,
      name: "eur",
      isoCode: "EUR",
      sign: "€",
    },
    {
      code: 398,
      name: "тг",
      isoCode: "KZT",
      sign: "₸",
    },
    {
      code: 980,
      name: "грн",
      isoCode: "UAH",
      sign: "₴",
    },
    {
      code: 946,
      name: "lei",
      isoCode: "RON",
      sign: "L",
    },
  ],

  actionsCloseAferSaveOrder: [
    { value: 0, title: "Не закрывать окно заказа" },
    { value: 1, title: "Закрывать окно заказа" },
  ],

  exportProductsTextItems: [
    { value: 0, title: "Не выгружать" },
    { value: 1, title: "Выгружать в примечание к сделке" },
    { value: 2, title: "Выгружать в поле сделки" },
    { value: 3, title: "Выгружать в примечание и в поле сделки" },
  ],

  stockCountTypeItems: [
    { value: 0, title: "По всем складам" },
    { value: 1, title: "По выбранному складу и юр. лицу" },
  ],

  stockFormatTypeItems: [
    { value: 0, fixed: true, title: "Свободный" },
    { value: 1, title: "Фактический" },
    { value: 2, title: "Резервный" },
  ],

  orderParamTypes: [
    { value: "text", code: "", title: "Текстовая строка" },
    { value: "date", code: "", title: "Дата" },
    { value: "bool", code: "", title: "Флаг" },
    { value: "int", code: "", title: "Число" },
  ],

  showEmptyStocksItems: [
    { value: 0, title: "Да" },
    { value: 1, title: "Нет" },
  ],

  showErrorsTypes: [
    { value: 0, title: "В центре нотификаций" },
    { value: 1, title: "В примечании к сделке" },
  ],

  priceChangeItems: [
    { value: 0, title: "В окне детализации" },
    { value: 1, title: "В окне добавления товара" },
  ],

  discountChangeItems: [
    { value: 0, title: "Разрешить" },
    { value: 1, title: "Запретить" },
  ],

  helpers: {
    stockColumn: `Отображение столбца "Остаток" в таблице позиций заказа при работе со складами в режиме "Заказ с разных складов"`,
    stockFormat: `Отображение остатков в формате Свободный/Фактический/Резервный в таблице подбора номенклатуры, где свободный остаток - это остаток, за исключением резерва, фактический - весь доступный остаток, резервный - резерв`,
    defaultManager: "Сопоставление менеджера по умолчанию, если в amoCRM не найден или отстуствует менеджер из 1С",
    stockCountBySearch:
      'При активной опции "По всем складам" в подборе товара отображаются суммарные остатки по всем складам и организациям. При активной опции "По выбранному складу и Юр. лицу" только по выбранным в окне детализации сделки юр.лицу и складу',
    showEmptyStocks: "При снятом флаге склады, где выбранный товар отсутствует не отображаются",
    appendCfToLeadPrice:
      "Позволяет указать числовые поля, значения которых будут суммироваться с суммой заказа и записываться в поле Бюджет. Выбранные поля отображаются в форме детализации заказа",
    disableWorkOrderStatuses:
      "Выбор воронок и этапов воронок на которых запрещено создавать или редактировать заказ 1С. При попытке открыть окно заказа будет выдаваться предупреждение",
    disablePriceChange:
      "Запрещает редактирование цены позиции пользователем в окне детализации заказа и/или в окне добавления",
    disableDiscountChange:
      "Запрещает или разрешает редактирование скидки позиции пользователем в окне детализации заказа",
    exportProductsText:
      'Дублирование детализации заказа строкой. Кастомное поле сделки должно иметь тип "Текстовая область"',
    updateResponsible: "Обновление ответственного в amoCRM при измении в 1С у выбранных сущностей",
    updateContragentName: "Обновление наименования карточки компании/контакта по данным из 1С",
    serviceUseOnly: "Только для сервисного использования в панели администратора Генезис",
    setIndividualContract: "Подстановка производится, если договор единственный",
  },

  updateButtonText: "Обновить данные в 1С",
  disabledSectionText: "Доступно только на расширенном тарифе",
  disabledProSectionText: "Доступно только на профессиональном тарифе",
};

const getters = {
  helpers: (state) => state.helpers,
  auth1cTypes: (state) => state.auth1cTypes,
  currencies: (state) => state.currencies,
  stockWorkTypes: (state) => state.stockWorkTypes,
  updateResponsibleUserCards: (state) => state.updateResponsibleUserCards,
  partnerTypes: (state) => state.partnerTypes,
  syncTypes: (state) => state.syncTypes,
  payTypes: (state) => state.payTypes,
  orderTabs: (state) => state.orderTabs,
  vatTypes: (state) => state.vatTypes,
  showStockColumnTypes: (state) => state.showStockColumnTypes,
  booleanTypes: (state) => state.booleanTypes,
  managerTypes: (state) => state.managerTypes,
  discountViewTypes: (state) => state.discountViewTypes,
  currences: (state) => state.currences,
  actionsCloseAferSaveOrder: (state) => state.actionsCloseAferSaveOrder,
  orderAttachTypes: (state) => state.orderAttachTypes,
  exportProductsTextItems: (state) => state.exportProductsTextItems,
  stockCountTypeItems: (state) => state.stockCountTypeItems,
  stockFormatTypeItems: (state) => state.stockFormatTypeItems,
  linkedContactsTypes: (state) => state.linkedContactsTypes,
  orderParamTypes(state, getters, rootState, rootGetters) {
    return [...state.orderParamTypes, ...rootGetters["additionalTypes/dropDownItems"]];
  },

  tabs: (state) => state.tabs,

  errorOrderGetLinkedEntity() {
    return (id) => `Не удалось получить контакт/компанию по id ${id}`;
  },

  cardItems: (state) => state.cardItems,

  priceChangeItems: (state) => state.priceChangeItems,
  discountChangeItems: (state) => state.discountChangeItems,

  showErrorsTypes: (state) => state.showErrorsTypes,

  updateButtonText: (state) => state.updateButtonText,
  disabledSectionText: (state) => state.disabledSectionText,
  disabledProSectionText: (state) => state.disabledProSectionText,

  errorOrderlSaveLead: (state) => state.errorOrderlSaveLead,
  errorOrderLinkContact: (state) => state.errorOrderLinkContact,
  errorOrderCustomerContact: (state) => state.errorOrderCustomerContact,
  errorWorkOrderOnThisStatus: (state) => state.errorWorkOrderOnThisStatus,

  eventTypes1cToAmo: (state) => state.eventTypes1cToAmo,
  eventTypesAmoTo1c: (state, getters, rootState, rootGetters) => {
    return [...state.eventTypesAmoTo1c, ...rootGetters["templates/eventDropdownItems"]];
  },

  showEmptyStocksItems: (state) => state.showEmptyStocksItems,

  cardTab(state) {
    return {
      name: state.CARD_TAB_NAME,
      id: state.CARD_TAB_ID,
    };
  },

  storages: (state) => state.storages,

  confirmRemoveProducts: (state) => state.confirmRemoveProducts,
  confirmCloseOrder: (state) => state.confirmCloseOrder,
  confirmApplyProvision: (state) => state.confirmApplyProvision,
  confirmRemoveStorage: (state) => state.confirmRemoveStorage,
  confirmRemoveDocument: (state) => state.confirmRemoveDocument,
  confirmSaveSettings: (state) => state.confirmSaveSettings,
};

export default {
  namespaced: true,
  state,
  getters,
};
