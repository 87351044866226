<template>
  <div :class="$style.container">
    <div :class="$style.mainInfo">
      <div v-if="!features.includes('no-additional-information-in-search')" :class="$style.cell" data-code="show-info">
        <button
          :class="[$style.detailInfoButton, { [$style.detailInfoButton_active]: showDetailInfo }]"
          title="Подробнее"
          @click="onDetailInfoClick">
          <svg>
            <use xlink:href="#gnsz-1c-icon__info"></use>
          </svg>
        </button>
      </div>
      <div v-if="defaults.showImages" :class="$style.cell" data-code="image">
        <img @click="openGallery" :class="$style.image" v-if="preview" :src="preview" />
        <svg :class="$style.noimage" v-else><use xlink:href="#gnzs-ms-icon__noimage"></use></svg>
      </div>
      <div :class="$style.cell" data-code="name" :title="item.fullname || item.name">
        <div :class="$style.cellInner" @dblclick="nameDblClickHandler" @click="nameClickHandler">
          {{ item.name }}
        </div>
        <div :class="$style.productInfo">
          <div title="Артикул">
            {{ item.article ? item.article : "—" }}
          </div>
          <div v-if="defaults.showCodeColumn || (count != 0 && count != '—')">,&nbsp;</div>
          <div v-if="defaults.showCodeColumn" title="Код">
            {{ item.code ? item.code : "—" }}
          </div>
          <div v-if="defaults.showCodeColumn && count != 0 && count != '—'">,&nbsp;</div>
          <div :class="$style.productInfo__count">
            <div v-if="count != '—'" title="Количество">{{ count }}</div>
            <div>&nbsp;</div>
            <div title="Единицы измерения">{{ item.measure }}</div>
          </div>
        </div>
      </div>
      <div :class="$style.cell" data-code="price">
        <div :class="$style.cellInner">
          {{ priceStr }}
        </div>
      </div>
      <div v-if="!showAlerAdd" :class="$style.cell" data-code="append-button">
        <input
          :class="$style.inputCount"
          type="number"
          v-model="countToOrder"
          @keypress.enter="addItemToOrder"
          min="1" />
        <GnzsButton :class="$style.appendButton" @click="addItemToOrder">Добавить</GnzsButton>
      </div>

      <div :class="[$style.alertAdd, { [$style.alertAdd_showed]: showAlerAdd }]">
        <svg :class="$style.alertAddIcon">
          <use xlink:href="#gnsz-1c-icon__check"></use>
        </svg>
        Добавлено в заказ
      </div>
    </div>

    <div v-if="showDetailInfo" :class="$style.detailInfo">
      <div v-if="item.stocks.length" :class="$style.detailInfoColumn" data-code="stocks">
        <div v-if="item.description" :class="$style.detailDescription">
          <div :class="$style.detailInfoHeader">Описание</div>
          <div>{{ item.description }}</div>
        </div>
        <div :class="$style.detailInfoHeader">Остатки</div>
        <div :class="[$style.detailInfoTableContainer, 'custom-scroll']">
          <table :class="$style.detailInfoTable">
            <thead>
              <td>Склад</td>
              <td>Организация</td>
              <td>Кол-во</td>
              <td v-if="defaults.stockFormatItems.includes(1)">Факт</td>
              <td v-if="defaults.stockFormatItems.includes(2)">Резерв</td>
            </thead>
            <tr v-for="(stock, index) in item.stocks" :key="`stock_${index}`">
              <td>{{ stock.name }}</td>
              <td>{{ stock.organization_name }}</td>
              <td>{{ stock.value }}</td>
              <td v-if="defaults.stockFormatItems.includes(1)">{{ stockColumn(stock, "fact") }}</td>
              <td v-if="defaults.stockFormatItems.includes(2)">{{ stockColumn(stock, "reserve") }}</td>
            </tr>
          </table>
        </div>
      </div>
      <div v-if="item.prices.length" :class="$style.detailInfoColumn" data-code="prices">
        <div :class="$style.detailInfoHeader">Цены</div>
        <div :class="[$style.detailInfoTableContainer, 'custom-scroll']">
          <table :class="$style.detailInfoTable" data-code="prices">
            <tr v-for="(price, index) in item.prices" :key="`price_${index}`">
              <td>{{ price.name }}</td>
              <td>{{ price.cost + " " + price.currency_name }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import _ from "underscore";
  import { mapActions, mapGetters } from "vuex";
  import { getStockCountBySearch } from "@/utilities/orderProduct";
  import GnzsButton from "@/gnzs-controls/Button";
  import initAddProductModal from "../Modals/AddProductModal";
  import $ from "jquery";

  export default {
    data() {
      return {
        showDetailInfo: false,
        showAlerAdd: false,
        countToOrder: 1, // кол-во, которое пойдет в заказ
        clickTimeoutId: null, // таймер для разделения событий клика и двойного клика
        isPreventClick: false,
        clickDelay: 500,
      };
    },
    props: {
      item: {
        type: Object,
        default: () => ({}),
      },
    },
    computed: {
      ...mapGetters({
        productCount: "settings/productCount",
        stockCountBySearch: "settings/stockCountBySearch",
        defaults: "settings/defaults",
        features: "features/getFeatures",
        orderStockId: "order/stockId",
        orderOrgId: "order/organization1cId",
        agreementId: "order/agreementId",
        agreementPtId: "agreements/getPriceTypeId",
        auth: "settings/auth",
        amoAccountId: "amoAccountId",
      }),
      stockParams() {
        return { stockCountBySearch: this.stockCountBySearch, stockFormat: this.defaults.stockFormatItems };
      },
      countTitle() {
        let title = "Свободный";
        if (this.defaults.stockFormatItems.includes(1)) title += "/Фактический";
        if (this.defaults.stockFormatItems.includes(2)) title += "/Резервный";
        return title + " остаток";
      },
      count() {
        return getStockCountBySearch(this.item, this.stockParams, this.orderStockId, this.orderOrgId);
      },
      count2() {
        if (!this.item?.stocks?.length) return "—";

        if (!this.stockCountBySearch) {
          return this.item.stocks.reduce((count, s) => (count += +s.value), 0);
        }

        if (this.stockCountBySearch == 1 && this.orderStockId.length && this.orderOrgId) {
          const stock = this.item.stocks.find((s) => s.id == this.orderStockId && s.organization_id == this.orderOrgId);
          if (stock) return stock.value;
        }

        return 0;
      },
      priceTypeId() {
        let ptId = this.$store.getters["settings/priceTypeId"];
        ptId = ptId ? ptId : this.item.prices?.[0]?.id;

        let isAgreementPrice =
          this.defaults.isAgreementsEnabled &&
          this.defaults.showAgreementsPrices &&
          this.features.includes("prices-for-agreement");

        if (isAgreementPrice) {
          const finded = this.agreementPtId(this.agreementId);
          if (finded) ptId = finded;
        }

        return ptId;
      },
      priceObj() {
        return this.item?.prices?.length
          ? this.priceTypeId?.length
            ? this.item.prices.find((p) => p.id == this.priceTypeId)
            : this.item.prices[0]
          : 0;
      },
      preview() {
        if (!this.item?.images?.length) return "";
        const mainImage = this.item.images.find((i) => i.is_main);
        if (!mainImage) return "";
        return `https://api1c.gnzs.ru/v2/product-images?Client-Id=${this.amoAccountId}&Key=${
          this.auth.key1c
        }&url=${encodeURIComponent(mainImage.preview_url)}`;
      },
      price() {
        let price = this.priceObj ? this.priceObj.cost : 0;
        if (
          this.defaults.isAgreementsEnabled &&
          this.defaults.showAgreementsPrices &&
          this.features.includes("prices-for-agreement")
        ) {
          if (this.item?.agreement_price?.price && this.item.agreement_price.price != 0) {
            price = this.item.agreement_price.price;
          }
        }
        return price;
      },
      priceStr() {
        let cost = this.priceObj ? this.priceObj.cost : 0;
        if (
          this.defaults.isAgreementsEnabled &&
          this.defaults.showAgreementsPrices &&
          this.features.includes("prices-for-agreement")
        ) {
          if (this.item?.agreement_price?.price && this.item.agreement_price.price != 0) {
            return `${cost} руб.`;
          }
        }
        return _.isEmpty(this.priceObj) ? "—" : cost + " " + this.priceObj.currency_name;
      },
    },
    methods: {
      ...mapActions({
        addProductData: "products/addProductData",
        addProduct: "order/addProduct",
        toggleGallery: "products/toggleGallery",
        setGallery: "products/setGallery",
      }),
      openGallery() {
        this.setGallery(this.item.images);
        this.toggleGallery();
      },
      stockColumn(stock, name) {
        return stock?.[name] ? stock[name] : 0;
      },
      addProductToOrder(payload) {
        this.addProductData(payload.productData);
        this.addProduct(payload);
      },
      onDetailInfoClick() {
        this.showDetailInfo = !this.showDetailInfo;
      },
      nameClickHandler() {
        this.clickTimeoutId = setTimeout(() => {
          if (!this.isPreventClick) {
            this.openProductModal();
          }
          this.isPreventClick = false;
        }, this.clickDelay);
      },
      nameDblClickHandler() {
        clearTimeout(this.clickTimeoutId);
        this.isPreventClick = true;
        this.addItemToOrder();
      },
      addItemToOrder() {
        this.addProductToOrder({
          productData: this.item,
          count: this.countToOrder,
          price: this.price,
          priceTypeId: this.priceTypeId,
        });
        this.showAlerAdd = true;
        setTimeout(() => (this.showAlerAdd = false), 900);
        let wrapper = $(`.gnzs-1c-scroll-table-wrapper`);
        let draggable = $(`.gnzs-1c-scroll-table-draggable`);
        setTimeout(() => this.$store.dispatch("table/calc", { wrapper, draggable }), 100);
      },
      openProductModal() {
        this.$store.dispatch("products/setToAdd", this.item);
        initAddProductModal(this.item, this.countToOrder);
      },
    },
    beforeMount() {
      this.countToOrder = this.productCount;
    },
    components: {
      GnzsButton,
    },
  };
</script>

<style lang="scss" module>
  @import "./SearchResultsItem.scss";
</style>
