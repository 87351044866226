import { api1C } from './api';

export const getRealizationsList = (params) => 
    api1C.get('partial-realization', {
        params,
    });

export const getRealization = (params) =>
    api1C.get('partial-realization', {
        params,
    });

export const spendRealization = (data, params) => 
	api1C.post('partial-realization', data, { params });