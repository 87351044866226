<template>
  <GnzsSection header="Работа с заказом">
    <template v-slot:description>
      Укажите настройки, которые будут отображаться по умолчанию при создании нового заказа
    </template>

    <div :class="w.table">
      <div :class="w.tableRow">
        <div>После сохранения заказа</div>
        <div>
          <GnzsDropdown
            :class="w.w200"
            v-model="actionCloseAferSaveOrder"
            :items="actionsCloseAferSaveOrder"
            :use-empty-item="false"></GnzsDropdown>
        </div>
      </div>

      <div v-if="!features.includes('no-choice-of-storage')" :class="w.tableRow">
        <div>Работа со складами</div>
        <div>
          <GnzsDropdown
            :class="w.w200"
            v-model="stockWorkType"
            :items="stockWorkTypes"
            :use-empty-item="false"></GnzsDropdown>
        </div>
      </div>

      <div :class="w.tableRow">
        <div :class="w.switcherTitle">Поле склад обязательное</div>
        <div>
          <GnzsDropdown
            :class="w.w200"
            v-model="defaults.isStockRequired"
            :items="booleanTypes"
            :disabled="stockWorkType != 0"
            :use-empty-item="false"></GnzsDropdown>
        </div>
      </div>

      <div v-if="!isInsideAmo && source.length == 0" :class="w.tableRow">
        <div :class="w.switcherTitle">Поле организация обязательное</div>
        <div>
          <div :class="w.withHelper">
            <GnzsDropdown
              :class="w.w200"
              v-model="defaults.isOrganizationRequired"
              :items="booleanTypes"
              :use-empty-item="false"></GnzsDropdown>
            <GnzsHelper side="right" :value="helpers.serviceUseOnly" />
          </div>
        </div>
      </div>

      <div :class="w.tableRow">
        <div>Отображать столбец "Остаток"</div>
        <div>
          <div :class="w.withHelper">
            <GnzsDropdown
              :class="w.w200"
              v-model="defaults.showStockColumn"
              :items="showStockColumnTypes"
              :use-empty-item="false"
              :disabled="stockWorkType != 1"></GnzsDropdown>
            <GnzsHelper side="right" :value="helpers.stockColumn" />
          </div>
        </div>
      </div>

      <div :class="w.tableRow">
        <div>Суммировать к бюджету сделки</div>
        <div>
          <div :class="w.withHelper">
            <div :class="w.w200">
              <GnzsDropdownCheckboxes
                v-if="leadNumericCfs.length"
                :class="w.w200"
                v-model="appendCfToLeadPrice"
                :items="leadNumericCfs"
                :use-empty-item="false"></GnzsDropdownCheckboxes>
              <span v-else :class="w.textOpacity5">Нет полей с типом "Число"</span>
            </div>
            <GnzsHelper side="right" :value="helpers.appendCfToLeadPrice" />
          </div>
        </div>
      </div>

      <div :class="w.tableRow">
        <div>Запрет создания/ред-я на этапах</div>
        <div>
          <div :class="w.withHelper">
            <GnzsDropdownCheckboxes :class="w.w200" v-model="disableWorkOrderStatuses" :items="amoStatuses" />
            <GnzsHelper side="right" :value="helpers.disableWorkOrderStatuses" />
          </div>
        </div>
      </div>

      <div v-if="false" :class="w.tableRow">
        <div>Запрет редактирования цены</div>
        <div>
          <div :class="w.withHelper">
            <GnzsDropdownCheckboxes
              :class="w.w200"
              v-model="defaults.disablePriceChange"
              :items="priceChangeItems"
              :useEmptyItem="false"></GnzsDropdownCheckboxes>
            <GnzsHelper side="right" :value="helpers.disablePriceChange" />
          </div>
        </div>
      </div>

      <div v-if="false" :class="w.tableRow">
        <div>Редактирование скидки</div>
        <div>
          <div :class="w.withHelper">
            <GnzsDropdown
              :class="w.w200"
              v-model="defaults.disableDiscountChange"
              :items="discountChangeItems"
              :useEmptyItem="false"></GnzsDropdown>
            <GnzsHelper side="right" :value="helpers.disableDiscountChange" />
          </div>
        </div>
      </div>

      <div :class="w.tableRow">
        <div>Выгружать позиции текстом</div>
        <div :class="$style.flexhelp">
          <div data-code="first-dropdown" :class="[w.w200, $style.mr10]">
            <GnzsDropdown
              :class="w.w200"
              v-model="exportProductsText.type"
              :use-empty-item="false"
              :items="exportProductsTextItems" />
          </div>
          <div v-if="isEnableExportProductField" :class="[w.w200, $style.mr10]">
            <GnzsDropdown
              :class="w.w200"
              v-model="exportProductsText.cfId"
              :use-empty-item="false"
              :items="leadTextareaCfs" />
          </div>
          <div :class="w.centeredContainer">
            <GnzsHelper side="right" :value="helpers.exportProductsText" />
          </div>
        </div>
      </div>

      <div :class="w.tableRow">
        <div :class="w.switcherTitle">Запретить изменение Юр. лица и РС</div>
        <div>
          <GnzsSwitcher v-model="defaults.disableOrganizationChange"></GnzsSwitcher>
        </div>
      </div>

      <div :class="w.tableRow">
        <div :class="w.switcherTitle">Обновить данные в 1С всегда доступно</div>
        <div>
          <GnzsSwitcher v-model="defaults.updateDataEnabled"></GnzsSwitcher>
        </div>
      </div>

      <div :class="w.tableRow">
        <div :class="w.switcherTitle">Включить изображения товаров</div>
        <div>
          <GnzsSwitcher v-model="defaults.showImages"></GnzsSwitcher>
        </div>
      </div>

      <div :class="w.tableRow">
        <div>Закрытие окна по клику вне области</div>
        <div>
          <GnzsSwitcher v-model="defaults.closeOrderByOverlayClick" />
        </div>
      </div>
    </div>

    <div :class="$style.paramsHeader">Дополнительные параметры</div>
    <div :class="w.table" v-if="orderCFs.length">
      <div :class="w.tableHeader">
        <div>Параметр</div>
        <div>Реквизит 1С</div>
        <div>Тип</div>
        <div>Значение по умолчанию</div>
        <div>Поле сделки</div>
        <div>Обязательное</div>
      </div>

      <div :class="w.tableRow" v-for="(param, i) in orderCFs" :key="i">
        <div>
          <GnzsInput v-model="param.title" placeholder="Название" />
        </div>
        <div>
          <GnzsInput
            :class="[param.attribute.length ? '' : $style.inputInvalid]"
            v-model="param.attribute"
            placeholder="Реквизит 1С" />
        </div>
        <div>
          <GnzsDropdown
            :class="w.w200"
            :value="CFvalue(i)"
            @input="setParamType($event, i)"
            :items="orderParamTypes"
            :useEmptyItem="false"></GnzsDropdown>
        </div>
        <div :class="w.w200">
          <GnzsInput v-if="param.type == 'text'" v-model="param.default" />
          <GnzsInput v-else-if="param.type == 'int'" :type="'number'" v-model="param.default" />
          <GnzsSwitcher v-else-if="param.type == 'bool'" v-model="param.default" />
          <DatePicker
            v-else-if="param.type == 'date'"
            :class="$style.dateInput"
            :format="'DD.MM.YYYY'"
            :clearable="false"
            type="date"
            :value="date(param.default)"
            @change="setDefaultOrderCfDate({ attribute: param.attribute, value: $event })">
            <template v-slot:icon-calendar>
              <svg :class="$style.calendar">
                <use xlink:href="#card-calendar"></use>
              </svg>
            </template>
          </DatePicker>
          <GnzsDropdown
            v-else
            :value="param.default"
            :isSearchable="typesDataTotal({ code: param.code, type: param.type }) > 10"
            :isChuncked="typesDataTotal({ code: param.code, type: param.type }) > 50"
            :isLoading="isTypesDataloading({ code: param.code, type: param.type })"
            :items="typesData({ code: param.code, type: param.type })"
            :searchedItems="typesDataSearched({ code: param.code, type: param.type })"
            @search="typesDataSearch({ code: param.code, type: param.type, query: $event })"
            @input="setDefaultOrderCfValue({ attribute: param.attribute, value: $event })"
            @scrollend="onScrollEnd({ code: param.code, type: param.type })" />
        </div>
        <div>
          <GnzsDropdown
            :class="w.w200"
            :value="entityCfValue('lead', i)"
            @input="setLeadCf($event, i)"
            :items="leadCfs"
            :isSearchable="true"
            :useEmptyItem="false"></GnzsDropdown>
        </div>
        <div :class="w.cellRequired">
          <div :class="[w.cellInner, w.checkboxRequireContainer]">
            <GnzsCheckbox v-model="param.required" />
          </div>
        </div>
        <div>
          <GnzsButton type="remove" @click="removeParams(i)" />
        </div>
      </div>
    </div>

    <GnzsButton type="append" @click="addParams" :class="[$style.mt5, w.appendParamsButton]"
      >Добавить параметр</GnzsButton
    >
  </GnzsSection>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";

  import GnzsHelper from "@/gnzs-controls/Helper";
  import GnzsSection from "@/gnzs-controls/Section";
  import GnzsInput from "@/gnzs-controls/Input";
  import GnzsDropdown from "@/gnzs-controls/Dropdown";
  import GnzsDropdownCheckboxes from "@/gnzs-controls/DropdownCheckboxes";
  import GnzsButton from "@/gnzs-controls/Button";
  import GnzsCheckbox from "@/gnzs-controls/Checkbox";
  import GnzsSwitcher from "@/gnzs-controls/Switcher";
  import DatePicker from "vue2-datepicker";
  import "@/assets/datepicker/ru";
  import "@/assets/datepicker/datepicker.css";

  import moment from "moment";

  // функция для создания методов вычисляемого поля, которое может работать с директивой v-model
  const mapFieldModel = function (fieldName) {
    return {
      get() {
        return this.$store.getters["settings/defaults"][fieldName];
      },
      set(value) {
        this.$store.dispatch("settings/setDefaultsField", { field: fieldName, value });
      },
    };
  };

  export default {
    computed: {
      ...mapGetters({
        features: "features/getFeatures",

        helpers: "localSettings/helpers",
        defaults: "settings/defaults",
        orderCFs: "settings/orderCFs",
        CFvalue: "settings/CFvalue",
        entityCfValue: "settings/entityCfValue",

        isInsideAmo: "isInsideAmo",

        source: "settings/source",

        typesData: "additionalTypes/itemData",
        typesDataTotal: "additionalTypes/itemDataTotal",
        typesDataSearched: "additionalTypes/itemDataSearched",
        isTypesDataloading: "additionalTypes/isItemDataLoading",

        orderParamTypes: "localSettings/orderParamTypes",

        actionsCloseAferSaveOrder: "localSettings/actionsCloseAferSaveOrder",
        exportProductsTextItems: "localSettings/exportProductsTextItems",

        stockWorkTypes: "localSettings/stockWorkTypes",
        showStockColumnTypes: "localSettings/showStockColumnTypes",
        booleanTypes: "localSettings/booleanTypes",

        amoStatuses: "amo/statusesDropdownItems",
        exportProductsText: "settings/exportProductsText",
        isEnableExportProductField: "settings/isEnableExportProductField",
        appendCfToLeadPrice: "settings/appendCfToLeadPrice",
        amoCfByTypes: "amo/customFieldsByTypes",
        customFieldsByIds: "amo/customFieldsByIds",
        amoCfs: "amo/customFields",

        priceChangeItems: "localSettings/priceChangeItems",
        discountChangeItems: "localSettings/discountChangeItems",
      }),

      stockWorkType: { ...mapFieldModel.call(this, "stockWorktype") },
      actionCloseAferSaveOrder: { ...mapFieldModel.call(this, "actionCloseAferSaveOrder") },
      disableWorkOrderStatuses: { ...mapFieldModel.call(this, "disableWorkOrderStatuses") },

      leadTextareaCfs() {
        return this.amoCfByTypes["lead"]["textarea"];
      },
      leadNumericCfs() {
        return this.amoCfByTypes["lead"]["numeric"];
      },
      leadCfs() {
        return this.amoCfs("lead");
      },
    },
    methods: {
      ...mapActions({
        addParams: "settings/addParams",
        removeParams: "settings/removeParams",
        setDefaultsField: "settings/setDefaultsField",
        setOrderCfType: "settings/setOrderCfType",
        setEntityCf: "settings/setEntityCf",
        load1ctypeItems: "additionalTypes/load1ctypeItems",
        typesDataSearch: "additionalTypes/searchItems",
        setDefaultOrderCfDate: "settings/setDefaultOrderCfDate",
        setDefaultOrderCfValue: "settings/setDefaultOrderCfValue",
      }),
      async onScrollEnd({ code, type }) {
        await this.load1ctypeItems(code, type);
      },
      setField(value) {
        this.setDefaultsField({ key: "showEmptyStocks", value });
      },
      setLeadCf(value, index) {
        const type = Object.values(this.customFieldsByIds.lead).find((cf) => cf.id == value)?.field_type;
        this.setEntityCf({ entity: "lead", index, type, value });
      },
      setParamType(value, index) {
        let type = typeof value == "string" ? value : value.type;
        let code = value?.code ? value.code : "";
        this.setOrderCfType({ index, type, code });
      },
      date(val) {
        return val ? new Date(moment(val, "DD.MM.YYYY 0:00:00")) : null;
      },
    },
    components: {
      GnzsSection,
      GnzsInput,
      GnzsButton,
      GnzsDropdown,
      GnzsCheckbox,
      GnzsDropdownCheckboxes,
      GnzsSwitcher,
      GnzsHelper,
      DatePicker,
    },
  };
</script>

<style lang="scss" module="w">
  @import "@/assets/scss/Settings/style.scss";
</style>

<style lang="scss" module>
  @import "./style.scss";
</style>
