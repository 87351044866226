/**
 * @author Ilya Orishin. (continue from Artyom Baskakow) 2021
 * 
 * Функционал соглашений
 */

import {load1cAgreements} from '@/api/1c-api';
import {beatArrayByConditions} from '@/utilities/agreements';


const state = () => ({
	items: [], // Фильтрованные соглашения
	all: [], // Все соглашения
	isCreating: false,
	isLoading: false,
});

const getters = {
	items: state => state.items,
	isCreating: state => state.isCreating,

	allByOrgId(state){
		// Все соглашения с фильтром по организации
		return (id) => state.all.filter(val => val.organization_id == id || val.organization_id == '00000000-0000-0000-0000-000000000000').map(i => ({
			title: i.name,
			value: i.id,
		}))
	},

	default: (state, getters, rootState, rootGetters) => {
		// Соглашение по умолчанию в настройках
		return rootGetters['settings/defaults'].defaultAgreement
	},

	common: (state) => {
		/**
		 * Общие соглашения партнера и контрагента
		 * 
		 * @param {string} pId - Ид партнера 
		 * @param {number} pId - Ид контрагента 
		 * 
		 * */ 
		return (pId, cId) => state.items.filter(val => val.partner_id == pId || val.owner_id == cId)
	},

	byPartnerId: (state) => {
		// Соглашения партнера
		return (id) => state.items.filter(val => val.partner_id == id)
	},

	byOwnerId(state){
		// Соглашения контрагента
		return (id) => state.items.filter(val => val.owner_id == id)
	},

	dropdownItems: (state, getters, rootState, rootGetters) => {
		// Соглашения группированные на типовые и индивидуальные
		return (items) => beatArrayByConditions({
			'Типовые': val => val.owner_id == 0,
			'Индивидуальные': val => val.owner_id != 0
		}, items, rootGetters['pricetypes/priceName'])
	},

	// Соглашения для выбора в настройках (только типовые)
	settingsItems: state => state.items.filter(val => val.owner_id == 0).map(i => ({
		title: i.name,
		value: i.id,
	})),

	getPriceTypeId(state){
		// Получить тип цены у соглашения
		return (id) => state.items.find(val => val.id == id)?.price_type_id
	},

	useContract(state){
		// Получить признак "Использовать договор" у соглашения
		return (id) => state.items.find(val => val.id == id)?.use_contract
	},

	getTypeNds(state){
		// Получить тип НДС у соглашения
		return (id) => {
			let nds_flag = state.items.find(val => val.id == id).nds_included
			return (nds_flag == 1) ? 1 : 2
		} 
	},

	getOrganizationId(state){
		// Получить организацию соглашения
		return (id) => state.items.find(val => val.id == id).organization_id
	}
};

const actions = {
	// Загрузка всех соглашений
	async loadAllItems({commit, rootGetters}){
		try {
			const resp = await load1cAgreements({
				limit: 100,
				offset: 0,
			});

			commit('setAllItems', resp.data.result)
		} catch (e) {
			console.debug(`1С ${rootGetters['partnerName']}: ошибка загрузки типовых соглашений`, e)
		}
	},

	async loadSettingsItems({commit, rootGetters}){
		// Загрузка соглашений для настроек (в зависимости от выбора организации по умолчанию)
		try {

			const organization_id = rootGetters['settings/defaults'].organizationId
			const resp = await load1cAgreements({
				organization_id,
				limit: 100,
				offset: 0,
			});

			commit('setItems', resp.data.result)
		} catch (e) {
			console.debug(`1С ${rootGetters['partnerName']}: ошибка загрузки типовых соглашений`, e)
		}
	},

	async load({state, commit, dispatch, rootGetters}){
		// Загрузка соглашений контрагента и организации (для окна детализации)
		try{
			state.isLoading = true;

			let {organization_id, company_id, contact_id} = rootGetters['order/orderData'];
			const entity_id = company_id ? company_id : contact_id;

			await dispatch('pricetypes/load', {}, {root: true});

			let params = {
				organization_id,
				entity_id,
				limit: 100,
				offset: 0,
			}

			if (!organization_id){
				params.ignore_entity_id = 1
			}

			const resp = await load1cAgreements(params);

			commit('setItems', resp.data.result);


		} catch(e) {
			console.debug(`1С ${rootGetters['partnerName']}: ошибка загрузки соглашений`, e);

		} finally {
			state.isLoading = false;
		}
	},
};

const mutations = {
	setItems: (state, items) => state.items = items || [],
	setAllItems: (state, items) => state.all = items || [],
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
