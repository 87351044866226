import {get1cCatalogs} from '@/api/1c-api';
import _ from 'underscore';

const state = () => ({
    /* пример структуры
        tree: {
            id: {
                id,
                name,
                isLoadedChilds,
                isOpened,
                isSearched,
                tree
            }
        }
    */
    tree: [],
    isInitialized: false,
    selectedItem: null, // ссылка на выбранный элемент при поиске
});


const getters = {
    tree: state => state.tree,
    selectedItem: state => state.selectedItem,
    selectedItemId: state => state.selectedItem?.id,
};


const actions = {
    async load({state, commit, rootGetters}){
        try{
            if(state.isInitialized) return;

            const respCatalogs = await get1cCatalogs();
            commit('setTree', {catalogs: respCatalogs.data});
            commit('setInitialized');
        } catch(e){
            console.debug(`1С ${rootGetters['partnerName']}: Ошибка получения каталогов`, e);
        }
    },

    async selectItem({dispatch, commit}, catalog){
        commit('setActive', catalog);
        if(catalog.isLoadedChilds) return;

        dispatch('loadChilds', catalog);
    },

    dropItemSelection({commit}){
        commit('dropItemSelection');
    },

    async loadChilds({commit, rootGetters}, parent){
        try{
            const respChildCatalogs = await get1cCatalogs({parent_id: parent.id});
            commit('setTree', {catalogs: respChildCatalogs.data, parent});
        }catch(e){
            console.debug(`1С ${rootGetters['partnerName']}: Ошибка получения каталогов`, e);
        }
    }
};


// пометка всех флагов открытия в false рекурсивно
function closeAll(root) {
    root.isSearched = false;
    root.isOpened = false;
    if(!root.tree.length) return;

    root.tree.forEach(t => closeAll(t));
}


const mutations = {
    setTree(state, {catalogs, parent = null}){
        let tree;

        if(_.isEmpty(parent)){
            tree = state.tree;
        } else {
            tree = parent.tree;
            parent.isLoadedChilds = true;
        }

        catalogs.forEach(c => {
            tree.push({
                parent,
                id: c.id,
                name: c.name,
                isLoadedChilds: false,
                isOpened: false,
                isSearched: false,
                tree: [],
            });
        });
    },

    setInitialized(state){
        state.isInitialized = true;
    },

    setActive(state, elem){
        state.tree.forEach(t => closeAll(t));
        elem.isSearched = true;
        state.selectedItem = elem;
        let node = elem;

        while(node){
            node.isOpened = true;
            if(_.isEmpty(node.parent)) return;
            node = node.parent;
        }
    },

    dropItemSelection(state){
        state.selectedItem = null;
    },
};



export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}