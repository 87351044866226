<template>
    <GnzsSection header='Кнопка "Провести реализацию" в окне заказа'>
        <template v-slot:description>
            Если включено, то в модальном окне детализации заказа добавится кнопка "Провести реализацию", при нажатии на которую будет запускаться процесс проведения реализации на стороне 1С. Если настройка не включена, то реализация будет проводиться при формировании закрывающей печатной формы.
            При активном функционале частичной реализации из профессионального тарифа данный функционал будет недоступен.
        </template>
        <template v-slot:header-control>
            <GnzsSwitcher
                v-model="defaults.isRealizationButtonEnabled"
            ></GnzsSwitcher>
        </template>
    </GnzsSection>
</template>

<script>
    import { mapGetters } from "vuex";

    import GnzsSection from "@/gnzs-controls/Section";
    import GnzsSwitcher from "@/gnzs-controls/Switcher";

    export default {
        computed: {
            ...mapGetters({
                defaults: "settings/defaults",
            }),
        },
        components: {
            GnzsSection,
            GnzsSwitcher,
        },
    };
</script>

<style lang="scss" module="w">
    @import '@/assets/scss/Settings/style.scss';
</style>
