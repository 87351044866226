import Vue from "vue";
import initTableFields from "./tableFields";
// import md5 from './getMd5';

const _ = require("underscore");

const initPipelineEvents = () => ({
  disableReversePipelineMove: false,
  enableMoveFromClosedStatus: false,
  orderAttachment1cToAmo: {
    type: 1, //  0 - всегда создавать новую сделку; 1 - прикреплять к любой открыткой сделке контрагента; 2 - прикреплять к сделке в определенных этапах
    searchStatuses: [], // [1, 2, 3, ...] (142, 142 - игнорируем)
    tags: "", // tag1, tag2
  },

  events1cToAmo: [
    // пример структуры
    // {
    //     type: '', //  lead_attached, realization_created
    //     statusesFrom: [324, 324, 234],
    //     statusTo: 1,
    // },
  ],
  eventsAmoTo1c: [
    // пример структуры
    // {
    //     type: '', // oder_1c, printed_forms
    //     action: '', // create, delete, ...
    //     content: '', // {название печ. формы}, ...
    //     statusesFrom: [324, 324, 234],
    //     statusTo: 1,
    // },
  ],
});

const initExportProductsText = () => ({
  type: 0, // 0 - не выгружать, 1 - выгружать в примечание, 2 - выгружать в поле сделки, 3 - выгрузать в примечание и в поле
  cfId: 0, // id кастомного поля сделки/покупателя
});

export default function (data) {
  if (!data?.source) {
    Vue.set(data, "source", []);
  }

  // eslint-disable-next-line no-prototype-builtins
  if (!data.hasOwnProperty("massActions")) {
    Vue.set(data, "massActions", {
      leads: [],
      contacts: [],
      companies: [],
    });
  }

  // eslint-disable-next-line no-prototype-builtins
  if (!data.hasOwnProperty("extraActions")) {
    Vue.set(data, "extraActions", []);
  }

  // eslint-disable-next-line no-prototype-builtins
  if (!data.defaults.hasOwnProperty("discountViewType")) {
    Vue.set(data.defaults, "discountViewType", 0);
  }

  // eslint-disable-next-line no-prototype-builtins
  if (!data.defaults.hasOwnProperty("setIndividualContract")) {
    Vue.set(data.defaults, "setIndividualContract", true);
  }

  if (!Array.isArray(data.defaults?.showStocks)) {
    Vue.set(data.defaults, "showStocks", []);
  }

  if (!data.defaults?.managerId) {
    Vue.set(data.defaults, "managerId", "");
  }

  if (!data.defaults?.closeOrderByOverlayClick) {
    Vue.set(data.defaults, "closeOrderByOverlayClick", false);
  }

  if (!data.defaults?.updateDescription) {
    Vue.set(data.defaults, "updateDescription", false);
  }

  if (!data.defaults?.showImages) {
    Vue.set(data.defaults, "showImages", false);
  }

  if (!data.defaults?.showEmptyRemains) {
    Vue.set(data.defaults, "showEmptyRemains", false);
  }

  if (!data.defaults?.isDescriptionEnabled) {
    Vue.set(data.defaults, "isDescriptionEnabled", false);
  }

  if (!data.defaults?.isContractRequired) {
    Vue.set(data.defaults, "isContractRequired", false);
  }

  // eslint-disable-next-line no-prototype-builtins
  if (!data.defaults.hasOwnProperty("isAgreementRequired")) {
    Vue.set(data.defaults, "isAgreementRequired", true);
  }

  if (!data.defaults?.disableOrganizationChange) {
    Vue.set(data.defaults, "disableOrganizationChange", false);
  }

  if (!data.defaults?.ownerAgreementFirst) {
    Vue.set(data.defaults, "ownerAgreementFirst", false);
  }

  if (!data?.extraCfs) {
    Vue.set(data, "extraCfs", {});
  }

  if (!data?.extraCfs?.descriptionCfId) {
    Vue.set(data.extraCfs, "descriptionCfId", "");
  }

  if (!data?.extraCfs?.payCalendarCfId) {
    Vue.set(data.extraCfs, "payCalendarCfId", "");
  }

  if (!data?.storage) {
    Vue.set(data, "storage", {
      use: false,
      storageId: "",
      storageType: "",
      storageMail: "",
      storageAuthLink: "",
    });
  }

  // eslint-disable-next-line no-prototype-builtins
  if (!data.defaults.hasOwnProperty("showCodeColumn")) {
    Vue.set(data.defaults, "showCodeColumn", false);
  }

  // eslint-disable-next-line no-prototype-builtins
  if (!data.defaults.hasOwnProperty("updateDataEnabled")) {
    Vue.set(data.defaults, "updateDataEnabled", false);
  }

  // eslint-disable-next-line no-prototype-builtins
  if (!data.defaults.hasOwnProperty("disablePriceChangeOnAdd")) {
    Vue.set(data.defaults, "disablePriceChangeOnAdd", false);
  }

  // eslint-disable-next-line no-prototype-builtins
  if (!data.defaults.hasOwnProperty("linkedContactsType")) {
    Vue.set(data.defaults, "linkedContactsType", [0]);
  }

  // eslint-disable-next-line no-prototype-builtins
  if (!data.defaults.hasOwnProperty("isSignatoriesEnabled")) {
    data.defaults.isSignatoriesEnabled = false;
  }

  // eslint-disable-next-line no-prototype-builtins
  if (!data.defaults.hasOwnProperty("checkEmptyContactsData")) {
    data.defaults.checkEmptyContactsData = true;
  }

  // eslint-disable-next-line no-prototype-builtins
  if (!data.defaults.hasOwnProperty("isContractCreationEnabled")) {
    data.defaults.isContractCreationEnabled = true;
  }

  // eslint-disable-next-line no-prototype-builtins
  if (!data.defaults.hasOwnProperty("disableCommonAgreements")) {
    data.defaults.disableCommonAgreements = false;
  }

  // eslint-disable-next-line no-prototype-builtins
  if (!data.defaults.hasOwnProperty("disableCommonContracts")) {
    data.defaults.disableCommonContracts = false;
  }

  if (typeof data.defaults.stockCountBySearch == "undefined") {
    // если не было настройки Остатки при подборе
    data.defaults.stockCountBySearch = 0;
  }

  const entities = ["company", "contact", "lead"];
  const exceptFields = ["payment_detail", "payment_flag", "partial_payment_flag", "payment_sum", "payment_full_date"];

  entities.forEach((entity) => {
    for (let key in data.orderFields[entity]) {
      let fSet = data.orderFields[entity][key];
      /* eslint-disable-next-line no-prototype-builtins */
      if (!(fSet.hasOwnProperty("sync") || exceptFields.includes(key))) {
        Vue.set(fSet, "sync", 0);
      }

      /* eslint-disable-next-line no-prototype-builtins */
      if (!fSet.hasOwnProperty("type")) {
        Vue.set(fSet, "type", "");
      }
    }
  });

  if (!data.defaults?.showStockColumn) {
    // если нет настройки показывать столбец "остаток"
    data.defaults.showStockColumn = 0;
  }
  /* eslint-disable-next-line no-prototype-builtins */
  if (data.defaults.hasOwnProperty("stockWorktype") && data.defaults.stockWorktype == 3) {
    data.defaults.stockWorktype = 1;
    data.defaults.showStockColumn = 1;
  }

  //прикручиваем поля секции iframe если еще не были добавлены в объект настроек
  if (!data?.iframe) {
    Vue.set(data, "iframe", {
      use: false,
      login: "",
      password: "",
      v2: {
        use: false,
        users: [],
      },
    });
  }

  if (!data?.iframe?.v2) {
    Vue.set(data.iframe, "v2", {
      use: false,
      users: [],
    });
  }

  //прикручиваем поля реализация, если еще не были добавлены в объект настроек
  if (!data?.realizationsInfo) {
    Vue.set(data, "realizationsInfo", [
      { dateCf: "", numberCf: "" },
      { dateCf: "", numberCf: "" },
      { dateCf: "", numberCf: "" },
      { dateCf: "", numberCf: "" },
    ]);
  }

  /*
      realization_flag: {id: '', required: false},
      partial_realization_flag: {id: '', required: false},
      realization_full_date: {id: '', required: false},
      realization_detail: {id: '', required: false},
      realization_sum: {id: '', required: false},
  */

  if (!data.orderFields.lead.realization_sum) {
    // если не было настройки
    Vue.set(data.orderFields.lead, "realization_sum", { id: "", unupdatable: false, required: false });
  }

  if (!data.orderFields.lead.realization_detail) {
    // если не было настройки
    Vue.set(data.orderFields.lead, "realization_detail", { id: "", unupdatable: false, required: false });
  }

  if (!data.orderFields.lead.partial_realization_flag) {
    // если не было настройки
    Vue.set(data.orderFields.lead, "partial_realization_flag", { id: "", unupdatable: false, required: false });
  }

  //прикручиваем поля оплат, если еще не были добавлены в объект настроек
  if (!data?.paysInfo) {
    Vue.set(data, "paysInfo", [
      { dateCf: "", sumCf: "" },
      { dateCf: "", sumCf: "" },
      { dateCf: "", sumCf: "" },
      { dateCf: "", sumCf: "" },
    ]);
  }

  //прикручиваем pipelineEvents, если еще не были добавлены в объект настроек
  if (_.isEmpty(data.pipelineEvents)) {
    data.pipelineEvents = initPipelineEvents();
  }

  /* eslint-disable-next-line no-prototype-builtins */
  if (!data.pipelineEvents.hasOwnProperty("disableReversePipelineMove")) {
    Vue.set(data.pipelineEvents, "disableReversePipelineMove", false);
  }

  /* eslint-disable-next-line no-prototype-builtins */
  if (!data.pipelineEvents.hasOwnProperty("enableMoveFromClosedStatus")) {
    Vue.set(data.pipelineEvents, "enableMoveFromClosedStatus", false);
  }

  /* eslint-disable-next-line no-prototype-builtins */
  if (!data.defaults.hasOwnProperty("isStockRequired")) {
    Vue.set(data.defaults, "isStockRequired", true);
  }

  /* eslint-disable-next-line no-prototype-builtins */
  if (!data.defaults.hasOwnProperty("isOrganizationRequired")) {
    Vue.set(data.defaults, "isOrganizationRequired", true);
  }

  /* eslint-disable-next-line no-prototype-builtins */
  if (!data.defaults.hasOwnProperty("isProductsEnabled")) {
    Vue.set(data.defaults, "isProductsEnabled", false);
  }

  if (!data?.partners) {
    // если нет настройки партнеры
    Vue.set(data, "partners", {
      cf_company_partner: "",
      cf_contact_partner: "",
      directory_code: "",
      directory_type: "",
    });
  }

  if (!data?.organizationsDefaults) {
    // если нет настроек по умолчанию для организаций
    Vue.set(data, "organizationsDefaults", []);
  }

  if (!data?.managerDefaults) {
    // если нет настроек по умолчанию для менеджеров
    Vue.set(data, "managerDefaults", []);
  }

  if (!data.defaults?.stockFormat) {
    // если нет настройки формата остатков
    data.defaults.stockFormat = 0;
  }

  if (!data?.cardsFields) {
    // если нет настройки справочников внутри сущностей
    Vue.set(data, "cardsFields", []);
  }

  if (!data.defaults?.showAgreementsPrices) {
    // если нет настройки показывать цены в зависимости от соглашения
    data.defaults.showAgreementsPrices = false;
  }

  /* eslint-disable-next-line no-prototype-builtins */
  if (!data.defaults.hasOwnProperty("isRealizationButtonEnabled")) {
    data.defaults.isRealizationButtonEnabled = false;
  }

  if (!Array.isArray(data.defaults?.disablePriceChange)) {
    // если нет настройки запрета редактирования цены
    Vue.set(data.defaults, "disablePriceChange", []);
  }

  if (!data.defaults?.disableDiscountChange) {
    // если нет настройки запрета редактирования скидки
    data.defaults.disableDiscountChange = 0;
  }

  if (!data.defaults?.isEvotorButtonEnabled) {
    // если нет настройки реализации
    data.defaults.isEvotorButtonEnabled = false;
  }

  if (!data.defaults?.isAutoDiscountEnabled) {
    // если нет настройки реализации
    data.defaults.isAutoDiscountEnabled = false;
  }

  if (!data.defaults?.isInvoicesEnabled) {
    // если нет настройки реализации
    data.defaults.isInvoicesEnabled = false;
  }

  if (!data.defaults?.isPartialRealizationEnabled) {
    // если нет настройки реализации
    data.defaults.isPartialRealizationEnabled = false;
  }

  if (!data.defaults?.isPaysCalendarEnabled) {
    // если нет настройки реализации
    data.defaults.isPaysCalendarEnabled = false;
  }

  if (!data.defaults?.partnerId) {
    // если нет партнера
    data.defaults.partnerId = "";
  }

  if (!data.defaults.currency) {
    // если не заполнена валюта
    data.defaults.currency = "руб";
  }
  if (!data.defaults.exportProductsText) {
    // если не заполнена валюта
    data.defaults.exportProductsText = initExportProductsText();
  }

  /* eslint-disable-next-line no-prototype-builtins */
  if (!data.defaults.hasOwnProperty("useCancellationPositions")) {
    data.defaults.useCancellationPositions = false;
  }

  /* eslint-disable-next-line no-prototype-builtins */
  if (!data.defaults.hasOwnProperty("useCancellationReasons")) {
    data.defaults.useCancellationReasons = false;
  }

  /* eslint-disable-next-line no-prototype-builtins */
  if (!data.defaults.hasOwnProperty("isCancellationEditDisabled")) {
    data.defaults.isCancellationEditDisabled = false;
  }
  if (!data.defaults.appendCfToLeadPrice) {
    // если не заполнена валюта
    data.defaults.appendCfToLeadPrice = [];
  }
  if (!data.defaults.showErrors) {
    // если нет настройки отображения ошибок интеграции
    data.defaults.showErrors = [];
  }
  if (!data.defaults.updateEntityNames) {
    // если нет настройки обновления имен сущностей
    data.defaults.updateEntityNames = false;
  }

  /* eslint-disable-next-line no-prototype-builtins */
  if (!data.defaults.hasOwnProperty("updateResponsibleUser")) {
    data.defaults.updateResponsibleUser = [];
  }
  if (!data.defaults.defaultAgreement) {
    // если нет соглашения по умолчанию
    data.defaults.defaultAgreement = "";
  }
  if (!data.defaults.actionCloseAferSaveOrder) {
    // если не было настройки закрытия модалки
    data.defaults.actionCloseAferSaveOrder = 0;
  }

  /* eslint-disable-next-line no-prototype-builtins */
  if (!data.defaults.hasOwnProperty("actionCloseAferSaveRealization")) {
    data.defaults.actionCloseAferSaveRealization = 0;
  }
  if (!data.defaults.productCount) {
    // если не было настройки закрытия модалки
    data.defaults.productCount = 1;
  }
  if (typeof data.defaults.stockCountBySearch == "undefined") {
    // если не было настройки Остатки при подборе
    data.defaults.stockCountBySearch = 0;
  }
  if (!data.defaults.disableWorkOrderStatuses) {
    // если не было настройки
    data.defaults.disableWorkOrderStatuses = [];
  }
  if (!data.defaults.enableContragentCreating) {
    data.defaults.enableContragentCreating = [];
  }
  if (!data.defaults.stockFormatItems) {
    Vue.set(data.defaults, "stockFormatItems", [0]);
  }
  if (data.defaults.stockFormat == 1) {
    Vue.set(data.defaults, "stockFormatItems", [0, 1]);
    data.defaults.stockFormat = 0;
  }
  if (!data.orderFields.lead.order_sum) {
    // если не было настройки
    Vue.set(data.orderFields.lead, "order_sum", { id: "", unupdatable: false, required: false });
  }

  if (!data.orderFields.lead.payment_full_date) {
    // если не было настройки
    Vue.set(data.orderFields.lead, "payment_full_date", { id: "", unupdatable: false, required: false });
  }

  if (!data.orderFields.lead.payment_detail) {
    // если не было настройки
    Vue.set(data.orderFields.lead, "payment_detail", { id: "", unupdatable: false, required: false });
  }

  if (!data.orderFields.lead.realization_created) {
    // если не было настройки
    Vue.set(data.orderFields.lead, "realization_created", { id: "", unupdatable: false, required: false });
  }

  if (!data.orderFields.lead.realization_number) {
    // если не было настройки
    Vue.set(data.orderFields.lead, "realization_number", { id: "", unupdatable: false, required: false });
  }

  if (!data.orderFields.lead.realization_date) {
    // если не было настройки
    Vue.set(data.orderFields.lead, "realization_date", { id: "", unupdatable: false, required: false });
  }

  if (!data.orderFields.lead.order_description) {
    // если не было настройки
    Vue.set(data.orderFields.lead, "order_description", { id: "", sync: 0, unupdatable: false, required: false });
  }

  if (!data.orderFields.company.company_tax_number) {
    // если не было настройки
    Vue.set(data.orderFields.company, "company_tax_number", { id: "", sync: 0, unupdatable: false, required: false });
  }

  if (!data.orderFields.company.company_1c_code) {
    // если не было настройки
    Vue.set(data.orderFields.company, "company_1c_code", { id: "", sync: 0, unupdatable: false, required: false });
  }

  if (!data.orderFields.contact.contact_1c_code) {
    // если не было настройки
    Vue.set(data.orderFields.contact, "contact_1c_code", { id: "", sync: 0, unupdatable: false, required: false });
  }

  if (!data?.contractFields) {
    Vue.set(data, "contractFields", {});
  }

  /* eslint-disable-next-line no-prototype-builtins */
  if (!data?.contractFields.hasOwnProperty("name_required")) {
    Vue.set(data.contractFields, "name_required", true);
  }

  /* eslint-disable-next-line no-prototype-builtins */
  if (!data?.contractFields.hasOwnProperty("number_required")) {
    Vue.set(data.contractFields, "number_required", false);
  }
  if (!data?.contractFields?.date_required) {
    Vue.set(data.contractFields, "date_required", false);
  }
  if (!data?.contractFields?.date_to_required) {
    Vue.set(data.contractFields, "date_to_required", false);
  }
  if (!data?.contractFields?.contract_custom_fields) {
    // если не было настройки
    Vue.set(data.contractFields, "contract_custom_fields", []);
  }
  if (!data.orderFields.order_custom_fields) {
    // если не было настройки
    Vue.set(data.orderFields, "order_custom_fields", []);
  }

  data.orderFields.order_custom_fields.forEach((v) => {
    /* eslint-disable-next-line no-prototype-builtins */
    if (!v.hasOwnProperty("default")) {
      v.default = null;
    }
  });

  if (!data.orderFields.lead.templates) {
    // если не было настройки
    Vue.set(data.orderFields.lead, "templates", {});
  }
  if (!data.orderFields.lead.partial_payment_flag) {
    // если не было настройки
    Vue.set(data.orderFields.lead, "partial_payment_flag", { id: "", unupdatable: false, required: false });
  }

  /* eslint-disable-next-line no-prototype-builtins */
  if (!data.hasOwnProperty("tableFields")) {
    Vue.set(data, "tableFields", initTableFields());
  }

  data.tableFields.forEach((tf) => {
    /* eslint-disable-next-line no-prototype-builtins */
    if (!tf.hasOwnProperty("required")) {
      Vue.set(tf, "required", false);
    }
  });

  if (!data.defaults.isRequestContractInfo) {
    // если не было настройки
    Vue.set(data.defaults, "isRequestContractInfo", false);
  }

  ["lead", "company", "contact"].forEach((entity) => {
    for (const key in data.orderFields[entity]) {
      if (key != "custom_fields") {
        if (!data.orderFields[entity][key].unupdatable) {
          Vue.set(data.orderFields[entity][key], "unupdatable", false);
        }
      }
    }
  });

  ["lead", "company", "contact"].forEach((entity) => {
    data.orderFields[entity].custom_fields.forEach((cf) => {
      if (!cf.unupdatable) Vue.set(cf, "unupdatable", false);
    });
  });
}
