<template>
    <div :class="[$style.options, clipped ? $style.clipped : '']"
         :style="bounds">
         <div :class="$style.checkable">
        <GnzsCheckbox
            :value="isAllChecked || isSomeChecked"
            :class="$style.optionsChecker"
            :isSomeChecked="isSomeChecked"
            @input="setCheckedAll"
        >
        </GnzsCheckbox>
        </div>
        <ul :class="$style.list">
            <template v-for="(option, index) in options">
                <li v-if="option.show" :key="index"
                    @click="option.click" 
                    :class="[$style.list_item, {[$style.list_item_standart] : option.standart}]"
                ><svg :class="option.classes"><use :xlink:href="option.icon"></use></svg>{{option.name}}</li>
            </template>
        </ul>
    </div>
</template>

<script>
import GnzsCheckbox from '@/gnzs-controls/Checkbox';

import {mapActions, mapGetters} from 'vuex';
import openProvisionModal from './../Modals/AddProvisionModal';
import openDiscountModal from './../Modals/AddDiscountModal';
import openPricetypeModal from './../Modals/AddPricetypeModal';
import openStockModal from './../Modals/AddStockModal';
import openVatModal from './../Modals/AddVatModal';
import openSelectByProvisionModal from './../Modals/SelectByProvModal';
import {openConfirmModal} from '@/gnzs-controls/Modal/modal.utils';

export default {
    props: {
        bounds: {
            type: Object
        },
        clipped: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapGetters({
            someChecked: 'order/someChecked',
            isAllChecked: 'order/isAllChecked',
            isSomeChecked: 'order/isSomeChecked',
            defaults: 'settings/defaults',
            tariffFeatures: 'features/getFeatures',
            confirmData: 'localSettings/confirmRemoveProducts',
            isNdsUsed: 'order/isNdsUsed',
            tableField: 'settings/tableField',
        }),
        options(){
            return [
            {
                    name: '',
                    icon: '#gnzs-1c-icon__arrow-up',
                    classes: [this.$style.icon, this.$style.icon_arrow],
                    show: true,
                    click: this.moveUp,
                    standart: false
                },
                {
                    name: '',
                    icon: '#gnzs-1c-icon__arrow-down',
                    classes: [this.$style.icon, this.$style.icon_arrow],
                    show: true,
                    click: this.moveDown,
                    standart: false
                },
                {
                    name: 'Реализация',
                    icon: '#gnzs-1c-icon__shipment',
                    classes: [this.$style.icon, this.$style.icon_shipment],
                    show: this.defaults.isPartialRealizationEnabled && this.tariffFeatures.includes('partial-realization'),
                    click: this.addRealization,
                    standart: true
                },
                {
                    name: 'Счет',
                    icon: '#gnzs-1c-icon__invoice',
                    classes: [this.$style.icon, this.$style.icon_invoice],
                    show: this.defaults.isInvoicesEnabled && this.tariffFeatures.includes('partial-invoices'),
                    click: this.addInvoice,
                    standart: true
                },
                {
                    name: 'Выделить по обеспечению',
                    icon: '#gnzs-1c-icon__select',
                    classes: [this.$style.icon, this.$style.icon_select],
                    show: this.tariffFeatures.includes('provisions') && this.defaults.isProvisionEnabled,
                    click: this.selectByProvision,
                    standart: true
                },
                {
                    name: 'Обеспечение',
                    icon: '#gnzs-1c-icon__list',
                    classes: [this.$style.icon, this.$style.icon_provision],
                    show: this.tariffFeatures.includes('provisions') && this.defaults.isProvisionEnabled,
                    click: this.addProvision,
                    standart: true  
                },
                {
                    name: 'Склад',
                    icon: '#gnzs-1c-icon__store',
                    classes: [this.$style.icon, this.$style.icon_store],
                    show: this.defaults.stockWorktype == 1,
                    click: this.addStock,
                    standart: true
                },
                {
                    name: 'Тип цены',
                    icon: '#gnzs-1c-icon__pricetype',
                    classes: [this.$style.icon, this.$style.icon_pricetype],
                    show: true,
                    click: this.addPricetype,
                    standart: true
                },
                {
                    name: 'Скидка',
                    icon: '#gnzs-1c-icon__pricetype',
                    classes: [this.$style.icon, this.$style.icon_discount],
                    show: true,
                    click: this.addDiscount,
                    standart: true
                },
                {
                    name: 'НДС',
                    icon: '#gnzs-1c-icon__vat',
                    classes: [this.$style.icon, this.$style.icon_vat],
                    show: this.isNdsUsed && this.tableField({key: 'vat', action: 'edit'}),
                    click: this.addVat,
                    standart: true
                },
                {
                    name: 'Удалить',
                    icon: '#gnzs-1c-icon__delete',
                    classes: [this.$style.icon_delete],
                    show: true,
                    click: this.remove,
                    standart: true
                },
            ]
        }
    },
    methods: {
        ...mapActions({
            moveUp: 'order/moveUp',
            moveDown: 'order/moveDown',
            removeProducts: 'order/removeProducts',
            setCheckedAll: 'order/setCheckedAll',
            newRealization: 'realizations/newItem',
            newInvoice: 'invoices/newItem',
            setTabMode: 'order/setTabMode',
            calcOrder: 'order/calcOrder'
        }),
        addRealization(){
            this.newRealization()
            this.setCheckedAll(false)
            this.setTabMode('1')
        },
        addInvoice(){
            this.newInvoice()
            this.setCheckedAll(false)
            this.setTabMode('3')
        },
        addProvision(){
            openProvisionModal()
        },
        addPricetype(){
            openPricetypeModal()
        },
        selectByProvision(){
            openSelectByProvisionModal()
        },
        addDiscount(){
            openDiscountModal()
        },
        addVat(){
            openVatModal()
        },
        addStock(){
            openStockModal()
        },
        remove(){
            openConfirmModal({
                ...this.confirmData,
                onAccept: () => {
                    this.removeProducts()
                    this.calcOrder()
                    this.setCheckedAll(false)
                }
            })
        }
    },
    components: {
        GnzsCheckbox
    }
}
</script>

<style lang="scss" module>
    @import './ProductsTable.scss';
</style>