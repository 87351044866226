/**
 * @author Ilya Orishin 2022, continue from Artyom Baskakow
 * 
 * Отправка файла по email
 */
import { getRawDocument } from '@/api/1c-api';
import {getDropboxSharingFileDataByLink} from './dropbox';


/**
 * Отправить бинарный файл по email из amo
 * 
 * @param {Object} 
 * {
 *  fileLink - ссылка на файл
 *  filename - имя файла
 *  iframeName - имя окна
 *  id файла в таблице (только для яндекс хранилища)
 * }
 * @returns {void}
 */
export const sendDocToEmail = async function({fileLink, filename, iframeName}){

    try{

        const response = await getRawDocument(fileLink)
        const fileData = response.data

        window.parent.postMessage({ event: `${iframeName}:sendDocToEmail`, doc: {fileData, filename} }, "*");

    } catch(e){
        console.debug('1С Интеграция: Ошибка', e);
        throw 'Ошибка получения данных документа по ссылке';
    }
}

/**
 * Конвертация File в Blob
 * 
 * @param {File} file - файл
 * @returns {Blob} - бинарник файла
 */
const fileToBlob = async (file) => new Blob([new Uint8Array(await file.arrayBuffer())], {type: file.type });

/**
 * Отправить файл по email из amo
 * 
 * @param {Object} 
 * {
 *  dropboxToken - токен дропбокс
 *  fileLink - ссылка на файл
 *  filename - имя файла
 *  iframeName - имя окна
 * }
 * @returns {void}
 */
export const sendDropboxDocToEmail = async function({dropboxToken, fileLink, iframeName, filename}){

    try{

        const response = await getDropboxSharingFileDataByLink({
            accessToken: dropboxToken, 
            link: fileLink,
            fileName: filename,
        });

        const fileData = await fileToBlob(response[0]) 

        window.parent.postMessage({ event: `${iframeName}:sendDocToEmail`, doc: {fileData, filename} }, "*");

    } catch(e){
        console.debug('1С Интеграция: Ошибка', {e});
        throw 'Ошибка получения данных документа по ссылке';
    }
}