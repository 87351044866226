<template>
    <div>
        <div :class="$style.itemsWrapper">
            <GnzsDropdown 
                :class="$style.realizationsDropdown"
                v-model="activeItem"
                :items="invoices"
            />
        </div>
        <table :class="$style.rtable">
            <thead ref="table" :class="$style.rtableHeader">
                <tr :class="$style.rtableRow">
                    <th :class="p.cell" data-code="number">
                        <div :class="p.cellInner">№</div>
                    </th>
                    <th :class="p.cell" data-code="article">
                        <div :class="p.cellInner">
                            Артикул
                        </div>
                    </th>
                    <th :class="p.cell" data-code="name">
                        <div :class="p.cellInner">
                            Наименование
                        </div>
                    </th>
                    <th v-if="stockWorktype==1 || stockWorktype == 3" :class="p.cell" data-code="stock">
                        <div :class="p.cellInner">
                            Склад
                        </div>
                    </th>
                    <th :class="p.cell" data-code="units">
                        <div :class="p.cellInner">
                            Ед. изм
                        </div>
                    </th>
                    <th :class="p.cell" data-code="count">
                        <div :class="p.cellInner">
                            Кол-во
                        </div>
                    </th>
                    <th :class="p.cell" data-code="price">
                        <div :class="p.cellInner">
                            Цена
                        </div>
                    </th>
                    <th :class="p.cell" data-code="total-price">
                        <div :class="p.cellInner">
                            Сумма
                        </div>
                    </th>
                </tr>
                
            </thead>

            <tbody :class="$style.rtableBody">
                <InvoiceTableRow
                    v-for="(product, index) in products" 
                    :key="index"
                    :product="product"
                    :index="index"
                />
            </tbody>

        </table>
        <div v-if="isLoading" :class="$style.loader">
            <span class="spinner-icon"></span>
        </div>
    </div>
</template>

<script>
import GnzsDropdown from '@/gnzs-controls/Dropdown';
import InvoiceTableRow from './InvoiceTableRow';
import {mapGetters} from 'vuex';

// функция для создания методов вычисляемого поля, которое может работать с директивой v-model
const mapFieldModel = function(key){
    return {
        get(){
            return this.$store.getters[`invoices/getValue`](key);
        },
        set(value){
            this.$store.dispatch('invoices/setValue', {key, value});
        }
    }
}

export default {
    computed: {
        ...mapGetters({
            defaults: 'settings/defaults',
            stockWorktype: 'settings/stockWorktype',
            invoices: 'invoices/list',
            item: 'invoices/item',
            products: 'invoices/itemProducts',
            isLoading: 'invoices/isLoading'
        }),
        activeItem: { ...mapFieldModel.call(this, 'active') }
    },
    components: {
        GnzsDropdown,
        InvoiceTableRow
    }
}
</script>

<style lang="scss" module>
    @import 'Invoices';
</style>

<style lang="scss" module="p">
    @import '../ProductsTable/ProductsTable.scss';
</style>