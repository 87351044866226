<template>
    <Modal ref="modal">
        <template v-slot>
            <div>
                <span class="modal-body__close" ref="close">
                    <span class="icon icon-modal-close"></span>
                </span>

                <div :class="$style.header">
                    Новый партнер
                </div>

                <div :class="$style.body">
                    <!-- Имя партнера -->
                    <div :class="$style.row">
                        <div :class="$style.cell">
                            Имя партнера
                        </div>
                        <div :class="$style.cell" ref="partnerName">
                            <GnzsInput 
                                tabindex="1" 
                                v-model="name" 
                            />
                        </div>
                    </div>

                    <!-- Тип партнера -->
                    <div :class="$style.row">
                        <div :class="$style.cell">
                            Тип партнера
                        </div>
                        <div :class="$style.cell" data-code="type" >
                            <GnzsDropdown
                                tabindex="2" 
                                v-model="type"
                                :items="types" 
                            />
                        </div>
                    </div>

                </div>
                <div :class="$style.actions">
                    <GnzsButton 
                        type="primary" 
                        @click="create" 
                        :loading="isCreating"
                        :disabled="!allowCreate"
                    >Создать партнера</GnzsButton>
                    <GnzsButton type="cancel" @click="closeModal">Отмена</GnzsButton>
                </div>
            </div>
        </template>
    </Modal>
</template>

<script>
import { mapActions, mapGetters} from 'vuex';

import Modal from '@/gnzs-controls/Modal';

import GnzsInput from '@/gnzs-controls/Input';
import GnzsDropdown from '@/gnzs-controls/Dropdown';
import GnzsButton from '@/gnzs-controls/Button';

export default {
    data(){
        return {
            name: '',
            type: '',
        }
    },
    computed: {
        ...mapGetters({
            isCreating: 'partners/isPartnerCreating',
            types: 'localSettings/partnerTypes'
        }),

        allowCreate(){
            return this.name.length && this.type;
        }
    },
    methods: {
        ...mapActions({
            createPartner: 'partners/create'
        }),
        closeModal(){
            this.$refs.modal.close()
        },
        
        async create(){
            await this.createPartner({
                name: this.name,
                type: this.type,      
            })
            this.closeModal();
        },
    },
    components: {
        Modal,
        GnzsInput,
        GnzsButton,
        GnzsDropdown
    }
}
</script>


<style lang="scss" module>
    @import './AddPartnerModal.scss';
</style>