<template>
    <GnzsSection header='Область подбора товаров'>
        <template v-slot:description>
            Настройте правила отображения товаров в области подбора
        </template>

        <div :class="w.table">
            
            <div :class="w.tableRow">
                <div>Остатки при подборе</div>
                <div>
                    <div :class="w.withHelper">
                        <GnzsDropdown 
                            :class="w.w200"
                            v-model="defaults.stockCountBySearch" 
                            :items="stockCountTypeItems"
                            :use-empty-item="false"
                        ></GnzsDropdown>
                        <GnzsHelper side="right" :value="helpers.stockCountBySearch"/>
                    </div>
                </div>
            </div>

            <div :class="w.tableRow">
                <div>Формат отображения остатков</div>
                <div>
                    <div :class="w.withHelper">
                        <GnzsDropdownCheckboxes
                            :class="w.w200"
                            v-model="defaults.stockFormatItems" 
                            :items="stockFormatTypeItems"
                            :use-empty-item="false"
                        ></GnzsDropdownCheckboxes>
                        <GnzsHelper side="right" :value="helpers.stockFormat"/>
                    </div>
                </div>
            </div>

            <div :class="w.tableRow">
                <div>Отображать склады с нулевым остатком</div>
                <div>
                    <div :class="w.withHelper">
                        <GnzsDropdown 
                            :class="w.w200"
                            v-model="defaults.showEmptyStocks" 
                            :items="showEmptyStocksItems"
                            :use-empty-item="false"
                        ></GnzsDropdown>
                        <GnzsHelper side="right" :value="helpers.showEmptyStocks"/>
                    </div>
                </div>
            </div>

            <div :class="w.tableRow">
                <div>Опция "Скрывать нулевые остатки" по-умолчанию</div>
                <div>
                    <gnzs-dropdown 
                        :class="w.w200"
                        v-model="defaults.showEmptyRemains" 
                        :items="[{title: 'Активна', value: true},{title: 'Не активна', value: false}]"
                        :use-empty-item="false"
                    ></gnzs-dropdown>
                </div>
            </div>

            <div :class="w.tableRow">
                <div>Отображать склады и товары на них</div>
                <div>
                    <div :class="w.withHelper">
                        <GnzsDropdownCheckboxes
                            :class="w.w200"
                            v-model="defaults.showStocks" 
                            :items="stocks"
                            :is-searchable="stocks.length > 5"
                            :use-empty-item="false"
                        ></GnzsDropdownCheckboxes>
                    </div>
                </div>
            </div>

            <div :class="w.tableRow">
                <div :class="w.switcherTitle">Отображать код товара</div>
                <div>
                    <GnzsSwitcher
                        v-model="defaults.showCodeColumn"
                    ></GnzsSwitcher>
                </div>
            </div>

            <div :class="w.tableRow">
                <div :class="w.switcherTitle">Запрет редактирования цены</div>
                <div>
                    <GnzsSwitcher
                        v-model="defaults.disablePriceChangeOnAdd"
                    ></GnzsSwitcher>
                </div>
            </div>

        </div>
    </GnzsSection>
</template>

<script>
import GnzsHelper from '@/gnzs-controls/Helper';
import GnzsSection from '@/gnzs-controls/Section';
import GnzsDropdown from '@/gnzs-controls/Dropdown';
import GnzsDropdownCheckboxes from '@/gnzs-controls/DropdownCheckboxes';
import GnzsSwitcher from '@/gnzs-controls/Switcher';

import {mapGetters} from 'vuex'

export default {
    computed: {
        ...mapGetters({
            defaults: 'settings/defaults',
            stocks: 'stocks/dropdownItems',
            stockCountTypeItems: 'localSettings/stockCountTypeItems',
            stockFormatTypeItems: 'localSettings/stockFormatTypeItems',
            showEmptyStocksItems: 'localSettings/showEmptyStocksItems',
            helpers: 'localSettings/helpers',
        })
    },
    components: {
        GnzsSection,
        GnzsDropdown,
        GnzsHelper,
        GnzsSwitcher,
        GnzsDropdownCheckboxes
    },
}
</script>

<style lang="scss" module>
</style>

<style lang="scss" module="w">
    @import '@/assets/scss/Settings/style.scss';
</style>