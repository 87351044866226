import { API_AMOCRM } from "./api";

import {apiCoreInterceptor} from './interceptors';

API_AMOCRM.interceptors.request.use(apiCoreInterceptor)

export const getEntityById = async ({entitiesType, id}) => {
    const response = await API_AMOCRM.get(`/amo/${entitiesType}/${id}`, { params: { with: 'contacts'}})
    return response?.data
}

export const getEntitiesArrayById = async ({entitiesType, ids}) => {
    const response = await API_AMOCRM.get(`/amo/${entitiesType}?id[]=${ids.join('&id[]=')}`);
    return response?.data
}