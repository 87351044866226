<template>
    <div :class="$style.root" ref="control">
        <gnzs-button :class="$style.button" @click="onButtonClick">
            <svg>
                <use xlink:href="#gnzs-1c-icon__edit-pen"></use>
            </svg>
        </gnzs-button>

        <form 
            ref="form" 
            tabindex="0"
            :class="[$style.form, {[$style[`form_align_${align}`]]: align.length}]"
            @focusout="onFocusout"
        >
            <div :class="$style.formControl" ref="controlContainer" @keydown="onKeydown">
                <slot name="control"></slot>
            </div>
            <div>
                <gnzs-button type="primary" @click="onSave">Сохранить</gnzs-button>
                <gnzs-button type="cancel" @click="hideForm">Отменить</gnzs-button>
            </div>
        </form>
    </div>
</template>

<script>
import gnzsButton from '../Button';

export default {
    props: {
        controlType: {
            type: String,
            default: 'input', // input | textarea
        },
        align: {
            type: String,
            default: 'left', // left | right
        }
    },
    methods: {
        onButtonClick(){
            this.$refs['form'].classList.add(this.$style.form_opened);
            this.$refs['controlContainer'].childNodes[0].focus();
        },
        onSave(){
            this.$emit('onSave');
            this.hideForm();
        },
        onKeydown(e){
            if(e.key=='Enter'){
                e.preventDefault();
                this.onSave();
            } 
        },
        onFocusout(e){
            let isStayInsideForm = false;

            if(e.relatedTarget){
                const parentForm = e.relatedTarget.closest(`.${this.$style.form}`);
                isStayInsideForm = e.relatedTarget && parentForm && parentForm.length;
            }

            if(!isStayInsideForm) this.hideForm();
        },
        hideForm(){
            this.$refs['form'].classList.remove(this.$style.form_opened);
        },
    },
    components: {
        gnzsButton,
    }
}
</script>



<style lang="scss" module>
    @import './style.scss';
</style>