<template>
    <div :class="$style.scroller_back">
        <div
        :class="[$style.scroller, {[$style.scroller_active]: isDisabledStatus}, 'gnzs-1c-scroll-table-scroller']"
        :style="{
        width: scrollWidth + 'px',
        'margin-left': scrollOffset + 'px'
        }">
        </div>
    </div>
</template>

<script>
import $ from 'jquery'
import {mapActions, mapGetters} from 'vuex'

export default {
    computed: {
        ...mapGetters({
            scrollWidth: 'table/scrollWidth',
            scrollOffset: 'table/scrollOffset',
            minOffset: 'table/minOffset',
            isDisabledStatus: 'order/isDisabledStatus'
        }),
        minOffsetReal(){
            let wrapper = $(`.gnzs-1c-scroll-table-wrapper`)
            let draggable = $(`.gnzs-1c-scroll-table-draggable`)
            return this.minOffset(wrapper,draggable)         
        },
        scrollWidthReal(){
            let wrapper = $(`.gnzs-1c-scroll-table-wrapper`)
            let draggable = $(`.gnzs-1c-scroll-table-draggable`)
            return this.scrollWidth(wrapper,draggable)
        },
    },
    methods: {
        ...mapActions({
            scrollHandler: 'table/scrollHandler',
            setValue: 'table/setValue'
        }),
        onScroll(event){
            let wrapper = $(`.gnzs-1c-scroll-table-wrapper`)
            let draggable = $(`.gnzs-1c-scroll-table-draggable`)
            this.scrollHandler({event, wrapper, draggable, minOffset: this.minOffset})
        }
    },
    mounted(){
        this.setValue({key: 'isScrollMounted', value: true})
    },
    created(){
        document.addEventListener("mousedown", (ev) => {
            if ($(ev.target).hasClass('gnzs-1c-scroll-table-draggable-el')){
                this.setValue({key: 'draggablePressed', value: true})
            } 
            if ($(ev.target).hasClass('gnzs-1c-scroll-table-scroller')){
                this.setValue({key: 'scrollPressed', value:true})
            }
        });
        document.addEventListener("mouseup", () => {
            this.setValue({key:'draggablePressed', value:false})
            this.setValue({key: 'scrollPressed', value: false})
        });
        document.addEventListener("mousemove", (ev) => this.onScroll(ev))
    }  
}
</script>

<style lang="scss" module>
    @import './ProductsTable.scss';
</style>