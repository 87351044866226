<template>
    <li 
        :class="[
            $style.catalogItem,
            {
                [$style.catalogItem_opened]: item.isOpened,
                [$style.catalogItem_searched]: selectedCatalogId==item.id
                }
            ]"
        :has-childs="item.tree.length ? 'Y' : 'N'"
        :data-level="level"
    >
        <div :class="$style.catalogTitle" @click="onItemClick" :title="item.name">{{ item.name }}</div>
        <ul 
            v-if="item.isLoadedChilds && item.tree.length"
            :class="$style.catalogs" 
            :data-level="level+1"
        >
            <search-tree-item 
                v-for="childItem in item.tree"
                :key="childItem.id"
                :item="childItem"
                :level="level+1"
            />
        </ul>
    </li>
</template>

<script>

export default {
    name: 'SearchTreeItem',
    props: {
        item: {
            type: Object,
            defalt: {},
        },
        level: {
            type: Number,
            default: 0,
        }
    },
    computed: {
        selectedCatalogId(){
            return this.$store.getters['catalogs/selectedItemId'];
        },
    },
    methods: {
        onItemClick(){
            this.$store.dispatch('catalogs/selectItem', this.item);
            this.$store.dispatch('products/search');
        }
    },
}
</script>


<style lang="scss" module>
    @import './SearchTree.scss';
</style>