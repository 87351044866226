<template>
    <div :class="[$style.control, $style.w350, $style.space]">
        <GnzsInput  v-if="param.type=='text'" 
            :value="attrValue(table, index, param.attribute)"
            :type="'text'"
            @input="setAttrValue({table, index, attribute: param.attribute, value: $event})" 
        />
        <GnzsInput  v-else-if="param.type=='int'" 
            :value="attrValue(table, index, param.attribute)"
            :type="'number'"
            @input="setAttrValue({table, index, attribute: param.attribute, value: $event})" 
        />
        <GnzsSwitcher v-else-if="param.type=='bool'" 
            :value="attrValue(table, index, param.attribute)"
            @input="setAttrValue({table, index, attribute: param.attribute, value: $event})" 
        />
        <DatePicker 
            v-else-if="param.type=='date'"
            :class="$style.dateInput"
            :value="attrValue(table, index, param.attribute)"
            :format="'DD.MM.YYYY'" 
            :clearable="false" 
            type="date"
            @change="setAttrValue({table, index, attribute: param.attribute, value: $event})" 
            >
            <template v-slot:icon-calendar>
                <svg :class="$style.calendar">
                    <use xlink:href="#card-calendar"></use>
                </svg>
            </template>
        </DatePicker>
        <GnzsDropdown v-else 
            :value="attrValue(table, index, param.attribute)" 
            :isSearchable="typesDataTotal({code:param.code, type:param.type}) > 10"
            :isChuncked="typesDataTotal({code:param.code, type:param.type}) > 50"
            :isLoading="isTypesDataloading({code:param.code, type:param.type})"
            :items="typesData({code:param.code, type:param.type})"
            :searchedItems="typesDataSearched({code:param.code, type:param.type})"
            @search="typesDataSearch({code:param.code, type:param.type, query: $event})"
            @input="setAttrValue({table, index, attribute: param.attribute, code: param.code, value: $event })"
            @scrollend="onScrollEnd({code: param.code, type: param.type})"
            :use-empty-item="false"
        />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import GnzsInput from '@/gnzs-controls/Input';
import GnzsDropdown from '@/gnzs-controls/Dropdown';
import GnzsSwitcher from '@/gnzs-controls/Switcher';
import DatePicker from 'vue2-datepicker'
import '@/assets/datepicker/ru';
import '@/assets/datepicker/datepicker.css';

export default {
    props: {
        param: {
            type: Object,
            default: () => ({})
        },
        table: {
            type: String,
            default: ''
        },
        index: {
            type: Number,
            default: 0
        }
    },
    computed: {
        ...mapGetters({
            attrValue: 'additionalTypes/attrTableValue',
            typesData: 'additionalTypes/itemData',
            typesDataTotal: 'additionalTypes/itemDataTotal',
            typesDataSearched: 'additionalTypes/itemDataSearched',
            isTypesDataloading: 'additionalTypes/isItemDataLoading',
        })
    },
    methods: {
        ...mapActions({
            load1ctypeItems: 'additionalTypes/load1ctypeItems',
            typesDataSearch: "additionalTypes/searchItems",
            setAttrValue: 'additionalTypes/setAttrValue',
        }),
        async onScrollEnd({code, type}){
            await this.load1ctypeItems({code, type})
        },
    },
    components: {
        GnzsInput,
        GnzsDropdown,
        GnzsSwitcher,
        DatePicker
    }
}
</script>

<style lang="scss" module>
    @import './CustomField.scss';
</style>