<template>
<div ref="parent" :class="[{[$style.wrapper]: !isDisabledStatus}]" v-if="showTable">
    <div :class="[$style.wrapper__inner, 'gnzs-1c-scroll-table-wrapper']">
        <div 
            :style="{ 'margin-left': tableOffset + 'px' }"
            :class="[$style.draggable, 'gnzs-1c-scroll-table-draggable']">
            <ProductsTable :space="space" />
        </div>
    </div>
    <ProductsTableScroller />
</div>    
<ProductsTable :class="$style.fullwidth" v-else/>
</template>

<script>
import $ from 'jquery'
import {mapActions, mapGetters} from 'vuex'
import ProductsTable from './ProductsTable'
import ProductsTableScroller from './ProductsTableScroller'

export default {
    data(){
        return {
            space: 1000
        }
    },
    computed: {
        ...mapGetters({
            minOffset: 'table/minOffset',
            tableOffset: 'table/tableOffset',
            showTable: 'table/showTable',
            isDisabledStatus: 'order/isDisabledStatus'
        }),
        minOffsetReal(){
            let wrapper = $(`.gnzs-1c-scroll-table-wrapper`)
            let draggable = $(`.gnzs-1c-scroll-table-draggable`)
            return this.minOffset(wrapper,draggable)         
        }
    },
    methods: {
        ...mapActions({
            setValue: 'table/setValue',
            calc: 'table/calc'
        }),
        handleScroll(){
            this.space = this.$refs?.parent?.offsetWidth ? this.$refs.parent.offsetWidth : 2000
        }
    },
    components: {
        ProductsTable,
        ProductsTableScroller
    },
    mounted(){
        this.setValue({key:'isWrapperMounted', value: true})
        let wrapper = $(`.gnzs-1c-scroll-table-wrapper`)
        let draggable = $(`.gnzs-1c-scroll-table-draggable`)
        setTimeout(() => this.calc({wrapper, draggable}), 100)
    },
    created() {
        $('.gnzs-modal-scroller').first().on('scroll', this.handleScroll)
    }
}
</script>


<style lang="scss" module>
    @import './ProductsTable.scss';
</style>