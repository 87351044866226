<template>
    <div :class="$style.container">
        <div :class="[$style.tabItems, {[$style.overcover]: overcover}]">
            <div v-for="tab in tabs" 
                :key="tab.id"
                :class="[$style.tabItem, {[$style.tabItem_active]: tab.id==activeId}]"
                @click="onClick(tab)"
            >
                <span>{{tab.title}}</span>
            </div>
        </div>
        <div :class="$style.tabContent" v-show="initialized">
            <slot></slot>
        </div>
    </div>
</template>


<script>
export default {
    data() {
        return {
            activeId: '',
            initialized: false,
        }
    },
    props: {
        tabs: {
            type: Array,
            default: ()=>[],
            validator: function(tabs){
                return tabs.every(v => typeof v.id == 'string' && typeof v.title == 'string')
            },
        },
        overcover: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        onClick(tab){
            this.activeId = tab.id;
            this.$emit('click', tab.id);
            this.showActiveContent();
        },
        setActiveId(tabId){
            this.activeId = tabId
            this.showActiveContent()
        },
        showActiveContent(){
            this.$slots.default.forEach(s => {
                if(typeof s.elm?.getAttribute=='function') {
                    s.elm.hidden = s.elm.getAttribute('gnzs-tab-id')!==this.activeId
                }
            })
        }
    },
    mounted(){
        this.activeId = this.tabs.length ? this.tabs[0].id : '';
        this.showActiveContent();
        this.initialized = true;
    },
}
</script>


<style lang="scss" module>
    .container{
        position: relative;
        width: 100%;
    }

    .tabItems{
        display: flex;
        widows: 100%;
        border-bottom: 1px solid #4c8bf7;
    }

    .overcover{
        position: relative;
        z-index: 11;
    }

    .tabItem{
        position: relative;
        padding: 7px 10px;
        margin-right: 20px;
        cursor: pointer;
        opacity: .6;
        box-sizing: border-box;

        &_active{
            opacity: 1;

            &:after{
                content: '';
                position: absolute;
                height: 3px;
                background: #4c8bf7;
                bottom: 0;
                width: 100%;
                left: 0;
                right: 0;
            }
        }
    }
</style>