import _ from "underscore";

export const getProductsCount = (state) => state.data.products?.length;

export const getPricesHints = (productData) => {
  if (!productData?.prices) return [];
  return productData.prices.map((p) => ({
    id: p.id,
    title: `${p.cost} ${p.currency_name}`,
    value: p.cost,
    info: p.name,
  }));
};

export const getStockDropdownItems = (productData, orgId, orgName) => {
  if (!productData || !productData.hasOwnProperty("stocks")) return []; /* eslint-disable-line no-prototype-builtins */
  if (!Array.isArray(productData.stocks) || _.isEmpty(productData.stocks)) return [];

  //если организация не выбрана, выводим все склады
  if (!(orgId || orgName)) {
    return productData.stocks.map((s) => ({
      value: s.id,
      title: s.name,
    }));
  }

  // иначе попытаемся отсортировать по организации
  let groupedStocks = [];

  let orgStocks = productData.stocks.filter((val) => val.organization_id == orgId);

  if (orgStocks && orgStocks.length) {
    groupedStocks.push({
      value: `Склады ${orgName}`,
      title: `Склады ${orgName}`,
      items: productData.stocks
        .filter((val) => val.organization_id == orgId)
        .map((s) => ({
          value: s.id,
          title: s.name,
          info: `(${s.value})`,
        })),
    });
  }

  groupedStocks.push({
    value: `Склады других организаций`,
    title: `Склады других организаций`,
    items: productData.stocks
      .filter((val) => val.organization_id != orgId)
      .map((s) => ({
        value: s.id,
        title: s.name,
        info: `(${s.value})`,
      })),
  });

  return groupedStocks;
};

export const getDiscount = (price, count, discountPercentage) => {
  return parseFloat((price * count * (discountPercentage / 100)).toFixed(2));
};

export const getDiscountPercentage = (price, count, discount) => {
  const sum = price * count;
  if (sum == 0) return 0;

  return parseFloat(((1 - (sum - discount) / sum) * 100).toFixed(2));
};

export const getPriceByTotalPrice = (
  totalPrice,
  count,
  discountPercentage,
  discount,
  discountViewType,
  VATPercentage,
  VATType
) => {
  const d = discountPercentage / 100;
  const vat = VATPercentage / 100;

  if (VATType == 2) {
    // НДС сверху
    return discountViewType == 0 // если скидка процентами
      ? parseFloat((totalPrice / (count - count * d + count * vat - count * d * vat)).toFixed(2))
      : parseFloat(((totalPrice + discount + discount * vat) / (count + count * vat)).toFixed(2));
  } else {
    // НДС в т.ч. или без (не учитываем в формуле НДС)
    return discountViewType == 0 // если скидка процентами
      ? parseFloat((totalPrice / (count - count * d)).toFixed(2))
      : parseFloat(((totalPrice + discount) / count).toFixed(2));
  }
};

export const getIncludingVAT = (price, count, discount, VATPercentage) => {
  const VAT = VATPercentage / 100;
  const sum = price * count;
  return parseFloat((((sum - discount) * VAT) / (1 + VAT)).toFixed(2));
};

export const getVATOnTop = (price, count, discount, VATPercentage) => {
  return parseFloat(((price * count - discount) * (VATPercentage / 100)).toFixed(2));
};

export const getVAT = (price, count, discount, VATPercentage, VATType) => {
  switch (VATType) {
    case 1:
      return getIncludingVAT(price, count, discount, VATPercentage);
    case 2:
      return getVATOnTop(price, count, discount, VATPercentage);
    default:
      return 0;
  }
};

export const getTotalPrice = (price, count, autoDiscount, discount, VAT = 0) => {
  return parseFloat((price * count - discount - autoDiscount + VAT).toFixed(2));
};

export const getStockCountBySearch = (product, stockParams, orderStockId, orderOrgId) => {
  if (!product?.stocks?.length) return "—";

  let res = "";

  let value = 0,
    fact = 0,
    reserve = 0;

  if (!stockParams.stockCountBySearch || !orderStockId) {
    value = product.stocks.reduce((count, s) => (count += isNaN(+s.value) ? 0 : +s.value), 0);
    fact = product.stocks.reduce((count, s) => (count += s?.fact ? (isNaN(+s.fact) ? 0 : +s.fact) : 0), 0);
    reserve = product.stocks.reduce((count, s) => (count += s?.reserve ? (isNaN(+s.reserve) ? 0 : +s.reserve) : 0), 0);
  }

  if (stockParams.stockCountBySearch == 1 && orderStockId.length && orderOrgId) {
    const stock = product.stocks.find((s) => s.id == orderStockId && s.organization_id == orderOrgId);
    if (stock) {
      value = stock.value;
      fact = stock?.fact ? stock.fact : 0;
      reserve = stock?.reserve ? stock.reserve : 0;
    }
  }

  res += `${value}`;
  if (stockParams.stockFormat.includes(1)) res += `/${fact}`;
  if (stockParams.stockFormat.includes(2)) res += `/${reserve}`;

  return res;
};

export const getStockCount = (product, stockParams, orderStockId, orderOrgId) => {
  if (!product?.stocks?.length) return "—";

  if (!stockParams.stockCountBySearch || !orderStockId) {
    let value = product.stocks.reduce((count, s) => (count += +s.value), 0);
    return value;
  }

  if (stockParams.stockCountBySearch == 1 && orderStockId.length && orderOrgId) {
    const stock = product.stocks.find((s) => s.id == orderStockId && s.organization_id == orderOrgId);
    if (stock) return stock.value;
  }

  return 0;
};
