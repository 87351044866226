<template>
    <button 
        type="button"
        :class="[
            $style.root,
            {[$style[`root_${type}`]]: type.length},
            {[$style.root_loading]: loading},
            {[$style.root_disabled]: disabled && !loading},
        ]"
        @click="onClick"
        tabindex=""
    >

        <div v-if="type==='remove'" :class="[$style.icon, $style.icon_remove]" >
            <svg xmlns="http://www.w3.org/2000/svg">
                <path d="M11.498 3.33H0c0-.353.137-.691.38-.94.243-.25.573-.39.917-.39h10.406c.344 0 .674.14.917.39.243.249.38.587.38.94h-1.502zM3.901.67c0-.176.067-.345.188-.47a.646.646 0 01.456-.2h3.9c.174 0 .34.07.463.196a.679.679 0 01.191.474v.67H3.901V.67zm1.297 4l.653 8.64H4.545L3.9 4.66l1.297.01zm2.604-.01h1.287l-.653 8.65H7.139l.663-8.65zm-5.266 8.93l.136 1.05h7.675l.137-1.05.868-8.93h1.473l-1.122 9.98a1.36 1.36 0 01-.376.967 1.294 1.294 0 01-.94.393H2.583c-.344 0-.674-.14-.917-.39a1.348 1.348 0 01-.38-.94L.147 4.66h1.52l.869 8.93z"></path>
            </svg>
        </div>

        <div v-else-if="type==='update'" :class="[$style.icon, $style.icon_update]" >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path fill="currentColor" d="M256.455 8c66.269.119 126.437 26.233 170.859 68.685l35.715-35.715C478.149 25.851 504 36.559 504 57.941V192c0 13.255-10.745 24-24 24H345.941c-21.382 0-32.09-25.851-16.971-40.971l41.75-41.75c-30.864-28.899-70.801-44.907-113.23-45.273-92.398-.798-170.283 73.977-169.484 169.442C88.764 348.009 162.184 424 256 424c41.127 0 79.997-14.678 110.629-41.556 4.743-4.161 11.906-3.908 16.368.553l39.662 39.662c4.872 4.872 4.631 12.815-.482 17.433C378.202 479.813 319.926 504 256 504 119.034 504 8.001 392.967 8 256.002 7.999 119.193 119.646 7.755 256.455 8z" class="">
                </path>
            </svg>
        </div>

        <div v-else-if="type==='add'" :class="[$style.icon, $style.icon_add]" >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path fill="currentColor" d="M384 250v12c0 6.6-5.4 12-12 12h-98v98c0 6.6-5.4 12-12 12h-12c-6.6 0-12-5.4-12-12v-98h-98c-6.6 0-12-5.4-12-12v-12c0-6.6 5.4-12 12-12h98v-98c0-6.6 5.4-12 12-12h12c6.6 0 12 5.4 12 12v98h98c6.6 0 12 5.4 12 12zm120 6c0 137-111 248-248 248S8 393 8 256 119 8 256 8s248 111 248 248zm-32 0c0-119.9-97.3-216-216-216-119.9 0-216 97.3-216 216 0 119.9 97.3 216 216 216 119.9 0 216-97.3 216-216z">
                </path>
            </svg>
        </div>

        <div v-else-if="type==='copy'" :class="[$style.icon, $style.icon_copy]" >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    <path fill="currentColor" d="M433.941 65.941l-51.882-51.882A48 48 0 0 0 348.118 0H176c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h224c26.51 0 48-21.49 48-48v-48h80c26.51 0 48-21.49 48-48V99.882a48 48 0 0 0-14.059-33.941zM266 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h74v224c0 26.51 21.49 48 48 48h96v42a6 6 0 0 1-6 6zm128-96H182a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h106v88c0 13.255 10.745 24 24 24h88v202a6 6 0 0 1-6 6zm6-256h-64V48h9.632c1.591 0 3.117.632 4.243 1.757l48.368 48.368a6 6 0 0 1 1.757 4.243V112z">
                    </path>
            </svg>
        </div>

        <div :class="$style.icon" v-else-if="iconId.length">
            <svg><use :xlink:href="iconId"></use></svg> 
        </div>

        <span v-if="!isSlotEmpty" :class="$style.inner">
            <slot></slot>
        </span>
        <div :class="$style.spinner" v-if="type!=='cancel'">
            <span :class="$style.spinnerIcon"></span>
        </div>
    </button>
</template>

<script>
import _ from 'underscore';

export default {
    name: 'gnzs-button',
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        additionalClass: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: '',
        },
        iconId: {
            type: String,
            default: ''
        }
    },
    computed: {
        isSlotEmpty(){
            return _.isEmpty(this.$slots);
        }
    },
    methods: {
        onClick(){
            if(!this.loading && !this.disabled){
                this.$emit('click', this);
            }
        }
    },
}
</script>



<style lang="scss" module>
    @import './style.scss';
</style>