<template>
<div :class="[{[$style.wrapper]: !isDisabledStatus}]" v-if="showTable">
    <div :class="[$style.wrapper__inner, 'gnzs-1c-scroll-table-r-wrapper']">
        <div 
            :style="{ 'margin-left': tableOffset + 'px' }"
            :class="[$style.draggable, 'gnzs-1c-scroll-table-r-draggable']">
            <RealizationTable />
        </div>
    </div>
    <RealizationTableScroller />
</div>    
<RealizationTable :class="$style.fullwidth" v-else/>
</template>

<script>
import $ from 'jquery'
import {mapActions, mapGetters} from 'vuex'
import RealizationTable from './RealizationTable'
import RealizationTableScroller from './RealizationTableScroller'

export default {
    computed: {
        ...mapGetters({
            minOffset: 'tableR/minOffset',
            tableOffset: 'tableR/tableOffset',
            showTable: 'tableR/showTable',
            isDisabledStatus: 'order/isDisabledStatus'
        }),
        minOffsetReal(){
            let wrapper = $(`.gnzs-1c-scroll-table-r-wrapper`)
            let draggable = $(`.gnzs-1c-scroll-table-r-draggable`)
            return this.minOffset(wrapper,draggable)         
        },
    },
    methods: {
        ...mapActions({
            setValue: 'tableR/setValue',
            calc: 'tableR/calc'
        })
    },
    components: {
        RealizationTable,
        RealizationTableScroller
    },
    mounted(){
        this.setValue({key:'isWrapperMounted', value: true})
        let wrapper = $(`.gnzs-1c-scroll-table-r-wrapper`)
        let draggable = $(`.gnzs-1c-scroll-table-r-draggable`)
        setTimeout(() => this.calc({wrapper, draggable}), 100)
    }
}
</script>


<style lang="scss" module>
    @import '../ProductsTable/ProductsTable.scss';
</style>