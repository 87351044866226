/**
 * @author Ilya Orishin 2022
 *
 * Функции для работы с amo
 */

import { addTextNote } from "@/api/amocrm-api";
import { uuidv4 } from "./order";

/**
 * Создать фон для загрузки, пока не открылся iframe
 *
 * @param {string} iframeName - наименование окна
 * @param {string} overlayId - id фона
 *
 * @returns {void}
 */
export const createLoadingOverlay = (iframeName) => {
  const overlayId = uuidv4();
  window.parent.postMessage(
    {
      event: `${iframeName}:renderLoadingOverlay`,
      data: { overlayId },
    },
    "*"
  );
};

/**
 * Удалить фон для загрузки, если открылся iframe
 *
 * @param {string} iframeName - наименование окна
 * @param {string} overlayId - id фона
 *
 * @returns {void}
 */
export const removeLoadingOverlay = (iframeName, overlayId) => {
  window.parent.postMessage(
    {
      event: `${iframeName}:removeLoadingOverlay`,
      data: { overlayId },
    },
    "*"
  );
};

/**
 * Получить название типа сущности по typeId (1,2,3,12)
 *
 * @param {Number} typeId - id типа сущности
 * @param {string} mode - single/multiple
 *
 * @returns {string} - название типа сущности
 */
export const getCurrentEntityTypeName = (typeId, mode = "single") => {
  if (mode == "single") {
    switch (parseInt(typeId)) {
      case 1:
        return "contact";
      case 2:
        return "lead";
      case 3:
        return "company";
      case 12:
        return "customer";
      default:
        "";
    }
  } else if (mode == "multiple") {
    return typeId == 3 ? "companies" : getCurrentEntityTypeName(typeId) + "s";
  } else {
    throw new Error("parameter 2 must be 'single' or 'multiple'");
  }
};

/**
 * Получить значение кастомного поля
 *
 * @param {Object} entity - объект сущности (lead, customer, company, contact)
 * @param {Number} cfId - ИД кастомного поля
 *
 * @returns {mixed} - значение кастомного поля
 */
export const getCfValue = (entity, cfId) => {
  if (!entity) return "";
  if (!entity["custom_fields_values"]?.length) return "";

  for (let cf of entity["custom_fields_values"]) {
    if (cf.field_id == cfId) {
      return cf["values"][0].value;
    }
  }

  return "";
};

/**
 * Проверка, имеет ли сущность {lead, customer} прикрепленную компанию
 *
 * @param {Object} entity
 *
 * @returns {number}
 */
export const entityHasLinkedCompany = (entity) => entity?._embedded?.companies.length;

/**
 * Проверка, имеет ли сущность {lead, customer} прикрепленный контакт
 *
 * @param {Object} entity
 *
 * @returns {number}
 */
export const entityHasLinkedContact = (entity) => entity?._embedded?.contacts.filter((c) => c.is_main).length;

/**
 * Отобразить ошибку в центре нотификаций и/или в примечании к сущности
 *
 * @param {Object: {
 *  header - заголовок ошибки
 *  text - текст ошибки
 *  targets - массив, где нужно отображать ошибки из настроек [] || [0] || [1] || [0,1]
 *  entityId - id сущности
 *  entityType - тип сущности (1,2,3,12)
 *  iframeName - имя окна iframe
 * }}
 *
 * @returns {void}
 */
export const showError = ({ header, text, targets, entityId, entityType, iframeName, partnerName = "" }) => {
  let route = "leads";
  if (entityType == 12) {
    route = "customers";
  }

  if (!header) header = `1С Интеграция ${partnerName}`;

  if (targets.includes(0)) {
    // если показывать ошибку в центре нотификаций
    showErrorNotification({ iframeName, header, text });
  }

  if (targets.includes(1) && entityId) {
    // если показывать ошибку в примечании к сделке
    addTextNote(entityId, `${header}: ${text}`, route);
  }
};

/**
 * Отобразить ошибку в центре нотификаций
 *
 * @param {Object: {
 *  iframeName - имя окна iframe
 *  header - заголовок ошибки
 *  text - текст ошибки
 * }}
 *
 * @returns {void}
 */
export const showErrorNotification = ({ iframeName, header, text }) => {
  window.parent.postMessage({ event: `${iframeName}:showErrorNoty`, noty: { header, text } }, "*");
};

/**
 * Сгенерировать ссылку на сущность
 *
 * @param {number || string} entityId - id сущности
 * @param {number} entityType - тип сущности (2,3,4,12)
 * @param {object} accountData - данные аккаунта
 *
 * @returns {void}
 */
export const generateEntityLink = (entityId, entityType, accountData) => {
  const { amoSubdomain, amoTopLevelDomain } = accountData;
  let type = "n";

  switch (+entityType) {
    case 1:
      type = "contacts";
      break;
    case 2:
      type = "leads";
      break;
    case 3:
      type = "companies";
      break;
    case 12:
      type = "customers";
      break;
  }

  return `https://${amoSubdomain}.amocrm.${amoTopLevelDomain}/${type}/detail/${entityId}`;
};
