<template>
    <div 
        :class="$style.root" 
        tabindex="-1" 
        @focusin="onFocusinHandler"
        @focusout="onFocusoutHandler"
        ref="container"
    >
        <input 
            :type="type" 
            :class="$style.input"
            :data-name="name"
            :value="value"
            :disabled="disabled == 1"
            v-on="inputListeners"
            ref="input"
        />
        <div :class="['custom-scroll', $style.hints]" v-if="hints.length" ref="hints">
            <div :class="$style.hintItem" v-for="(hint, i) of hints" :key="i" @click="onHintClickHandler(hint)">
                <div :class="$style.hintValue">{{hint.title}}</div>
                <div :class="$style.hintInfo">{{hint.info}}</div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'gnzs-input-with-hints',
    props: {
        name: {
            type: String,
            default: '',
        },
        value: {
            default: '',
        },
        hints: {
            type: Array,
            default: () => [], // [{id: 1, value: 100, title: '100 руб', info: '(Розничная)'}]
        },
        type: {
            type: String,
            default: 'text',
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        inputListeners: function () {
            var vm = this
            return Object.assign({},
            this.$listeners,
            {
                // This ensures that the component works with v-model
                input: function (event) {
                    vm.$emit('input', event.target.value)
                }
            })
        },
    },
    methods: {
        onFocusinHandler(){
            if(!this.hints.length || this.$props.disabled) return;
            this.showHints();
        },
        showHints(){
            this.$refs['container'].classList.add(this.$style.root_opened);
            this.$refs['input'].style.width = this.$refs['hints'].offsetWidth + 'px';
        },
        hideHints(){
            this.$refs['input'].style.width = '';
            this.$refs['container'].classList.remove(this.$style.root_opened);
        },
        onFocusoutHandler(e){
            const isTargetChild =   e && e.relatedTarget && 
                                    this.$refs['container'] === e.relatedTarget.closest(`.${this.$style.root}`);

            if(isTargetChild) return;
            this.hideHints();
        },
        onHintClickHandler(hint){
            this.$refs['input'].value = hint.value;
            this.$emit('input', hint.value);
            this.$emit('hintClick', hint);
            this.hideHints();
        }
    }
}
</script>


<style lang="scss" module>
    @import './style.scss';
</style>