export const GNZS_WIDGET_ID = 27;
export const GNZS_DEV_ACCOUNT_ID = 30632335; // 31068430 //30865082 //30491173 // 28878712 / 17563087 //29569105 //17563087 //28830832;
export const GNZS_CLIENT_ID = "d9322b36-0001-4269-bb61-976571e8a51a";
export const API_TARIFF_ID = 12;
export const MINIMAL_TARIFF_ID = 3;

export const PERSONAL_FEATURES = [
  {
    accountId: 30913102,
    features: ["additional-buttons", "mass-actions", "order-iframe", "order-iframe-v2"],
  },
  {
    accountId: 9042213,
    features: ["additional-buttons"],
  },
  {
    accountId: 30938754,
    features: ["mass-actions"],
  },
];

export const PARTNERS = [
  {
    id: 27,
    name: "Генезис",
    logo: "#gnzs-1c-icon__company-logo",
    background: "#000",
    guide: "https://gnzs.ru/instructions/integration-1c-amocrm-user-guide?utm_source=widget_card_tab",
    clientId: "d9322b36-0001-4269-bb61-976571e8a51a",
  },
  {
    id: 62,
    name: "WBOX",
    logo: "#gnzs-1c-icon__wbox",
    background: "#213a7a",
    guide: "https://delomatika.com/integration1c",
    clientId: "51e21c82-52b2-4add-95b8-f67d746d2e4e",
  },
  {
    id: 78,
    name: "AITRINITY",
    logo: "#gnzs-1c-icon__aitrinity",
    background: "#000",
    guide: " https://aitrinity.ru/1c",
    clientId: "11fa8030-3791-4021-8567-eabb6ffdfa07",
  },
];
