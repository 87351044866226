<template>
  <div :class="$style.wrapper">
    <div v-for="(doc, index) in documents" :key="index" :class="$style.container" :data-id="doc.id">
      <a :href="doc.gdi" :class="$style.infoContainer" target="_blank">
        <svg :class="$style.icon">
          <use :xlink:href="getIconId(doc.ext)"></use>
        </svg>
        <div :class="$style.titleContainer">
          <div :class="$style.title" :title="getDocName(doc)">{{ getDocName(doc) }}</div>
          <div :class="$style.subtitle" :title="doc.organization.name">{{ doc.organization.name }}</div>
          <div :class="$style.subtitle">{{ getDateCreation(doc) }}</div>
        </div>
      </a>
      <div :class="$style.contextMenuContainer">
        <GnzsContextMenuButton :items="getContextMenuItems(doc)" />
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from "vuex";
  import moment from "moment";

  import GnzsContextMenuButton from "@/gnzs-controls/ContextMenuButton";

  export default {
    computed: {
      ...mapGetters({
        documents: "documents/items",
        dropboxToken: "settings/dropboxToken",
        iframeName: "iframe/iframeName",
        confirmData: "localSettings/confirmRemoveDocument",
      }),
    },
    methods: {
      ...mapActions({
        copyLinkToClipboard: "documents/copyLinkToClipboard",
        sendToEmail: "documents/sendToEmail",
        download: "documents/download",
        setDocumentToRemove: "documents/setDocumentToRemove",
      }),
      getDocName(doc) {
        return doc.name || `${doc.templateName} ${doc.number}`;
      },
      getDateCreation(doc) {
        if (doc.createdAt) {
          return "Создан " + moment(doc.createdAt).format("DD.MM.YYYY HH:mm");
        }
        return "";
      },
      getContextMenuItems(document) {
        return [
          {
            iconLink: "#gnzs-1c-icon__copy",
            text: "Скопировать ссылку",
            onClick: () => this.$store.dispatch("documents/copyLinkToClipboard", document),
          },
          {
            iconLink: "#gnzs-1c-icon__email",
            text: "Отправить по Email",
            onClick: () => this.$store.dispatch("documents/sendToEmail", document),
          },
          {
            iconLink: "#gnzs-1c-icon__download",
            text: "Скачать документ",
            onClick: () => this.$store.dispatch("documents/download", document),
          },
          {
            iconLink: "#gnzs-1c-icon__remove",
            text: "Удалить документ",
            onClick: () => this.openConfirmModal({ document }),
          },
        ];
      },
      getIconId(name) {
        switch (name) {
          case ".pdf":
            return "#gnzs-1c-icon__doc-pdf";
          case ".xlsx":
            return "#gnzs-1c-icon__doc-excel";
          case ".docx":
            return "#gnzs-1c-icon__doc-word";
          case ".xls":
            return "#gnzs-1c-icon__doc-excel";
          case ".doc":
            return "#gnzs-1c-icon__doc-word";
          case ".jpg":
            return "#gnzs-1c-icon__doc-image";
          case ".jpeg":
            return "#gnzs-1c-icon__doc-image";
          case ".png":
            return "#gnzs-1c-icon__doc-image";
        }
        return "#gnzs-1c-icon__doc-html";
      },
      openConfirmModal(document) {
        this.setDocumentToRemove(document);
        window.parent.postMessage(
          {
            event: `${this.iframeName}:openConfirmModal`,
            data: {
              ...this.confirmData,
              confirmEventName: "removeDocument",
            },
          },
          "*"
        );
      },
    },
    components: {
      GnzsContextMenuButton,
    },
  };
</script>

<style lang="scss" module>
  @import "./Documents.scss";
</style>
