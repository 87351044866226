<template>
  <div :class="$style.container">
    <div
      ref="categories"
      :class="['custom-scroll', 'gnzs-search-tree-container', $style.resizeCell, $style.treeContainer]">
      <SearchTree />
    </div>
    <div :class="[$style.productsContainer]">
      <div :class="['custom-scroll', $style.productsHeader]">
        <div v-if="!features.includes('no-additional-information-in-search')" data-code="show-info"></div>
        <div v-if="defaults.showImages" data-code="image">
          <div :class="$style.picture__wrapper">
            <svg :class="$style.picture"><use xlink:href="#gnzs-ms-icon__noimage"></use></svg>
          </div>
        </div>
        <div data-code="name"><div :class="$style.caption">Наименование</div></div>
        <div data-code="price"><div :class="$style.caption">Цена</div></div>
        <div data-code="append-button"></div>
      </div>
      <div :class="['custom-scroll', $style.products]" ref="results" @scroll="onResultScroll">
        <SearchResultsItem v-for="(item, index) in searchResult" :key="index" :item="item" />

        <div :class="[$style.preloader, { [$style.preloader_showed]: isSearchingProducts }]">
          <SearchResultsItemPreload :nameLength="200" />
          <SearchResultsItemPreload :nameLength="100" />
          <SearchResultsItemPreload :nameLength="150" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";

  import SearchTree from "../SearchTree/SearchTree";
  import SearchResultsItem from "../SearchResultsItem/SearchResultsItem";
  import SearchResultsItemPreload from "../SearchResultsItem/SearchResultsItemPreload";

  export default {
    data() {
      return {
        scrollBottomOffset: 50, // отступ в пикселях от нижней границы, когда нужно запускать подгрузку следующей страницы
      };
    },
    computed: {
      ...mapGetters({
        defaults: "settings/defaults",
      }),
      features() {
        return this.$store.getters["features/getFeatures"];
      },
      searchResult() {
        return this.$store.getters["products/searchResult"];
      },
      isSearchingProducts() {
        return this.$store.getters["products/isSearching"];
      },
    },
    methods: {
      onResultScroll(e) {
        const containerHeight = e.target.clientHeight;
        const height = e.target.scrollHeight;
        const top = e.target.scrollTop;
        const bottom = height - (containerHeight + top);

        const isNeedLoadNextPage = bottom < this.scrollBottomOffset && !this.isSearchingProducts;

        if (isNeedLoadNextPage) {
          this.$store.dispatch("products/searchNextPage");
        }
      },
    },
    mounted() {
      const container = this.$refs.categories;

      const saved = localStorage.getItem("gnzs-1c-product-table-width");

      if (saved) {
        container.style.width = `${saved}px`;
      } else {
        container.style.width = `294px`;
      }

      const observer = new ResizeObserver((e) => {
        e.forEach((el) => {
          const width = el?.target?.offsetWidth;
          if (width != 0) localStorage.setItem("gnzs-1c-product-table-width", width);
        });
      });

      observer.observe(container);
    },
    components: {
      SearchTree,
      SearchResultsItem,
      SearchResultsItemPreload,
    },
  };
</script>

<style lang="scss" module>
  @import "./SearchResults.scss";
</style>
