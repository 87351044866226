export default {
  title: 'Распределение сделок',

  tokenError: 'Токен авторизации истек !',

  buttons: {
    append: 'Добавить',
    back: 'Назад',
    save: 'Сохранить',
    cancel: 'Отмена',
    templateAppend: 'Добавить шаблон настроек',
  },

  breadcrumbs: {
    templateTitle: 'Новый шаблон',
  },

  body: {
    sections: {
      main: 'Основные настройки',
      groupsAndUsers: 'Группы и пользователи для распределения',
      additional: 'Дополнительные настройки',
    },
    templateName: 'Наименование шаблона настроек',
    distributionType: 'Тип распределения',
    usersQueue: {
      settingsDescription: {
        title: 'Отметьте группы или отдельных пользователей',
        show: 'Подробнее',
        hide: 'Скрыть',
      },
      description: 'Если отметить всю группу, интеграция будет распределять сделки между ее пользователями, динамически подхватывая изменения, если вы добавили/удалили пользователя из группы. Если отмечены только отдельные пользователи, то распределение будет происходить только между ними.',
    },
    usersPercent: {
      settingsDescription: {
        title: 'Укажите проценты распределения',
        show: 'Подробнее',
        hide: 'Скрыть',
      },
      description: 'Проценты по группам должны составлять в сумме 100%. Также у группы вы можете выбрать формат распределения между пользователями данной группы: выберите опцию "распределять равномерно", чтобы внутри данной группы распределение шло равномерно всем; если нужно задать разное соотношение распределение между пользователями, выберите опцию "распределять по процентам" - в этом случае сумма процентов по пользователям внутри группы так же должна быть равна 100%.',
      group: 'из 100% по группам',
      distribute: 'распределять',
    },
    repeatControl: 'Контроль повторных сделок',
    AssignOnlyWorkime: {
      title: {
        label: 'Распределять только в рабочее время',
      },
      workTime: {
        time: 'Рабочее время',
        days: 'Рабочие дни',
        from: 'c',
        to: 'до',
        weekDays: {
          mon: 'Пн',
          tue: 'Вт',
          wed: 'Ср',
          thu: 'Чт',
          fri: 'Пт',
          sat: 'Сб',
          sun: 'Вс',
        },
      },
      DNA: {
        activated: {
          warning: 'Важно: пользователи текущей настройки должны соответствовать пользователям в выбранном графике, иначе распределение для них происходить не будет.',
        },
        notActivated: {
          warning: 'Внимание! необходимо установить',
          link: 'виджет ДНК',
        },
      },
    },
    distributionAgain: {
      title: 'Распределять повторно, если этап не сменится',
      across: 'Через',
      min: 'мин',
    },
    changeContacts: 'Сменить ответственного у контакта и компании',
    changeLeadTasks: 'Сменить ответственного у открытых задач по сделке',
    changeContactTasks: 'Сменить ответственного у открытых задач по контактам',
    changeCompanyTasks: 'Сменить ответственного у открытых задач по компании',
  },

  assignTypes: [
    { value: 1, title: 'По очереди' },
    { value: 2, title: 'По процентам' },
  ],

  assignPercentTypes: [
    { value: 1, title: 'равномерно' },
    { value: 2, title: 'по процентам' },
  ],

  repeatLeadsControlTypes: [
    { value: 1, title: 'Не учитывать повторные сделки' },
    { value: 2, title: 'Повторные сделки по Контакту распределять на ответственного' },
    { value: 3, title: 'Повторные сделки по Компании распределять на ответственного' },
    { value: 4, title: 'Повторные сделки по Контакту или Компании распределять на ответственного' },
  ],

  assignOnlyWorktimeTypes: [
    { value: 1, title: 'по общему графику' },
    { value: 2, title: 'по индивидуальному графику' },
  ],
}