import store from '@/store/';

export const getHeaders = () => { 
    return {
        accountId:store.state.amoAccountId,
        subdomain: store.state.accountData.amoSubdomain
    }
}

export const docflowAmoCRMInterceptor = (config) => {
    const { headers } = config;
    const {accountId, subdomain} = getHeaders()
    headers['Amo-Account-Id'] = accountId;
    headers['X-Amo-Subdomain'] = subdomain
    return config;
};