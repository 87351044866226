import axios from 'axios';
import {docflowAmoCRMInterceptor} from './interceptors'

export const apiDocflow = axios.create({
    baseURL: 'https://docflowv2.gnzs.ru/api/v1/',
    withCredentials: true,
    validateStatus(status) {
        return status >= 200 && status < 300;
    },
});

apiDocflow.interceptors.request.use(docflowAmoCRMInterceptor)
