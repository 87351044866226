import { api1C } from './api';

export const get1cJournalFind = (params) => {
  params.action = 'find'
  return api1C.get(`request`, {
    params
  });
}

export const get1cJournalMetaMethod = () => {
  const params = {
    action: 'metaMethod'
  }
  return api1C.get(`request`, {
    params
  });
}

export const get1cJournalLog = (params) => {
  return api1C.get(`request-log`, {
    params
  });
}