/**
 * @author Ilya Orishin. 2022
 * 
 * Копирование строки в буфер обмена
 * 
 * @param {string} str - целевая строка
 * 
 * @returns {void}
 */
export const copyToClipboard = str => {
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
};