<template>
    <div :class="$style.searchTree">
         <ul :class="$style.catalogs" data-level="0">
            <SearchTreeItem
                v-for="item in catalogsTree"
                :key="item.id"
                :item="item"
            />
        </ul>
    </div>
</template>

<script>
import SearchTreeItem from './SearchTreeItem';

export default {
    name: 'SearchTree',
    computed: {
        catalogsTree(){
            return this.$store.getters['catalogs/tree'];
        }
    },
    components: {
        SearchTreeItem,
    }
}
</script>


<style lang="scss" module>
    @import './SearchTree.scss';
</style>