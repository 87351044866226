export const AMO_CF_TEXT = 1;
export const AMO_CF_NUMERIC = 2;
export const AMO_CF_CHECKBOX = 3;
export const AMO_CF_SELECT = 4;
export const AMO_CF_MULTISELECT = 5;
export const AMO_CF_DATE = 6;
export const AMO_CF_URL = 7;
export const AMO_CF_MULTITEXT = 8;
export const AMO_CF_TEXTAREA = 9;
export const AMO_CF_RADIOBUTTON = 10;
export const AMO_CF_STREETADDRESS = 11;
export const AMO_CF_SMART_ADDRESS = 13;
export const AMO_CF_BIRTHDAY = 14;
export const AMO_CF_LEGAL_ENTITY = 15;
export const AMO_CF_ITEMS = 16;
export const AMO_CF_ORG_LEGAL_NAME = 17;
export const AMO_CF_ORG_DATE_TIME = 19;

export const amoCfTypes = {
    'text': AMO_CF_TEXT,
    'numeric': AMO_CF_NUMERIC,
    'checkbox': AMO_CF_CHECKBOX,
    'select': AMO_CF_SELECT,
    'multiselect': AMO_CF_MULTISELECT,
    'date': AMO_CF_DATE,
    'url': AMO_CF_URL,
    'multitext': AMO_CF_MULTITEXT,
    'textarea': AMO_CF_TEXTAREA,
    'radiobutton': AMO_CF_RADIOBUTTON,
    'street_address': AMO_CF_STREETADDRESS,
    'smart_address': AMO_CF_SMART_ADDRESS,
    'birthday': AMO_CF_BIRTHDAY,
    'legal_entity': AMO_CF_LEGAL_ENTITY,
    'items': AMO_CF_ITEMS,
    'org_legal_name': AMO_CF_ORG_LEGAL_NAME,
    'date_time': AMO_CF_ORG_DATE_TIME,
}