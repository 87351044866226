<template>
    <Modal ref="modal">
        <template v-slot>
            <div :class="$style.wrapper">
                <span class="modal-body__close" ref="close">
                <span class="icon icon-modal-close"></span>
                </span>

                <div :class="$style.header">
                    Применить НДС
                </div>

                <div :class = "$style.controls">
                    <GnzsInput
                        type="number"
                        v-model="selectedVat"
                    />
                </div>

                <div :class="$style.actions">
                    <GnzsButton 
                        type="primary" 
                        @click="apply"
                        :disabled="!`${selectedVat}`.length"
                    >Применить</GnzsButton>
                    <GnzsButton type="cancel" @click="closeModal">Отмена</GnzsButton>
                </div>
            </div>
        </template>
    </Modal>
</template>

<script>

import Modal from '@/gnzs-controls/Modal';

import GnzsInput from '@/gnzs-controls/Input';
import GnzsButton from '@/gnzs-controls/Button';
import {mapActions, mapGetters} from 'vuex';

const mapFieldModel = function(key){
    return {
        get(){
            return this.$store.getters[`order/getRootValue`](key);
        },
        set(value){
            this.$store.dispatch('order/setRootValue', {key, value});
        }
    }
}

export default {
    computed: {
        ...mapGetters({
            defaults: 'settings/defaults'
        }),
        selectedVat: { ...mapFieldModel.call(this, 'selectedVat') },
    },
    methods: {
        ...mapActions({
            applyVat: 'order/applyVat',
            setDefaultVat: 'order/setDefaultVat',
            setCheckedAll: 'order/setCheckedAll'
        }),
        apply(){
            this.applyVat()
            this.setCheckedAll()
            this.closeModal();
        },
        closeModal(){
            this.$refs.modal.close()
        },
    },
    components: {
        Modal,
        GnzsButton,
        GnzsInput
    },
    created(){
        this.setDefaultVat(+this.defaults.vat)
    }
}
</script>

<style lang="scss" module>
    @import './AddVatModal.scss';
</style>