import Vue from 'vue';
import store from '@/store';
import $ from 'jquery'

import AddDiscountModal from './AddDiscountModal.vue';

export default function(){
    let element = $(`<div id="gnzs-1c-amo__add-discount-modal-container"></div>`)
    $('body').append(element)

    new Vue({
        store,
        render: h => h(AddDiscountModal)
    }).$mount('#gnzs-1c-amo__add-discount-modal-container');
}