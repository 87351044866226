<template>
    <Modal ref="modal" :loaded="isUpdateContragentModalLoaded" @decline="onOverlayClick">
        <template v-slot>
            <div :class="$style.header">Обновление данных</div>
            <div :class="$style.body">
                <div v-if="loading" :class="$style.spinner">
                    <span :class="$style.spinnerIcon"></span>
                </div>
                <div v-else-if="!errorText">
                    <span>Данные успешно обновлены</span>
                </div>
                <div v-else>
                    <div>{{ errorText }}</div>
                </div>
            </div>
        </template>
    </Modal>
</template>

<script>
import {mapGetters} from 'vuex';

import Modal from '@/gnzs-controls/Modal';

export default {
    computed: {
        ...mapGetters({
            loading: 'contragents/isLoading',
            errorText: 'contragents/errorText',
            isUpdateContragentModalLoaded: 'isUpdateContragentModalLoaded',
            iframeName: 'iframe/iframeName'
        }),
    },
    methods: {
        onOverlayClick(){
            return window.parent.postMessage({ event: `${this.iframeName}:closeModal` }, "*");
        },
    },
    components: {
        Modal
    }
}
</script>


<style lang="scss" module>
    @import './style.scss';
</style>