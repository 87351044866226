import { api1C } from './api';

export const getStorage = (id) =>{
    const params = {id}
    return api1C.get(`storage`, {params});
}

export const createStorage = (type) => {
    const data = {type}
    return api1C.post(`storage`, data)
}