/**
 * @author Ilya Orishin, 2021
 */

/**
 * Конвертация числа в строку с разделителем на сотни, тысячи и.т.д
 * 1000 -> 1 000
 * 
 * @param {Integer} x - число
 * @returns {String} - число в новом формате
 */
function prettyNumber(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

/**
 * Инициализация фильтров
 * 
 * @param {Vue} Vue 
 */
export const initFilters = (Vue) => {

    Vue.filter('price', function(value){
        if(!value) return '0';
    
        const parts = parseFloat(value)
            .toFixed(2)
            .toString()
            .split('.');
    
        return `${prettyNumber(parts[0])}.${parts[1]}`;
    });

}

