import { amoCfTypes } from "@/api/amocrm-api";

const getEntitiesStr = (entity) => {
  switch (entity) {
    case "company":
      return "companies";
    case "contact":
      return "contacts";
    case "lead":
      return "leads";
    case "customer":
      return "customers";
    default:
      return entity;
  }
};

const getEntityCfs = (state, entity, types = []) => {
  const result = [];
  let e = getEntitiesStr(entity);

  for (let cfId in state.customFields[e]) {
    const cf = state.customFields[e][cfId];
    const val = { value: cf.id, title: cf.name };

    if (types.length) {
      if (types.includes(cf.field_type)) result.push(val);
    } else {
      result.push(val);
    }
  }

  return result;
};

const getEntityCfsTypesArray = (state, entity) => {
  const cfs = { all: getEntityCfs(state, entity) };

  for (let cfType in amoCfTypes) {
    cfs[cfType] = getEntityCfs(state, entity, [amoCfTypes[cfType]]);
  }

  return cfs;
};

const state = () => ({
  customFields: {},
  productSettings: {},
  pipelines: [],
  currentEntityData: {},
  users: [],
});

const getters = {
  currentEntityData: (state) => state.currentEntityData,

  customFields(state) {
    return (entity, types = []) => getEntityCfs(state, entity, types);
  },

  customFieldsByTypes(state) {
    return {
      lead: getEntityCfsTypesArray(state, "lead"),
      company: getEntityCfsTypesArray(state, "company"),
      contact: getEntityCfsTypesArray(state, "contact"),
      customer: getEntityCfsTypesArray(state, "customer"),
    };
  },

  pipelines: (state) => {
    return state.pipelines.map((p) => ({
      value: p.id,
      title: p.name,
    }));
  },

  customFieldsByIds(state) {
    return {
      lead: state.customFields.leads,
      company: state.customFields.companies,
      contact: state.customFields.contacts,
      customer: state.customFields.customers,
    };
  },

  productCatalogSystemFields(state) {
    if (!state.customFields?.catalogs) return;
    if (!state.productSettings?.catalog_id) return;

    const productCatalog = state.customFields.catalogs[state.productSettings?.catalog_id];

    if (!productCatalog) return;

    const fields = {
      catalog_id: state.productSettings?.catalog_id,
    };

    for (let cfId in productCatalog) {
      if (productCatalog[cfId]["code"].length) {
        const prodKey = productCatalog[cfId]["code"].toLowerCase();
        fields[prodKey] = cfId;
      }
    }

    return fields;
  },

  users: (state) => {
    return state.users.map((u) => ({
      value: u.id,
      title: u.name,
      is_free: u.is_free,
    }));
  },

  /*
    Получим статусы воронки, включая закрывающие
    */
  allStatuses: (state) =>
    state.pipelines
      .map((p) =>
        p.statuses
          .filter((s) => s.is_editable == true || [142, 143].includes(s.id))
          .map((s) => ({
            value: [142, 143].includes(s.id) ? `${p.id}_${s.id}` : s.id,
            title: `${p.name}: ${s.name}`,
          }))
      )
      .flat(),

  statusesDropdownItems: (state) =>
    state.pipelines
      .map((p) =>
        p.statuses
          .filter((s) => ![142, 143].includes(s.id))
          .map((s) => ({
            title: `${p.name}: ${s.name}`,
            value: s.id,
          }))
      )
      .flat(),

  statusesWithClosedDropdownItems: (state) =>
    state.pipelines
      .map((p) =>
        p.statuses.map((s) => ({
          title: `${p.name}: ${s.name}`,
          value: s.id,
        }))
      )
      .flat(),

  pipelineById: (state) => (id) => state.pipelines.find((p) => p.id == id),

  pipelineStatusesById(state) {
    return (id) =>
      state.pipelines
        .find((v) => v.id == id)
        ?.statuses?.filter((s) => s.type != 1)
        .map((s) => ({
          value: s.id,
          color: s.color,
          title: s.name,
        }));
  },

  statusById: (state) => (id) => {
    for (let p of state.pipelines) {
      for (let s of p.statuses) {
        if (s.id == id) return s;
      }
    }
  },

  pipelineStatusesDropdownItems: (state) => {
    return state.pipelines.map((p) => {
      return {
        value: p.id,
        title: p.name,
        items: p.statuses.map((s) => ({
          value: `${p.id}_${s.id}`,
          title: s.name,
        })),
      };
    });
  },
};

const actions = {
  async load({ commit, rootGetters }) {
    try {
      const accountData = rootGetters["accountData"];

      const pipelines = Object.entries(accountData.amoPipelines).map(([id, v]) => {
        v.statuses = (v.statuses ? Object.entries(v.statuses) : [])
          .map(([sid, sv]) => {
            return { id: sid, ...sv };
          })
          .sort((pre, el) => pre.sort - el.sort);
        return { id, ...v };
      });

      commit("setData", { key: "customFields", payload: accountData.amoCustomFields });
      commit("setData", { key: "pipelines", payload: pipelines });

      let catalog_id = accountData.amoObjectAmocrm?.products?.catalog_id;
      let enabled = accountData.amoObjectAmocrm?.products?.enabled;
      if (!enabled) enabled = false;

      commit("setData", { key: "productSettings", payload: { catalog_id, is_enabled: enabled } });
      commit("setData", { key: "users", payload: accountData.amoUsers });
    } catch (e) {
      console.error(`1С ${rootGetters["partnerName"]}: Ошибка получения данных аккаунта amoCRM`, { e });
    }
  },

  setCurrentEntityData: ({ state }, data) => {
    state.currentEntityData = data;
  },
};

const mutations = {
  setData(state, { key, payload }) {
    state[key] = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
