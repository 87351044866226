import { api1C } from './api';


export const createPartner = (params) => {
    return api1C.post('partners', params);
}

export const getPartners = (params) => {
    return api1C.get('partners', {
        params,
    });
}