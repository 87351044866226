import { getOrganizations } from "@/api/docflow-api";

const state = () => ({
  items: [],
  failLoading: false,
  organizationsBy1cId: {},
  accountsBy1cId: {},
});

const getters = {
  items: (state) => state.items,
  failLoading: (state) => state.failLoading,

  settingsItems: (state) => {
    return state.items.map((org) => {
      let name = org.name.replaceAll('\\"', '"'); /* eslint-disable-line no-useless-escape */
      return {
        value: org.id1C,
        title: name,
        items: org.accounts.map((acc) => {
          let accName = acc.name.replaceAll('\\"', '"'); /* eslint-disable-line no-useless-escape */
          return {
            value: acc.id1C,
            title: `${org.name}, ${accName}`,
          };
        }),
      };
    });
  },

  orderItems: (state, getters, rootState, rootGetters) => {
    const closedChoise = rootGetters["features/getFeatures"].includes("no-choice-of-organization");
    const organizations = getters["settingsItems"];

    if (closedChoise) {
      const organizationAccount1cId = rootGetters["order/organizationAccount1cId"];
      const defaultOrganization = rootGetters["settings/organizationAccountId"];
      let filterdOrganizations = organizations.filter((org) => {
        return org.items.find((item) => item.value == organizationAccount1cId || item.value == defaultOrganization);
      });

      filterdOrganizations.forEach((organization) => {
        organization.items = organization.items.filter((org) => {
          return org.value == organizationAccount1cId || org.value == defaultOrganization;
        });
      });

      return filterdOrganizations;
    } else {
      return organizations;
    }
  },

  getOrganizationById1c(state) {
    return (id1c) => state.organizationsBy1cId[id1c];
  },

  getOrganizationAccountById1c(state) {
    return (accountId1c) => {
      return state.accountsBy1cId[accountId1c];
    };
  },
};

const actions = {
  async load({ commit, rootGetters }) {
    try {
      const respOrg = await getOrganizations();
      commit("initOrganizations", respOrg.data.rows);
    } catch (e) {
      console.debug(`1С ${rootGetters["partnerName"]}: Ошибка получения организаций`, { e });
      commit("setFailLoading");
    }
  },
};

const mutations = {
  initOrganizations(state, organizations) {
    state.items = organizations;
    state.accountsBy1cId = {};
    state.organizationsBy1cId = {};

    organizations.forEach((org) => {
      state.organizationsBy1cId[org.id1C] = org;
      org.accounts.forEach((acc) => {
        state.accountsBy1cId[acc.id1C] = acc;
      });
    });
  },

  setFailLoading(state) {
    state.failLoading = true;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
