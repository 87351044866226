import dbxLib from 'dropbox';

export const getDropboxFileNameByLink = (link) => {
    link = decodeURI(link);

    const  splitTest = function (str) {
        return str.split('\\').pop().split('/').pop();
    }
    const fileName = splitTest(link).slice(0, -5);

    return fileName;
};


export const getDropboxSharingFileDataByLink = async ({accessToken, link, fileName = ''}) => {
    if(fileName == '')
        fileName = getDropboxFileNameByLink(link);
    

    const Dropbox = dbxLib.Dropbox;
    const dbx = new Dropbox({accessToken: accessToken});

    try{
        const response = await dbx.sharingGetSharedLinkFile({url: link});

        const blob = response.fileBlob;
        const dT = new ClipboardEvent('').clipboardData || // Firefox < 62 workaround exploiting https://bugzilla.mozilla.org/show_bug.cgi?id=1422655
            new DataTransfer(); // specs compliant (as of March 2018 only Chrome)
        dT.items.add(new File([blob], fileName));

        return dT.files;

    } catch(e){
        console.error('1С Интеграция: error get dropbox file data', e);
    }
    
    return '';
}

export const downloadFileByLink = async ({accessToken, link}) => {
    const Dropbox = dbxLib.Dropbox;
    const dbx = new Dropbox({accessToken});

    dbx.sharingGetSharedLinkFile({url: link})
        .then(function(data) {
            var downloadUrl = URL.createObjectURL(data.fileBlob);
            var downloadButton = document.createElement('a');
            downloadButton.setAttribute('href', downloadUrl);
            downloadButton.setAttribute('download', data.name);
            downloadButton.innerText = 'Download: ' + data.name;
            downloadButton.click();
        })
        .catch(function(e) {
            console.error('1С Интеграция: error download dropbox file', e);
        });
    
    return false;

}