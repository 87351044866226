/**
 * @author Ilya Orishin. 2023
 * 
 * Перевод двумерного массива в объект парметров
 * 
 * @param {array} arr - целевой массив
 * @param {string} name - имя массива
 * @param {params} params - параметры
 * 
 * @returns {object} params - параметры
 */
export default function(arr, name, params = {}){
    arr.forEach((a, i) => Object.entries(a).forEach(([k, e])=> params[`${name}[${i}][${k}]`] = e))
    return params
}
