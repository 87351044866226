<template>
  <div :class="$style.root" ref="iframeRef">
    <div v-if="isLoading" :class="$style.spinner" ref="spinnerRef">
      <div :class="$style.spinnerIcon" />
    </div>
  </div>
</template>

<script>
  import { GNZS_WIDGET_ID } from "@/store/constants";
  import { getJwtToOtherWidget } from "@/api/gnzs-core-api";
  import { mapGetters } from "vuex";

  const iframeName = "dna-iframe";

  export default {
    data() {
      return {
        isLoading: true,
      };
    },
    computed: {
      ...mapGetters({
        accountId: "amoAccountId",
        dataToken: "token",
      }),
    },
    methods: {
      setLoading(value) {
        this.isLoading = value;
      },
      async createIframe() {
        const { token } = await getJwtToOtherWidget(43, this.dataToken);

        let url = `https://dna-iframe.gnzs.ru?token=${token}&lang=ru&iframe-name=${iframeName}&from-widget-id=${GNZS_WIDGET_ID}&mode=widgets-list`;

        const iframe = document.createElement("iframe");
        iframe.name = iframeName;
        iframe.src = url;

        this.$refs["iframeRef"].append(iframe);

        iframe.onload = () => {
          this.setLoading(false);

          setTimeout(this.resizeWindow, 100);
        };
      },
      resizeWindow() {
        const width = document.documentElement.clientWidth;
        const height = document.documentElement.clientHeight;
        window.frames[iframeName].postMessage(
          {
            event: `${iframeName}:getWindowSize`,
            data: { width, height },
          },
          "*"
        );
        window.frames[iframeName].postMessage({ event: `${iframeName}:getIframeHeight` }, "*");
      },
    },
    mounted() {
      this.createIframe();

      window.addEventListener("resize", this.resizeWindow);
    },
  };
</script>

<style lang="scss" module>
  @import "./style.scss";
</style>
