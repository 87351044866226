import {get1cPriceTypes} from '@/api/1c-api';

const state = () => ({
    items: [],
});


const getters = {
    dropdownItems(state){
        return state.items.map(p => ({
            value: p.id,
            title: p.name,
        }))
    },

    priceName(state){
        return (id) => state.items.filter(val => val.id == id)[0]
    }
};


const actions = {
    async load({commit, rootGetters}){
        try{
            const respPrices = await get1cPriceTypes();
            commit('initPriceTypes', respPrices.data.result);
        } catch(e){
            console.debug(`1С ${rootGetters['partnerName']}: Ошибка получения типов цен`, e);

        }
    }
};


const mutations = {
    initPriceTypes(state, priceTypes){
        state.items = priceTypes;
    }
};



export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}