<template>
    <Modal ref="modal">
        <template v-slot>
            <div :class="$style.wrapper">
                <span class="modal-body__close" ref="close">
                <span class="icon icon-modal-close"></span>
                </span>

                <div :class="$style.header">
                    Выделить по обеспечению
                </div>

                <div :class = "$style.controls">
                    <GnzsDropdown
                        v-model="selected"
                        :use-empty-item="false"
                        :items="statuses"
                        :emptyItemTitle="'Выбрать'"
                    />
                </div>

                <div :class="$style.actions">
                    <GnzsButton 
                        type="primary" 
                        @click="apply"
                        :disabled="!selected"
                    >Применить</GnzsButton>
                    <GnzsButton type="cancel" @click="closeModal">Отмена</GnzsButton>
                </div>
            </div>
        </template>
    </Modal>
</template>

<script>

import Modal from '@/gnzs-controls/Modal';

import GnzsButton from '@/gnzs-controls/Button';
import GnzsDropdown from '@/gnzs-controls/Dropdown';

import {mapActions, mapGetters} from 'vuex';

export default {
    data(){
        return {
            selected: ''
        }
    },
    computed: {
        ...mapGetters({
            statuses: 'provisions/provisionStatuses',
        })
    },
    methods: {
        ...mapActions({
            setCheckedByProvision: 'order/setCheckedByProvision'
        }),
        apply(){
            this.setCheckedByProvision(this.selected)
            this.closeModal();
        },
        closeModal(){
            this.$refs.modal.close()
        },
    },
    components: {
        Modal,
        GnzsButton,
        GnzsDropdown
    },
    created(){
        this.selected = this.statuses?.[0]?.value
    }
}
</script>

<style lang="scss" module>
    @import './SelectByProvModal.scss';
</style>