<template>
  <div :class="$style.container">
    <div v-if="!isOrderCreated" :class="$style.createOrder" @click="openOrderModal">
      <svg width="36" height="37">
        <use xlink:href="#gnzs-1c-icon__create-plus"></use>
      </svg>
      <span :class="$style.createOrderTitle">Создать заказ</span>
    </div>

    <template v-else>
      <div :class="$style.editOrder" @click="openOrderModal">
        <div :class="$style.editOrderTitleContainer">
          <span :class="$style.editOrderTitle">Детализация заказа</span>
          <svg :class="$style.editOrderIcon">
            <use xlink:href="#gnzs-1c-icon__edit-pen"></use>
          </svg>

          <span :class="$style.editOrderCost">{{ orderTotalPrice | price }} {{ currencyText }}</span>
        </div>
        <div :class="$style.orderParams">
          <span :class="$style.editOrderSubtitle">{{ productsQuantitySubtitle || "" }}</span>
          <span :class="$style.editOrderSubtitle" :title="orderOrgSubtitle">{{ orderOrgSubtitle }}</span>
        </div>
      </div>
      <div :class="$style.description__context">
        <GnzsContextMenuButton :items="items" />
      </div>
    </template>
  </div>
</template>

<script>
  import GnzsContextMenuButton from "@/gnzs-controls/ContextMenuButton";

  import { mapActions, mapGetters } from "vuex";
  import { createLoadingOverlay, removeLoadingOverlay } from "@/utilities/amo";

  export default {
    data() {
      return {
        items: [{ text: "Скопировать", iconLink: "#gnzs-1c-icon__copy", onClick: () => this.openCopyOrderModal() }],
      };
    },
    computed: {
      ...mapGetters({
        organizationName: "order/organizationName",
        organizationAccountName: "order/organizationAccountName",
        productsQuantitySubtitle: "order/productsQuantity",
        orderTotalPrice: "order/totalPrice",
        orderHasProducts: "order/hasProducts",
        isOrderCreated: "order/isCreated",
        currency: "settings/currency",
        currencies: "localSettings/currencies",
        orderCurrency: "order/orderCurrency",
        order: "order/orderData",
        auth: "settings/auth",
        iframe: "settings/iframe",
        tariffFeatures: "features/getFeatures",
        iframeName: "iframe/iframeName",
        amoUserId: "amoUserId",
      }),
      orderOrgSubtitle() {
        let caption = "";
        if (this.organizationName) {
          caption += this.organizationName;
        }
        if (this.organizationAccountName) {
          caption += `, ${this.organizationAccountName}`;
        }
        return caption;
      },
      currencyText() {
        return this.currencies.find((c) => c.code == this.orderCurrency)?.isoCode || "RUB";
      },
    },
    methods: {
      ...mapActions({
        saveEmpty1cOrder: "order/saveEmpty1cOrder",
        update1c: "order/update1cWithCompany",
        updateOrderByEntityData: "order/updateOrderByEntityData",
      }),
      openCopyOrderModal() {
        const url = new URL(document.location).origin;
        window.parent.postMessage(
          {
            event: `${this.iframeName}:openFullScreenModal`,
            url: `${url}/order-copy`,
          },
          "*"
        );
      },
      checkUrl(url1c) {
        if (url1c.includes("http:")) {
          window.parent.postMessage(
            {
              event: `${this.iframeName}:openErrorModal`,
              data: {
                errorText: `Адрес сервиса должен быть опубликован по https`,
              },
            },
            "*"
          );
          return;
        }
      },
      async openOrderModal() {
        const url = new URL(document.location).origin;
        const url1c = this.auth.url1c.replace("/hs/amocrm/", "");

        if (this.iframe.v2.use && this.tariffFeatures.includes("order-iframe-v2")) {
          this.checkUrl(url1c);

          const backgroundId = createLoadingOverlay(this.iframeName);

          let login = "";
          let password = "";

          const user = this.iframe.v2.users.find((u) => u.id == this.amoUserId);

          if (user) {
            login = user.login;
            password = user.password;
          }

          await this.updateOrderByEntityData();

          window.parent.postMessage(
            {
              event: `${this.iframeName}:open1cIframeV2`,
              data: {
                url: `${url1c}/?N=${login}&P=${password}&mainWindowMode=EmbeddedWorkplace`,
                data: { order: this.order },
              },
            },
            "*"
          );

          removeLoadingOverlay(this.iframeName, backgroundId);
        } else if (this.iframe.use && this.tariffFeatures.includes("order-iframe")) {
          this.checkUrl(url1c);

          const backgroundId = createLoadingOverlay(this.iframeName);

          const login = this.iframe.login;
          const password = this.iframe.password;

          if (!this.isOrderCreated) {
            await this.saveEmpty1cOrder();
          } else {
            await this.update1c();
          }

          const orderRef = this.order.order_ref;

          window.parent.postMessage(
            {
              event: `${this.iframeName}:open1cIframe`,
              data: {
                authUrl: `${url1c}/?N=${login}&P=${password}`,
                orderUrl: `${url1c}/?mainWindowMode=EmbeddedWorkplace&DisableHomePageForms#${orderRef}`,
              },
            },
            "*"
          );

          removeLoadingOverlay(this.iframeName, backgroundId);
        } else {
          window.parent.postMessage(
            {
              event: `${this.iframeName}:openFullScreenModal`,
              url: `${url}/modal`,
            },
            "*"
          );
        }
      },
    },
    components: {
      GnzsContextMenuButton,
    },
  };
</script>

<style lang="scss" module>
  @import "./OrderInfo.scss";
</style>
