<template>
    <button :class="$style.button" @click="onChange">
        <div :class="$style.tooltip__trigger" @mouseleave="hideToolTip" @mouseover="showToolTip"></div>
        <div hidden id="tooltip" :class="$style.tooltip"></div>
        <svg>
            <use v-if="value==1" :xlink:href="getIcon(value)"></use>
            <use v-else-if="value==2" :xlink:href="getIcon(value)"></use>
            <use v-else xlink:href="#gnzs-icon__arrow-from-to"></use>
        </svg>
    </button>
</template>

<script>
import $ from 'jquery';

export default {
    data(){
        return {
            timeoutID: null
        }
    },
    props: {
        value: {
            type: Number,
            default: 0,
        },
        tooltips: {
            type: Object,
            default: () => ({
                from: 'из',
                to: 'в',
                fromTo: 'из в'
            })
        },
        reverse: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        tooltip(){
            switch (this.$props.value){
                case 1: return this.$props.tooltips.from;
                case 2: return this.$props.tooltips.to;
                default: return this.$props.tooltips.fromTo;
            }
        }
    }, 
    methods: {
        getIcon(value){
            if (value == 1) return this.$props.reverse ? '#gnzs-icon__arrow-to' : '#gnzs-icon__arrow-from'
            if (value == 2) return this.$props.reverse ? '#gnzs-icon__arrow-from' : '#gnzs-icon__arrow-to'
            return '#gnzs-icon__arrow-from-to'
        },
        hideToolTip(event){
            clearTimeout(this.timeoutID)
            $(event.target).children().remove();
        },
        showToolTip(event){
            let element = event.target
            if ($(element).attr('id') == 'tooltip') return
            if ($(element).children().length) return

            this.timeoutID = setTimeout(() => {
                let tooltip = $('#tooltip').clone()
                tooltip.attr('hidden', false);
                tooltip.html(this.tooltip)
                $(element).append(tooltip);
            }, 1000)
        },
        onChange(){
            switch (this.$props.value){
                case 0: this.$emit('input', 1); break;
                case 1: this.$emit('input', 2); break;
                case 2: this.$emit('input', 0); break;
                default: this.$emit('input',0); break;
            }
        }
    },
}
</script>

<style lang="scss" module>
@import "./style.scss";
</style>