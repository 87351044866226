/**
 * @author Ilya Orishin, 2022
 * 
 * Созадние модальных окон
 */
import Vue from 'vue'
import $ from 'jquery'
import Modal from '@/gnzs-controls/Modal'
import { uuidv4 } from '../../utilities/order'

/**
 * Рендер модального окна
 * 
 * @param {Object} props 
 */
const renderModal = (props) => {
    const selector = `gnzs-ms-modal-${uuidv4()}`
    let element = `<div id="${selector}"></div>`
    $('body').append($(element))

    new Vue({
        render: h => h(Modal, {props}),
    }).$mount(`#${selector}`);
}

/**
 * Рендер окна подтверждения
 * 
 * @param {Object} data 
 * {
 *  noCloseOnDecline - не закрывать окно на кнопку отмены
 *  noCloseOnAccept - не закрывать окно на кнопку принятия
 *  noOverlayEvent - не закрывать окно по клику вне области
 *  innerHTML - текст внутри окна, есть поддержка HTML
 *  text - Заголовок в формате h2
 *  width - ширина окна
 *  acceptText - текст на кнопку принятия
 *  declineText - текст на кнопку принятия
 *  onAccept - колбэк на accept
 *  onDecline - колюэк на decline
 * }
 */
export const openConfirmModal = (data) => {
    const disabledProps = ['type', 'loaded', 'closable'];
    let props = Object.fromEntries((Object.entries(data)).filter(([key]) => !disabledProps.includes(key)));
    props.type = 'confirm'

    renderModal(props)
}

/**
 * Рендер информационного окна
 * 
 * @param {Object} data 
 * {
 *  innerHTML - текст внутри окна, есть поддержка HTML
 *  width - ширина окна
 *  closable - нужно ли отображать иконку закрытия
 * }
 */
export const openInfoModal = (data) => {
    const allowedProps = ['innerHTML', 'header', 'width', 'closable', 'fixHeight'];
    const props = Object.fromEntries(Object.entries(data).filter(([key]) => allowedProps.includes(key)));

    renderModal(props)
}