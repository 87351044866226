<template>
  <GnzsSection
    :disabled="!tariffFeatures.includes('directories-in-dropdown')"
    :disabledText="disabledSectionText"
    header="Синхронизация справочников">
    <template v-slot:description> Для работы справочников необходима доработка со стороны 1С </template>
    <div :class="w.table">
      <div v-if="cardsFields.length" :class="w.tableHeader">
        <div>Тип сущности</div>
        <div><span>Поле в </span><span :class="w.amocrmTitle">amoCRM</span></div>
        <div>Справочник</div>
        <div>Обязательное</div>
        <div>Мультисписок</div>
      </div>

      <div :class="w.tableRow" v-for="(param, index) in cardsFields" :key="index">
        <div :class="w.w200">
          <GnzsDropdown
            :value="param.amoType"
            :items="cardItems"
            :useEmptyItem="false"
            @input="setCardsFieldsDirectoryCfs({ index, amoType: $event })" />
        </div>
        <div :class="w.w200">
          <GnzsDropdown
            :value="param.amoCfId"
            :items="cfs(param.amoType)"
            :disabled="!param.amoType"
            :isSearchable="cfs(param.amoType).length > 10"
            @input="onSetCfId({ index, amoCfId: $event })"
            :useEmptyItem="false" />
        </div>
        <div :class="w.w200">
          <GnzsDropdown
            :value="{ code: param.directory_code, type: param.directory_type }"
            :items="directories"
            @input="setCardsFieldsDirectoryCfs({ index, directory_code: $event.code, directory_type: $event.type })"
            :useEmptyItem="false" />
        </div>
        <div :class="[w.cellRequired, w.w105]">
          <div :class="[w.cellInner, w.checkboxRequireContainer]">
            <GnzsCheckbox :value="param.required" @input="setCardsFieldsDirectoryCfs({ index, required: $event })" />
          </div>
        </div>
        <div :class="[w.cellRequired, w.w105]">
          <div :class="[w.cellInner, w.checkboxRequireContainer]">
            <GnzsCheckbox :value="param.multiple" @input="setCardsFieldsDirectoryCfs({ index, multiple: $event })" />
          </div>
        </div>
        <div>
          <GnzsButton type="remove" @click="removeCardsFieldsDirectoryItem(index)" />
        </div>
      </div>
    </div>
    <GnzsButton type="append" @click="addCardsFieldsDirectoryItem" :class="[w.mt15, w.appendParamsButton]"
      >Добавить поле</GnzsButton
    >
  </GnzsSection>
</template>
<script>
  import { mapActions, mapGetters } from "vuex";

  import GnzsSection from "@/gnzs-controls/Section";
  import GnzsCheckbox from "@/gnzs-controls/Checkbox";
  import GnzsDropdown from "@/gnzs-controls/Dropdown";
  import GnzsButton from "@/gnzs-controls/Button";

  export default {
    computed: {
      ...mapGetters({
        directories: "additionalTypes/dropDownItems",
        customFieldsByTypes: "amo/customFieldsByTypes",
        customFieldsByIds: "amo/customFieldsByIds",
        cardItems: "localSettings/cardItems",
        cardsFields: "settings/cardsFields",
        tariffFeatures: "features/getFeatures",
        disabledSectionText: "localSettings/disabledSectionText",
      }),
    },
    components: {
      GnzsSection,
      GnzsCheckbox,
      GnzsDropdown,
      GnzsButton,
    },
    methods: {
      ...mapActions({
        setDefaultsField: "settings/setDefaultsField",
        setCardsFieldsDirectoryCfs: "settings/setCardsFieldsDirectoryCfs",
        addCardsFieldsDirectoryItem: "settings/addCardsFieldsDirectoryItem",
        removeCardsFieldsDirectoryItem: "settings/removeCardsFieldsDirectoryItem",
      }),
      onSetCfId({ index, amoCfId }) {
        const amoType = this.cardsFields[index].amoType;
        const amoCfType = Object.values(this.customFieldsByIds[amoType]).find((cf) => cf.id == amoCfId)?.field_type;
        this.setCardsFieldsDirectoryCfs({ index, amoCfId, amoCfType });
      },
      cfs(type) {
        return this.customFieldsByTypes[type]?.["text"] ? this.customFieldsByTypes[type]["text"] : [];
      },
    },
  };
</script>

<style lang="scss" module="w">
  @import "@/assets/scss/Settings/style.scss";
</style>
