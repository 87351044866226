/**
 * @author Ilya Orishin. 2022
 * 
 * Валидация url сервиса 1С
 */

/*
 Список валидаторов. Валидаторы состоят из функции валидации (validate) и текста ошибки
*/
const validators = [
    {
        validate: (url) => (/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/i).test(url), // eslint-disable-line no-useless-escape
        errorText: 'Адрес сервиса содержит недопустимые символы'
    },
    {
        validate: (url) => url.endsWith('hs/amocrm/'),
        errorText: 'Адрес сервиса должен оканчиваться на hs/amocrm/'
    },
    {
        validate: (url) => {
            const [,,baseUrl] = url.split('/')
            return !url.includes('localhost') && !(/^10\.10\.\d+.\d+|192\.168\.\d+.\d+|127\.\d+\.\d+.\d+/i).test(baseUrl)
        },
        errorText: 'Адрес сервиса содержит недопустимый хост'
    },
];

/**
 * url -> string, проходимся по списку валидаторов и валидируем email
 * Метод some остановит выполнение, как только нарвется на ложный результат от валидатора
 * 
 * @param {string} url - URL сервиса 1С
 * @returns {Object} res - {valid, error}
*/
export default function (url){
    const res = {valid: true, error: ''}
    validators.some(validator => {
        let valid = validator.validate(url)
        if (!valid){
            res.valid = false
            res.error = validator.errorText
        }
        return !valid
    })
    return res
}