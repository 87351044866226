import { updateCustomer } from "@/api/1c-api/customers"
import { getEntityById } from "@/api/amocrm-api/entities"
import { setContragentData } from "@/utilities/contragents"
import * as orderUtil from '@/utilities/order';

const state = () => ({
    isLoading: true,
    errorText: ''
})

const getters = {
    isLoading: state => state.isLoading,
    errorText: state => state.errorText
}

const actions = {
    async update({commit, rootGetters}, {id, type}){
        commit('setLoading', true)
        const amoCfsByIds = rootGetters['amo/customFieldsByIds'];
        const users = rootGetters['accountData'].amoUsers
        const groups = Object.values(rootGetters['accountData'].amoUserGroups)
        const currentUser = rootGetters['amoUser']
        const amoTimezone = rootGetters['accountData'].amoTimezone
        const settings = rootGetters['settings/getDocflowSettings'];
        const features = rootGetters['features/getFeatures']
        const data = {
            current_user_id: `${currentUser.id}`,
            current_user_name: `${currentUser.name}`,
            current_user_phone: `${currentUser.phone}`,
            current_user_emall: `${currentUser.email}`,
            
            company_id: "",
            company_short_name: "",
            company_custom_fields: [],
            bank_bik: "",
            bank_ks: "",
            bank_name: "",
            company_account: "",
            company_inn: "",
            company_kpp: "",
            company_name: "",
            company_ogrn: "",
            company_phone: "",
            company_email: "",
            company_phones: [],
            company_emails: [],
            company_physical_address: "",
            company_postal_address: "",
            company_registered_address: "",
            company_tax_number: "",
            company_1c_code: "",
            company_manager_id: "",
            company_manager_name: "",
            company_manager_email: "",
            company_manager_group_id: 0,
            company_manager_group_name: "",

            contacts: [],

            contact_id: "",
            contact_short_name: "",
            contact_name: "",
            contact_phone: "",
            contact_email: "",
            contact_phones: "",
            contact_emails: [],
            contact_address: [],
            contact_responsible_user_id: "",
            contact_custom_fields: [],
            contact_1c_code: '',
        }

        if (type == 1){
            try {
                const contact = await getEntityById({entitiesType: 'contacts', id})
                setContragentData({
                    amoTimezone,
                    type,
                    entity: contact,
                    orderFieldsSettings: settings.orderFields,
                    amoCfs: amoCfsByIds,
                    users,
                    groups,
                    features,
                    data
                })
            } catch (e){
                console.debug('Ошибка записи данных контакта', {e})
            }
        } else if (type == 3){
            try {
                const company = await getEntityById({entitiesType: 'companies', id})

                const isCompanyContactsRequired = settings.defaults.linkedContactsType.includes(0)

                let contacts = []
                
                if (rootGetters['features/getFeatures'].includes('contacts-amo-to-1с') && isCompanyContactsRequired) {

                    const ids = company._embedded.contacts.map(c => c.id).slice(0, 10)
        
                    if (ids?.length) {
                        contacts = await orderUtil.getContactsData({ids, rootGetters})
                    }
                }

                setContragentData({
                    amoTimezone,
                    type,
                    entity: company,
                    orderFieldsSettings: settings.orderFields,
                    amoCfs: amoCfsByIds,
                    users,
                    groups,
                    contacts,
                    features,
                    data
                })
            } catch (e){
                console.debug('Ошибка записи данных компании', {e})
            }
        }

        try {
            const response = await updateCustomer(data)
            if (response.data?.error){
                commit('setError', response.data.error)
            }
        } catch (e){
            console.debug('Ошибка записи данных контрагента', {e})
        }

        commit('setLoading', false)
    }
}

const mutations = {
    setError: (state, text) => state.errorText = text,
    setLoading: (state, value) => state.isLoading = value
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}

