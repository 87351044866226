<template>
  <div :class="$style.container" ref="close">
    <div :class="$style.inputWrapper">
      <div :class="$style.activeOptions" @click="activeOptions"></div>
      <div :class="$style.inputWrapper__option">
        <div :class="$style.iconSearch">
          <svg viewBox="0 0 16 15" id="common--filter-search" xmlns="http://www.w3.org/2000/svg">
            <path id="diSearch" class="dicls-1"
                  d="M818.493 273.994a6.5 6.5 0 116.5-6.5 6.5 6.5 0 01-6.5 6.5zm0-11.007a4.506 4.506 0 104.5 4.506 4.5 4.5 0 00-4.5-4.506zm9.516 12.124l-.893.9-3.108-2.633 1.381-1.388z"
                  transform="translate(-812 -261)"></path>
          </svg>
        </div>
        <div v-if="filterItems.length !== 0" :class="$style.filterItems">
          <FilterOption @click="onOptionItemClose" v-for="(item, index) in filterItems" :key="index" :item="item"/>
        </div>
        <div v-else>
          <span :class="$style.searchNone">Фильтр</span>
        </div>
      </div>
      <div :class="$style.inputWrapper__reload" @click="onReload()">
        <svg><use xlink:href="#gnsz-1c-icon__reload"></use></svg>
      </div>
    </div>

    <div v-show="active" :class="$style.optionsContainer">
      <div :class="[$style.optionsBody, 'custom-scroll']" ref="optionsBody">

        <div :class="$style.options">
          <slot></slot>
        </div>

      </div>

      <div :class="$style.optionsActions" v-show="isChanged">
        <GnzsButton type="primary" @click="onOptionsAccept">Применить</GnzsButton>
        <GnzsButton type="cancel" @click="onOptionsCancel">Сбросить</GnzsButton>
      </div>
    </div>
  </div>
</template>

<script>
import GnzsButton from '../Button';
import FilterOption from './filterOption';

export default {
  data() {
    return {
      active: false
    }
  },
  props: {
    isChanged: {
      type: Boolean,
      default: false,
    },
    filterItems: {
      type: Array, // Пример массива: [{title: '', key: '', value: ''}]
      default: () => [],
      required: false,
    }
  },
  mounted() {
    const close = this.$refs.close
    let $this = this
    // const def = this.filterItems.length
    window.addEventListener('click', function (e) {
      if (!close.contains(e.target)) {
        if($this.active){
          $this.active = false
          $this.$emit('filter-accept');
        }
      }
    });
  },
  methods: {
    activeOptions(){
      this.active = !this.active
    },
    onOptionsAccept() {
      this.$emit('filter-accept');
      this.active = false
    },
    onOptionsCancel() {
      this.$emit('filter-cancel');
      this.active = false
    },
    onOptionItemClose(item){
      this.$emit('filter-item-close', item);
    },
    onReload(){
      this.$emit('filter-reload');
      this.active = false
    }
  },
  components: {
    GnzsButton,
    FilterOption
  }
}
</script>


<style lang="scss" module>
@import './style.scss';
</style>