import { render, staticRenderFns } from "./TabPreload.vue?vue&type=template&id=db7e4d92"
var script = {}
import style0 from "./TabPreload.vue?vue&type=style&index=0&id=db7e4d92&prod&lang=scss&module=true"
import style1 from "./TabPreload.vue?vue&type=style&index=1&id=db7e4d92&prod&lang=scss&module=info"
import style2 from "./TabPreload.vue?vue&type=style&index=2&id=db7e4d92&prod&lang=scss&module=crdoc"
import style3 from "./TabPreload.vue?vue&type=style&index=3&id=db7e4d92&prod&lang=scss&module=doc"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)
this["info"] = (style1.locals || style1)
this["crdoc"] = (style2.locals || style2)
this["doc"] = (style3.locals || style3)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports