export const isIncludeDirectories = (docFlowSettings) =>
  docFlowSettings.cardsFields?.length ||
  !!isDirectoriesExists(docFlowSettings.orderFields.order_custom_fields) ||
  !!isDirectoriesExists(docFlowSettings.contractFields.contract_custom_fields);

export const isDirectoriesExists = (value) =>
  !!value.filter((ocf) => ["Справочник", "Перечисление"].includes(ocf.type))?.length;

export const deepClone = (value) => {
  return JSON.parse(JSON.stringify(value));
};

export const getGroups = (groups) => {
  let result = [];

  for (let gKey in groups) {
    result.push({
      id: groups[gKey].id,
      name: groups[gKey].name,
      isChecked: false,
      percent: 0,
      assignPercentType: 1,
    });
  }

  return result;
};

export const getActualGroupsData = (groups, amoGroups) => {
  amoGroups.forEach((amoGroup) => {
    const sGroup = groups.find((group) => group.id == amoGroup.id);

    if (sGroup) {
      amoGroup.isChecked = sGroup.isChecked;
      amoGroup.percent = sGroup.percent;
      amoGroup.assignPercentType = sGroup.assignPercentType;
    }
  });

  return amoGroups;
};

export const getUsers = (users, avatars, baseDomain) => {
  let result = [];

  for (let mKey in users) {
    if (users[mKey].is_free) continue;

    const avatar = avatars.find((user) => user.id == users[mKey].id)?.avatar || "";

    result.push({
      id: users[mKey].id,
      name: users[mKey].name,
      groupId: users[mKey].group_id,
      isChecked: false,
      percent: 0,
      avatar: avatar.length ? baseDomain + avatar : "",
    });
  }

  return result;
};

export const getActualUsersData = (users, amoUsers) => {
  amoUsers.forEach((amoUser) => {
    const sUser = users.find((user) => user.id == amoUser.id);

    if (sUser) {
      amoUser.isChecked = sUser.isChecked;
      amoUser.percent = sUser.percent;
    }
  });

  return amoUsers;
};
