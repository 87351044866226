<template>
  <div :class="$style.header">
    <div :class="$style.headerInner">
      <div :class="$style.title">
        {{ modalTitle }} от
        <DatePicker
          :format="'DD.MM.YYYY'"
          :placeholder="currentDate"
          :class="$style.inputDate"
          :value="orderDate"
          @change="updateOrderDate"
          @mouseover="showTooltip"
          @mouseleave="hideTooltip"
          type="date" />
        <div v-if="showToolTip" :class="$style.tooltip">Изменить дату счёта</div>
      </div>
      <div :class="$style.buttonsWrapper">
        <div :class="$style.buttonsContainer" v-if="tabMode == 0 || tabMode == 2 || tabMode == 4">
          <GnzsButton type="cancel" :class="$style.upcover" @click="onCancelClick">Отмена</GnzsButton>

          <GnzsButton
            v-if="!hasOrder1c"
            :class="$style.upcover"
            :disabled="!isOrderChanged || isDisabledStatus"
            :loading="isOrderSaving"
            @click="beforeSave()"
            >Сохранить</GnzsButton
          >

          <GnzsButton
            type="primary"
            :class="$style.upcover"
            :disabled="disableSave1c || isDisabledStatus"
            :loading="isOrderSaving1c"
            @click="beforeSave('1c')"
            >Сохранить и отправить в 1С</GnzsButton
          >
        </div>
        <div v-else-if="tabMode == 1">
          <GnzsButton type="cancel" @click="onCancelClick">Отмена</GnzsButton>
          <GnzsButton
            type="primary"
            :disabled="hasId || !activeRId || !hasOrder1c"
            :loading="isRealizationSaving"
            @click="spendItem"
            >Провести реализацию</GnzsButton
          >
        </div>
        <div v-else-if="tabMode == 3">
          <GnzsButton type="cancel" @click="onCancelClick">Отмена</GnzsButton>
          <GnzsButton
            type="primary"
            :disabled="hasId || !activeIId || !hasOrder1c"
            :loading="isInvoiceSaving"
            @click="saveInvoice"
            >Сохранить счёт</GnzsButton
          >
        </div>
      </div>
    </div>
    <GnzsCheckbox
      v-if="(tariffFeatures.includes('new-date-and-number-on-order-update') && tabMode == 0) || tabMode == 2"
      :value="updateOrderNumber"
      :class="$style.updateSwitcher"
      @input="setUpdateOrderNumber"
      label="Обновить номер счета"></GnzsCheckbox>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";
  import moment from "moment";
  import { closeOrderModalWithConfirm } from "@/utilities/order";
  import GnzsButton from "@/gnzs-controls/Button";
  import GnzsCheckbox from "@/gnzs-controls/Checkbox";
  import DatePicker from "vue2-datepicker";
  import "@/assets/datepicker/ru";
  import "@/assets/datepicker/datepicker.css";
  import { showModal } from "@/utilities/order";

  export default {
    data() {
      return {
        showToolTip: false,
        timeoutID: null,
      };
    },
    computed: {
      ...mapGetters({
        isOrderChanged: "order/isChanged",
        modalTitle: "order/modalTitle",
        orderDate: "order/date",
        hasOrder1c: "order/hasOrder1c",
        isOrderSaving: "order/isSaving",
        isOrderSaving1c: "order/isSaving1c",
        isRealizationSaving: "realizations/isSaving",
        isInvoiceSaving: "invoices/isSaving",
        updateOrderNumber: "order/updateOrderNumber",
        defaults: "settings/defaults",
        agreementId: "order/agreementId",
        tariffFeatures: "features/getFeatures",
        confirmData: "localSettings/confirmCloseOrder",
        tabMode: "order/tabMode",
        hasId: "realizations/hasId",
        activeRId: "realizations/active",
        activeIId: "invoices/active",
        iframeName: "iframe/iframeName",
        isDisabledStatus: "order/isDisabledStatus",
        errorMsg: "localSettings/errorWorkOrderOnThisStatus",
      }),
      disableSave1c() {
        if (!this.hasOrder1c) return false;
        return !this.isOrderChanged;
      },
      currentDate() {
        return moment().format("DD.MM.YYYY");
      },
    },
    methods: {
      ...mapActions({
        closeModal: "closeModal",
        saveOrder: "order/save",
        saveOrder1c: "order/save1c",
        setCheckedAll: "order/setCheckedAll",
        spendItem: "realizations/spendItem",
        saveInvoice: "invoices/spendItem",
        updateDate: "order/updateDate",
      }),
      async beforeSave(mode) {
        if (this.isDisabledStatus) {
          showModal("Ошибка сохранения заказа", this.errorMsg);
        } else {
          if (mode == "1c") {
            await this.saveOrder1c();
          } else {
            await this.saveOrder();
          }
        }
      },
      updateOrderDate(value) {
        this.updateDate(value);
      },
      setUpdateOrderNumber(value) {
        this.$store.dispatch("order/setValue", { key: "new_date_and_number_on_order_update", value });
      },
      onCancelClick() {
        closeOrderModalWithConfirm(this.isOrderChanged, this.iframeName);
        this.setCheckedAll(false);
      },
      hideTooltip() {
        clearTimeout(this.timeoutID);
        this.showToolTip = false;
      },
      showTooltip() {
        this.timeoutID = setTimeout(() => {
          this.showToolTip = true;
        }, 1000);
      },
    },
    components: {
      GnzsButton,
      GnzsCheckbox,
      DatePicker,
    },
  };
</script>

<style lang="scss" module>
  @import "./Header.scss";
</style>
