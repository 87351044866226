import Vue from "vue";
import Vuex from "vuex";
/* eslint-disable no-unused-vars */
import { verifyAndDecodeToken, getAccountInfo, getDevJwtToken, getJwtToOtherWidget } from "@/api/gnzs-core-api/";
import { GNZS_WIDGET_ID, GNZS_DEV_ACCOUNT_ID, API_TARIFF_ID, PARTNERS } from "@/store/constants.js";
import { getGroups, getUsers } from "@/utilities/store/helper";
import setEvents from "@/events";

// modules
import iframe from "@/store/modules/iframe";
import settings from "@/store/modules/settings";
import amo from "@/store/modules/amo";
import localSettings from "@/store/modules/localSettings";
import organizations from "@/store/modules/organizations";
import pricetypes from "@/store/modules/priceTypes";
import stocks from "@/store/modules/stocks";
import templates from "@/store/modules/templates";
import order from "@/store/modules/order";
import documents from "@/store/modules/documents";
import products from "@/store/modules/products";
import catalogs from "@/store/modules/catalogs";
import features from "@/store/modules/features";
import realization from "@/store/modules/realization";
import contracts from "@/store/modules/contracts";
import agreements from "@/store/modules/agreements";
import provisions from "@/store/modules/provisions";
import partners from "@/store/modules/partners";
import realizations from "@/store/modules/realizations";
import invoices from "@/store/modules/invoices";
import additionalTypes from "@/store/modules/additionalTypes";
import table from "@/store/modules/table";
import tableR from "@/store/modules/tableR";
import signatories from "./modules/signatories";
import journal from "./modules/journal";
import partnersSolutions from "./modules/partnersSolutions";
import contragents from "./modules/contragents";
import cancellationReasons from "./modules/cancellationReaspons";

// localizations
import RU from "@/localizations/ru";
import EN from "@/localizations/en";
import ES from "@/localizations/es";
import PT from "@/localizations/pt";
import { load1ctypesItems } from "@/api/1c-api/additionalDirectories";

Vue.use(Vuex);

const state = () => ({
  isLoad: true,
  accountData: null,
  widgetInfo: null,
  widgetStatus: null,
  decodeToken: null,
  token: null,
  lang: null,
  amoAccountId: null,
  amoUserId: null,
  amoUser: {
    id: "",
    name: "",
    phone: "",
    email: "",
  },
  isAdmin: false,
  groups: [],
  users: [],
  widgetTokens: {},
  tokenError: null,
  isAmoAuthorized: true,
  isSettingsInitialized: false,
  isCardTabLoaded: false,
  isOrderModalLoaded: false,
  isOrderModalOpened: false,
  isOrderLinkLoaded: false,
  isUpdateModalLoaded: false,
  isUpdateContragentModalLoaded: false,
  orderLinkError: "",
  widgetId: GNZS_WIDGET_ID,
});

const getters = {
  isDevMode: () => process.env.NODE_ENV === "development",
  widgetTokens: (state) => state.widgetTokens,
  token: (state) => state.token,
  isLoad: (state) => state.isLoad,
  groups: (state) => state.groups,
  users: (state) => state.users,
  accountData: (state) => state.accountData,
  amoAccountId: (state) => state.amoAccountId,
  widgetInfo: (state) => state.widgetInfo,
  widgetStatus: (state) => state.widgetStatus,
  lang: (state) => state.lang,
  avatars: (state) => {
    const avatars = {};
    state.users.forEach((user) => {
      avatars[user.id] = user.avatar;
    });
    return avatars;
  },
  isInsideAmo: (state) => (state.decodeToken?.iss && state.decodeToken.iss.includes(".amocrm.") ? true : false),

  isAmoAuthorized: (state) => state.isAmoAuthorized,

  localization: (state) => {
    if (state.lang === "ru") return RU;
    if (state.lang === "en") return EN;
    if (state.lang === "es") return ES;
    if (state.lang === "pt") return PT;
    return RU;
  },

  tokenError: (state) => state.tokenError,

  isSettingsInitialized: (state) => state.isSettingsInitialized,

  isMsAuthorizied: (state, getters, rootState, rootGetters) => {
    return rootGetters["users/items"]?.length;
  },

  amoUserId: (state) => state.amoUserId,
  amoUser: (state) => state.amoUser,
  isAdmin: (state) => state.isAdmin,

  orderLinkError: (state) => state.orderLinkError,

  isCardTabLoaded: (state) => state.isCardTabLoaded,
  isOrderModalLoaded: (state) => state.isOrderModalLoaded,
  isOpened: (state) => state.isOrderModalOpened,
  isOrderLinkLoaded: (state) => state.isOrderLinkLoaded,
  isCopyModalLoaded: (state) => state.isCopyModalLoaded,
  isServiceTestLoaded: (state) => state.isServiceTestLoaded,
  isUpdateModalLoaded: (state) => state.isUpdateModalLoaded,
  isUpdateContragentModalLoaded: (state) => state.isUpdateContragentModalLoaded,
  widgetId: (state) => state.widgetId,

  partnerName: (state, getters) => getters.partnerData.name,
  partnerData: (state) => PARTNERS.find((p) => p.id == state.widgetId),
};

const actions = {
  async iFrameInit({ commit, getters, dispatch }) {
    let url = new URL(location.href);
    let token = url.searchParams.get("token");
    let lang = url.searchParams.get("lang") || "ru";
    let isAdmin = url.searchParams.get("is-admin");
    let iframeName = url.searchParams.get("iframe-name");
    let parentIframeName = url.searchParams.get("parent-iframe-name");
    let integrationCode = url.searchParams.get("integration-code");
    let widgetId = url.searchParams.get("widget-id");
    let userId = url.searchParams.get("user-id");

    if (widgetId) commit("setWidgetId", widgetId);

    commit("setAdmin", isAdmin);

    try {
      if (!token?.length && getters.isDevMode) {
        token = await getDevJwtToken(process.env.VUE_APP_DEVELOPER_API_TOKEN, getters.widgetId, GNZS_DEV_ACCOUNT_ID); // Указываем id аккаунта, на котором тестируем и разрабатываем
        console.debug("DEVELOPMENT MODE", { token });
      }

      // Получить из get параметра gosklad и отослать в verify X-External-Integration-Code

      let decodeToken = await verifyAndDecodeToken(getters.widgetId, token, integrationCode);
      let accountData = await getAccountInfo(getters.widgetId, decodeToken.account_id, token, integrationCode);

      let { amoUserGroups, amoUsers } = accountData;

      let baseDomain = "https://" + accountData.amoSubdomain;
      if (accountData.amoTopLevelDomain == "ru") {
        baseDomain += ".amocrm.ru";
      } else {
        baseDomain += ".kommo.com";
      }

      const userAvatars = accountData.amoObjectAmocrm._users_avatars;

      let groups = getGroups(amoUserGroups);
      let users = getUsers(amoUsers, userAvatars, baseDomain);

      if (userId) {
        commit("setCurrentUserId", userId);
        if (userId != 0) {
          let currentUser = amoUsers.find((u) => u.id == userId);
          commit("setAmoUser", {
            id: currentUser.id,
            name: currentUser.name,
            phone: currentUser.phone_number,
            email: currentUser.login,
          });
        } else {
          commit("setAmoUser", {
            id: 0,
            name: "Панель администратора генезис",
            phone: "",
            email: "",
          });
        }
      }

      dispatch("features/setData", decodeToken);

      commit("setAccountData", accountData);
      commit("setDecodeToken", decodeToken);
      commit("setWidgetStatus", decodeToken.gnzsWidgetStatus);
      commit("setToken", token);
      commit("setLang", lang);
      commit("setAmoAccountId");
      commit("setGroups", groups);
      commit("setUsers", users);

      setEvents(iframeName);
      dispatch("iframe/setIframeName", iframeName);
      dispatch("iframe/setParentIframeName", parentIframeName);
      dispatch("iframe/resizeWindow");
      commit("isLoad", false);
    } catch (error) {
      if (error?.response?.status === 401) {
        commit("setValue", { key: "isAmoAuthorized", value: false });
      }
      if (error?.response?.status === 403) {
        console.debug(error);
        commit("setValue", { key: "isAmoAuthorized", value: false });
        commit("setTokenError", error.response.data.message);
      }
    }
  },

  async loadAddElementModal({ dispatch }, payload) {
    await dispatch("settings/load");
    await dispatch("additionalTypes/load");
    await dispatch("additionalTypes/setItem2add", payload);
    await dispatch("additionalTypes/onModalOpened");
  },

  async loadEditElementModal({ commit, dispatch, rootGetters }, { code, type, id }) {
    await dispatch("settings/load");
    await dispatch("additionalTypes/load");
    let el = null;
    try {
      const resp = await load1ctypesItems(rootGetters["order/entity"], { code, type, id });
      el = resp.data?.result?.[0];
      commit("additionalTypes/pushItem", el);
      await dispatch("additionalTypes/setItem2edit", el);
    } catch (e) {
      commit("additionalTypes/setError", "Ошибка загрузки элемента справочника");
      console.debug("Ошибка загрузки элемента справочника", { e });
    }
  },

  async loadAdvancedSettings({ commit, dispatch, getters }) {
    let features = getters["features/getFeatures"];

    await dispatch("amo/load");
    await dispatch("templates/load");
    await dispatch("settings/load");
    await dispatch("pricetypes/load");
    await dispatch("organizations/load");
    await dispatch("stocks/load");

    await dispatch("journal/load");

    if (features.includes("agreements-1с-to-amo")) {
      await dispatch("agreements/loadAllItems");
    }

    if (features.includes("provisions")) {
      await dispatch("provisions/load");
    }

    await dispatch("additionalTypes/load");

    const typecodes = getters["settings/CFTypes"];

    for (const item of typecodes) {
      await dispatch("additionalTypes/load1ctypeItems", item);
    }

    // сохраняем данные о системных полях каталога Товары в настройки
    const catalogSfs = getters["amo/productCatalogSystemFields"];
    dispatch("settings/setProductCatalog", catalogSfs);

    commit("setValue", { key: "isSettingsInitialized", value: true });
  },

  async loadCopyModal({ dispatch, commit }) {
    await dispatch("settings/load");
    await dispatch("order/load");

    commit("setValue", { key: "isCopyModalLoaded", value: true });

    await dispatch("order/copyOrder");
  },

  async loadOrderUpdateModal({ dispatch, commit, rootGetters }) {
    await dispatch("amo/load");
    await dispatch("settings/load");

    commit("setValue", { key: "isUpdateModalLoaded", value: true });

    const tariffId = rootGetters["features/getTariffId"];

    if (tariffId == API_TARIFF_ID) {
      commit("contragents/setError", "Функция недоступна, так как вы используете тариф API");
      commit("contragents/setLoading", false);
      return;
    }

    await dispatch("order/load");
    await dispatch("order/updateOrderByEntityData");
    await dispatch("order/updateOrder1c");
  },

  async loadUpdateContragentModal({ dispatch, commit, rootGetters }, data) {
    await dispatch("amo/load");
    await dispatch("settings/load");

    commit("setValue", { key: "isUpdateContragentModalLoaded", value: true });

    const tariffId = rootGetters["features/getTariffId"];

    if (tariffId == API_TARIFF_ID) {
      commit("contragents/setError", "Функция недоступна, так как вы используете тариф API");
      commit("contragents/setLoading", false);
      return;
    }

    await dispatch("contragents/update", data);
  },

  async loadServiceTest({ dispatch, commit }) {
    await dispatch("settings/load");

    commit("setValue", { key: "isServiceTestLoaded", value: true });

    await dispatch("settings/test");
  },

  async loadCardTab({ dispatch, commit, rootGetters }) {
    await dispatch("amo/load");
    await dispatch("templates/load");
    await dispatch("settings/load");
    await dispatch("documents/load");
    await dispatch("organizations/load");
    await dispatch("stocks/load");
    await dispatch("order/load");
    await dispatch("order/updateOrderByEntityData");

    const features = rootGetters["features/getFeatures"];
    const isSignatoriesEnabled = rootGetters["settings/defaults"].isSignatoriesEnabled;

    if (isSignatoriesEnabled && features.includes("document-signer")) {
      await dispatch("signatories/load");
    }

    commit("setValue", { key: "isCardTabLoaded", value: true });
  },

  async loadOrderModal({ commit, dispatch, rootGetters }) {
    await dispatch("amo/load");
    await dispatch("settings/load");
    await dispatch("organizations/load");
    await dispatch("stocks/load");
    await dispatch("order/load");

    await dispatch("order/updateOrderByEntityData");
    await dispatch("order/setGeneralDiscountType");

    const isErrorOpenOrder = rootGetters["order/errorOpenOrder"]?.length;

    if (isErrorOpenOrder) {
      commit("setValue", { key: "isOrderModalLoaded", value: true });
      return;
    }

    const features = rootGetters["features/getFeatures"];
    const isProvisionEnabled = rootGetters["settings/defaults"].isProvisionEnabled,
      isContractsEnabled = rootGetters["settings/defaults"].isContractsEnabled,
      isAgreementsEnabled = rootGetters["settings/defaults"].isAgreementsEnabled,
      isPartnersEnabled = rootGetters["settings/defaults"].isPartnersEnabled,
      isPartialRealizationEnabled = rootGetters["settings/defaults"].isPartialRealizationEnabled,
      isInvoicesEnabled = rootGetters["settings/defaults"].isInvoicesEnabled,
      isCancellationPositionsEnabled = rootGetters["settings/defaults"].useCancellationPositions,
      isCancellationReasonsEnabled = rootGetters["settings/defaults"].useCancellationReasons;

    if (isCancellationPositionsEnabled && isCancellationReasonsEnabled) {
      await dispatch("cancellationReasons/load");
    }

    await dispatch("catalogs/load");
    await dispatch("pricetypes/load");
    if (features.includes("partial-realization") && isPartialRealizationEnabled) {
      await dispatch("realizations/loadList");
    }
    if (features.includes("partial-invoices") && isInvoicesEnabled) {
      await dispatch("invoices/loadList");
    }

    if (features.includes("contracts-1с-to-amo") && isContractsEnabled) {
      await dispatch("contracts/load");
      dispatch("order/setDefaultContract");
    }

    if (features.includes("agreements-1с-to-amo") && isAgreementsEnabled) {
      await dispatch("agreements/load");
      dispatch("order/setOwnerAgreement");
    }
    if (features.includes("provisions") && isProvisionEnabled) {
      await dispatch("provisions/load");
    }
    if (features.includes("partners-1c-to-amo") && isPartnersEnabled) {
      await dispatch("partners/load");
    }

    const prodIds = rootGetters["order/products"].map((p) => p.id);
    if (prodIds.length) {
      await dispatch("products/getById", prodIds);
    }

    // if paysCalendarEnabled
    if (rootGetters["amoAccountId"] == 28830832) {
      const widgetToken = await getJwtToOtherWidget(51, rootGetters["token"]);
      commit("setWidgetToken", { id: 51, token: widgetToken.token });
    }

    const typecodes = rootGetters["settings/CFTypes"];

    rootGetters["settings/contractCFTypes"].forEach((ccf) => {
      let finded = typecodes.find((ocf) => ocf.code == ccf.code && ocf.type == ccf.type);
      if (!finded) typecodes.push(ccf);
    });

    if (rootGetters["settings/isDirectoriesExists"]) {
      await dispatch("additionalTypes/load");

      for (const item of typecodes) {
        await dispatch("additionalTypes/load1ctypeItems", item);
      }
    }

    commit("setValue", { key: "isOrderModalLoaded", value: true });
  },

  async loadOrderUdpdateModal({ commit, dispatch, getters }) {
    await dispatch("amo/load");
    await dispatch("settings/load");

    await dispatch("order/updateOrderByEntityData");

    const isErrorOpenOrder = getters["order/errorOpenOrder"]?.length;

    if (isErrorOpenOrder) {
      commit("setValue", { key: "isOrderModalLoaded", value: true });
      return;
    }
    await dispatch("catalogs/load");

    const prodIds = getters["order/products"].map((p) => p.id);
    if (prodIds.length) {
      await dispatch("products/getById", prodIds);
    }

    await dispatch("order/load");

    commit("setValue", { key: "isOrderModalLoaded", value: true });
  },

  setCurrentUserId: ({ commit }, id) => commit("setCurrentUserId", id),
};

const mutations = {
  isLoad: (state, isLoad) => (state.isLoad = isLoad),
  setAccountData: (state, accountData) => (state.accountData = accountData),
  setWidgetInfo: (state, widgetInfo) => (state.widgetInfo = widgetInfo),
  setWidgetStatus: (state, widgetStatus) => (state.widgetStatus = widgetStatus),
  setDecodeToken: (state, decodeToken) => (state.decodeToken = decodeToken),
  setToken: (state, token) => (state.token = token),
  setAmoAccountId: (state) => (state.amoAccountId = state.accountData.id),
  setWidgetId: (state, id) => (state.widgetId = +id),
  setCurrentUserId: (state, id) => (state.amoUserId = id),
  setLang: (state, lang) => (state.lang = lang),
  setGroups: (state, groups) => (state.groups = groups),
  setUsers: (state, users) => (state.users = users),
  setTokenError: (state, error) => (state.tokenError = error),
  setValue: (state, { key, value }) => (state[key] = value),
  setOpened: (state) => (state.isOrderModalOpened = true),
  setAdmin: (state, value) => (state.isAdmin = value === "Y" ? true : false),
  setWidgetToken: (state, { id, token }) => (state.widgetTokens[id] = token),
  setAmoUser: (state, user) => (state.amoUser = user),
};

const config = {
  /* strict: process.env.NODE_ENV !== 'production', */
  modules: {
    // here use modules
    iframe,
    additionalTypes,
    order,
    amo,
    catalogs,
    documents,
    features,
    stocks,
    localSettings,
    organizations,
    pricetypes,
    products,
    settings,
    templates,
    realization,
    contracts,
    agreements,
    provisions,
    partners,
    realizations,
    invoices,
    table,
    tableR,
    signatories,
    journal,
    partnersSolutions,
    contragents,
    cancellationReasons,
  },
  state,
  getters,
  actions,
  mutations,
};

export default new Vuex.Store(config);
